import React, { useState, useEffect } from "react";
import "./navbar.css";
import logo from "../../Assets/Images/BMI_Logo.png";
import arrow from "../../Assets/Images/arrow.svg";
import menu from "../../Assets/Images/Mobile_menu.svg";
import cross from "../../Assets/Images/cross.png";
import Botton from "../Button/Button";
import user from "../../Assets/Images/userIconTwo.svg";
import { Routes, Route, useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import AOS from "aos";
import "aos/dist/aos.css";

const Navbar = (props) => {
  const { text, className, style, name, event, componentPath } = props;

  useEffect(() => {
    AOS.init({
      duration: 200,
    });
    AOS.refresh();
  }, []);

  const [showMobNav, setshowMobNav] = useState(false);

  const IsLoggedIn = useSelector((state) => {
    return state.getLoggedInStatus?.data?.isLoggedIn;
  });

  // navigation
  const navigate = useNavigate();

  const handleHome = () => {
    navigate("/");
  };

  const showMobileMenu = () => {
    setshowMobNav(true);
  };

  const handleLoginButton = () => {
    console.log("Login Btn Clicked");
    setshowMobNav(false)
    navigate("/", {
      state: {
        componentPath: componentPath,
      },
    });
  };

  const navbarItem = [
    "Buy Insurance",
    "Renew Policy",
    "Claims",
    "Support",
    "Become Agent",
    "About Us",
  ];

  const routeMyAccount = () => {
    navigate("/My-Account");
  };

  const handleNavRoute = (menu) => {
    // alert(menu);
    if (menu === "About Us") {
     // navigate("/About-us");
    }
  };

  return (
    <>
      <nav className="nav_container">
        <div className="nav_parent maxWidth ">
          <div className="nav_child">
            <img
              src={logo}
              className="navLogo"
              alt=""
              srcSet=""
              onClick={handleHome}
            />
          </div>
          <div className="nav_mob_menu_toggle" onClick={() => showMobileMenu()}>
            <img src={menu} alt="" srcSet="" />
          </div>
          <div className="nav_child nav_menu nav_menuPc">
            <div className="navItem_text">
              {navbarItem.map((item, index) => (
                <div
                  key={index}
                  className="navItem"
                  id={index}
                  onClick={(e) => handleNavRoute(item)}
                >
                  {item}
                  {index !== 3 && index !== 5 && index !== 6 && (
                    <div className="navItem_arrow">
                      <img src={arrow} alt="" srcSet="" />
                    </div>
                  )}
                </div>
              ))}
            </div>

            {IsLoggedIn ? (
              <button
                className="my-account-btn"
                onClick={(e) => routeMyAccount()}
              >
                <div className="icon-circle-navbar">
                  <img src={user} alt="" srcset="" />
                </div>
                <p>My Account</p>
              </button>
            ) : (
              <Botton
                text="Login"
                className="button nav_btn"
                event={(e) => handleLoginButton(e)}
              />
            )}
          </div>
          {showMobNav && (
            <div className="nav_menuMob" data-aos="fade-left" >
              <div className="nav_menuMob_head">
                <p className="menu__title_nav">Menu</p>
                <img
                  src={cross}
                  onClick={() => setshowMobNav(false)}
                  alt=""
                  srcSet=""
                />
              </div>
              <div className="navItem_text">
                {navbarItem.map((item, index) => (
                  <p className="navItem" id={index} onClick={(e) => handleNavRoute(item)} >
                    {item}
                    {index !== 3 && index !== 5 && index !== 6 && (
                      <div className="navItem_arrow">
                        <img src={arrow} alt="" srcSet="" />
                      </div>
                    )}
                  </p>
                ))}
              </div>
              {IsLoggedIn ? (
              <button
                className="my-account-btn"
                onClick={(e) => routeMyAccount()}
              >
                <div className="icon-circle-navbar">
                  <img src={user} alt="" srcset="" />
                </div>
                <p>My Account</p>
              </button>
            ) : (
              <Botton
                text="Login"
                className="button nav_btn"
                event={(e) => handleLoginButton(e)}
              />
            )}
            </div>
          )}
        </div>
      </nav>
    </>
  );
};

export default Navbar;
