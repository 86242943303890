import {
  zunoQuickQuoteAction,
  zunoFullQuoteAction,
  zunoIssuePolicyAction,
  zunoKycCustomerAction,
  zunoManualKycAction,
  zunoOnlinePaymentAction,
  zunoPdfDownloadAction,
  zunoInsertBodydAction,
} from "../Actions/zunoApiAction";
import { createSlice } from "@reduxjs/toolkit";

export const zunoQuickQuoteReducer = createSlice({
  name: "zunoQuickQuote",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(zunoQuickQuoteAction.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(zunoQuickQuoteAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(zunoQuickQuoteAction.rejected, (state, action) => {
      console.error("Error", action.payload);
      state.isError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    resetZunoQuoteAction: (state, action) => {
      state.data = undefined
    }
  }
});

export const { resetZunoQuoteAction } = zunoQuickQuoteReducer.actions;

export const zunoFullQuoteReducer = createSlice({
  name: "zunoFullQuote",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  reducers: {
    resetzunofullQuote: (state) => {
      state.isLoading = false;
      state.data = null;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(zunoFullQuoteAction.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(zunoFullQuoteAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(zunoFullQuoteAction.rejected, (state, action) => {
      console.error("Error", action.payload);
      state.isError = true;
      state.isLoading = false;
    });
  }
});

export const { resetzunofullQuote } = zunoFullQuoteReducer.actions;

export const zunoIssuePolicyReducer = createSlice({
  name: "zunoIssuePolicy",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(zunoIssuePolicyAction.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(zunoIssuePolicyAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(zunoIssuePolicyAction.rejected, (state, action) => {
      console.error("Error", action.payload);
      state.isError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    resetZunoFullQuoteAction: (state, action) => {
      state.data = undefined
    }
  }
});

export const { resetIssuePolicyAction } = zunoIssuePolicyReducer.actions;

export const zunoKycCustomerReducer = createSlice({
  name: "zunoKycCustomer",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(zunoKycCustomerAction.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(zunoKycCustomerAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(zunoKycCustomerAction.rejected, (state, action) => {
      console.error("Error", action.payload);
      state.isError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    resetZunoAutoKycAction: (state, action) => {
      state.data = undefined
    }
  }
});
export const { resetZunoAutoKycAction } = zunoKycCustomerReducer.actions;

export const zunoManualKycReducer = createSlice({
  name: "zunoManualKyc",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(zunoManualKycAction.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(zunoManualKycAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(zunoManualKycAction.rejected, (state, action) => {
      console.error("Error", action.payload);
      state.isError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    resetManualKycQuoteAction: (state, action) => {
      state.data = undefined
    }
  }
});
export const { resetManualKycQuoteAction } = zunoManualKycReducer.actions;

export const zunoOnlinePaymentReducer = createSlice({
  name: "zunoOnlinePayment",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(zunoOnlinePaymentAction.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(zunoOnlinePaymentAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(zunoOnlinePaymentAction.rejected, (state, action) => {
      console.error("Error", action.payload);
      state.isError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    resetOnlinePaymentQuoteAction: (state, action) => {
      state.data = undefined
    }
  }
});
export const { resetOnlinePaymentQuoteAction } = zunoOnlinePaymentReducer.actions;

export const zunoPdfDownloadReducer = createSlice({
  name: "zunoPdfDownload",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(zunoPdfDownloadAction.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(zunoPdfDownloadAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(zunoPdfDownloadAction.rejected, (state, action) => {
      console.error("Error", action.payload);
      state.isError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    resetPdfDownloadAction: (state, action) => {
      state.data = undefined
    }
  }
});
export const { resetPdfDownloadAction } = zunoPdfDownloadReducer.actions;

export const zunoInsertBodyReducer = createSlice({
  name: "zunoInsertBody",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(zunoInsertBodydAction.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(zunoInsertBodydAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(zunoInsertBodydAction.rejected, (state, action) => {
      console.error("Error", action.payload);
      state.isError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    zunoInsertBodyDownloadAction: (state, action) => {
      state.data = undefined
    }
  }
});
export const { zunoInsertBodyDownloadAction } = zunoInsertBodyReducer.actions;
