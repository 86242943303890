import React, { useState } from "react";
import "./Partners.css";
import Botton from "../Button/Button";
import { color } from "@mui/system";

const Partners = (props) => {
  const { firstLot, secondLot, hideTitle, noPadding, component } = props;
  const [expandPartners, setexpandPartners] = useState(false);

  const handleExpansion = () => {
    setexpandPartners(!expandPartners);
  };

  return (
    <>
      <div
        className={
          noPadding ? "noPadding partners_container" : "partners_container"
        }
      >
        <div className="partners_container_parent maxWidth">
          {component === "viewPlans" || component === "landingPage" ? (
            <h1 className="title partner_title ">
              Who are our <span>Insurers and Partners?</span>
            </h1>
          ) : (
            ""
          )}
          <div className="partner_logo_container">
            {firstLot?.map((img, index) => (
              <div key={index} className="logo_container">
                <div className="logo_container_partner">
                  <img
                    src={img}
                    className={index === 2 ? "testClass" : ""}
                    alt=""
                    srcSet=""
                    id={index === 4 ? "hide-partner-logo" : ""}
                  />
                </div>
              </div>
            ))}
            <div
              className={
                expandPartners === false
                  ? "partner_logo_container_two expand_partner_container"
                  : "partner_logo_container_two expand_partner_container showAllPartners"
              }
              id=""
            >
              {secondLot?.map((img, index) => (
                <div key={index} className="logo_container">
                  <div className="logo_container_partner">
                    <img src={img} alt="" srcSet="" />
                  </div>
                </div>
              ))}
            </div>
            <div className="partner_logo_container_button">
              {expandPartners ? (
                <p
                  className="underline_text blue-txt "
                  onClick={() => handleExpansion()}
                >
                  View Less
                </p>
              ) : (
                <p
                  className="underline_text blue-txt"
                  onClick={() => handleExpansion()}
                >
                  View More
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Partners;
