import React, { useState, useEffect } from "react";
import "./ClientAccount.css";
import Navbar from "../../Navbar/Navbar";
import Footer from "../../Footer/Footer";
import userIcon from "../../../Assets/Images/profile.svg";
import myPolicy from "../../../Assets/Images/myPolicy.svg";
import myRequest from "../../../Assets/Images/myRequest.svg";
import settings from "../../../Assets/Images/settings.svg";
import logout from "../../../Assets/Images/logout.svg";
import InputFields from "../../InputFields/InputFields";
import ProfileCard from "./Profile Section/ProfileCard";
import PolicyCard from "./Policy Section/PolicyCard";
import Botton from "../../Button/Button";
import RequestCard from "./Request Section/RequestCard";
import SettingsCard from "./Settings Section/SettingsCard";
import { isLoggedInAction } from "../../../redux/Reducers/isLoginAuthentication";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ClientAccount = () => {
  // policySection profileSection requestSection settingSection
  const [ContentType, setContentType] = useState("profileSection");
  const [IsUploadPolicyAttempted, setIsUploadPolicyAttempted] = useState(false); // true false
  const [NoPolicyFound, setNoPolicyFound] = useState(false); // true false
  const [ShowPolicyTypeList, setShowPolicyTypeList] = useState(false); // true false
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const LoggedInData = useSelector((state) => {
    return state?.getLoggedInStatus?.data?.loginData;
  });

  useEffect(() => {
    if (LoggedInData) {
      setFormValue((prevItems) => ({
        ...prevItems,
        fullName: LoggedInData.name,
        phoneNumber: LoggedInData.mobile_no,
        email: LoggedInData.email,
        aadhar: LoggedInData.aadhar_no,
        pan: LoggedInData.pan_no
      }))
    }
  }, [LoggedInData])

  let formValues = {
    fullName: "",
    phoneNumber: "",
    email: "",
    userUploadedDocument: "",
    uploadedPolicyType: "",
    uploadedPolicyNumber: "",
    uploadedPolicyInsurer: "",
    uploadedPolicyDocument: "",
    uploadedPolicyStartDate: "",
    uploadedPolicyEndDate: "",
    isKycUpdated: true,
    sendAllNotification: false,
    sendPolicyUpdates: false,
    sendPolicyRenewalReminder: false,
    sendOffers: false,
    sendReminderCalls: false,
  };

  const [FormValue, setFormValue] = useState(formValues);
  const [errors, setErrors] = useState({});

  const handleProfileSubmit = () => {
    const newErrors = {};

    if (!FormValue.fullName) {
      newErrors.fullName = "Name is required";
    }

    // if (!FormValue.email) {
    //   newErrors.email = "Email is required";
    // }

    // let regexEmail = /^\S+@\S+\.\S+$/;
    // if (FormValue.email && !regexEmail.test(FormValue.email)) {
    //   newErrors.email = "Email is Invalid";
    // }

    if (!FormValue.pan) {
      newErrors.pan = "Pan number is required";
    }

    let regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    if (
      FormValue.pan &&
      !regpan.test(FormValue.pan)
    ) {
      newErrors.pan = "Pan number is in invalid format";
    }

    var regaadhar = /^[2-9]{1}[0-9]{11}$/;
    if (
      !FormValue.aadhar
    ) {
      newErrors.aadhar = "Aadhar number is required";
    }

    if (
      FormValue.aadhar &&
      !regaadhar.test(FormValue.aadhar)
    ) {
      // invalid aadhar card number
      newErrors.aadhar = "Aadhar number is in invalid format";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [ContentType])

  const handleInputChange = (e) => {
    console.log("FormValue", FormValue)
    const { id, value, name, checked, type } = e?.target || {};
    console.log(value, name, checked, type, "valuename");

    if (type == "checkbox") {
      if (name == "sendAllNotification" && checked == true) {
        setFormValue((prevItems) => ({
          ...prevItems,
          sendAllNotification: true,
          sendPolicyUpdates: true,
          sendPolicyRenewalReminder: true,
          sendOffers: true,
          sendReminderCalls: true,
        }));
      } else if (name == "sendAllNotification" && checked == false) {
        setFormValue((prevItems) => ({
          ...prevItems,
          sendAllNotification: false,
          sendPolicyUpdates: false,
          sendPolicyRenewalReminder: false,
          sendOffers: false,
          sendReminderCalls: false,
        }));
      } else {
        console.log("else part");
        setFormValue((prevItems) => ({
          ...prevItems,
          [name]: checked,
        }));
      }
    } else {
      if (type == "submit") {
        //edit 
        const isValid = handleProfileSubmit();
        if (isValid) {
          alert("submit")
        }
      } else {
        setFormValue((prevItems) => ({
          ...prevItems,
          [name]: value,
        }));
      }
    }
  };

  const handleUploadedPolicyInsurer = (e) => {
    console.log(e, "uploadedPolicyInsurer");
    setFormValue((prevItems) => ({
      ...prevItems,
      uploadedPolicyInsurer: e.value,
    }));
  };

  let breadcrumb = [
    {
      icon: userIcon,
      title: "My Profile",
      name: "profileSection",
    },
    {
      icon: myPolicy,
      title: "My Policies",
      name: "policySection",
    },
    {
      icon: myRequest,
      title: "My Request",
      name: "requestSection",
    },
    {
      icon: settings,
      title: "Settings",
      name: "settingSection",
    },
    {
      icon: logout,
      title: "Logout",
      name: "logoutSection",
    },
  ];

  const handleContentReveal = async (name) => {
    //alert(name);
    setContentType(name);
    if (name == "logoutSection") {
      await dispatch(isLoggedInAction({ isLoggedIn: false }))
      navigate("/")
    }
  };

  var today = new Date();
  var curHr = today.getHours();
  let greetingTitle = "Hello, Good Evening!";

  if (curHr < 12) {
    greetingTitle = "Hello, Good Morning!";
  } else if (curHr < 18) {
    greetingTitle = "Hello, Good Afternoon!";
  }

  const handleUploadPolicyScreen = (product) => {
    // alert(product);
    setIsUploadPolicyAttempted(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleCancelPolicyUpload = () => {
    setIsUploadPolicyAttempted(false);
  };

  const handlePopUp = () => {
    if (ShowPolicyTypeList) {
      setShowPolicyTypeList(!ShowPolicyTypeList);
    }
  };

  const addNewPolicy = () => {
    alert("Add new Policy Btn Clicked!!");
  };

  return (
    <>
      <Navbar />
      <section className="clientAccount-container" onClick={handlePopUp}>
        <div className="clientAccount-parent maxWidth">
          <div className="content-container-profile">
            <div className="left-content-container-profile">
              <div className="breadCrumb-container-profile-section">
                {breadcrumb.map((data, key) => (
                  <div
                    className={
                      ContentType === data.name
                        ? "breadCrumb-profilesection breadCrumb-profilesection-active "
                        : "breadCrumb-profilesection"
                    }
                    key={key}
                    onClick={(e) => handleContentReveal(data.name)}
                  >
                    <div className="icon-profile-breadcrumb">
                      <img src={data.icon} alt="" srcset="" />
                    </div>
                    <p>{data.title}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="right-content-container-profile">
              {
                ContentType !== "settingSection" && (
                  <div className="heading_rightSide_findMycar heading_rightSide_findMycar-policyCard">
                    <div>
                      <p>{greetingTitle}</p>
                      <p
                        className={
                          NoPolicyFound &&
                            ContentType === "policySection" &&
                            !IsUploadPolicyAttempted
                            ? "info_block_left_desc info_block_left_desc-red"
                            : "info_block_left_desc"
                        }
                      >
                        {NoPolicyFound &&
                          ContentType === "policySection" &&
                          !IsUploadPolicyAttempted
                          ? "We don't find any policies in your in your account yet. Get your policy in 2min."
                          : ContentType === "policySection" && !NoPolicyFound
                            ? "You can manage all your policies."
                            : "Welcome to your profile."}
                      </p>
                    </div>
                    {ContentType === "policySection" && !NoPolicyFound && (
                      <div>
                        <Botton
                          text="Add New Policy"
                          className="button btn-polsection"
                          event={(e) => addNewPolicy()}
                        />
                      </div>
                    )}
                  </div>
                )
              }

              {ContentType === "profileSection" && (
                <ProfileCard
                  FormValue={FormValue}
                  handleInputChange={handleInputChange}
                  errors={errors}
                />
              )}

              {ContentType === "policySection" && (
                <PolicyCard
                  FormValue={FormValue}
                  handleInputChange={handleInputChange}
                  NoPolicyFound={NoPolicyFound}
                  IsUploadPolicyAttempted={IsUploadPolicyAttempted}
                  handleUploadPolicy={handleUploadPolicyScreen}
                  ShowPolicyTypeList={ShowPolicyTypeList}
                  setShowPolicyTypeList={setShowPolicyTypeList}
                  handleUploadedPolicyInsurer={handleUploadedPolicyInsurer}
                  handleCancelPolicyUpload={handleCancelPolicyUpload}
                />
              )}

              {
                ContentType === "requestSection" && (
                  <RequestCard />
                )
              }

              {
                ContentType === "settingSection" && (
                  <SettingsCard
                    handleInputChange={handleInputChange}
                    FormValue={FormValue}
                  />
                )
              }

            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ClientAccount;
