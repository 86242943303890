import { createAsyncThunk } from "@reduxjs/toolkit";

export const digitQuickQuoteAction = createAsyncThunk(
  "digitQuikQuotes",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_DIGIT_QUICK_QUOTE_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const digitCreateQuoteAction = createAsyncThunk(
  "digitCreateQuotes",
  async (bodyData) => {
    const response = await fetch(
      //"https://t61qx5z813.execute-api.us-east-1.amazonaws.com/dev/createProposal", API GAETWAY
      `${process.env.REACT_APP_DIGIT_CREATE_PROPOSAL_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const digitKycStatusAction = createAsyncThunk(
  "digitKycStatus",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_DIGIT_KYC_STATUS_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const digitPdfDownloadAction = createAsyncThunk(
  "digitDownloadPdf",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_DIGIT_PDF_DOWNLOAD_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

