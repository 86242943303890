import { createAsyncThunk } from "@reduxjs/toolkit";

export const zunoQuickQuoteAction = createAsyncThunk(
  "zunoQuikQuotes",
  async (bodyData) => {
    const response = await fetch(
      "https://gcxnmzzndf.execute-api.us-east-1.amazonaws.com/zuno-quick-quote",
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const zunoFullQuoteAction = createAsyncThunk(
  "zunoFullQuotes",
  async (bodyData) => {
    const response = await fetch(
      "https://gcxnmzzndf.execute-api.us-east-1.amazonaws.com/zuno-full-quote",
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const zunoIssuePolicyAction = createAsyncThunk(
  "zunoIssuePolicy",
  async (bodyData) => {
    const response = await fetch(
      "https://gcxnmzzndf.execute-api.us-east-1.amazonaws.com/zuno-issue-policy",
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const zunoKycCustomerAction = createAsyncThunk(
  "zunoKycCustomer",
  async (bodyData) => {
    const response = await fetch(
      "https://gcxnmzzndf.execute-api.us-east-1.amazonaws.com/zuno-auto-kyc",
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const zunoManualKycAction = createAsyncThunk(
  "zunoManualKyc",
  async (bodyData) => {
    const response = await fetch(
      "https://gcxnmzzndf.execute-api.us-east-1.amazonaws.com/zuno-manual-kyc",
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const zunoOnlinePaymentAction = createAsyncThunk(
  "zunoOnlinePayment",
  async (bodyData) => {
    const response = await fetch(
      "https://gcxnmzzndf.execute-api.us-east-1.amazonaws.com/zuno-online-payment",
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const zunoPdfDownloadAction = createAsyncThunk(
  "zunoPdfDownload",
  async (bodyData) => {
    const response = await fetch(
      "https://gcxnmzzndf.execute-api.us-east-1.amazonaws.com/zuno-pdf-download",
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const zunoInsertBodydAction = createAsyncThunk(
  "zunoInsertBody",
  async (bodyData) => {
    const response = await fetch(
      "https://gcxnmzzndf.execute-api.us-east-1.amazonaws.com/zuno_insertBodyData",
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);
