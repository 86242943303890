import React, { useState } from "react";
import "./Card.css";

const Card = (props) => {
  const {
    icons,
    title,
    subtitle,
    className,
    classNameTwo,
    classNameThree,
    hoverContent,
  } = props;

  const [ShowHoverContent, setShowHoverContent] = useState(false);

  function MouseOver() {
    setShowHoverContent(true);
  }

  function MouseOut() {
    setShowHoverContent(false);
  }

  return (
    <>
      {/* <div className={className} onMouseOver={MouseOver} onMouseOut={MouseOut}> */}
      <div className={className}>
        <div className={classNameThree}>
          <img src={icons} alt="" srcSet="" />
        </div>
        <div className={classNameTwo}>
          <p> {title} </p>
          <p>
            {" "}
            {subtitle} {ShowHoverContent ? hoverContent : ""}{" "}
          </p>
        </div>
      </div>
    </>
  );
};

export default Card;
