import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CheckBox from "../Checkbox/CheckBox";
import RadioButton from "../RadioButton/RadioButton";
import InputFields from "../InputFields/InputFields";
import Botton from "../Button/Button";
import { NEW, ROLLOVER } from "../../utils/commonUtils";
import moment from "moment";
import { Routes, Route, useNavigate, Link } from "react-router-dom";
import { isInitialAction } from "../../redux/Reducers/inspectionReducer";
import loader2 from "../../Assets/Loader/Loader.gif";
import "./loaderMain.css";

const ModalInp = (props) => {
  const dispatch = useDispatch();

  let prevPolicyData = {
    isPrevPolicyExp: null,
    prevPolicyExpLastMonth: null,
    isClaimPrevPolicy: null,
  };

  const {
    clickEvent,
    closeModal,
    ManualInpValue,
    businessType,
    errors,
    uniqueClass,
    vehicleType,
    edit,
    make,
    model,
    vehicleNumber,
    varient,
    fuelType,
    regDate,
    handleRouteToFMC,
    vehicleDetails,
    pincode
  } = props;

  const navigate = useNavigate();

  const [prevPolicyInfo, setPrevPolicyInfo] = useState(prevPolicyData);
  const [isDisabledButton, setIsDisabledButton] = useState(true);
  const [checkboxes, setCheckboxes] = useState({
    isCngLpgVehicleType: false,
    isPospSale: true,
  });

  const [userData, setUserData] = useState({
    cngKitValue: "",
    mobileNo: '',
    pincode: pincode,
  })

  localStorage.setItem("businessType", businessType);

  const handleCheckboxChange = (checkboxName) => {
    setCheckboxes({
      ...checkboxes,
      [checkboxName]: !checkboxes[checkboxName],
    });
  };

  const handleRadioButtonChange = (event, type) => {
    const { name, value } = event.target;
    if (name == "isPrevPolicyExp") {
      dispatch(
        isInitialAction({
          isInspectionReq: value == "Yes" ? true : false,
        })
      );
    }
    let getDate90Days = moment().subtract(90, "days").format("Do MMM YYYY");
    setPrevPolicyInfo((prevItems) => ({
      ...prevItems,
      [name]: value == "Yes" ? true : false,
      dateAgo: getDate90Days,
    }));
  };

  const getVehicleData = useSelector((state) => {
    return state?.getVehicleData;
  });

  useEffect(() => {
    if (!getVehicleData.isLoading) {
      setIsDisabledButton(false);
    }
  }, [getVehicleData]);

  const handlePhoneNumber = (e) => {
    const { id, value, name } = e?.target || {}
    if (value.length <= 10) {
      setUserData((prevItems) => ({
        ...prevItems,
        [name]: value,
      }))
    }

    if (name === "cngKitValue") {
      setUserData((prevItems) => ({
        ...prevItems,
        [name]: value,
      }))
    }
  }

  return (
    <>
      <div id="cover-spin" style={{ display: isDisabledButton ? "" : "none" }}>
        <img src={loader2} className="gifLoader"></img>
      </div>

      <div className="modal-inputs-parent">

        <div className="leftSide_modal-mobile">
          <div className="leftSide_modal-mobile-mmv-container">
            <div className="head-mmv-modal">
              <p className="leftSide_modal-mm" >{make} {model}</p>
              <Botton
                icons={edit}
                text="Edit"
                event={handleRouteToFMC}
                className="edit_btn "
              />
            </div>
            <p className="leftSide_modal-CarNum">{vehicleNumber}</p>
            <div className="leftSide_modal-vfr">
              <p>{varient} <span>•</span> {fuelType} <span>•</span> {regDate} </p>
            </div>
          </div>
        </div>

        <div className="modal_selection_container" >
          <p className="modal_selection_question">
            Did your previous policy expire?
          </p>
          <div className="modal_options_container">
            <RadioButton
              label="Yes"
              value="Yes"
              id="polExpiry"
              groupName="isPrevPolicyExp"
              changeEvent={handleRadioButtonChange}
              errClassName={errors?.isPrevPolicyExp ? "input-err" : ""}
            />
            <RadioButton
              label="No"
              value="No"
              id="polExpiryNo"
              groupName="isPrevPolicyExp"
              changeEvent={handleRadioButtonChange}
              errClassName={errors?.isPrevPolicyExp ? "input-err" : ""}
            />
          </div>
          {prevPolicyInfo.isPrevPolicyExp && (
            <>
              <p className=" small_textModal">
                Did your previous policy expired after {" "}
                {prevPolicyInfo.dateAgo ? prevPolicyInfo.dateAgo : ""}?
              </p>
              <div className="modal_options_container">
                <RadioButton
                  label="Yes"
                  id="polExp"
                  value="Yes"
                  groupName="prevPolicyExpLastMonth"
                  changeEvent={handleRadioButtonChange}
                  errClassName={errors?.prevPolicyExpLastMonth ? "input-err" : ""}
                />
                <RadioButton
                  label="No"
                  id="polExpNo"
                  value="No"
                  groupName="prevPolicyExpLastMonth"
                  changeEvent={handleRadioButtonChange}
                  errClassName={errors?.prevPolicyExpLastMonth ? "input-err" : ""}
                />
              </div>
            </>
          )}
        </div>

        <div className="border-modalInp"></div>
        {
          !prevPolicyInfo.prevPolicyExpLastMonth && (
            <div className="modal_selection_container">
              <p className="modal_selection_question">
                Made a claim in previous policy?
              </p>
              <div className="modal_options_container">
                <RadioButton
                  label="Yes"
                  value="Yes"
                  id="claimYes"
                  groupName="isClaimPrevPolicy"
                  changeEvent={handleRadioButtonChange}
                  errClassName={errors?.isClaimPrevPolicy ? "input-err" : ""}
                />
                <RadioButton
                  label="No"
                  value="No"
                  id="claimNo"
                  groupName="isClaimPrevPolicy"
                  changeEvent={handleRadioButtonChange}
                  errClassName={errors?.isClaimPrevPolicy ? "input-err" : ""}
                />
              </div>
            </div>
          )
        }

        {fuelType?.toLowerCase() == "petrol" && (
          <div className="checkbox_container checkbox_container-modalinp">
            {
              vehicleType === "4W" && (
                <CheckBox
                  label="I have CNG/LPG fitted after the purchase"
                  className="small_text small_textModal checkBox-label"
                  type="checkbox"
                  checked={checkboxes.isCngLpgVehicleType}
                  changeEvent={() => handleCheckboxChange("isCngLpgVehicleType")}
                />
              )
            }
            {/* <CheckBox
            label="Sale for PoSP"
            className="small_text small_textModal checkBox-label"
            type="checkbox"
            checked={checkboxes.isPospSale}
            changeEvent={() => handleCheckboxChange("isPospSale")}
          /> */}
          </div>
        )}

        <div className="border-modalInp"></div>

        {
          checkboxes.isCngLpgVehicleType === true && (
            <div className="input_group_pform input_group_pform-modalInp">
              <div className="inputGropOne_form inputGrop ">
                <InputFields
                  value={userData.cngKitValue}
                  placeholder="CNG/LPG Kit Value"
                  id="cngKitValue"
                  type="number"
                  name="cngKitValue"
                  errState={errors?.cngKitValue ? true : false}
                  errMsg={errors?.cngKitValue}
                  errClassName="input-err"
                  onChangeEvent={(e) => handlePhoneNumber(e)}
                />
              </div>
            </div>
          )
        }

        <div className="input_group_pform input_group_pform-modalInp">
          <div className="inputGropOne_form inputGrop inputGrop-modalInp ">
            <InputFields
              value={userData.mobileNo}
              placeholder="Phone Number"
              id="mobileNo"
              type="number"
              name="mobileNo"
              errState={errors?.phoneNumber ? true : false}
              errMsg={errors?.phoneNumber}
              errClassName="input-err"
              onChangeEvent={(e) => handlePhoneNumber(e)}
            />
            <p className="msg-phoneNo" >Strictly no spam. Get quote and policy updates</p>
          </div>
          <div className="inputGropOne_form inputGrop inputGrop-modalInp">
            <InputFields
              value={userData.pincode}
              placeholder="Pin code"
              id="pincode"
              type="number"
              name="pincode"
              errState={errors?.pincode ? true : false}
              errMsg={errors?.pincode}
              errClassName="input-err"
              onChangeEvent={(e) => setUserData({
                ...userData,
                pincode: e.target.value,
              })}
            />
            <p className="msg-phoneNo" >Enter your current address Pincode for a special discount.</p>
          </div>
        </div>
      </div>

      <div className="button_container">
        <Botton
          text="Back To Search"
          className="button modalBtn secondry_btn"
          event={closeModal}
        />
        <Botton
          text={isDisabledButton ? "Loading..." : "Check Premium"}
          className="button modalBtn"
          isDisabledButton={
            businessType !== "rollover" ? false : isDisabledButton
          }
          event={(e) =>
            clickEvent(
              businessType !== ROLLOVER
                ? { ...ManualInpValue, ...prevPolicyInfo, ...checkboxes, ...userData, e }
                : {
                  ...prevPolicyInfo,
                  ...getVehicleData?.data?.data,
                  ...checkboxes,
                  ...ManualInpValue,
                  ...userData,
                  e,
                }
            )
          }
        />
      </div>
    </>
  );
};

export default ModalInp;
