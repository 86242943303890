import React from 'react'
import car from "../../../../Assets/Images/car.svg";
import tick from "../../../../Assets/Images/success.svg";
import Botton from '../../../Button/Button';

const ExpiredPolicy = (props) => {

    const { handleDownloadPolicy } = props;

    return (
        <div className="ProfileCard-container">
            <section className="NoPoliciesFound-container PoliciesFound-container ">
                <div className="product-info-container">
                    <div className="product-info-left-container">
                        <div className="product-icon-container">
                            <img src={car} alt="" srcSet="" />
                        </div>
                        <div className="product-insurer-container">
                            <p>987654321 - Private Car Policy Insurance</p>
                            <p>HDFC Ergo</p>
                        </div>
                    </div>
                    <div className="product-info-right-container">
                        <p>
                            <span>Start:</span> 24 Jan 2023
                        </p>
                        <p>
                            <span>End:</span> 24 Jan 2023
                        </p>
                    </div>
                </div>

                <div className="seprator-policyCard"></div>

                <div className="vehicleInfo-policyCard ">
                    <p className="textWithKey">
                        <span>Name: </span>Vaibhav Dubey
                    </p>
                    <p className="textWithKey">
                        <span>Car Number: </span> MH 01 CB 5421
                    </p>
                </div>

                <div className="breakup-contianer-policyCard">
                    <div className="breakup-contianer-part-policyCard">
                        <div className="breakup-contianer-part-left-policyCard">
                            <div className="txt-with-tick-policycard">
                                <p className="textWithKey textWithKey-bold">
                                    <span>Invoice Cover</span> ₹11,222.41
                                </p>
                                <img src={tick} alt="" srcSet="" />
                            </div>
                            <div className="txt-with-tick-policycard">
                                <p className="textWithKey textWithKey-bold">
                                    <span>PA Cover</span> ₹11,222.41
                                </p>
                                <img src={tick} alt="" srcSet="" />
                            </div>
                            <div className="txt-with-tick-policycard">
                                <p className="textWithKey textWithKey-bold">
                                    <span>Zero Depreciation</span> ₹11,222.41
                                </p>
                                <img src={tick} alt="" srcSet="" />
                            </div>
                        </div>
                    </div>

                    <div className="breakup-contianer-part-policyCard">
                        <div className="breakup-contianer-part-left-policyCard">
                            <div className="txt-with-tick-policycard">
                                <p className="text-color-one">Engine Protector</p>
                                <img src={tick} alt="" srcSet="" />
                            </div>
                            <div className="txt-with-tick-policycard">
                                <p className="text-color-one">Consumable</p>
                                <img src={tick} alt="" srcSet="" />
                            </div>
                            <div className="txt-with-tick-policycard">
                                <p className="text-color-one">Engine Protector</p>
                                <img src={tick} alt="" srcSet="" />
                            </div>
                        </div>
                    </div>

                    <div className="breakup-contianer-part-policyCard">
                        <div className="breakup-contianer-part-left-policyCard">
                            <div className="txt-with-tick-policycard">
                                <p className="text-color-one">Engine Protector</p>
                                <img src={tick} alt="" srcSet="" />
                            </div>
                            <div className="txt-with-tick-policycard">
                                <p className="text-color-one">Consumable</p>
                                <img src={tick} alt="" srcSet="" />
                            </div>
                            <div className="txt-with-tick-policycard">
                                <p className="text-color-one">Engine Protector</p>
                                <img src={tick} alt="" srcSet="" />
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <Botton
                        text="Download Policy"
                        className="button btn-polsection"
                        event={(e) => handleDownloadPolicy("POLICYNUM00003380")}
                    />
                </div>
            </section>            
            
            <section className="NoPoliciesFound-container PoliciesFound-container ">
                <div className="product-info-container">
                    <div className="product-info-left-container">
                        <div className="product-icon-container">
                            <img src={car} alt="" srcSet="" />
                        </div>
                        <div className="product-insurer-container">
                            <p>987654321 - Private Car Policy Insurance</p>
                            <p>HDFC Ergo</p>
                        </div>
                    </div>
                    <div className="product-info-right-container">
                        <p>
                            <span>Start:</span> 24 Jan 2023
                        </p>
                        <p>
                            <span>End:</span> 24 Jan 2023
                        </p>
                    </div>
                </div>

                <div className="seprator-policyCard"></div>

                <div className="vehicleInfo-policyCard ">
                    <p className="textWithKey">
                        <span>Name: </span>Vaibhav Dubey
                    </p>
                    <p className="textWithKey">
                        <span>Car Number: </span> MH 01 CB 5421
                    </p>
                </div>

                <div className="breakup-contianer-policyCard">
                    <div className="breakup-contianer-part-policyCard">
                        <div className="breakup-contianer-part-left-policyCard">
                            <div className="txt-with-tick-policycard">
                                <p className="textWithKey textWithKey-bold">
                                    <span>Invoice Cover</span> ₹11,222.41
                                </p>
                                <img src={tick} alt="" srcSet="" />
                            </div>
                            <div className="txt-with-tick-policycard">
                                <p className="textWithKey textWithKey-bold">
                                    <span>PA Cover</span> ₹11,222.41
                                </p>
                                <img src={tick} alt="" srcSet="" />
                            </div>
                            <div className="txt-with-tick-policycard">
                                <p className="textWithKey textWithKey-bold">
                                    <span>Zero Depreciation</span> ₹11,222.41
                                </p>
                                <img src={tick} alt="" srcSet="" />
                            </div>
                        </div>
                    </div>

                    <div className="breakup-contianer-part-policyCard">
                        <div className="breakup-contianer-part-left-policyCard">
                            <div className="txt-with-tick-policycard">
                                <p className="text-color-one">Engine Protector</p>
                                <img src={tick} alt="" srcSet="" />
                            </div>
                            <div className="txt-with-tick-policycard">
                                <p className="text-color-one">Consumable</p>
                                <img src={tick} alt="" srcSet="" />
                            </div>
                            <div className="txt-with-tick-policycard">
                                <p className="text-color-one">Engine Protector</p>
                                <img src={tick} alt="" srcSet="" />
                            </div>
                        </div>
                    </div>

                    <div className="breakup-contianer-part-policyCard">
                        <div className="breakup-contianer-part-left-policyCard">
                            <div className="txt-with-tick-policycard">
                                <p className="text-color-one">Engine Protector</p>
                                <img src={tick} alt="" srcSet="" />
                            </div>
                            <div className="txt-with-tick-policycard">
                                <p className="text-color-one">Consumable</p>
                                <img src={tick} alt="" srcSet="" />
                            </div>
                            <div className="txt-with-tick-policycard">
                                <p className="text-color-one">Engine Protector</p>
                                <img src={tick} alt="" srcSet="" />
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <Botton
                        text="Download Policy"
                        className="button btn-polsection"
                        event={(e) => handleDownloadPolicy("POLICYNUM00003380")}
                    />
                </div>
            </section>            
            
            <section className="NoPoliciesFound-container PoliciesFound-container ">
                <div className="product-info-container">
                    <div className="product-info-left-container">
                        <div className="product-icon-container">
                            <img src={car} alt="" srcSet="" />
                        </div>
                        <div className="product-insurer-container">
                            <p>987654321 - Private Car Policy Insurance</p>
                            <p>HDFC Ergo</p>
                        </div>
                    </div>
                    <div className="product-info-right-container">
                        <p>
                            <span>Start:</span> 24 Jan 2023
                        </p>
                        <p>
                            <span>End:</span> 24 Jan 2023
                        </p>
                    </div>
                </div>

                <div className="seprator-policyCard"></div>

                <div className="vehicleInfo-policyCard ">
                    <p className="textWithKey">
                        <span>Name: </span>Vaibhav Dubey
                    </p>
                    <p className="textWithKey">
                        <span>Car Number: </span> MH 01 CB 5421
                    </p>
                </div>

                <div className="breakup-contianer-policyCard">
                    <div className="breakup-contianer-part-policyCard">
                        <div className="breakup-contianer-part-left-policyCard">
                            <div className="txt-with-tick-policycard">
                                <p className="textWithKey textWithKey-bold">
                                    <span>Invoice Cover</span> ₹11,222.41
                                </p>
                                <img src={tick} alt="" srcSet="" />
                            </div>
                            <div className="txt-with-tick-policycard">
                                <p className="textWithKey textWithKey-bold">
                                    <span>PA Cover</span> ₹11,222.41
                                </p>
                                <img src={tick} alt="" srcSet="" />
                            </div>
                            <div className="txt-with-tick-policycard">
                                <p className="textWithKey textWithKey-bold">
                                    <span>Zero Depreciation</span> ₹11,222.41
                                </p>
                                <img src={tick} alt="" srcSet="" />
                            </div>
                        </div>
                    </div>

                    <div className="breakup-contianer-part-policyCard">
                        <div className="breakup-contianer-part-left-policyCard">
                            <div className="txt-with-tick-policycard">
                                <p className="text-color-one">Engine Protector</p>
                                <img src={tick} alt="" srcSet="" />
                            </div>
                            <div className="txt-with-tick-policycard">
                                <p className="text-color-one">Consumable</p>
                                <img src={tick} alt="" srcSet="" />
                            </div>
                            <div className="txt-with-tick-policycard">
                                <p className="text-color-one">Engine Protector</p>
                                <img src={tick} alt="" srcSet="" />
                            </div>
                        </div>
                    </div>

                    <div className="breakup-contianer-part-policyCard">
                        <div className="breakup-contianer-part-left-policyCard">
                            <div className="txt-with-tick-policycard">
                                <p className="text-color-one">Engine Protector</p>
                                <img src={tick} alt="" srcSet="" />
                            </div>
                            <div className="txt-with-tick-policycard">
                                <p className="text-color-one">Consumable</p>
                                <img src={tick} alt="" srcSet="" />
                            </div>
                            <div className="txt-with-tick-policycard">
                                <p className="text-color-one">Engine Protector</p>
                                <img src={tick} alt="" srcSet="" />
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <Botton
                        text="Download Policy"
                        className="button btn-polsection"
                        event={(e) => handleDownloadPolicy("POLICYNUM00003380")}
                    />
                </div>
            </section>
        </div>
    )
}

export default ExpiredPolicy
