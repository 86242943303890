import {
    digitQuickQuoteAction,
    digitCreateQuoteAction,
    digitKycStatusAction,
    digitPdfDownloadAction
} from "../Actions/digitApiAction";
import { createSlice } from "@reduxjs/toolkit";

export const digitQuickQuoteReducer = createSlice({
    name: "digitQuikQuotes",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    reducers: {
        resetDigitQuote: (state) => {
          state.data = null;
        },
      },
    extraReducers: (builder) => {
        builder.addCase(digitQuickQuoteAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(digitQuickQuoteAction.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(digitQuickQuoteAction.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
});

export const digitCreateQuoteReducer = createSlice({
    name: "digitCreateQuotes",
    initialState: {
        data: null,
    },
    reducers: {
        resetDigitProposal: (state) => {
          state.isLoading = false;
          state.data = null;
          state.isError = false;
        },
      },
    extraReducers: (builder) => {
        builder.addCase(digitCreateQuoteAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(digitCreateQuoteAction.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(digitCreateQuoteAction.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
});

export const digitKycStatusReducer = createSlice({
    name: "digitKycStatus",
    initialState: {
        isLoading: true,
        data: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(digitKycStatusAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(digitKycStatusAction.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(digitKycStatusAction.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
    reducers: {
        resetDigitKycStatus: (state, action) => {
          state.data = undefined
        }
      }
});

export const { resetDigitKycStatus } = digitKycStatusReducer.actions

export const digitPdfDownloadReducer = createSlice({
    name: "digitDownloadPdf",
    initialState: {
        isLoading: true,
        data: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(digitPdfDownloadAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(digitPdfDownloadAction.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(digitPdfDownloadAction.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
    reducers: {
        resetDigitPdfDownloadReducer: (state, action) => {
          state.data = undefined
        }
      }
});

export const { resetDigitPdfDownloadReducer } = digitPdfDownloadReducer.actions

