import React, { useState, useEffect, useRef, useCallback } from "react";
import "./ViewPlans.css";
import Testimonials from "../Testimonials/Testimonials";
import Partners from "../Partners/Partners";
import Accordian from "../Accordian/Accordian";
import Paper from "./Paper";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Calender from "../InputFields/Calender";
import TopContent from "./TopContent";
import DropDown from "../DropDown/DropDown";
import CheckBox from "../Checkbox/CheckBox";
import AddonContainer from "./AddonContainer";
import QuotesContainer from "../QuotesContainer/QuotesContainer";
import Footer from "../Footer/Footer";
import moment from "moment";
import { transactionURLAction } from "../../redux/Actions/TransactionAction";
import { updateVehicleInfoAction } from "../../redux/Reducers/commonVehicleFetchApiReducer";
import {
  calculatePrevNcbNewNcb,
  getPolicyType,
  getPolicyExpiryDate,
  calculateDate,
  FINDMYCAR,
} from "../../utils/commonUtils";
import {
  getModelAction,
  getFuelTypeAction,
  getVariantAction,
  getCarBrandAction,
} from "../../redux/Actions/mmvAction";
import { allBodyDataAction } from "../../redux/Reducers/bodyDataReducer";
import ToolTip from "./ToolTip";
import { ROLLOVER, NEW } from "../../utils/commonUtils";
import {
  zunoFullQuoteAction,
  zunoQuickQuoteAction,
} from "../../redux/Actions/zunoApiAction";
import { shreeRamQuickQuoteAction } from "../../redux/Actions/shreeRamApiAction";
import { digitQuickQuoteAction } from "../../redux/Actions/digitApiAction";
import { sbiQuickQuoteAction } from "../../redux/Actions/sbiApiAction";
import { iciciQuickQuoteAction } from "../../redux/Actions/iciciApiAction";
import { ackoQuickQuoteAction } from "../../redux/Actions/ackoApiAction";
import { alladdOnDataDataAction } from "../../redux/Reducers/addOnsDataReducer";
import Navbar from "../Navbar/Navbar";
import Botton from "../Button/Button";
import edit from "../../Assets/Images/document.svg";
import arrow from "../../Assets/Images/arrow.svg";
import info from "../../Assets/Images/info.svg";
import close from "../../Assets/Images/Close.svg";
import EditDetails from "./EditDetails";
import { format } from "date-fns-tz";
import AOS from "aos";
import "aos/dist/aos.css";
import InputFields from "../InputFields/InputFields";
import Calendar from "../InputFields/Calendar/Calendar";
import CustomCalendar from "../InputFields/Calendar/Calendar";
import CustomDateField from "../InputFields/CustomDateField/CustomDateField";
import loader2 from "../../Assets/Loader/Loader.gif"
import { isInitialAction } from "../../redux/Reducers/inspectionReducer";
import { isViewPlanFlowAction } from "../../redux/Reducers/isViewPlanFlowCompleted";
import CallWidget from "../Widgets/CallWidget";
import IdvTab from "./IdvTab";


const ViewPlans = (props) => {
  const {
    firstLot,
    secondLot,
    carQueries,
    bikeQueries,
    healthQueries,
    taxiQueries,
    testimonials,
    sbiQUickQuoteData,
  } = props;

  useEffect(() => {
    AOS.init({
      duration: 200,
    });
    AOS.refresh();
  }, []);

  const [MakeList, setMakeList] = useState([]);
  const search = useLocation().search;
  const transactionId = new URLSearchParams(search).get("id");
  var today = new Date();
  var curHr = today.getHours();

  let greetingTitle = "Hello, Good Evening!";
  if (curHr < 12) {
    greetingTitle = "Hello, Good Morning!";
  } else if (curHr < 18) {
    greetingTitle = "Hello, Good Afternoon!";
  }

  const dispatch = useDispatch();
  const location = useLocation();
  let storageVehicleNumber = localStorage.getItem("vehicleNumber");
  let storageBusinessType = localStorage.getItem("businessType");
  let storageisFindMyCar = localStorage.getItem("isFindMyCar");
  let mobileNo = localStorage.getItem("mobileNo");
  let pincode = localStorage.getItem("pincode");
  let [updateCarDetails, setUpdateCarDetails] = useState(false)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const asyncFn = async () => {
      let bikeBrand = await dispatch(getCarBrandAction());
      setMakeList(bikeBrand?.payload?.data?.vehicelinfo)
    };
    asyncFn();
  }, [])

  let [businessType, setBusinessType] = useState(location?.state?.businessType
    ? location?.state?.businessType
    : storageBusinessType)
  let vehicleNumber = location?.state?.vehicleNumber
    ? location?.state?.vehicleNumber
    : storageVehicleNumber;
  let isFindMyCar = location?.state?.isFindMyCar
    ? location?.state?.isFindMyCar
    : storageisFindMyCar
      ? JSON.parse(storageisFindMyCar)
      : "";

  let policyTypeList = [];

  const vehicleData = useSelector((state) => {
    return businessType == ROLLOVER
      ? state?.getVehicleData?.data?.data
        ? (state?.getVehicleData?.data?.data.hasOwnProperty('data')
          ? state?.getVehicleData?.data?.data?.data : state?.getVehicleData?.data?.data)
        : state?.getVehicleData?.data
      : state?.getVehicleData?.data?.data?.data;
  });

  console.log(vehicleData.prevPolicyExpLastMonth, "vehicleDatamddk");

  const [ncbCopy, setncbCopy] = useState({
    newNcb: 0,
    prevNcb: 0,
  });

  const [ncbData, setncbData] = useState(!ncbCopy?.newNcb ? calculatePrevNcbNewNcb(
    vehicleData?.regn_dt,
    vehicleData?.isClaimPrevPolicy,
    businessType,
    vehicleData?.prevPolicyExpLastMonth
  ) : {
    newNcb: 0,
    prevNcb: 0,
  });

  const isLoggedInAction = useSelector((state) => {
    return state?.getLoggedInStatus?.data?.loginData;
  });


  const [showInsDropDown, setshowInsDropDown] = useState(false);
  const [showPolicyDropdown, setshowPolicyDropdown] = useState(false);
  const [showAddonMenu, setshowAddonMenu] = useState(false);
  const [IdvValue, setIdvValue] = useState();

  const [minIdvValue, setminIdvValue] = useState({
    sbi: 0,
    digit: 0,
    zuno: 0,
    shreeram: 0,
    icici: 0,
    acko: 0,
  });

  const [maxIdvValue, setmaxIdvValue] = useState({
    sbi: 0,
    digit: 0,
    zuno: 0,
    shreeram: 0,
    icici: 0,
    acko: 0,
  });

  const [allIDVValues, setAllIDVValues] = useState({
    min: 0,
    max: 0,
  });

  const [onBLurIdbValue, setOnBLurIdbValue] = useState(1000000);
  const [PolicyTypeValue, setPolicyTypeValue] = useState(
    getPolicyType(
      vehicleData?.regn_dt,
      businessType !== NEW
        ? new Date(
          getPolicyExpiryDate(
            vehicleData?.isPrevPolicyExp,
            vehicleData?.prevPolicyExpLastMonth
          )
        )
        : new Date()
    )
  );

  const [ShowPassengerAmtList, setShowPassengerAmtList] = useState(false);
  const [ShowAccesoriesField, setShowAccesoriesField] = useState(false);
  const [ShowPcSumInsuredField, setShowPcSumInsuredField] = useState(false);
  const [MinDateValue, setMinDateValue] = useState("");
  const [MaxDateValue, setMaxDateValue] = useState("");
  const [notWorkingAddons, setNotWorkingAddons] = useState([]);
  const getPreviousAddonData = useSelector((state) => {
    return state?.addOnData?.data;
  });
  const [ElectricalAmt, setElectricalAmt] = useState(null);
  const [NonElectricalAmt, setNonElectricalAmt] = useState(null);
  const [PassengerCoverAmt, setPassengerCoverAmt] = useState(null);
  const [policyTypeOption, setPolicyTypeOption] = useState([
    { label: "Comprehensive", value: "Comprehensive" },
    { label: "Third Party", value: "Third Party" },
  ]);
  const [EditMmvMobile, setEditMmvMobile] = useState(false);
  const [EditPolicyTypeMobile, setEditPolicyTypeMobile] = useState(false);
  const [EditNcbMobile, setEditNcbMobile] = useState(false);
  const [AddonsForMobile, setAddonsForMobile] = useState(false);
  const [ShowBottomTab, setShowBottomTab] = useState(true);
  const [ShowIdvTab, setShowIdvTab] = useState(false);
  const [ShowRegDateCalendar, setShowRegDateCalendar] = useState(false);
  const [ShowRegDateCalendarModal, setShowRegDateCalendarModal] = useState(false);
  const [ShowExpDateCalendar, setShowExpDateCalendar] = useState(false);
  const [ShowExpDateCalendarModal, setShowExpDateCalendarModal] = useState(false);
  const [ShowOdExpDateCalendar, setShowOdExpDateCalendar] = useState(false);
  const [PrevPolicyTypeList, setPrevPolicyTypeList] = useState(false);
  const [ShowOdExpDateCalendarModal, setShowOdExpDateCalendarModal] = useState(false);
  const [RegDateClone, setRegDateClone] = useState(null);
  const [OdExpDateClone, setOdExpDateClone] = useState(null);
  const [ExpDateClone, setExpDateClone] = useState(null);
  const [ShowLoader, setShowLoader] = useState(false);
  const [LoaderFromConfirmPg, setLoaderFromConfirmPg] = useState(false);
  const [CompanyWiseLoader, setCompanyWiseLoader] = useState(false);
  const [ShowFlowExpCalendar, setShowFlowExpCalendar] = useState(false);
  const [ShowFlowOdCalendar, setShowFlowOdCalendar] = useState(false);
  const [ShowAlternateExpOptions, setShowAlternateExpOptions] = useState(false);
  const [SelectedAlternateExpOption, setSelectedAlternateExpOption] = useState(null);
  const [ShowNumber, setShowNumber] = useState(false);
  const [ShowSkeleton, setShowSkeleton] = useState(false)


  let maxRegDate = new Date();

  let carsAge = '';
  let width = window.innerWidth;

  const policyTypeOptionForNew = [
    {
      label: "Bundled",
      value: "Bundled",
    },
  ];

  // getting inspection details

  const inspectDetails = useSelector((state) => {
    return state?.inspectDetails?.data?.isInspectionReq
  })

  // GETTING THE STATUS OF THE VIEWPLANS FLOW POPUP

  const getviewPlanFlowStatus = useSelector((state) => {
    return state?.getviewPlanFlowStatus?.data?.isViewPlanFlowCompleted
  })

  // logic for loader as per the company name :->

  let getCompanyName = useSelector((state) => {
    return state?.companyName?.data?.companyName
  });

  let sbiQuickQuoteStatus = useSelector((state) => {
    return state?.sbiQuickQuote?.isLoading
  });

  let digitQuickQuouteStatus = useSelector((state) => {
    return state?.digitQuickQuote?.isLoading
  });

  let iciciQuickQuoteStatus = useSelector((state) => {
    return state?.iciciQuickQuote?.isLoading
  });

  let ackoQuickQuoteStatus = useSelector((state) => {
    return state?.ackoQuickQuote?.isLoading
  });

  let shreeramQuickQuoteStatus = useSelector((state) => {
    return state?.shreeRamQuickQuote?.isLoading
  });

  let zunoQuickQuoteStatus = useSelector((state) => {
    return state?.zunoQuickQuote?.isLoading
  });

  useEffect(() => {
    switch (getCompanyName) {
      case "sbi":
        setCompanyWiseLoader(sbiQuickQuoteStatus);
        break;
      case "digit":
        setCompanyWiseLoader(digitQuickQuouteStatus);
        break;
      case "icici":
        setCompanyWiseLoader(iciciQuickQuoteStatus);
        break;
      case "zuno":
        setCompanyWiseLoader(zunoQuickQuoteStatus);
        break;
      case "acko":
        setCompanyWiseLoader(ackoQuickQuoteStatus);
        break;
      case "shreeram":
        setCompanyWiseLoader(shreeramQuickQuoteStatus);
        break;
      default:
        break;
    }
  }, [
    getCompanyName,
    sbiQuickQuoteStatus,
    digitQuickQuouteStatus,
    iciciQuickQuoteStatus,
    ackoQuickQuoteStatus,
    shreeramQuickQuoteStatus,
    zunoQuickQuoteStatus
  ]);

  // insurer list

  let insurerList = [
    {
      label: "Acko",
      value: "Acko",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "Bajaj Allianz",
      value: "Bajaj Allianz",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "Chola MS",
      value: "Chola MS",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "Digit",
      value: "Digit",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "Edelweiss",
      value: "Edelweiss",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "Future Generali",
      value: "Future Generali",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "HDFC Ergo",
      value: "HDFC Ergo",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "Iffco Tokio",
      value: "Iffco Tokio",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "ICICI Lombard",
      value: "ICICI Lombard",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "Kotak",
      value: "Kotak",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "Magma HDI",
      value: "Magma HDI",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "National Insurance",
      value: "National Insurance",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "Navi Insurance",
      value: "Navi Insurance",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "Oriental Insurance",
      value: "Oriental Insurance",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "Royal Sundaram",
      value: "Royal Sundaram",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "Raheja Qbe",
      value: "Raheja Qbe",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "Reliance",
      value: "Reliance",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "SBI General",
      value: "SBI General",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "Sompo Insurance",
      value: "Sompo Insurance",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "Tata AIG",
      value: "Tata AIG",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "The New India Assurance",
      value: "The New India Assurance",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "United India",
      value: "United India",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
    {
      label: "Liberty",
      value: "Liberty",
      className: "small_text dropDownLabel ",
      changeEvent: (e) => HandleInsurerList(e),
      idForDropdown: "insDpContainer",
      idForP: "insIdForP",
    },
  ];

  // addons list

  let data = [
    {
      id: "checkbox1",
      label: "Mandate by IRDAI",
      name: "paCover",
      value: "PA Cover",
      className: "small_text small_textModal ",
      classNameTwo: "viewPlan_checkbox",
      tooltip: `A compulsory personal accident or PA cover is an add-on cover that provides protection against any accidental injuries to the owner-driver of an insured car. It provides compensation in case the owner-driver of the car suffers from bodily injuries, permanent disability or death following the car accident.`,
      checked: false,
      changeEvent: (e) => handleCheckboxChange(e),
    },
    {
      id: "checkbox2",
      label: "Zero Depreciation",
      name: "zeroDep",
      value: "Zero Dep",
      className: "small_text small_textModal ",
      classNameTwo: "viewPlan_checkbox",
      tooltip: `Zero depreciation cover is also known as bumper-to-bumper or nil depreciation cover. With zero depreciation coverage, the policyholder does not have to pay the depreciation value of the damaged or replaced parts and can claim the full amount under their comprehensive car insurance.`,
      checked: false,
      changeEvent: (e) => handleCheckboxChange(e),
    },
    {
      id: "checkbox3",
      label: "Consumables",
      name: "consumables",
      value: "Consumables",
      className: "small_text ",
      classNameTwo: "",
      tooltip: `Covers expenses incurred towards Consumables (nut and bolt, screw, washers, grease, lubricants clip, ac gas, bearings, distilled water, engine oil, oil filter, fuel filter, break oil and the like) arising out of damage to the vehicle.`,
      checked: false,
      changeEvent: (e) => handleCheckboxChange(e),
    },
    {
      id: "checkbox4",
      label: "24/7 Road Assistance",
      name: "roadAssistance",
      value: "24/7 Road Assistance",
      className: "small_text ",
      classNameTwo: "",
      tooltip: `Road side assistance provides support for basic on-road breakdown situations like tyre change, battery jump start, emergency fuel, medical assistance etc which are not covered under Insurance. As the price is very nominal, it is a good to have add-on.`,
      checked: false,
      changeEvent: (e) => handleCheckboxChange(e),
    },
    {
      id: "checkbox5",
      label: "Engine Protection",
      name: "engineProtection",
      value: "Engine Protection",
      className: "small_text ",
      classNameTwo: "",
      tooltip: `When the Engine of the car is submerged in a water logged area, using or cranking the engine can result in engine ceasing. This is not covered under regular Insurance. Engine protector covers such non-accidental exclusions related to your engine. It is a must buy for luxury cars where engine is very costly & is placed at low ground clearance.`,
      checked: false,
      changeEvent: (e) => handleCheckboxChange(e),
    },
    {
      id: "checkbox6",
      label: "Invoice Cover",
      name: "invoiceCover",
      value: "Invoice Cover",
      className: "small_text ",
      classNameTwo: "",
      tooltip: `In case of theft or total damage to your car, you are only eligible for reimbursement up to the Insured declared value of your car, which will be very less than the Invoice value. In case of such an event, selecting Invoice cover makes you eligible for full Invoice amount reimbursement.`,
      checked: false,
      changeEvent: (e) => handleCheckboxChange(e),
    },
    {
      id: "checkbox7",
      label: "Key and Lock Replacement",
      name: "keyLockReplacement",
      value: "Key and Lock Replacement",
      className: "small_text ",
      classNameTwo: "",
      tooltip: `In case your keys are lost or stolen, you have to request a new one from the manufacturer. In most cases, you may even need to replace the locks, which is another added expenditure. Key and Lock Replacement Cover covers the expenses incurred for procuring a new key. It is a must buy for high end and luxury cars as the new set of keys can be very expensive.`,
      checked: false,
      changeEvent: (e) => handleCheckboxChange(e),
    },
    {
      id: "checkbox8",
      label: "NCB Protection",
      name: "ncbProtection",
      value: "NCB Protection",
      className: "small_text ",
      classNameTwo: "",
      tooltip: `An NCB Protect cover gives you extra cushioning. It keeps the bonus component intact even if some claims (depending upon the terms and conditions) are raised during the policy period. You can opt for NCB protection cover to benefit from the renewal discount irrespective of a claim (or claims) during the policy period`,
      checked: false,
      changeEvent: (e) => handleCheckboxChange(e),
    },
    {
      id: "checkbox10",
      label: "Driver Cover",
      name: "driverCover",
      value: "Driver Cover",
      className: "small_text ",
      classNameTwo: "",
      tooltip: `It covers medical costs incurred in the treatment of the injured driver and passengers. It gives financial aid in case of the death of owner-driver/passengers. It provides disability liability cover to the owner-driver and passengers of an insured car.`,
      checked: false,
      changeEvent: (e) => handleCheckboxChange(e),
    },
    {
      id: "checkbox11",
      label: "Passenger Cover",
      name: "passengerCover",
      value: "Passenger Cover",
      className: "small_text ",
      classNameTwo: "",
      tooltip: `Passenger cover in car insurance is financial coverage for passengers who are travelling in the insured car. While it is necessary to have the personal accident cover for the owner-driver of the car, covering passengers is not mandatory.`,
      checked: false,
      changeEvent: (e) => handleCheckboxChange(e),
    },
    {
      id: "checkbox12",
      label: "Accessories",
      name: "accessories",
      value: "Accessories",
      className: "small_text ",
      classNameTwo: "",
      tooltip: `Cover your car extra fitted electrical and non-electrical accessories.`,
      checked: false,
      changeEvent: (e) => handleCheckboxChange(e),
    },
    {
      id: "checkbox13",
      label: "Tyre Protection",
      name: "tyreProtection",
      value: "tyreProtection",
      className: "small_text ",
      classNameTwo: "",
      tooltip: `A tyre protection cover is a car insurance add-on cover that gives coverage for the repair and replacement cost of tyres. This add-on cover provides insurance cover for accidental loss and damage or cuts to the tyres in addition to the labour charges incurred during the repair or replacement of tyres.`,
      checked: false,
      changeEvent: (e) => handleCheckboxChange(e),
    },
    {
      id: "checkbox14",
      label: "Motor Protection",
      name: "shreeramProtect",
      value: "shreeramProtect",
      className: "small_text ",
      classNameTwo: "",
      tooltip: `In case of any motor accident arising out of the use of the Insured Vehicle, We will provide you an advocate to guide and/or defend you from police and proceedings in the court of judicial magistrate only.`,
      checked: false,
      changeEvent: (e) => handleCheckboxChange(e),
    },
    // {
    //   id: "checkbox14",
    //   label: "EMI Protection",
    //   name: "emiProtection",
    //   value: "emiProtection",
    //   className: "small_text ",
    //   classNameTwo: "",
    //   tooltip: `EMI Protection Cover: UIN 1RDAN123A0002V01201718 In consideration of payment of additional premium, the insured will be paid regular Equated Monthly Instalment (EMI) payable to the financier of the vehicle recorded in our books due to an accident involving the insured vehicle.`,
    //   checked: false,
    //   changeEvent: (e) => handleCheckboxChange(e),
    // },
    {
      id: "checkbox15",
      label: "Loss of Personal Belongings",
      name: "lossofPersonalBelongings",
      value: "lossofPersonalBelongings",
      className: "small_text ",
      classNameTwo: "",
      tooltip: `Personal belongings add on provides you coverage against such belongings kept in your car. While theft of a car is covered, theft of the items kept in the car is not. Personal belongings might include items such as laptop, smartphone, camera, musical instruments, etc.`,
      checked: false,
      changeEvent: (e) => handleCheckboxChange(e),
    },
    {
      id: "checkbox16",
      label: "Daily Allowance",
      name: "dailyAllowance",
      value: "dailyAllowance",
      className: "small_text ",
      classNameTwo: "",
      tooltip: `The daily allowance add-on cover for car insurance can be purchased with a maximum coverage duration. It basically means that the insurance company will pay you the compensation for a fixed number of days that is mentioned in the policy document or till the time your car gets repaired, whichever happens earlier.`,
      checked: false,
      changeEvent: (e) => handleCheckboxChange(e),
    },
  ];

  if (getPreviousAddonData?.coverage?.length) {
    data = getPreviousAddonData?.coverage[0];
  }

  let getCalculatedDate = calculateDate(
    PolicyTypeValue,
    businessType !== NEW
      ? new Date(
        getPolicyExpiryDate(
          vehicleData?.isPrevPolicyExp,
          vehicleData?.prevPolicyExpLastMonth
        )
      )
      : "",
    businessType == FINDMYCAR ? ROLLOVER : businessType,
    businessType !== NEW
      ? new Date(vehicleData?.regn_dt)
      : vehicleData?.policyStartDate
  );

  const [ExpiryDate, setExpiryDate] = useState(
    businessType !== NEW
      ? new Date(
        getPolicyExpiryDate(
          vehicleData?.isPrevPolicyExp,
          vehicleData?.prevPolicyExpLastMonth
        )
      )
      : null
  );

  let testDateFormatRegDate = new Date(vehicleData?.policyStartDate);

  const formatDate = (date) => {
    if (date) {
      return format(date, "yyyy-MM-dd'T'HH:mm:ssXXX", {
        timeZone: "Asia/Kolkata",
      });
    }
  };

  const [test, setTest] = useState(data);

  // main object where value get stored

  const [carDetails, setCarDetails] = useState({
    cngKitValue: vehicleData?.cngKitValue ? vehicleData?.cngKitValue : null,
    vehicleNumber:
      businessType !== NEW
        ? businessType == "FindMyCar"
          ? vehicleData?.rto?.split("-")[0]
          : vehicleNumber?.toUpperCase()
        : vehicleData?.rto?.split("-")[0],
    // policyRegDate -------> carRegDate hai
    policyRegDate:
      businessType !== NEW
        ? new Date(vehicleData?.regn_dt)
        : formatDate(new Date(vehicleData?.policyStartDate)),
    policyExpDate:
      PolicyTypeValue == "Bundled" && businessType !== NEW
        ? getCalculatedDate.prevoiusPolicyTPEndDate
        : ExpiryDate,
    fuelType:
      businessType == ROLLOVER
        ? vehicleData?.fuelType
          ? vehicleData?.fuelType
          : vehicleData?.model_details?.[0]?.fuelType
        : vehicleData?.fuelType,
    make:
      businessType == ROLLOVER
        ? vehicleData?.make
          ? vehicleData?.make
          : vehicleData?.model_details?.[0]?.Brand
        : vehicleData?.make,
    model:
      businessType == ROLLOVER
        ? vehicleData?.model
          ? vehicleData?.model
          : vehicleData?.model_details?.[0]?.Model
        : vehicleData?.model,
    varient:
      businessType == ROLLOVER
        ? vehicleData?.varient
          ? vehicleData?.varient
          : vehicleData?.model_details?.[0]?.Variant_Name
        : vehicleData?.varient,
    cc:
      businessType == ROLLOVER
        ? vehicleData?.cc
          ? vehicleData?.cc
          : vehicleData?.model_details?.[0]?.displacement
        : vehicleData?.cc,
    isClaimPrevPolicy:
      businessType !== NEW
        ? ncbData?.newNcb != "0"
          ? false
          : vehicleData?.isClaimPrevPolicy
        : "",
    newNcb: businessType !== NEW ? ncbData?.newNcb : "0",
    prevNcb: businessType !== NEW ? ncbData?.prevNcb : "0",
    isPrevPolicyExp: businessType !== NEW ? vehicleData?.isPrevPolicyExp : "",
    prevPolicyExpLastMonth:
      businessType !== NEW
        ? vehicleData?.isPrevPolicyExp === false
          ? false
          : vehicleData?.prevPolicyExpLastMonth
        : "",
    prevPolicyType: businessType !== NEW ? PolicyTypeValue : "",
    prevPolicyODexpDate:
      businessType !== NEW && PolicyTypeValue != "Comprehensive"
        ? new Date(
          getPolicyExpiryDate(
            vehicleData?.isPrevPolicyExp,
            vehicleData?.prevPolicyExpLastMonth
          )
        )
        : "",
    isCngLpgVehicleType: vehicleData?.isCngLpgVehicleType,
    odInternalCngLpg:
      vehicleData?.fuelType?.toUpperCase() == "INTERNAL_LPG_CNG".toUpperCase()
        ? true
        : false,
    tpInternalCngLpg:
      vehicleData?.fuelType?.toUpperCase() == "INTERNAL_LPG_CNG".toUpperCase()
        ? true
        : false,
    typeOfBusiness: businessType == FINDMYCAR ? ROLLOVER : businessType,
    PolicyTypeValue:
      businessType !== NEW
        ? PolicyTypeValue === "Bundled" && carsAge == 1
          ? "Own Damage"
          : "Comprehensive"
        : "Bundled",
    onBLurIdbValue: onBLurIdbValue,
    idvValue: null,
    chassisNumber: businessType == ROLLOVER ? vehicleData?.chassisnumber : "",
    engineNumber: businessType == ROLLOVER ? vehicleData?.enginenumber : "",
    seatingCapacity:
      businessType == ROLLOVER
        ? vehicleData?.model_details?.[0]?.seating_capacity
        : "",
    mfgDate: businessType == ROLLOVER ? new Date(vehicleData?.regn_dt) : "",
    posp: true,
    ownDamge: null,
    totalAddonsPrice: null,
    totalTPprice: null,
    paidDriverAmt: null,
    netPayable: null,
    gstPrice: null,
    grossPremium: null,
    basicTPprice: null,
    paCover: null,
    basicOwnDamge: null,
    ncbDiscount: null,
    ownerName: null,
    email: null,
    phoneNumber: null,
    gstNumber: null,
    companyName: null,
    carOwner: null,
    gender: "male",
    maritalStatus: "single",
    dob: null,
    nomineeName: null,
    nomineeAge: null,
    nomineeRelation: null,
    addressOne: null,
    addressTwo: null,
    state: null,
    city: null,
    pincode: vehicleData?.pincode ? vehicleData?.pincode : pincode,
    mobileNo: vehicleData?.mobileNo ? vehicleData?.mobileNo : mobileNo,
    prevPolicyNumber: null,
    prevPolicyNameInsurer: null,
    carIsInLoan: null,
    carHypothecationCompany: null,
    pcCoverSumInsured: getPreviousAddonData?.pcCoverSumInsured
      ? getPreviousAddonData?.pcCoverSumInsured
      : null,
    electricalAccesories: getPreviousAddonData?.electricalAccesories
      ? getPreviousAddonData?.electricalAccesories
      : null,
    nonElectricalAccesories: getPreviousAddonData?.nonElectricalAccesories
      ? getPreviousAddonData?.nonElectricalAccesories
      : null,
    isFindMyCar: isFindMyCar,
    pospData: isLoggedInAction
  });


  const getModelValue = useSelector((state) => {
    return state?.getModel;
  });

  const ModelList = getModelValue?.data?.data?.vehicelinfo;

  const ModelListArray = ModelList?.map((item, key) => ({
    label: item.Model,
    value: item.Model,
  }));

  const FuelList = useSelector((state) => {
    return state?.getFuel?.data?.data?.vehicelinfo;
  });

  const variantListResponse = useSelector((state) => {
    return state?.getVariant?.data?.data?.vehicelinfo;
  });

  const FuelTypeList = FuelList?.map((item, key) => ({
    label: item.Fuel_Type,
    value: item.Fuel_Type,
  }));

  const VarientList = variantListResponse?.map((item, key) => ({
    label: item.Variant + " " + "(" + item.Cubic_Capacity + " cc" + ")",
    value: item.Variant,
  }));

  const ccListD = variantListResponse?.map((item, key) => ({
    label: item.Cubic_Capacity,
    value: item.Cubic_Capacity,
  }));

  const uniqueSet = new Set();

  ccListD?.forEach((item) => {
    if (item.label === item.value) {
      uniqueSet.add(JSON.stringify(item));
    }
  });

  const ccList = Array.from(uniqueSet, (item) => JSON.parse(item));

  const carDetailsCopy = {
    vehicleNumberCopy: carDetails?.vehicleNumber?.toUpperCase() || "",
    makeCopy: carDetails?.make || "",
    modelCopy: carDetails?.model || "",
    varientCopy: carDetails?.varient || "",
    fuelTypeCopy: carDetails?.fuelType || "",
    ccCopy: carDetails?.cc || "",
    regDateCopy: carDetails?.policyRegDate || "",
    prevPolicyTypeCopy: carDetails?.prevPolicyType || "",
    policyExpCopy: carDetails?.policyExpDate || "",
    odPolicyExpCopy: carDetails?.prevPolicyODexpDate || "",
    // tpPolicyExpCopy: carDetails?.prevPolicyTPexpDate || "",
    isClaimPrevPolicyCopy: carDetails?.isClaimPrevPolicy,
    prevNcbCopy: carDetails?.prevNcb,
    newNcbCopy: carDetails?.newNcb,
    electricalAccesoriesCopy: carDetails?.electricalAccesories || "",
    nonElectricalAccesoriesCopy: carDetails?.nonElectricalAccesories || "",
    policyTypeValueCopy: carDetails?.PolicyTypeValue || "",
    idvValueCopy: carDetails?.idvValue || "",
  };

  const [carDetailsCopyState, setcarDetailsCopyState] =
    useState(carDetailsCopy);

  useEffect(() => {
    setRegDateClone(carDetailsCopyState?.regDateCopy);
  }, [carDetailsCopyState?.regDateCopy]);

  useEffect(() => {
    setOdExpDateClone(carDetailsCopyState?.odPolicyExpCopy);
  }, [carDetailsCopyState?.odPolicyExpCopy]);

  useEffect(() => {
    setExpDateClone(carDetailsCopyState?.policyExpCopy);
  }, [carDetailsCopyState?.policyExpCopy]);

  const [errors, setErrors] = useState({});

  const handleApplyClick = (e, fieldName) => {
    if (fieldName === "Accessories") {
      if (!ElectricalAmt && !NonElectricalAmt) {
        setErrors({
          ...errors,
          accesories: "Please select at least one accessory",
        });
      } else {
        let checked = true;
        setCarDetails((prevData) => ({
          ...prevData,
          electricalAccesories: ElectricalAmt,
          nonElectricalAccesories: NonElectricalAmt,
        }));
        setShowAccesoriesField(false);
        if (ShowAccesoriesField) {
          setTest((prevCheckboxes) =>
            prevCheckboxes.map((checkbox) =>
              checkbox.id === "checkbox12" ? { ...checkbox, checked } : checkbox
            )
          );
        }
      }
    } else if (fieldName === "PassengerCover") {
      // Check if PassengerCoverAmt is not null or undefined
      if (PassengerCoverAmt !== null && PassengerCoverAmt !== undefined) {
        let checked = true;
        setCarDetails((prevData) => ({
          ...prevData,
          pcCoverSumInsured: PassengerCoverAmt,
        }));
        setShowPcSumInsuredField(false);
        if (ShowPcSumInsuredField) {
          setTest((prevCheckboxes) =>
            prevCheckboxes.map((checkbox) =>
              checkbox.id === "checkbox11" ? { ...checkbox, checked } : checkbox
            )
          );
        }
      } else {
        // Handle the case where PassengerCoverAmt is null or undefined
        setErrors({
          ...errors,
          passengerCover: "Passenger amount is required",
        });
      }
    } else {
      console.log("jsjsjs");
    }
  };

  const handleCancelPassenger = () => {
    setShowPcSumInsuredField(false);
    setPassengerCoverAmt(null);
  };

  const cancelAccessoriesField = () => {
    setShowAccesoriesField(false);
    setElectricalAmt(null);
    setNonElectricalAmt(null);
  };

  const makeObj = {
    responseMakeName: carDetailsCopyState?.makeCopy,
    id: null,
    modelName: carDetailsCopyState?.modelCopy,
    selectedFuel: carDetailsCopyState?.fuelTypeCopy,
  };

  useEffect(() => {
    const matchingCar = MakeList?.find(
      (car) =>
        car?.make?.toLowerCase() === makeObj?.responseMakeName?.toLowerCase()
    );
    if (matchingCar) {
      makeObj.id = matchingCar?.make;
    }
    dispatch(getModelAction(makeObj.id));
    dispatch(getFuelTypeAction(makeObj));
    dispatch(getVariantAction(makeObj));
  }, [
    // duplicate keyWords
    MakeList,
    carDetailsCopyState?.makeCopy,
    carDetailsCopyState?.modelCopy,
    carDetailsCopyState?.fuelTypeCopy,
    carDetailsCopyState?.varientCopy,
  ]);

  const handleMakeSelect = (e) => {
    setcarDetailsCopyState((prevItems) => ({
      ...prevItems,
      makeCopy: e.value,
      modelCopy: null,
      varientCopy: null,
      fuelTypeCopy: null,
      ccCopy: null,
    }));
  };

  const handleModelSelect = (e) => {
    setcarDetailsCopyState((prevItems) => ({
      ...prevItems,
      modelCopy: e.value,
      varientCopy: null,
      fuelTypeCopy: null,
      ccCopy: null,
    }));
  };

  const handleFuelSelect = (e) => {
    setcarDetailsCopyState((prevItems) => ({
      ...prevItems,
      fuelTypeCopy: e.value,
      varientCopy: null,
      ccCopy: null,
    }));
  };

  const handleVariantSelect = (e) => {
    let getCC = variantListResponse?.find(variantList => variantList?.Variant === e.value);
    setcarDetailsCopyState((prevItems) => ({
      ...prevItems,
      varientCopy: e?.value,
      ccCopy: getCC?.Cubic_Capacity,
    }));
  };

  const handleCCSelect = (e) => {
    setcarDetailsCopyState((prevItems) => ({
      ...prevItems,
      ccCopy: e.value,
    }));
  };

  const handleCopiedDetails = (e, constName, newNcb) => {
    const { name, id, value } = e?.target || {};
    if (name === "makeCopy") {
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        makeCopy: value,
        modelCopy: null,
        varientCopy: null,
        fuelTypeCopy: null,
        ccCopy: null,
      }));
    } else if (name === "modelCopy") {
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        modelCopy: value,
        varientCopy: null,
        fuelTypeCopy: null,
        ccCopy: null,
      }));
    } else if (name === "varientCopy") {
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        varientCopy: value,
        ccCopy: null,
      }));
    } else if (name === "fuelTypeCopy") {
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        fuelTypeCopy: value,
        varientCopy: null,
        ccCopy: null,
      }));
    } else if (name === "ccCopy") {
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        ccCopy: value,
      }));
    } else if (name === "isClaimPrevPolicyCopy") {
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        isClaimPrevPolicyCopy: value === "Yes" ? true : false,
      }));
      if (value === "Yes") {
        setcarDetailsCopyState((prevItems) => ({
          ...prevItems,
          prevNcbCopy: "0",
          newNcbCopy: "0",
        }));
      } else {
        setcarDetailsCopyState((prevItems) => ({
          ...prevItems,
          prevNcbCopy: carDetails?.prevNcb,
          newNcbCopy: carDetails?.newNcb,
        }));
      }
    } else if (constName === "prevNcbCopy") {
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        prevNcbCopy: e,
        newNcbCopy: newNcb,
      }));
    } else if (constName === "regDateCopy") {
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        regDateCopy: e,
      }));
    } else if (constName === "odPolicyExpCopy") {
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        odPolicyExpCopy: e,
      }));
    } else if (constName === "policyExpCopy") {
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        policyExpCopy: e,
      }));
    } else {
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        [name]: value,
      }));
    }
  };

  const calculateMinAndMaxDate = () => {
    const currentDate = new Date();
    if (carDetails?.isPrevPolicyExp === false || SelectedAlternateExpOption === "Policy has not expired yet.") {

      const futureDate = new Date(currentDate);
      futureDate.setDate(currentDate.getDate() + 45);

      setMinDateValue(currentDate);
      setMaxDateValue(futureDate);

    } else if (carDetails?.prevPolicyExpLastMonth === true) {

      const yesterdayDate = new Date(currentDate);
      const ninetyDaysBackDate = new Date(currentDate);

      yesterdayDate.setDate(currentDate.getDate() - 180);
      ninetyDaysBackDate.setDate(currentDate.getDate() - 90);

      setMaxDateValue(ninetyDaysBackDate);
      setMinDateValue(yesterdayDate);

    } else if (carDetails?.isPrevPolicyExp === true || SelectedAlternateExpOption === "Policy expired in last 90 days.") {

      const yesterdayDate = new Date(currentDate);
      const ninetyDaysBackDate = new Date(currentDate);

      yesterdayDate.setDate(currentDate.getDate() - 1);
      ninetyDaysBackDate.setDate(currentDate.getDate() - 90);

      setMaxDateValue(yesterdayDate);
      setMinDateValue(ninetyDaysBackDate);

    } else if (SelectedAlternateExpOption === "Policy expired more than 90 days." || SelectedAlternateExpOption === "I purchased a used car.") {

      const yesterdayDate = new Date(currentDate);
      const ninetyDaysBackDate = new Date(currentDate);

      yesterdayDate.setDate(currentDate.getDate() - 180);
      ninetyDaysBackDate.setDate(currentDate.getDate() - 90);

      setMaxDateValue(ninetyDaysBackDate);
      setMinDateValue(yesterdayDate);

    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    calculateMinAndMaxDate();
  }, [vehicleData]);

  carsAge = moment().diff(carDetails?.policyRegDate, 'years', true).toFixed(1);

  useEffect(() => {
    if (carDetails?.prevPolicyType === "Third Party") {
      setPolicyTypeOption([
        { label: "Comprehensive", value: "Comprehensive" },
        { label: "Third Party", value: "Third Party" },
      ]);
    } else if (carDetails?.prevPolicyType === "Comprehensive") {
      setPolicyTypeOption([
        { label: "Comprehensive", value: "Comprehensive" },
        { label: "Third Party", value: "Third Party" },
      ]);
    } else if (
      (carDetails?.prevPolicyType === "Bundled (3Y TP + 1Y OD)" ||
        carDetails?.prevPolicyType === "Bundled") &&
      carsAge <= 2.6
    ) {
      setCarDetails((prevItems) => ({
        ...prevItems,
        PolicyTypeValue: "Own Damage",
      }));
      setPolicyTypeOption([{ label: "Own Damage", value: "Own Damage" }]);
    } else if (
      (carDetails?.prevPolicyType === "Bundled (3Y TP + 1Y OD)" ||
        carDetails?.prevPolicyType === "Bundled") &&
      (carsAge > 2.6)
    ) {
      setPolicyTypeOption([
        { label: "Comprehensive", value: "Comprehensive" },
        { label: "Third Party", value: "Third Party" },
      ]);
    } else if (businessType === NEW) {
      setPolicyTypeOption([{ label: "Bundled", value: "Bundled" }]);
    } else {
      console.log("Previous Policy Type useEffect()");
    }

    if (carDetails?.prevPolicyType === "Third Party" && carDetails?.PolicyTypeValue === "Comprehensive") {
      if (carDetails.typeOfBusiness != "New") {
        dispatch(
          isInitialAction({
            isInspectionReq: true,
          })
        );
        setCarDetails((prevItems) => ({
          ...prevItems,
          newNcb: "0",
          prevNcb: "0",
        }))
        setcarDetailsCopyState({
          ...carDetailsCopyState,
          prevNcbCopy: "0",
          newNcbCopy: "0",
        });
      }
    } else {
      dispatch(
        isInitialAction({
          isInspectionReq: false,
        })
      );
      setCarDetails((prevItems) => ({
        ...prevItems,
        newNcb: ncbData?.newNcb,
        prevNcb: ncbData?.prevNcb,
      }))
      setcarDetailsCopyState({
        ...carDetailsCopyState,
        prevNcbCopy: ncbData?.prevNcb,
        newNcbCopy: ncbData?.newNcb,
      });
    }

  }, [carDetails?.prevPolicyType, carDetails?.policyRegDate]);

  useEffect(() => {
    dispatch(
      allBodyDataAction({
        ...carDetails,
        ...{ coverage: [test] },
        ...getCalculatedDate,
        // ...carDetails.cngKitValue
      })
    );
  }, [getCalculatedDate]);

  useEffect(() => {
    setCarDetails((prevItems) => ({
      ...prevItems,
      idvValue: IdvValue,
    }));
  }, [IdvValue]);

  //setting min max in IDV range
  useEffect(() => {
    let minArr = Object.values(minIdvValue);
    let maxArr = Object.values(maxIdvValue);
    minArr = minArr.filter((val) => val !== 0);

    setAllIDVValues((prevItems) => ({
      ...prevItems,
      min: Math.min(...minArr).toFixed(2),
      max: Math.max(...maxArr).toFixed(2),
    }));

  }, [minIdvValue, maxIdvValue]);

  const handleCheckboxChange = (event) => {
    const { id, checked, value, name } = event.target;
    setShowSkeleton(true);
    if (id === "checkbox12" && checked === true) {
      return setShowAccesoriesField(true);
    }
    if (id === "checkbox12" && checked === false) {
      setCarDetails((prevData) => ({
        ...prevData,
        electricalAccesories: null,
        nonElectricalAccesories: null,
        // pcCoverSumInsured: PassengerCoverAmt,
      }));
      setNonElectricalAmt(null);
      setElectricalAmt(null);
    }
    if (id === "checkbox11" && checked === true) {
      return setShowPcSumInsuredField(true);
    }
    if (id === "checkbox11" && checked === false) {
      setCarDetails((prevData) => ({
        ...prevData,
        pcCoverSumInsured: null,
      }));
      setPassengerCoverAmt(null);
      setShowPcSumInsuredField(false);
    }
    setTest((prevCheckboxes) =>
      prevCheckboxes.map((checkbox) =>
        checkbox.id === id ? { ...checkbox, checked } : checkbox
      )
    );
  };

  const handleNcbValue = (prevPolicyType, currentPolicyType) => {
    if (businessType !== "New") {
      if (
        prevPolicyType === "Third Party" ||
        currentPolicyType === "Third Party"
      ) {
        setCarDetails((prevItems) => ({
          ...prevItems,
          newNcb: "0",
          prevNcb: "0",
        }));
        setcarDetailsCopyState((prevItems) => ({
          ...prevItems,
          newNcbCopy: "0",
          prevNcbCopy: "0",
        }));
      } else {
        setCarDetails((prevItems) => ({
          ...prevItems,
          newNcb: ncbData?.newNcb,
          prevNcb: ncbData?.prevNcb,
        }));
        setcarDetailsCopyState((prevItems) => ({
          ...prevItems,
          newNcbCopy: carDetails.newNcb,
          prevNcbCopy: carDetails.prevNcb,
        }));
      }
    } else {
      setCarDetails((prevItems) => ({
        ...prevItems,
        newNcb: "0",
        prevNcb: "0",
      }));
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        newNcbCopy: "0",
        prevNcbCopy: "0",
      }));
    }
  };

  let addoOns = [];

  useEffect(() => {
    async function checkNotWrokingAddOn() {
      let checkedValue = test.filter((addonCheckBox) => {
        return addonCheckBox.checked === true;
      });

      addoOns.push(...checkedValue);
      const result = addoOns.filter(
        (element) => !notWorkingAddons.includes(element)
      );

      if (!carDetails.isPrevPolicyExp) {
        let sbiResponseData = await dispatch(
          sbiQuickQuoteAction({
            data: {
              ...carDetails,
              ...{ coverage: result.length ? [result] : [test] },
              // ...getCalculatedDate,
            },
          })
        );

        if (
          sbiResponseData?.payload?.body?.data?.quote?.PolicyLobList?.[0]
            ?.PolicyRiskList[0]?.IDV_Suggested
        ) {
          setminIdvValue((prevItems) => ({
            ...prevItems,
            sbi: sbiResponseData?.payload?.body?.data?.quote?.PolicyLobList?.[0]
              ?.PolicyRiskList[0]?.MinIDV_Suggested
              ? sbiResponseData?.payload?.body?.data?.quote?.PolicyLobList?.[0]
                ?.PolicyRiskList[0]?.MinIDV_Suggested
              : 0,
          }));
          setmaxIdvValue((prevItems) => ({
            ...prevItems,
            sbi: sbiResponseData?.payload?.body?.data?.quote?.PolicyLobList?.[0]
              ?.PolicyRiskList[0]?.MaxIDV_Suggested
              ? sbiResponseData?.payload?.body?.data?.quote?.PolicyLobList?.[0]
                ?.PolicyRiskList[0]?.MaxIDV_Suggested
              : 0,
          }));
        }

        if (sbiResponseData?.payload?.body?.data?.quote?.code) {
          let tempNotWorking = addoOns.pop();
          setNotWorkingAddons([...notWorkingAddons, tempNotWorking]);
        }
      }
    };
    const cleanTimeOut = setTimeout(async () => {
      setShowLoader(false);
      setShowSkeleton(false);
      if (transactionId && updateCarDetails) {
        (async function () {
          let data = {
            uuid: transactionId,
            bodyData: {
              ...carDetails,
              ...{ coverage: [test] },
              ...getCalculatedDate,
            },
            vehicleNumber,
            businessType,
            isFindMyCar,
            vehicleData: vehicleData,
            carDetails,
            type: "update",
            addOnData: test
          };
          await dispatch(transactionURLAction(data));
        })();
      }
      Promise.all([
        checkNotWrokingAddOn(),
        // dispatch(
        //   zunoQuickQuoteAction({
        //     data: {
        //       ...carDetails,
        //       ...{ coverage: [test] },
        //     },
        //   })
        // ),
        dispatch(
          digitQuickQuoteAction({
            data: {
              ...carDetails,
              ...{ coverage: [test] },
            },
          })
        ),
        dispatch(
          iciciQuickQuoteAction({
            data: {
              ...carDetails,
              ...{ coverage: [test] },
            },
          })
        ),
        dispatch(
          shreeRamQuickQuoteAction({
            data: {
              ...carDetails,
              ...{ coverage: [test] },
            },
          })
        ),
        dispatch(
          ackoQuickQuoteAction({
            data: {
              ...carDetails,
              ...{ coverage: [test] },
            },
          })
        ),
      ]).then((values) => {
        //handle icici idv response ( change array position when uat merged)
        if (values[2]?.payload?.data?.idv) {
          setminIdvValue((prevItems) => ({
            ...prevItems,
            icici: values[2]?.payload?.data?.idv.min
              ? values[2]?.payload?.data?.idv.min
              : 0,
          }));
          setmaxIdvValue((prevItems) => ({
            ...prevItems,
            icici: values[2]?.payload?.data?.idv.max
              ? values[2]?.payload?.data?.idv.max
              : 0,
          }));
        }

        //handle shreeram idv response
        if (values[3]?.payload?.data?.quote?.GetQuotResult) {
          setminIdvValue((prevItems) => ({
            ...prevItems,
            shreeram: values[3]?.payload?.data?.quote?.GetQuotResult?.minIDV
              ? values[3]?.payload?.data?.quote?.GetQuotResult?.minIDV
              : 0,
          }));
          setmaxIdvValue((prevItems) => ({
            ...prevItems,
            shreeram: values[3]?.payload?.data?.quote?.GetQuotResult?.maxIDV
              ? values[3]?.payload?.data?.quote?.GetQuotResult?.maxIDV
              : 0,
          }));
        }

        //handle Zuno idv response
        // if (values[1]?.payload?.data?.quote) {
        //   setminIdvValue((prevItems) => ({
        //     ...prevItems,
        //     zuno: values[1]?.payload?.data?.minIdv
        //       ? values[1]?.payload?.data?.minIdv
        //       : 0,
        //   }));
        //   setmaxIdvValue((prevItems) => ({
        //     ...prevItems,
        //     zuno: values[1]?.payload?.data?.maxIdv
        //       ? values[1]?.payload?.data?.maxIdv
        //       : 0,
        //   }));
        // }

        //handle digit idv response ( change array position when uat merged)
        if (values[1]?.payload?.data?.quote) {
          setminIdvValue((prevItems) => ({
            ...prevItems,
            digit: values[1]?.payload?.data?.quote?.["Motor-Quick Quote"]
              ?.vehicle?.vehicleIDV?.minimumIdv
              ? values[1]?.payload?.data?.quote?.["Motor-Quick Quote"]?.vehicle
                ?.vehicleIDV?.minimumIdv
              : 0,
          }));
          setmaxIdvValue((prevItems) => ({
            ...prevItems,
            digit: values[1]?.payload?.data?.quote?.["Motor-Quick Quote"]
              ?.vehicle?.vehicleIDV?.maximumIdv
              ? values[1]?.payload?.data?.quote?.["Motor-Quick Quote"]?.vehicle
                ?.vehicleIDV?.maximumIdv
              : 0,
          }));
        }

        //handle acko idv response
        if (values[4]?.payload?.data?.quote?.insured.length) {
          setminIdvValue((prevItems) => ({
            ...prevItems,
            acko: values[4]?.payload?.data?.quote?.insured[0].idv.values[1].idv_amount
              ? values[4]?.payload?.data?.quote?.insured[0].idv.values[1].idv_amount
              : 0,
          }));
          setmaxIdvValue((prevItems) => ({
            ...prevItems,
            acko: values[4]?.payload?.data?.quote?.insured[0].idv.values[2].idv_amount
              ? values[4]?.payload?.data?.quote?.insured[0].idv.values[2].idv_amount
              : 0,
          }));
        }

      });
    }, 3000);
    setcarDetailsCopyState({
      vehicleNumberCopy: carDetails?.vehicleNumber?.toUpperCase() || "",
      makeCopy: carDetails?.make || "",
      modelCopy: carDetails?.model || "",
      varientCopy: carDetails?.varient || "",
      fuelTypeCopy: carDetails?.fuelType || "",
      ccCopy: carDetails?.cc || "",
      regDateCopy: carDetails?.policyRegDate || "",
      prevPolicyTypeCopy: carDetails?.prevPolicyType || "",
      policyExpCopy: carDetails?.policyExpDate || "",
      odPolicyExpCopy: carDetails?.prevPolicyODexpDate || "",
      // tpPolicyExpCopy: carDetails?.prevPolicyTPexpDate || "",
      isClaimPrevPolicyCopy: carDetails?.isClaimPrevPolicy,
      prevNcbCopy: carDetails?.prevNcb,
      newNcbCopy: carDetails?.newNcb,
      electricalAccesoriesCopy: carDetails?.electricalAccesories || "",
      nonElectricalAccesoriesCopy: carDetails?.nonElectricalAccesories || "",
      policyTypeValueCopy: carDetails?.PolicyTypeValue || "",
      idvValueCopy: carDetails?.idvValue || "",
    });
    return () => {
      clearTimeout(cleanTimeOut);
    };
  }, [carDetails, test]);

  useEffect(() => {
    handleNcbValue(carDetails?.prevPolicyType, carDetails?.PolicyTypeValue);
  }, [carDetails?.prevPolicyType, carDetails?.PolicyTypeValue]);

  function calculateExpDate(regDateStr) {
    if (
      new Date(carDetails?.policyExpDate).getFullYear() -
      new Date(regDateStr).getFullYear() <=
      3
    ) {
      const regDate = new Date(regDateStr);

      const expDate = new Date(carDetails?.policyExpDate);
      expDate.setFullYear(regDate.getFullYear() + 3);

      const expDateStr = new Date(expDate);
      return expDateStr;
    }
  }

  // main handler for carDetails object

  const handleInputCarDetails = (e, constantName, const1) => {
    const { name, value, label, checked } = e?.target || {};
    if (name === "isClaimPrevPolicy") {
      setCarDetails({
        ...carDetails,
        [name]: value === "Yes" ? true : false,
      });
      if (value === "Yes") {
        setCarDetails((prevItems) => ({
          ...prevItems,
          prevNcb: "0",
          newNcb: "0",
        }));
        ncbData.newNcb = "0";
        ncbData.prevNcb = "0";
        setcarDetailsCopyState({
          ...carDetailsCopyState,
          isClaimPrevPolicyCopy: true,
          prevNcbCopy: "0",
          newNcbCopy: "0",
        });
      } else {
        setCarDetails((prevItems) => ({
          ...prevItems,
          prevNcb: ncbData?.newNcb,
          newNcb: ncbData?.prevNcb,
        }));
        setcarDetailsCopyState({
          ...carDetailsCopyState,
          isClaimPrevPolicyCopy: false,
          prevNcbCopy: ncbData?.prevNcb,
          newNcbCopy: ncbData?.newNcb,
        });
      }
    } else if (name === "isCngLpgVehicleType") {
      setCarDetails({
        ...carDetails,
        [name]: value === "Yes" ? true : false,
      });
    } else if (constantName === "prevNcb") {
      setCarDetails({
        ...carDetails,
        prevNcb: e,
        newNcb: const1,
      });
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        prevNcbCopy: e,
        newNcbCopy: const1,
      }));
    } else if (constantName === "policyRegDate" || name === "policyRegDate") {
      let regDateValue = constantName ? e : value;
      setCarDetails({
        ...carDetails,
        policyRegDate: regDateValue,
      });
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        regDateCopy: regDateValue,
      }));
    } else if (constantName === "policyExpDate") {
      setCarDetails({
        ...carDetails,
        policyExpDate: e,
      });
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        policyExpCopy: e,
      }));
      if (const1 === "makeNcbModalTrue") {
        if (carDetails.prevPolicyType !== "Third Party") {
          setEditNcbMobile(true);
        }
      }
    } else if (constantName === "prevPolicyODexpDate") {
      setCarDetails({
        ...carDetails,
        prevPolicyODexpDate: e,
      });
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        odPolicyExpCopy: e,
      }));
      if (const1 === "makeNcbModalTrue") {
        if (carDetails.prevPolicyType !== "Third Party") {
          setEditNcbMobile(true);
        }
      }
    } else if (name === "prevPolicyType" || name === "PolicyTypeValue") {
      if (name === "prevPolicyType") {
        if (value === "Third Party") {
          setCarDetails({
            ...carDetails,
            prevPolicyType: value,
            newNcb: "0",
            prevNcb: "0",
          });
          setcarDetailsCopyState((prevItems) => ({
            ...prevItems,
            newNcbCopy: "0",
            prevNcbCopy: "0",
          }));
        } else {
          setCarDetails({
            ...carDetails,
            prevPolicyType: value,
            newNcb: ncbData?.newNcb,
            prevNcb: ncbData?.prevNcb,
          });
          setcarDetailsCopyState((prevItems) => ({
            ...prevItems,
            newNcbCopy: carDetails.newNcb,
            prevNcbCopy: carDetails.prevNcb,
          }));
        }
      } else if (name === "PolicyTypeValue") {
        setShowLoader(true);
        if (value === "Third Party") {
          setCarDetails({
            ...carDetails,
            PolicyTypeValue: value,
            newNcb: "0",
            prevNcb: "0",
          });
          setcarDetailsCopyState((prevItems) => ({
            ...prevItems,
            newNcbCopy: "0",
            prevNcbCopy: "0",
          }));
        } else {
          setCarDetails({
            ...carDetails,
            PolicyTypeValue: value,
            newNcb: ncbData?.newNcb,
            prevNcb: ncbData?.prevNcb,
          });
          setcarDetailsCopyState((prevItems) => ({
            ...prevItems,
            newNcbCopy: carDetails.newNcb,
            prevNcbCopy: carDetails.prevNcb,
          }));
        }
      }
    } else {
      setCarDetails({
        ...carDetails,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    if (updateCarDetails) {
      let regDate = carDetails?.policyRegDate;
      let getPolicyTypeDetails = getPolicyType(carDetails?.policyRegDate, carDetails?.policyExpDate);
      let expDate = calculateExpDate(regDate);
      setCarDetails({
        ...carDetails,
        policyRegDate: regDate,
        prevPolicyType: businessType !== NEW ? getPolicyTypeDetails : "",
        PolicyTypeValue:
          businessType !== NEW
            ? getPolicyTypeDetails === "Bundled" && carsAge <= 2.6
              ? "Own Damage"
              : getPolicyTypeDetails === "Bundled" && carsAge > 2.6
                ? "Comprehensive"
                : getPolicyTypeDetails
            : "Bundled",
        policyExpDate: expDate ? expDate : ExpiryDate,
        prevPolicyODexpDate:
          businessType != NEW ? (expDate ? ExpiryDate : "") : ExpiryDate,
      });
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        regDateCopy: regDate,
        policyExpCopy: expDate ? expDate : ExpiryDate,
        prevPolicyTypeCopy: businessType !== NEW ? getPolicyTypeDetails : "",
        odPolicyExpCopy:
          businessType != NEW ? (expDate ? ExpiryDate : "") : ExpiryDate,
      }));
    }
  }, [carDetails?.policyRegDate]);

  useEffect(() => {
    let presentDay = new Date();
    presentDay.setHours(0, 0, 0, 0);

    let policyExpDate = new Date(carDetails.policyExpDate);
    policyExpDate.setHours(0, 0, 0, 0);

    if (policyExpDate.getTime() < presentDay.getTime()) {
      if (carDetails?.prevPolicyExpLastMonth !== false) {
        // alert("ka ho!");
        setCarDetails((prevItems) => ({
          ...prevItems,
          newNcb: "0",
          prevNcb: "0",
        }))
        setcarDetailsCopyState({
          ...carDetailsCopyState,
          prevNcbCopy: "0",
          newNcbCopy: "0",
        });
      }
      if (carDetails.typeOfBusiness != "New") {
        dispatch(
          isInitialAction({
            isInspectionReq: true,
          })
        );
      }
    } else {
      setCarDetails((prevItems) => ({
        ...prevItems,
        newNcb: ncbData?.newNcb,
        prevNcb: ncbData?.prevNcb,
        isPrevPolicyExp: false,
        prevPolicyExpLastMonth: false,
      }))
      setcarDetailsCopyState({
        ...carDetailsCopyState,
        prevNcbCopy: ncbData?.prevNcb,
        newNcbCopy: ncbData?.newNcb,
      });
      dispatch(
        isInitialAction({
          isInspectionReq: false,
        })
      );
    }
  }, [carDetails.policyExpDate])

  const cancelCalendarValue = (name) => {
    // alert(name);
    switch (name) {
      case "regDateCopy":
        setcarDetailsCopyState((prevItems) => ({
          ...prevItems,
          regDateCopy: carDetails?.policyRegDate,
        }));
        setShowRegDateCalendar(false);
        break;

      case "odPolicyExpCopy":
        setcarDetailsCopyState((prevItems) => ({
          ...prevItems,
          odPolicyExpCopy: carDetails?.prevPolicyODexpDate,
        }));
        setShowOdExpDateCalendar(false);
        break;

      case "policyExpCopy":
        setcarDetailsCopyState((prevItems) => ({
          ...prevItems,
          policyExpCopy: carDetails?.policyExpDate,
        }));
        setShowExpDateCalendar(false);
        break;

      default:
        break;
    }
  };

  const cancelCalendarValueFlow = (name) => {
    // alert(name);
    if (name === "odPolicyExpCopy") {
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        odPolicyExpCopy: carDetails?.prevPolicyODexpDate,
      }));
      setShowFlowOdCalendar(false);
      setPrevPolicyTypeList(true);
    } else if (name === "policyExpCopy") {
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        policyExpCopy: carDetails?.policyExpDate,
      }));
      setShowFlowExpCalendar(false);
      setPrevPolicyTypeList(true);
    } else {
      console.log("kdkdkd");
    }
  };

  const saveCalendarValue = (name) => {
    switch (name) {
      case "regDateCopy":
        handleInputCarDetails(
          carDetailsCopyState?.regDateCopy,
          "policyRegDate",
          ""
        );
        setShowRegDateCalendar(false);
        break;

      case "odPolicyExpCopy":
        handleInputCarDetails(
          carDetailsCopyState?.odPolicyExpCopy,
          "prevPolicyODexpDate",
          ""
        );
        setShowOdExpDateCalendar(false);
        break;

      case "policyExpCopy":
        handleInputCarDetails(
          carDetailsCopyState?.policyExpCopy,
          "policyExpDate",
          ""
        );
        setShowExpDateCalendar(false);
        break;

      default:
        break;
    }
  };

  const saveCalendarValueExpFlow = (name) => {
    switch (name) {
      case "odPolicyExpCopy":
        setShowFlowOdCalendar(false);
        setPrevPolicyTypeList(true);
        break;

      case "policyExpCopy":
        setShowFlowExpCalendar(false);
        setPrevPolicyTypeList(true);
        break;

      default:
        break;
    }
  }

  const saveCalendarValueModal = (name) => {
    // alert(name)
    switch (name) {
      case "regDateCopy":
        setcarDetailsCopyState((prevItems) => ({
          ...prevItems,
          regDateCopy: RegDateClone,
        }));
        setShowRegDateCalendarModal(false);
        break;

      case "odPolicyExpCopy":
        setcarDetailsCopyState((prevItems) => ({
          ...prevItems,
          odPolicyExpCopy: OdExpDateClone,
        }));
        setShowOdExpDateCalendarModal(false);
        break;

      case "policyExpCopy":
        setcarDetailsCopyState((prevItems) => ({
          ...prevItems,
          policyExpCopy: ExpDateClone,
        }));
        setShowExpDateCalendarModal(false);
        // setEditNcbMobile(true);
        break;

      default:
        break;
    }
  }

  const cancelCalendarValueModal = (name) => {
    // alert(name);
    switch (name) {
      case "regDateCopy":
        setRegDateClone(carDetailsCopyState?.regDateCopy);
        setShowRegDateCalendarModal(false);
        break;

      case "odPolicyExpCopy":
        setOdExpDateClone(carDetailsCopyState?.odPolicyExpCopy);
        setShowOdExpDateCalendarModal(false);
        break;

      case "policyExpCopy":
        setExpDateClone(carDetailsCopyState?.policyExpCopy);
        setShowExpDateCalendarModal(false);
        break;

      default:
        break;
    }
  }

  let count = test.filter((data) => {
    return data.checked == true;
  });

  let newCheckBoxData = [];

  if (AddonsForMobile === true && carDetails?.PolicyTypeValue === "Third Party") {
    newCheckBoxData = test.filter((data) => {
      return data.id === "checkbox10" || data.id === "checkbox11" || data.id === "checkbox1";
    });
  } else if (AddonsForMobile === true && carDetails?.PolicyTypeValue === "Own Damage") {
    newCheckBoxData = test.filter((data) => {
      return (
        data.id != "checkbox1" &&
        data.id != "checkbox10" &&
        data.id != "checkbox11"
      );
    });
  } else if (carDetails?.PolicyTypeValue === "Third Party") {
    newCheckBoxData = test.filter((data) => {
      return data.id === "checkbox10" || data.id === "checkbox11" || data.id === "checkbox1";
    });
  } else if (carDetails?.PolicyTypeValue === "Own Damage") {
    newCheckBoxData = test.filter((data) => {
      return (
        data.id != "checkbox1" &&
        // data.id != "checkbox2" &&
        data.id != "checkbox10" &&
        data.id != "checkbox11"
      );
    });
  } else if (AddonsForMobile) {
    newCheckBoxData = test.filter((data) => {
      return data;
    });
  } else {
    newCheckBoxData = test.filter((data) => {
      return data;
    });
  }

  const AddonsListTwo = newCheckBoxData.map((data) => ({
    label: (
      <CheckBox
        label={data.label}
        name={data.name}
        value={data.value}
        className={data.className}
        changeEvent={handleCheckboxChange}
        checked={data.checked}
        id={data.id}
        idForDropdown="topAddon"
      />
    ),
  }));

  const insurerDropDownMenu = insurerList.map((data, key) => ({
    label: (
      <CheckBox
        label={data.label}
        value={data.value}
        className={data.className}
        changeEvent={data.changeEvent}
        idForDropdown={data.idForDropdown}
        idForP={data.idForP}
      />
    ),
  }));

  const handleBlur = (e) => {
    setOnBLurIdbValue(e?.target?.value);
    setCarDetails((prevItems) => ({
      ...prevItems,
      idvValue: e?.target?.value,
    }))
  };

  const handleIDVinput = (e) => {
    const inputValue = e?.target?.value;
    const minValue = allIDVValues.min === Infinity ? 0 : allIDVValues.min;

    setCarDetails((prevItems) => ({
      ...prevItems,
      idvValue: inputValue !== minValue ? inputValue : minValue,
    }));
  };

  useEffect(() => {
    if (transactionId) {
      (async function () {
        setShowLoader(true);
        let data = {
          uuid: transactionId,
          bodyData: {
            ...carDetails,
            ...{ coverage: [test] },
            ...getCalculatedDate,
          },
          vehicleNumber,
          businessType,
          isFindMyCar,
          vehicleData: vehicleData,
          carDetails,
          type: "fetch",
          addOnData: test
        };
        let resp = await dispatch(transactionURLAction(data));
        setTimeout(() => {
          setUpdateCarDetails(true)
        }, 4000);
        if (Array.isArray(resp?.payload?.data?.txninfo)) {
          if (resp?.payload?.data?.txninfo[0].car_details_obj
            && resp?.payload?.data?.txninfo[0].vehicle_data) {
            setShowFlowExpCalendar(false);
            setShowFlowOdCalendar(false);
            await dispatch(
              isViewPlanFlowAction({
                isViewPlanFlowCompleted: true,
              })
            );
            let carDetailsObj = JSON.parse(resp?.payload?.data?.txninfo[0].car_details_obj);

            let bodyData = JSON.parse(resp?.payload?.data?.txninfo[0].body_data)
            await dispatch(allBodyDataAction(bodyData));

            setTest(bodyData?.coverage[0])
            vehicleNumber = resp?.payload?.data?.txninfo[0].vehicle_number;
            setBusinessType(resp?.payload?.data?.txninfo[0].business_type);
            setCarDetails(carDetailsObj);
            carDetailsObj.makeCopy = carDetailsObj?.make;
            carDetailsObj.modelCopy = carDetailsObj?.model;
            carDetailsObj.fuelTypeCopy = carDetailsObj?.fuelType;
            carDetailsObj.varientCopy = carDetailsObj?.varient;
            setcarDetailsCopyState(carDetailsObj)
            setncbData({
              newNcb: carDetailsObj?.newNcb,
              prevNcb: carDetailsObj?.prevNcb
            })
            setncbCopy({
              newNcb: carDetailsObj?.newNcb,
              prevNcb: carDetailsObj?.prevNcb
            })

            //for inspection tag
            if (carDetailsObj?.prevPolicyType === "Third Party" && carDetailsObj?.PolicyTypeValue === "Comprehensive") {
              if (carDetailsObj.typeOfBusiness != "New") {
                dispatch(
                  isInitialAction({
                    isInspectionReq: true,
                  })
                );
              }
            } else {
              dispatch(
                isInitialAction({
                  isInspectionReq: false,
                })
              );
            }
            let vehDetailsObj = JSON.parse(resp?.payload?.data?.txninfo[0].vehicle_data);
            vehDetailsObj.make = carDetailsObj?.make;
            vehDetailsObj.model = carDetailsObj?.model;
            vehDetailsObj.varient = carDetailsObj?.varient;
            vehDetailsObj.cc = carDetailsObj?.cc;

            let vehicleDataObj = resp?.payload?.data?.txninfo[0].business_type == ROLLOVER ?
              ({ data: vehDetailsObj }) :
              ({
                data: {
                  data: vehDetailsObj
                }
              });
            await dispatch(
              updateVehicleInfoAction({ ...vehicleDataObj })
            );
          } else {
            if (businessType !== NEW && getviewPlanFlowStatus !== true && vehicleData.prevPolicyExpLastMonth !== true) {
              if (PolicyTypeValue === "Bundled" && carsAge < 2.5) {
                setShowFlowOdCalendar(true);
              } else {
                setShowFlowExpCalendar(true);
              }
            }
          }
        }
        setShowLoader(false);
        return () => { }
      })();
    }
  }, [])

  const HandleInsurerList = (e) => {
    console.log(e.target.value, "HandleInsurerList");
  };

  // fun for dropdown option container

  document.onclick = function (e) {
    let id = e?.target?.id;
    let parentId = e?.target?.parentNode?.id;
    if (
      id === "policyTypeHead" ||
      id === "idForArrowPD" ||
      id === "idForHeadTxtPD" ||
      id === "idForPtinp"
    ) {
      setshowPolicyDropdown(!showPolicyDropdown);
      setshowAddonMenu(false);
      setshowInsDropDown(false);
    } else if (
      id === "addonHead" ||
      id === "idForArrowAddon" ||
      id === "addonHeadTxt"
    ) {
      setshowAddonMenu(!showAddonMenu);
      setshowPolicyDropdown(false);
      setshowInsDropDown(false);
    } else if (
      id === "insHead" ||
      id === "insHeadTxt" ||
      id === "insHeadArrow"
    ) {
      setshowInsDropDown(!showInsDropDown);
      setshowPolicyDropdown(false);
      setshowAddonMenu(false);
    } else if (parentId === "insDpContainer" || parentId === "insParentDp") {
      setshowInsDropDown(true);
      setshowPolicyDropdown(false);
      setshowAddonMenu(false);
    } else if (parentId === "topAddon" || id === "addonsList") {
      setshowAddonMenu(true);
      setshowPolicyDropdown(false);
      setshowInsDropDown(false);
    } else if (id === "policyTypeList") {
      setshowPolicyDropdown(true);
      setshowInsDropDown(false);
      setshowAddonMenu(false);
    } else if (
      e.target.className === "ReactModal_Overlay ReactModal_Overlay--after-open"
    ) {
      console.log("kakakaka");
    } else {
      setshowPolicyDropdown(false);
      setshowAddonMenu(false);
      setshowInsDropDown(false);
    }
  };

  const showAddonDropdown = (e) => {
    setshowAddonMenu(!showAddonMenu);
  };

  const AddonsList = newCheckBoxData.map((data) => (
    <ToolTip
      condition={ShowAccesoriesField}
      conditionTwo={ShowPcSumInsuredField}
      key={data.id}
      content={data.tooltip || "No Data"}
    >
      <CheckBox
        // Mandate by IRDAI
        label={data.label === "Mandate by IRDAI" ? "PA Cover" : data.label}
        name={data.name}
        value={data.value}
        className={data.className}
        changeEvent={handleCheckboxChange}
        checked={data.checked}
        id={data.id}
      />
    </ToolTip>
  ));

  const handlePopUps = () => {
    if (ShowPassengerAmtList === true) {
      setShowPassengerAmtList(false);
    }

    if (ShowNumber) {
      setShowNumber(false);
    }
  };

  // CALCULATING PREVIOUS POLICY TYPE:

  if (carsAge < 3) {
    policyTypeList = [
      {
        value: "Bundled",
        label: "Bundled",
        desc: "1 Year OD + 3 year Third Party",
      },
    ];
  } else if (carsAge > 3 && carsAge < 3.5) {
    policyTypeList = [
      {
        value: "Comprehensive",
        label: "Comprehensive",
        desc: "1 Year OD + 1 year Third Party",
      },
      {
        value: "Third Party",
        label: "Third Party",
        desc: "Offers protection against damages to the Third-Party by the insured vehicle.",
      },
      {
        value: "Bundled",
        label: "Bundled (3Y TP + 1Y OD)",
        desc: "1 Year OD + 3 year Third Party",
      },
    ];
  } else {
    policyTypeList = [
      {
        value: "Comprehensive",
        label: "Comprehensive",
        desc: "1 Year OD + 1 year Third Party",
      },
      {
        value: "Third Party",
        label: "Third Party",
        desc: "Offers protection against damages to the Third-Party by the insured vehicle.",
      },
    ];
  }

  const savePrevPolicySection = (sectionType) => {
    setcarDetailsCopyState((prevItems) => ({
      ...prevItems,
      policyTypeValueCopy: carDetailsCopyState?.prevPolicyTypeCopy,
    }))
    setPrevPolicyTypeList(false);
    if (carDetailsCopyState?.prevPolicyTypeCopy !== "Third Party" && carDetails?.prevPolicyExpLastMonth !== true) {
      setEditNcbMobile(true);
    } else {
      if (SelectedAlternateExpOption === "Policy expired in last 90 days.") {
        setEditNcbMobile(true);
      } else {
        setCarDetails((prevItems) => ({
          ...prevItems,
          prevNcb: "0",
          newNcb: "0",
          policyExpDate: carDetailsCopyState?.policyExpCopy,
          prevPolicyODexpDate: carDetailsCopyState?.odPolicyExpCopy || "",
          prevPolicyType: carDetailsCopyState?.prevPolicyTypeCopy,
        }));
        dispatch(
          isViewPlanFlowAction({
            isViewPlanFlowCompleted: true,
          })
        );
      }
    }
  };

  const handlePrevPolicySelection = (planType) => {
    setcarDetailsCopyState((prevItems) => ({
      ...prevItems,
      prevPolicyTypeCopy: planType,
    }));
  };

  const cancelPrevPolicySection = (sectionType) => {
    setcarDetailsCopyState((prevItems) => ({
      ...prevItems,
      prevPolicyTypeCopy: carDetails?.prevPolicyType,
    }));
    setPrevPolicyTypeList(false)
  }

  const handleModalDates = (value, name, emptyString) => {
    switch (name) {
      case "regDateCopy":
        setRegDateClone(value);
        break;

      case "odPolicyExpCopy":
        setOdExpDateClone(value);
        break;

      case "policyExpCopy":
        setExpDateClone(value);
        break;

      default:
        break;
    }
  }

  useEffect(() => {
    if (
      EditPolicyTypeMobile
      || ShowRegDateCalendar
      || EditMmvMobile
      || ShowIdvTab
      || AddonsForMobile
      || EditNcbMobile
      || ShowExpDateCalendar
      || ShowLoader
      || (CompanyWiseLoader && LoaderFromConfirmPg)
    ) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    // Cleanup function to reset the overflow property when the component unmounts
    return () => {
      document.body.style.overflow = '';
    };
  }, [
    EditPolicyTypeMobile,
    ShowRegDateCalendar,
    EditMmvMobile,
    ShowIdvTab,
    AddonsForMobile,
    EditNcbMobile,
    ShowExpDateCalendar,
    ShowLoader,
    CompanyWiseLoader
  ]);

  const getAlternateExpOption = (option, calendarType) => {
    setSelectedAlternateExpOption(option)
    const currentDate = new Date();
    let expirationDate;

    switch (option) {
      case "Policy has not expired yet.":
        expirationDate = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000);
        setPrevPolicyTypeList(true);
        setShowFlowExpCalendar(false);
        setShowFlowOdCalendar(false);
        // prevent user to select past dates
        const futureDate = new Date(currentDate);
        futureDate.setDate(currentDate.getDate() + 45);

        setMinDateValue(currentDate);
        setMaxDateValue(futureDate);
        dispatch(
          isInitialAction({
            isInspectionReq: false,
          })
        );
        break;
      case "Policy expired in last 90 days.":
        expirationDate = new Date(currentDate.getTime() - 60 * 24 * 60 * 60 * 1000);
        dispatch(
          isInitialAction({
            isInspectionReq: true,
          })
        );
        const yesterdayDate1 = new Date(currentDate);
        const ninetyDaysBackDate1 = new Date(currentDate);

        yesterdayDate1.setDate(currentDate.getDate() - 1);
        ninetyDaysBackDate1.setDate(currentDate.getDate() - 90);

        setMaxDateValue(yesterdayDate1);
        setMinDateValue(ninetyDaysBackDate1);
        // prevent user to select future dates
        setPrevPolicyTypeList(true);
        setShowFlowExpCalendar(false);
        setShowFlowOdCalendar(false);
        break;
      case "Policy expired more than 90 days.":
        expirationDate = new Date(currentDate.getTime() - 90 * 24 * 60 * 60 * 1000);
        const yesterdayDate = new Date(currentDate);
        const ninetyDaysBackDate = new Date(currentDate);

        yesterdayDate.setDate(currentDate.getDate() - 180);
        ninetyDaysBackDate.setDate(currentDate.getDate() - 90);

        setMaxDateValue(ninetyDaysBackDate);
        setMinDateValue(yesterdayDate);
        dispatch(
          isInitialAction({
            isInspectionReq: true,
          })
        );
        // prevent user to select dates under past 90 days
        setcarDetailsCopyState((prevItems) => ({
          ...prevItems,
          prevNcbCopy: "0",
          newNcbCopy: "0",
        }));
        setPrevPolicyTypeList(true);
        setShowFlowExpCalendar(false);
        setShowFlowOdCalendar(false);
        break;
      case "I purchased a used car.":
        expirationDate = new Date(currentDate.getTime() - 90 * 24 * 60 * 60 * 1000);
        dispatch(
          isInitialAction({
            isInspectionReq: true,
          })
        );

        const yesterdayDate2 = new Date(currentDate);
        const ninetyDaysBackDate2 = new Date(currentDate);

        yesterdayDate2.setDate(currentDate.getDate() - 180);
        ninetyDaysBackDate2.setDate(currentDate.getDate() - 90);

        setMaxDateValue(ninetyDaysBackDate2);
        setMinDateValue(yesterdayDate2);

        // prevent user to select dates under past 90 days
        setcarDetailsCopyState((prevItems) => ({
          ...prevItems,
          prevNcbCopy: "0",
          newNcbCopy: "0",
        }));

        setPrevPolicyTypeList(true);
        setShowFlowExpCalendar(false);
        setShowFlowOdCalendar(false);
        break;
      default:
        console.log("Invalid option");
        return;
    };

    if (calendarType === "expDate") {
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        policyExpCopy: expirationDate,
      }))
    } else {
      setcarDetailsCopyState((prevItems) => ({
        ...prevItems,
        odPolicyExpCopy: expirationDate,
      }))
    }
  };

  return (
    <>
      <Navbar />
      <CallWidget revealState={ShowNumber} updateRevealState={setShowNumber} />
      {
        (ShowLoader || (CompanyWiseLoader && LoaderFromConfirmPg)) && (
          <div id="cover-spin">
            <img src={loader2} className="gifLoader"></img>
          </div>
        )
      }
      <div className="vp-container" onClick={(e) => handlePopUps()}>
        <section className="viewPlans_content_container">
          <div
            className="viewPlans_content_parent maxWidth"
            id={
              carDetails?.PolicyTypeValue === "Third Party" ||
                carDetails?.prevPolicyType === "Third Party" ||
                vehicleData?.prevPolicyExpLastMonth === true
                ? "flexStart"
                : ""
            }
          >
            <div
              className={
                businessType === "New"
                  ? "viewPlans_content_child-new "
                  : "viewPlans_content_childOne viewPlans_content_child"
              }
              id={
                carDetails?.PolicyTypeValue === "Third Party" ||
                  carDetails?.prevPolicyType === "Third Party" ||
                  vehicleData?.prevPolicyExpLastMonth === true

                  ? "noBorderVp"
                  : ""
              }
            >
              <Paper
                businessType={businessType}
                mainTitle="My Car Details"
                heading="RTO Registration Number:"
                desc={
                  businessType !== NEW
                    ? carDetails?.vehicleNumber?.toUpperCase()
                    : vehicleData?.rto
                }
                headingTwo="Company Model:"
                HeadFour="RTO Registered Date:"
                classNameDate="datepicker-viewplans viewPlan_paper_child_title"
                descTwo={`${carDetails?.make || ""} ${carDetails?.model || ""
                  } ${carDetails?.varient || ""} (${carDetails?.cc || ""} CC)`}
                HeadThree="Fuel Type:"
                descThree={carDetails?.fuelType || ""}
                renderContent="editCarDetails"
                HandleRegDate={(e) => handleInputCarDetails(e)}
                value={carDetails?.policyRegDate}
                name="policyRegDate"
                carDetails={{ ...carDetails, ...getCalculatedDate }}
                handleInputCarDetails={handleInputCarDetails}
                MakeList={MakeList}
                FuelTypeList={FuelTypeList}
                ccList={ccList}
                ModelListArray={ModelListArray}
                VarientList={VarientList}
                setCarDetails={setCarDetails}
                carDetailsCopyState={carDetailsCopyState}
                handleCopiedDetails={handleCopiedDetails}
                setcarDetailsCopyState={setcarDetailsCopyState}
                handleMakeSelect={handleMakeSelect}
                handleModelSelect={handleModelSelect}
                handleFuelSelect={handleFuelSelect}
                handleVariantSelect={handleVariantSelect}
                handleCCSelect={handleCCSelect}
                componentClass={viewPlansClassNameForPaper}
                handleNcbValue={handleNcbValue}
                ShowRegDateCalendar={ShowRegDateCalendarModal}
                setShowRegDateCalendar={setShowRegDateCalendarModal}
                component={
                  <CustomDateField
                    className="customDatePicker customDatePicker-tab"
                    btnId="policyRegDateBtn"
                    textId="policyRegDateTxt"
                    imgId="policyRegDateImg"
                    dateValue={carDetails?.policyRegDate}
                    calendarReveal={ShowRegDateCalendar}
                    setCalendarReveal={setShowRegDateCalendar}
                  />
                }
              />
            </div>
            {businessType !== "New" && (
              <>
                <div
                  className="viewPlans_content_child"
                  id={
                    carDetails?.PolicyTypeValue === "Third Party" ||
                      carDetails?.prevPolicyType === "Third Party" ||
                      vehicleData?.prevPolicyExpLastMonth === true
                      ? "noBorderLeft"
                      : ""
                  }
                >
                  <Paper
                    businessType={businessType}
                    mainTitle="My Existing Policy Details"
                    heading="Policy Type:"
                    desc={
                      carDetails?.prevPolicyType === "Bundled (3Y TP + 1Y OD)"
                        ? "Bundled"
                        : carDetails?.prevPolicyType
                    }
                    HeadThree={
                      carDetails?.PolicyTypeValue === "Own Damage"
                        ? "OD Expiry Date:"
                        : (carDetails?.prevPolicyType === "Bundled" ||
                          carDetails?.prevPolicyType ===
                          "Bundled (3Y TP + 1Y OD)")
                          ? "Bundled Expiry Date:"
                          : "Policy Expiry Date:"
                    }
                    classNameDate="datepicker-viewplans viewPlan_paper_child_title"
                    renderContent="editPolDetails"
                    PolicyTypeValue={carDetails?.prevPolicyType}
                    handleInputCarDetails={handleInputCarDetails}
                    carDetails={{ ...carDetails, ...getCalculatedDate }}
                    HandleExpDate={(e) => handleInputCarDetails(e)}
                    value={carDetails?.policyExpDate}
                    name="policyExpDate"
                    MakeList={MakeList}
                    setCarDetails={setCarDetails}
                    carDetailsCopyState={carDetailsCopyState}
                    handleCopiedDetails={handleCopiedDetails}
                    setcarDetailsCopyState={setcarDetailsCopyState}
                    componentClass={viewPlansClassNameForPaper}
                    showCurrentPolicyType={false}
                    handleNcbValue={handleNcbValue}
                    policyTypeList={policyTypeList}
                    ShowOdExpDateCalendar={ShowOdExpDateCalendarModal}
                    setShowOdExpDateCalendar={setShowOdExpDateCalendarModal}
                    ShowExpDateCalendar={ShowExpDateCalendarModal}
                    setShowExpDateCalendar={setShowExpDateCalendarModal}
                    componentTwo={
                      <>
                        {carDetails?.PolicyTypeValue === "Own Damage" ? (
                          <CustomDateField
                            className="customDatePicker customDatePicker-tab"
                            btnId="policyOdExpDateBtn"
                            textId="policyOdExpDateTxt"
                            imgId="policyOdExpDateImg"
                            dateValue={carDetails?.prevPolicyODexpDate}
                            calendarReveal={ShowOdExpDateCalendar}
                            setCalendarReveal={setShowOdExpDateCalendar}
                          />
                        ) : (
                          <CustomDateField
                            className="customDatePicker customDatePicker-tab"
                            btnId="policyExpDateBtn"
                            textId="policyExpDateTxt"
                            imgId="policyExpDateImg"
                            dateValue={carDetails?.policyExpDate}
                            calendarReveal={ShowExpDateCalendar}
                            setCalendarReveal={setShowExpDateCalendar}
                          />
                        )}
                      </>
                    }
                  />
                </div>
                {carDetails?.PolicyTypeValue === "Third Party" ||
                  carDetails?.prevPolicyType === "Third Party" ||
                  carDetails?.prevPolicyExpLastMonth === true ? (
                  ""
                ) : (
                  <div className="viewPlans_content_child">
                    <Paper
                      businessType={businessType}
                      mainTitle="No Claim Bonus Details"
                      heading="Claim:"
                      desc={carDetails?.isClaimPrevPolicy ? "Yes" : "No"}
                      headingTwo={
                        !carDetails?.isClaimPrevPolicy ? "New NCB:" : ""
                      }
                      descTwo={
                        !carDetails?.isClaimPrevPolicy
                          ? carDetails?.newNcb + "%" || 0 + "%"
                          : ""
                      }
                      HeadThree={
                        !carDetails?.isClaimPrevPolicy ? "Previous NCB:" : ""
                      }
                      descThree={
                        !carDetails?.isClaimPrevPolicy
                          ? carDetails?.prevNcb + "%" || 0 + "%"
                          : ""
                      }
                      isClaimPrevPolicy={carDetails?.isClaimPrevPolicy}
                      renderContent="editClaimDetails"
                      classNameDate="viewPlan_paper_child_title"
                      componentClass={viewPlansClassNameForPaper}
                      handleInputCarDetails={handleInputCarDetails}
                      carDetails={{ ...carDetails, ...getCalculatedDate }}
                      setCarDetails={setCarDetails}
                      carDetailsCopyState={carDetailsCopyState}
                      handleCopiedDetails={handleCopiedDetails}
                      handleMakeSelect={handleMakeSelect}
                      setcarDetailsCopyState={setcarDetailsCopyState}
                      handleNcbValue={handleNcbValue}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </section>

        {/* *******************************************CALENDAR-POPUPS********************************* */}

        {/* reg date calendar */}

        {
          (ShowRegDateCalendar || ShowRegDateCalendarModal) && (
            <CustomCalendar
              value={
                ShowRegDateCalendarModal
                  ? RegDateClone
                  : carDetailsCopyState?.regDateCopy
              }
              headText="Enter your car registration date"
              name="regDateCopy"
              btnId="policyRegDateBtn"
              textId="policyRegDateTxt"
              imgId="policyRegDateImg"
              revealState={
                ShowRegDateCalendarModal
                  ? ShowRegDateCalendarModal
                  : ShowRegDateCalendar
              }
              setRevealState={
                ShowRegDateCalendarModal
                  ? setShowRegDateCalendarModal
                  : setShowRegDateCalendar
              }
              handleInputCarDetails={handleInputCarDetails}
              handleCopiedDetails={
                ShowRegDateCalendarModal
                  ? handleModalDates
                  : handleCopiedDetails
              }
              cancelCalendarValue={
                ShowRegDateCalendarModal
                  ? cancelCalendarValueModal
                  : cancelCalendarValue
              }
              saveCalendarValue={
                ShowRegDateCalendarModal
                  ? saveCalendarValueModal
                  : saveCalendarValue
              }
              maxDate={maxRegDate}
            />
          )
        }

        {/* od exp date calendar */}

        {
          (ShowOdExpDateCalendar || ShowOdExpDateCalendarModal) && (
            <CustomCalendar
              value={
                ShowOdExpDateCalendarModal
                  ? OdExpDateClone
                  : carDetailsCopyState?.odPolicyExpCopy
              }
              headText="Enter Own Damage Expiry date"
              name="odPolicyExpCopy"
              btnId="policyOdExpDateBtn"
              textId="policyOdExpDateTxt"
              imgId="policyOdExpDateImg"
              revealState={
                ShowOdExpDateCalendarModal
                  ? ShowOdExpDateCalendarModal
                  : ShowOdExpDateCalendar
              }
              setRevealState={
                ShowOdExpDateCalendarModal
                  ? setShowOdExpDateCalendarModal
                  : setShowOdExpDateCalendar
              }
              handleInputCarDetails={handleInputCarDetails}
              handleCopiedDetails={
                ShowOdExpDateCalendarModal
                  ? handleModalDates
                  : handleCopiedDetails
              }
              cancelCalendarValue={
                ShowOdExpDateCalendarModal
                  ? cancelCalendarValueModal
                  : cancelCalendarValue
              }
              saveCalendarValue={
                ShowOdExpDateCalendarModal
                  ? saveCalendarValueModal
                  : saveCalendarValue
              }
              maxDate={carDetails?.prevPolicyType == "Bundled" ? MaxDateValue : ""}
              minDate={carDetails?.prevPolicyType == "Bundled" ? MinDateValue : ""}
            />
          )
        }

        {/* expiry date calendar */}

        {
          (ShowExpDateCalendar || ShowExpDateCalendarModal) && (
            <CustomCalendar
              value={
                ShowExpDateCalendarModal
                  ? ExpDateClone
                  : carDetailsCopyState?.policyExpCopy
              }
              headText="Enter Previous Policy Expiry date"
              name="policyExpCopy"
              btnId="policyExpDateBtn"
              textId="policyExpDateTxt"
              imgId="policyExpDateImg"
              revealState={
                ShowExpDateCalendarModal
                  ? ShowExpDateCalendarModal
                  : ShowExpDateCalendar
              }
              setRevealState={
                ShowExpDateCalendarModal
                  ? setShowExpDateCalendarModal
                  : setShowExpDateCalendar
              }
              handleInputCarDetails={handleInputCarDetails}
              handleCopiedDetails={
                ShowExpDateCalendarModal
                  ? handleModalDates
                  : handleCopiedDetails
              }
              cancelCalendarValue={
                ShowExpDateCalendarModal
                  ? cancelCalendarValueModal
                  : cancelCalendarValue
              }
              saveCalendarValue={
                ShowExpDateCalendarModal
                  ? saveCalendarValueModal
                  : saveCalendarValue
              }
              maxDate={carDetails?.prevPolicyType !== "Bundled" ? MaxDateValue : ""}
              minDate={carDetails?.prevPolicyType !== "Bundled" ? MinDateValue : ""}
            />
          )
        }

        {/* flow_exp_date */}

        {
          ShowFlowExpCalendar && (
            <CustomCalendar
              value={carDetailsCopyState?.policyExpCopy}
              headText="Select Previous Policy Expiry date"
              name="policyExpCopy"
              btnId="policyExpDateBtn"
              textId="policyExpDateTxt"
              imgId="policyExpDateImg"
              revealState={ShowFlowExpCalendar}
              setRevealState={setShowFlowExpCalendar}
              handleInputCarDetails={handleInputCarDetails}
              handleCopiedDetails={handleCopiedDetails}
              cancelCalendarValue={cancelCalendarValueFlow}
              saveCalendarValue={saveCalendarValueExpFlow}
              preventhandleClickOutside={true}
              calendarType="expDate"
              ShowAlternateExpOptions={ShowAlternateExpOptions}
              setShowAlternateExpOptions={setShowAlternateExpOptions}
              getAlternateExpOption={getAlternateExpOption}
              maxDate={carDetails?.prevPolicyType !== "Bundled" ? MaxDateValue : ""}
              minDate={carDetails?.prevPolicyType !== "Bundled" ? MinDateValue : ""}
            // updateAlternateOptions={updateAlternateOptions}
            />
          )
        }

        {/* flow_od_date */}

        {
          ShowFlowOdCalendar && (
            <CustomCalendar
              value={carDetailsCopyState?.odPolicyExpCopy}
              headText="Select Own Damage Expiry date"
              name="odPolicyExpCopy"
              btnId="policyOdExpDateBtn"
              textId="policyOdExpDateTxt"
              imgId="policyOdExpDateImg"
              revealState={ShowFlowOdCalendar}
              setRevealState={setShowFlowOdCalendar}
              handleInputCarDetails={handleInputCarDetails}
              handleCopiedDetails={handleCopiedDetails}
              cancelCalendarValue={cancelCalendarValueFlow}
              saveCalendarValue={saveCalendarValueExpFlow}
              preventhandleClickOutside={true}
              calendarType="expDateOd"
              ShowAlternateExpOptions={ShowAlternateExpOptions}
              setShowAlternateExpOptions={setShowAlternateExpOptions}
              getAlternateExpOption={getAlternateExpOption}
              maxDate={MaxDateValue}
              minDate={MinDateValue}
            />
          )
        }

        {/* MOBILE -------- ADAPTIVE ------------------------------------------------------------------------------------------------------------------------------------------ */}

        <div className="mobile-top-content-container">
          <div className="mobile-top-content-mmv-container">
            <div className="mobile-top-content-mmv">
              <p className="Header-value-set Header-value-set-width">
                RTO Registration Number: <span>{storageVehicleNumber ? storageVehicleNumber : carDetails?.vehicleNumber}</span>
              </p>
              <p className="Header-value-set">
                Company Model:{" "}
                <span>
                  {`${carDetails?.make || ""} ${carDetails?.model || ""} ${carDetails?.varient || ""
                    } (${carDetails?.cc || ""} CC)`}
                </span>
              </p>
            </div>
            <Botton
              icons={edit}
              text="Edit"
              event={(e) => setEditMmvMobile(!EditMmvMobile)}
              className="edit_btn edit-btn-responsive"
            />
          </div>
          <div className="mobile-top-content-mmv-container mobile-top-content-mmv-container-bg mobile-top-content-mmv-container-copy">
            <div className="mobile-left-content mobile-left-content-policy-type" >
              <p className="head-mobile-topcontent">Policy Type: </p>
              <div
                className="fake-dropdown-mobile"
                onClick={(e) => setEditPolicyTypeMobile(!EditPolicyTypeMobile)}
              >
                <p className="content-mobile-topcontent">
                  {carDetails?.PolicyTypeValue}
                </p>
                <img src={arrow} alt="" srcSet="" />
              </div>
            </div>
            <div className="mobile-left-content">
              <p className="head-mobile-topcontent">
                Cover Value IDV <img src={info} alt="" srcset="" />{" "}
              </p>
              <div
                className="fake-dropdown-mobile"
                onClick={
                  carDetails?.PolicyTypeValue !== "Third Party"
                    ? (e) => setShowIdvTab(!ShowIdvTab)
                    : console.log("Not Applicable")
                }
              >
                <p className="content-mobile-topcontent">
                  {
                    carDetails?.PolicyTypeValue !== "Third Party" ? (
                      <>
                        {
                          carDetails?.idvValue
                            ? Math.round(carDetails?.idvValue)?.toLocaleString('en-IN')
                            : allIDVValues.min == Infinity
                              ? 0
                              : Math.round(allIDVValues.min)?.toLocaleString('en-IN')
                        }
                      </>
                    ) : (
                      "Not Applicable"
                    )
                  }
                </p>
                <img src={arrow} alt="" srcset="" />
              </div>
            </div>
          </div>
        </div>

        <section className="plansContainer_viewPlan  ">
          <div className="plansContainer_viewPlan_parent maxWidth ">
            <div className="bottomContent_container_vp">
              <div className="planSelectorContainer">
                <IdvTab
                  idvValue={carDetailsCopyState.idvValueCopy}
                  minIdvValue={allIDVValues.min == Infinity ? 0 : allIDVValues.min}
                  maxIdvValue={allIDVValues.max}
                  handleInputCarDetails={handleCopiedDetails}
                  IDVValues={allIDVValues}
                  handleIDVinput={handleIDVinput}
                  setcarDetailsCopyState={setcarDetailsCopyState}
                  setCarDetails={setCarDetails}
                  idv={carDetails?.idvValue}
                  isDisabled={carDetails.PolicyTypeValue === "Third Party" ? true : false}
                />
                <div className="policyTypeOption-container">
                  {
                    policyTypeOption.map((item, key) => (
                      <Botton
                        key={key}
                        text={item.value}
                        className={
                          carDetails?.PolicyTypeValue == item.value
                            ? "accordian_button active_accordian_btn"
                            : "accordian_button"
                        }
                        value={item.value}
                        name="PolicyTypeValue"
                        event={handleInputCarDetails}
                      />
                    ))
                  }
                </div>
              </div>

              <div className="bottomContent_header_vp_two">
                <div className="section_bottomContent_vp section_bottomContent_vpOne">
                  <div
                    className={
                      showInsDropDown
                        ? " addonContainer_vp-position  addonContainer_vp"
                        : "addonContainer_vp"
                    }
                  >
                    <p className="addonContainer_vp_title">
                      Add extra coverage{" "}
                    </p>
                    <AddonContainer
                      changeEvent={(e) => handleCheckboxChange(e)}
                      dataList={AddonsList}
                      ShowAccesoriesField={ShowAccesoriesField}
                      handleAccesoriesFields={(e) =>
                        setShowAccesoriesField(!ShowAccesoriesField)
                      }
                      ShowPcSumInsuredField={ShowPcSumInsuredField}
                      handleSumInsuredFields={(e) =>
                        setShowPcSumInsuredField(!ShowPcSumInsuredField)
                      }
                      handleInputCarDetails={handleInputCarDetails}
                      handleApplyClick={handleApplyClick}
                      handleCancelPassenger={handleCancelPassenger}
                      cancelAccessoriesField={cancelAccessoriesField}
                      errors={errors}
                      handleCopiedDetails={handleCopiedDetails}
                      carDetails={carDetails}
                      setElectricalAmt={setElectricalAmt}
                      setNonElectricalAmt={setNonElectricalAmt}
                      ElectricalAmt={ElectricalAmt}
                      NonElectricalAmt={NonElectricalAmt}
                      PassengerCoverAmt={PassengerCoverAmt}
                      setPassengerCoverAmt={setPassengerCoverAmt}
                    />
                  </div>
                </div>
                <div className="section_bottomContent_vp section_bottomContent_vpTwo">
                  <QuotesContainer
                    businessType={businessType}
                    vehicleData={vehicleData}
                    PolicyPlanType={carDetails?.PolicyTypeValue}
                    carDetails={{
                      ...carDetails,
                      ...getCalculatedDate,
                      ...{ coverage: [test] },
                    }}
                    handleInputCarDetails={handleInputCarDetails}
                    ShowPcSumInsuredField={ShowPcSumInsuredField}
                    ShowAccesoriesField={ShowAccesoriesField}
                    isFindMyCar={isFindMyCar}
                    setShowBottomTab={setShowBottomTab}
                    policyTypeOption={policyTypeOption}
                    passVehicleData={vehicleData}
                    policyTypeList={policyTypeList}
                    setLoaderFromConfirmPg={setLoaderFromConfirmPg}
                    CompanyWiseLoader={CompanyWiseLoader}
                    ShowExpDateCalendar={ShowExpDateCalendar}
                    setShowExpDateCalendar={setShowExpDateCalendar}
                    ShowRegDateCalendar={ShowRegDateCalendar}
                    setShowRegDateCalendar={setShowRegDateCalendar}
                    ShowOdExpDateCalendar={ShowOdExpDateCalendar}
                    setShowOdExpDateCalendar={setShowOdExpDateCalendar}
                    ShowSkeleton={ShowSkeleton}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* SELECT PREVIOUS POLICY TYPE */}

        {
          PrevPolicyTypeList && (
            <div
              className="edit-tabs-container-mob-height"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
            >
              <div className="active-bottom-edit-policy-tabs">
                <div className="edit-tabs-container">
                  <div className="edit-tabs-container-head">
                    <p>Select your previous policy type</p>
                    <img
                      src={close}
                      alt=""
                      srcset=""
                      onClick={(e) => cancelPrevPolicySection("prevPolicyTypeTab")}
                    />
                  </div>
                  <div className="policy-type-list-container-mob">
                    {policyTypeList?.map((data, key) => (
                      <div
                        key={key}
                        className={
                          carDetailsCopyState.prevPolicyTypeCopy === data.value
                            ? "pol-tab-rect pol-tab-rect-active"
                            : "pol-tab-rect"
                        }
                        onClick={(e) => handlePrevPolicySelection(data.value)}
                      >
                        <div className="fake-radio-tab-mobile">
                          <div className="circle-radio-mob">
                            <div
                              className={
                                carDetailsCopyState.prevPolicyTypeCopy ===
                                  data.value
                                  ? "circle-radio-mob-child circle-radio-mob-child-active"
                                  : "circle-radio-mob-child"
                              }
                            ></div>
                          </div>
                          <p className="radio-label-mobile-clone">
                            {data.label === "Bundled (3Y TP + 1Y OD)"
                              ? "Bundled"
                              : data.label}
                          </p>
                        </div>
                        <p className="radio-label-mobile-clone radio-label-mobile-clone-desc">
                          {data.desc}
                        </p>
                      </div>
                    ))}
                    <section className="btnContainer_pform btnContainer_pform-edit">
                      <Botton
                        text="Cancel"
                        className="button btn_pForm  secondry_btn"
                        event={(e) => cancelPrevPolicySection("prevPolicyTypeTab")}
                      />
                      <Botton
                        text="Save"
                        className="button btn_pForm"
                        // event={(e) => savePrevPolicySection("prevPolicyTypeTab")}
                        event={(e) => {
                          savePrevPolicySection("prevPolicyTypeTab");
                        }}
                      />
                    </section>
                  </div>
                </div>
              </div>
            </div>
          )
        }

        {/* EDIT Vehicle DETAILS */}

        {
          EditMmvMobile && (
            <div
              className="edit-tabs-container-mob-height"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
            >
              {EditMmvMobile && (
                <div className="active-bottom-edit-tabs">
                  <div className="edit-tabs-container">
                    <div className="edit-tabs-container-head">
                      <p>Edit my car details</p>
                      <img
                        src={close}
                        alt=""
                        srcSet=""
                        onClick={(e) => setEditMmvMobile(false)}
                      />
                    </div>
                  </div>
                  <EditDetails
                    renderContent={EditMmvMobile ? "editCarDetails" : ""}
                    businessType={businessType}
                    HandleRegDate={(e) => handleInputCarDetails(e)}
                    closeModal={(e) => setEditMmvMobile(false)}
                    value={carDetails?.policyRegDate}
                    name="policyRegDate"
                    carDetails={{ ...carDetails, ...getCalculatedDate }}
                    handleInputCarDetails={handleInputCarDetails}
                    MakeList={MakeList}
                    FuelTypeList={FuelTypeList}
                    ccList={ccList}
                    ModelListArray={ModelListArray}
                    VarientList={VarientList}
                    setCarDetails={setCarDetails}
                    carDetailsCopyState={carDetailsCopyState}
                    handleCopiedDetails={handleCopiedDetails}
                    setcarDetailsCopyState={setcarDetailsCopyState}
                    handleMakeSelect={handleMakeSelect}
                    handleModelSelect={handleModelSelect}
                    handleFuelSelect={handleFuelSelect}
                    handleVariantSelect={handleVariantSelect}
                    handleCCSelect={handleCCSelect}
                    componentClass={viewPlansClassNameForPaper}
                    handleNcbValue={handleNcbValue}
                    switchFunctionsForMobile={EditMmvMobile ? true : false}
                    setShowRegDateCalendar={setShowRegDateCalendarModal}
                    ShowRegDateCalendar={ShowRegDateCalendarModal}
                    ShowOdExpDateCalendar={ShowOdExpDateCalendar}
                    setShowOdExpDateCalendar={setShowOdExpDateCalendar}
                    ShowExpDateCalendar={ShowExpDateCalendar}
                    setShowExpDateCalendar={setShowExpDateCalendar}
                    policyTypeList={policyTypeList}
                    setncbCopy={setncbCopy}
                    setncbData={setncbData}
                  />
                </div>
              )}
            </div>
          )
        }

        {/* EDIT POLICY DETAILS */}

        {
          EditPolicyTypeMobile && (
            <div
              className="edit-tabs-container-mob-height"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
            >
              {EditPolicyTypeMobile && (
                <div className="active-bottom-edit-policy-tabs">
                  <div className="edit-tabs-container">
                    <div className="edit-tabs-container-head">
                      <p>New Policy Type</p>
                      <img
                        src={close}
                        alt=""
                        srcSet=""
                        onClick={(e) => setEditPolicyTypeMobile(false)}
                      />
                    </div>
                  </div>

                  <EditDetails
                    businessType={businessType}
                    classNameDate="datepicker-viewplans viewPlan_paper_child_title"
                    renderContent={EditPolicyTypeMobile ? "editPolDetails" : ""}
                    PolicyTypeValue={carDetails?.prevPolicyType}
                    handleInputCarDetails={handleInputCarDetails}
                    carDetails={{ ...carDetails, ...getCalculatedDate }}
                    HandleExpDate={(e) => handleInputCarDetails(e)}
                    value={carDetails?.policyExpDate}
                    name="policyExpDate"
                    MakeList={MakeList}
                    setCarDetails={setCarDetails}
                    carDetailsCopyState={carDetailsCopyState}
                    handleCopiedDetails={handleCopiedDetails}
                    setcarDetailsCopyState={setcarDetailsCopyState}
                    closeModal={(e) => setEditPolicyTypeMobile(false)}
                    switchFunctionsForMobile={EditPolicyTypeMobile ? true : false}
                    showCurrentPolicyType={EditPolicyTypeMobile ? true : false}
                    policyTypeDropdown={
                      businessType === NEW
                        ? policyTypeOptionForNew
                        : policyTypeOption
                    }
                    handleNcbValue={handleNcbValue}
                    ShowOdExpDateCalendar={ShowOdExpDateCalendar}
                    setShowOdExpDateCalendar={setShowOdExpDateCalendar}
                    ShowExpDateCalendar={ShowExpDateCalendar}
                    setShowExpDateCalendar={setShowExpDateCalendar}
                    setncbCopy={setncbCopy}
                    setncbData={setncbData}
                  />
                </div>
              )}
            </div>
          )
        }

        {/* EDIT IDV DETAILS */}

        {
          ShowIdvTab && (
            <div
              className="edit-tabs-container-mob-height"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
            >
              {
                ShowIdvTab && (
                  <div className="active-bottom-edit-IDV-tabs">
                    <div className="edit-tabs-container">
                      <div className="edit-tabs-container-head">
                        <p>Cover Value IDV</p>
                        <img
                          src={close}
                          alt=""
                          srcset=""
                          onClick={(e) => setShowIdvTab(false)}
                        />
                      </div>
                      <div className="idv-container-mobile">
                        <p className="text-with-blue-span">
                          Recommended IDV
                          <span>
                            (₹{" "}
                            {carDetails?.idvValue
                              ? carDetails?.idvValue?.toLocaleString()
                              : (allIDVValues.min == Infinity
                                ? 0
                                : allIDVValues.min
                              )?.toLocaleString()}
                            )
                          </span>
                        </p>
                        <InputFields
                          value={carDetails?.idvValue}
                          placeholder="₹ Enter your IDV Value"
                          id="idvValue"
                          type="number"
                          name="idvValue"
                          onChangeEvent={(e) => handleIDVinput(e)}
                        />
                        <p className="smallTxt-maxMin">
                          Your IDV can be between{" "}
                          {allIDVValues.min == Infinity ? 0 : allIDVValues.min} and{" "}
                          {allIDVValues.max}.
                        </p>
                        <section className="btnContainer_pform ">
                          <Botton
                            text="Cancel"
                            className="button btn_pForm  secondry_btn"
                            event={(e) => setShowIdvTab(false)}
                          />
                          <Botton
                            text="Save"
                            className="button btn_pForm  "
                            event={(e) => setShowIdvTab(false)}
                          />
                        </section>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          )
        }

        {/* ADDONS FOR MOBILE */}

        {
          !ShowPcSumInsuredField && !ShowAccesoriesField ? (
            <>
              {AddonsForMobile && (
                <div
                  className="edit-tabs-container-mob-height"
                  data-aos="fade-up"
                  data-aos-anchor-placement="top-bottom"
                >
                  <div
                    className={
                      AddonsForMobile
                        ? carDetails?.PolicyTypeValue === "Comprehensive"
                          ? "bottom-edit-tabs active-addons-bottom-edit-tabs"
                          : "bottom-edit-tabs active-addons-bottom-edit-tabs-tp"
                        : "bottom-edit-tabs addons-bottom-edit"
                    }
                  >
                    <div className="edit-tabs-container">
                      <div className="edit-tabs-container-head">
                        <p>Add extra Coverage</p>
                        <img
                          src={close}
                          alt=""
                          srcSet=""
                          onClick={(e) => setAddonsForMobile(false)}
                        />
                      </div>
                    </div>
                    <AddonContainer
                      changeEvent={(e) => handleCheckboxChange(e)}
                      dataList={AddonsList}
                      ShowAccesoriesField={ShowAccesoriesField}
                      handleAccesoriesFields={(e) =>
                        setShowAccesoriesField(!ShowAccesoriesField)
                      }
                      ShowPcSumInsuredField={ShowPcSumInsuredField}
                      handleSumInsuredFields={(e) =>
                        setShowPcSumInsuredField(!ShowPcSumInsuredField)
                      }
                      handleInputCarDetails={handleInputCarDetails}
                      handleApplyClick={handleApplyClick}
                      handleCancelPassenger={handleCancelPassenger}
                      cancelAccessoriesField={cancelAccessoriesField}
                      errors={errors}
                      handleCopiedDetails={handleCopiedDetails}
                      carDetails={carDetails}
                      setElectricalAmt={setElectricalAmt}
                      setNonElectricalAmt={setNonElectricalAmt}
                      ElectricalAmt={ElectricalAmt}
                      NonElectricalAmt={NonElectricalAmt}
                      PassengerCoverAmt={PassengerCoverAmt}
                      setPassengerCoverAmt={setPassengerCoverAmt}
                    />
                  </div>
                </div>
              )}
            </>
          ) : (
            ""
          )
        }

        {/* EDIT NCB DETAILS */}

        {
          EditNcbMobile && (
            <div
              className="edit-tabs-container-mob-height"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
            >
              {EditNcbMobile && (
                <div
                  className={
                    EditNcbMobile
                      ? "bottom-edit-tabs active-bottom-edit-ncb-tabs"
                      : "bottom-edit-tabs"
                  }
                >
                  <div className="edit-tabs-container">
                    <div className="edit-tabs-container-head edit-tabs-container-head-ncb">
                      <p>Edit my (NCB) No Claim Bonus details</p>
                      <img
                        src={close}
                        alt=""
                        srcSet=""
                        onClick={(e) => setEditNcbMobile(false)}
                      />
                    </div>
                    <EditDetails
                      businessType={businessType}
                      isClaimPrevPolicy={carDetails?.isClaimPrevPolicy}
                      renderContent={EditNcbMobile ? "editClaimDetails" : ""}
                      classNameDate="viewPlan_paper_child_title"
                      componentClass={viewPlansClassNameForPaper}
                      handleInputCarDetails={handleInputCarDetails}
                      carDetails={{ ...carDetails, ...getCalculatedDate }}
                      setCarDetails={setCarDetails}
                      carDetailsCopyState={carDetailsCopyState}
                      handleCopiedDetails={handleCopiedDetails}
                      handleMakeSelect={handleMakeSelect}
                      setcarDetailsCopyState={setcarDetailsCopyState}
                      closeModal={(e) => setEditNcbMobile(false)}
                      switchFunctionsForMobile={EditNcbMobile ? true : false}
                      handleNcbValue={handleNcbValue}
                      hideNcbTitle={true}
                      isNcbFlowPopUp={true}
                      setncbCopy={setncbCopy}
                      setncbData={setncbData}
                    />
                  </div>
                </div>
              )}
            </div>
          )
        }

        {/* BOTTOM FIXED TAB */}

        {
          !ShowPcSumInsuredField && !ShowAccesoriesField ?
            (
              <>
                {
                  ShowBottomTab &&
                  (
                    <div className="bottom-tab-sticky-container">
                      <div className="bottom-tab-sticky">
                        <div className="mobile-top-content-mmv-container mobile-top-content-mmv-container-copy-two mobile-top-content-mmv-container-copy">
                          <div
                            className="mobile-left-content mobile-left-content-addons mobile-left-content-bottom"
                            onClick={(e) => setAddonsForMobile(!AddonsForMobile)}
                          >
                            <p className="head-mobile-topcontent">Add-on Covers </p>
                            <div className="fake-dropdown-mobile content-mobile-topcontent-border">
                              <p className="content-mobile-topcontent ">
                                {count.length} covers
                              </p>
                              <img src={arrow} alt="" srcset="" />
                            </div>
                          </div>
                          {carDetails.PolicyTypeValue === "Third Party" ||
                            carDetails.prevPolicyType === "Third Party" ? (
                            <div className="mobile-left-content ">
                              <div className="Header-value-set-flex Header-value-set-flex-ncb-container">
                                <p className="Header-value-set Header-value-set-ncb-details ">
                                  New NCB:
                                  <span className="content-mobile-topcontent-border">
                                    Not Applicable
                                    <img src={arrow} alt="" srcset="" />
                                  </span>
                                </p>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="mobile-left-content "
                              onClick={(e) => setEditNcbMobile(!EditNcbMobile)}
                            >
                              <div className="Header-value-set-flex Header-value-set-flex-ncb-container">
                                {carDetails?.isClaimPrevPolicy && (
                                  <p className="Header-value-set Header-value-set-ncb-details  ">
                                    Claim:{" "}
                                    <span className="content-mobile-topcontent-border">
                                      {carDetails?.isClaimPrevPolicy ? "Yes" : "No"}
                                      <img src={arrow} alt="" srcset="" />
                                    </span>
                                  </p>
                                )}
                                {!carDetails?.isClaimPrevPolicy ? (
                                  <p className="Header-value-set Header-value-set-ncb-details ">
                                    New NCB:
                                    <span className="content-mobile-topcontent-border">
                                      {!carDetails?.isClaimPrevPolicy
                                        ? " " + carDetails?.newNcb + "%" || 0 + "%"
                                        : ""}
                                      <img src={arrow} alt="" srcset="" />
                                    </span>
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
              </>
            ) : (
              ""
            )
        }

        {/* MOBILE ---- ENDING ---- ADAPTIVE --------------------------------------------------------------------------------------------------------------------------------- */}

        {/* PARTNERS SECTION */}

        <Partners
          firstLot={firstLot}
          secondLot={secondLot}
          component="viewPlans"
        />

        {/* FAQ */}

        <Accordian
          carQueries={carQueries}
          bikeQueries={bikeQueries}
          healthQueries={healthQueries}
          taxiQueries={taxiQueries}
        />

        {/* FOOTER */}

        <Footer />
      </div >
    </>
  );
};

{/* <div className="topContent_vp maxWidth">
              <TopContent
                changeEventAddon={handleCheckboxChange}
                handleBlur={handleBlur}
                policyTypeDropdown={policyTypeOption}
                valueSelectionEventTwo={(e) => handleCheckboxChange(e)}
                valueSelectionEventThree={(e) => console.log(e)}
                clickEvent={(e) => setshowPolicyDropdown(!showPolicyDropdown)}
                ClickEventTwo={(e) => showAddonDropdown(e)}
                dropdownState={showPolicyDropdown}
                HeadDropdown={PolicyTypeValue}
                addonsDropdownList={AddonsListTwo}
                dropdownStateTwo={showAddonMenu}
                AddonCount={count.length}
                data={test}
                businessType={businessType}
                carDetails={{ ...carDetails, ...getCalculatedDate }}
                handleInputCarDetails={handleInputCarDetails}
                IDVValues={allIDVValues}
                handleIDVinput={handleIDVinput}
                clickEventOnHeadPT={() =>
                  setshowPolicyDropdown(!showPolicyDropdown)
                }
                ShowPcSumInsuredField={ShowPcSumInsuredField}
                handleSumInsuredFields={(e) =>
                  setShowPcSumInsuredField(!ShowPcSumInsuredField)
                }
                PassengerCoverAmt={PassengerCoverAmt}
                setPassengerCoverAmt={setPassengerCoverAmt}
                handleApplyClick={handleApplyClick}
                errors={errors}
              />
            </div> */}

const greetingTitleInSpan = "We got all the best Plans for you.";

const hyperLinks = [
  {
    head: "I need help.",
    desc: "Request a call from Expert.",
  },
  {
    head: "OR Call us.",
    desc: "770 0991 251",
  },
];

const viewPlansClassNameForPaper = [
  {
    class1: "viewPlan_paper_container",
    class2: "viewPlan_paper_head",
    class3: "viewPlan_paper_head_txt",
    class4: "viewPlan_paper_child_container",
    class5: "viewPlan_paper_child",
    class6: "viewPlan_paper_child_head_title",
    class7: "viewPlan_paper_child_head",
    class8: "viewPlan_paper_child_title",
  },
];

export default ViewPlans;
