import React, { useState, useEffect } from "react";
import InputFields from "../InputFields/InputFields";
import Botton from "../Button/Button";
import DropDown from "../DropDown/DropDown";
import grayArrow from "../../Assets/Images/grayArrow.svg";
import { getStateAction } from "../../redux/Actions/stateDetails";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Link, useNavigate, useHistory } from "react-router-dom";
import SearchSelect from "../DropDown/Search Select/SearchSelect";
import backIcon from "../../Assets/Images/backIcon.svg";
import { ACKO, TATA } from "../../utils/commonUtils";
import ButtonLoader from "../Pages/Loader/Button Loader/ButtonLoader";

const PropsalThree = (props) => {
  const dispatch = useDispatch();
  const [ShowStateDropdown, setShowStateDropdown] = useState(false);

  const {
    handleCityDropdown,
    addressOne,
    addressTwo,
    state,
    stateList,
    cityList,
    city,
    pincode,
    HandleInputChange,
    handleSubmitForm,
    ShowCityDropdown,
    handleBackBtn,
    handleCityValue,
    handleStateValue,
    formData,
    Edit,
    errors,
    CompanyName,
  } = props;

  const navigate = useNavigate();

  const handlePopUps = () => {
    if (ShowStateDropdown) {
      setShowStateDropdown(false);
    }
  };

  let vehicleType = localStorage.getItem("vehicleType");
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");

  let transactionStatus = useSelector((state) => {
    return state.getTransactionStatus;
  });

  return (
    <div>

      <div className="titleContainer titleContainer_pform ">
        <p>Address Details</p>
        <p>All fields are mandatory</p>
      </div>

      <form className="formSection-pform" action="" onClick={handlePopUps}>
        <div className="input_group_pform">
          <div className="inputGropOne_form inputGrop ">
            <InputFields
              value={addressOne}
              placeholder="Address Line 1"
              id="addressOne"
              type="text"
              name="addressOne"
              errState={errors?.addressOne ? true : false}
              errMsg={errors?.addressOne}
              errClassName="input-err"
              onChangeEvent={(e) => HandleInputChange(e)}
            />
          </div>
          <div className="inputGropOne_form inputGrop ">
            <InputFields
              value={addressTwo}
              placeholder="Address Line 2"
              id="addressTwo"
              type="text"
              name="addressTwo"
              errState={errors?.addressTwo ? true : false}
              errMsg={errors?.addressTwo}
              errClassName="input-err"
              onChangeEvent={(e) => HandleInputChange(e)}
            />
          </div>
        </div>

        <div className="input_group_pform">
          <div className="inputGropOne_form inputGrop ">
            <SearchSelect
              optionList={stateList}
              handleSelect={handleStateValue}
              value={state}
              ManualInpValue={formData}
              placeholder="State"
              label="State"
              name="state"
              errState={errors?.state ? true : false}
              errMsg={errors?.state}
              errClassName="input-err"
            />
          </div>
          <div className="inputGropOne_form inputGrop ">
            <SearchSelect
              optionList={cityList}
              handleSelect={handleCityValue}
              value={city}
              ManualInpValue={formData}
              placeholder="City"
              label="City"
              name="city"
              errState={errors?.city ? true : false}
              errMsg={errors?.city}
              errClassName="input-err"
            />
          </div>
        </div>

        <div className="input_group_pform">
          <div className="inputGropOne_form inputGrop ">
            <InputFields
              value={pincode}
              placeholder="Pin Code"
              id="pincode"
              type="number"
              name="pincode"
              errState={errors?.pincode ? true : false}
              errMsg={errors?.pincode}
              errClassName="input-err"
              onChangeEvent={(e) => HandleInputChange(e)}
              isDisabled={CompanyName === TATA || CompanyName === ACKO ? true : false}
            />
          </div>
          <div className="inputGropOne_form inputGrop "></div>
        </div>

        <section className="btnContainer_pform">
          <Botton
            text="Cancel"
            className="button btn_pForm  secondry_btn"
            event={() => navigate(vehicleType == "2W" ? "/View-Plans-Bike?id=" + id : "/View-Plans?id=" + id)}
          />

          {transactionStatus?.isLoading ?
            <ButtonLoader className="btn_pForm" />
            : <Botton
              text="Save"
              className="button btn_pForm  "
              event={(e) =>
                handleSubmitForm(Edit ? "kycDetails" : "AddressDetails", e)
              }
            />}
        </section>
      </form>
    </div>
  );
};

export default PropsalThree;
