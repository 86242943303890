import React, { Component } from 'react';
import "./App.css";
import '../src/components/style/Global.css'
import Root from './Root';

function App() {

  return (
    <>
      <Root/>
    </>
  );
}

export default App;