import React, { useState, useEffect } from "react";
import Botton from "../Button/Button";
import Calender from "../InputFields/Calender";
import grayArrow from "../../Assets/Images/grayArrow.svg";
import close from "../../Assets/Images/Close.svg";
import DropDown from "../DropDown/DropDown";
import InputFields from "../InputFields/InputFields";
import RadioButton from "../RadioButton/RadioButton";
import { NEW } from "../../utils/commonUtils";
import SearchSelect from "../DropDown/Search Select/SearchSelect";
import CustomDateField from "../InputFields/CustomDateField/CustomDateField";
import { isViewPlanFlowAction } from "../../redux/Reducers/isViewPlanFlowCompleted";
import { useDispatch, useSelector } from "react-redux";

const EditDetails = (props) => {
  const {
    ccList,
    MakeList,
    closeModal,
    carDetails,
    VarientList,
    businessType,
    FuelTypeList,
    setCarDetails,
    renderContent,
    policyTypeList,
    handleNcbValue,
    ModelListArray,
    handleCCSelect,
    handleMakeSelect,
    handleFuelSelect,
    handleCancelModal,
    handleModelSelect,
    policyTypeDropdown,
    handleCopiedDetails,
    handleVariantSelect,
    ShowRegDateCalendar,
    carDetailsCopyState,
    handleSaveCarDetails,
    handleInputCarDetails,
    showCurrentPolicyType,
    setShowRegDateCalendar,
    setcarDetailsCopyState,
    switchFunctionsForMobile,
    ShowOdExpDateCalendar,
    setShowOdExpDateCalendar,
    ShowExpDateCalendar,
    setShowExpDateCalendar,
    isNcbFlowPopUp,
    hideNcbTitle,
    setncbData,
    setncbCopy
  } = props;

  const dispatch = useDispatch();

  const makeListDropdown = MakeList?.map((data, key) => ({
    value: data.make,
    label: data.make,
  }));

  const [ShowMakeDropDown, setShowMakeDropDown] = useState(false);
  const [ShowModelDropDown, setShowModelDropDown] = useState(false);
  const [ShowVariantDropDown, setShowVariantDropDown] = useState(false);
  const [ShowCCDropDown, setShowCCDropDown] = useState(false);
  const [ShowFuelDropDown, setShowFuelDropDown] = useState(false);
  const [ShowPrevPolicyType, setShowPrevPolicyType] = useState(false)
  const [errors, setErrors] = useState({});

  const handlePopUps = () => {
    if (ShowMakeDropDown) {
      setShowMakeDropDown(!ShowMakeDropDown);
    } else if (ShowModelDropDown) {
      setShowModelDropDown(!ShowModelDropDown);
    } else if (ShowVariantDropDown) {
      setShowVariantDropDown(!ShowVariantDropDown);
    } else if (ShowCCDropDown) {
      setShowCCDropDown(!ShowCCDropDown);
    } else if (ShowFuelDropDown) {
      setShowFuelDropDown(!ShowFuelDropDown);
    } else if (ShowPrevPolicyType) {
      setShowPrevPolicyType(!ShowPrevPolicyType);
    }
  };

  const validateValidateDetailsMobile = () => {
    const newErrors = {};
    if (!carDetailsCopyState.vehicleNumberCopy) {
      newErrors.vehicleNumberCopy = "Vehicle Number is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSaveCarDetailsMobile = (event, isFindMyCar) => {
    let isValid = validateValidateDetailsMobile();
    if (isValid) {
      closeModal();
      setCarDetails((prevItems) => ({
        ...prevItems,
        vehicleNumber: carDetailsCopyState?.vehicleNumberCopy,
        prevPolicyType: carDetailsCopyState?.prevPolicyTypeCopy,
        policyExpDate: carDetailsCopyState?.policyExpCopy,
        prevPolicyODexpDate: carDetailsCopyState?.odPolicyExpCopy,
        // prevPolicyTPexpDate: carDetailsCopyState?.tpPolicyExpCopy,
        isClaimPrevPolicy: carDetailsCopyState?.isClaimPrevPolicyCopy,
        prevNcb: carDetailsCopyState?.prevNcbCopy,
        newNcb: carDetailsCopyState?.newNcbCopy,
        make: carDetailsCopyState?.makeCopy,
        model: carDetailsCopyState?.modelCopy,
        varient: carDetailsCopyState?.varientCopy,
        fuelType: carDetailsCopyState?.fuelTypeCopy,
        cc: carDetailsCopyState?.ccCopy,
        policyRegDate: carDetailsCopyState?.regDateCopy,
        PolicyTypeValue: carDetailsCopyState?.policyTypeValueCopy,
        isFindMyCar: isFindMyCar ? isFindMyCar : carDetails.isFindMyCar,
      }));
    }
  };

  const handleCancelModalMobile = () => {
    if (closeModal) {
      closeModal();
    }
    setcarDetailsCopyState((prevItems) => ({
      ...prevItems,
      vehicleNumberCopy: carDetails?.vehicleNumber,
      prevPolicyTypeCopy: carDetails?.prevPolicyType,
      policyExpCopy: carDetails?.policyExpDate,
      odPolicyExpCopy: carDetails?.prevPolicyODexpDate,
      tpPolicyExpCopy: carDetails?.prevPolicyTPexpDate,
      isClaimPrevPolicyCopy: carDetails?.isClaimPrevPolicy,
      prevNcbCopy: carDetails?.prevNcb,
      newNcbCopy: carDetails?.newNcb,
      makeCopy: carDetails?.make,
      modelCopy: carDetails?.model,
      varientCopy: carDetails?.varient,
      fuelTypeCopy: carDetails?.fuelType,
      ccCopy: carDetails?.cc,
      regDateCopy: carDetails?.policyRegDate,
      policyTypeValueCopy: carDetails?.PolicyTypeValue,
    }));
  };

  const saveNcbFlowData = () => {
    setCarDetails((prevItems) => ({
      ...prevItems,
      prevNcb: carDetailsCopyState?.prevNcbCopy,
      newNcb: carDetailsCopyState?.newNcbCopy,
      policyExpDate: carDetailsCopyState?.policyExpCopy,
      prevPolicyODexpDate: carDetailsCopyState?.odPolicyExpCopy || "",
      prevPolicyType: carDetailsCopyState?.prevPolicyTypeCopy,
    }));
    setncbData({
      newNcb: carDetailsCopyState?.newNcbCopy,
      prevNcb: carDetailsCopyState?.prevNcbCopy
    })
    setncbCopy({
      newNcb: carDetailsCopyState?.newNcbCopy,
      prevNcb: carDetailsCopyState?.prevNcbCopy
    })
    closeModal();
    dispatch(
      isViewPlanFlowAction({
        isViewPlanFlowCompleted: true,
      })
    );
  }

  const cancelNcbFlowData = () => {
    setcarDetailsCopyState((prevItems) => ({
      ...prevItems,
      prevNcbCopy: carDetails?.prevNcb,
      newNcbCopy: carDetails?.newNcb,
    }));
    closeModal();
  }

  return (
    <div onClick={handlePopUps}>
      {renderContent === "editCarDetails" && (
        <div className="editDetail_container editDetail_container-padding ">
          <div className="editDetail_header">
            <p>Edit my car details</p>
            <img src={close} alt="" srcSet="" onClick={handleCancelModal} />
          </div>

          <div className=" input_group_editForm ">
            <div className="inputGropOne_form inputGrop ">
              <InputFields
                value={carDetailsCopyState?.vehicleNumberCopy || ""}
                placeholder={
                  businessType === NEW ? "RTO Registration" : "Vehicle Number"
                }
                id="vehicleNumberCopy"
                type="text"
                name="vehicleNumberCopy"
                onChangeEvent={(e) => handleCopiedDetails(e)}
                errState={errors?.vehicleNumberCopy ? true : false}
                errMsg={errors?.vehicleNumberCopy}
                errClassName="input-err"
                isDisabled={true}
              />
            </div>
            <div className="inputGropOne_form inputGrop ">
              <SearchSelect
                optionList={makeListDropdown}
                handleSelect={handleMakeSelect}
                value={carDetailsCopyState?.makeCopy}
                placeholder="Make"
                label="Make"
                name="makeCopy"
              />
            </div>
          </div>

          <div className=" input_group_editForm ">
            <div className="inputGropOne_form inputGrop ">
              <SearchSelect
                optionList={ModelListArray}
                handleSelect={handleModelSelect}
                value={carDetailsCopyState?.modelCopy}
                placeholder="Model"
                label="Model"
                name="modelCopy"
              />
            </div>
            <div className="inputGropOne_form inputGrop ">
              <SearchSelect
                optionList={FuelTypeList}
                handleSelect={handleFuelSelect}
                value={carDetailsCopyState?.fuelTypeCopy}
                placeholder="Fuel Type"
                label="Fuel Type"
                name="fuelTypeCopy"
              />
            </div>
          </div>

          <div className=" input_group_editForm ">
            <div className="inputGropOne_form inputGrop ">
              <SearchSelect
                optionList={VarientList}
                handleSelect={handleVariantSelect}
                value={carDetailsCopyState?.varientCopy}
                placeholder="Variant"
                label="Variant"
                name="varientCopy"
              />
            </div>
            <div className="inputGropOne_form inputGrop ">
              <DropDown
                dropDownMenu={ccList}
                handleChangeInput={(e) => console.log(e)}
                value={carDetailsCopyState?.ccCopy}
                name="ccCopy"
                classNameForHead="headTxtClassPform"
                Head="CC"
                label="CC"
                arrowInGray={grayArrow}
                inputId="cc"
                clickEvent={(e) => console.log(e)}
                headClick={(e) => setShowCCDropDown(!ShowCCDropDown)}
                revealOptions={ShowCCDropDown}
                afterTrueClass="dropDown_container NoBorderRadius"
                className="dropDown_container"
                valueSelectionEvent={handleCopiedDetails}
                revealOptionsClasses=" dropDownOptions showdropDownOptions "
                classNameTwo="dropDownOptions"
                dropDownHeadClass="dropDownHead"
              />
            </div>
          </div>

          <div className=" input_group_editForm ">
            <div className="inputGropOne_form inputGrop ">
              <CustomDateField
                className="pForm_datePicker pForm_datePicker-tab "
                btnId="policyRegDateBtn"
                textId="policyRegDateTxt"
                imgId="policyRegDateImg"
                dateValue={carDetailsCopyState?.regDateCopy}
                placeholder="Registration Date"
                placeholderId="regPlaceholderId"
                calendarReveal={ShowRegDateCalendar}
                setCalendarReveal={setShowRegDateCalendar}
              />
            </div>
            <div className="inputGropOne_form inputGrop inputGrop-hide-mobile "></div>
          </div>

          <div className=" input_group_editForm input_group_editForm-mobile">
            <div className="inputGropOne_form inputGrop ">
              <DropDown
                dropDownMenu={policyTypeList}
                value={carDetailsCopyState?.prevPolicyTypeCopy}
                name="prevPolicyTypeCopy"
                classNameForHead="headTxtClassPform"
                Head="Previous Policy Type"
                label="Previous Policy Type"
                arrowInGray={grayArrow}
                inputId="cc"
                clickEvent={(e) => console.log(e)}
                handleChangeInput={(e) => console.log(e)}
                headClick={(e) => setShowPrevPolicyType(!ShowPrevPolicyType)}
                revealOptions={ShowPrevPolicyType}
                afterTrueClass="dropDown_container NoBorderRadius"
                className="dropDown_container"
                valueSelectionEvent={handleCopiedDetails}
                revealOptionsClasses=" dropDownOptions showdropDownOptions "
                classNameTwo="dropDownOptions"
                dropDownHeadClass="dropDownHead"
                idForHead="ccHead"
                idForHeadTxt="ccHeadTxt"
                idForArrow="ccHeadArrow"
                dropdownId="ccListDp"
                idForParentObj="ccParentDp"
              />
            </div>

            <div className="inputGropOne_form inputGrop">
              {carDetails?.PolicyTypeValue === "Own Damage" ? (
                <>
                  <CustomDateField
                    className="pForm_datePicker pForm_datePicker-tab"
                    btnId="policyOdExpDateBtn"
                    textId="policyOdExpDateTxt"
                    imgId="policyOdExpDateImg"
                    dateValue={carDetailsCopyState?.odPolicyExpCopy}
                    placeholder="Own Damage Expiry Date"
                    placeholderId="odPlaceholderId"
                    calendarReveal={ShowOdExpDateCalendar}
                    setCalendarReveal={setShowOdExpDateCalendar}
                  />
                </>
              ) : (
                <>
                  <CustomDateField
                    className="pForm_datePicker pForm_datePicker-tab"
                    btnId="policyExpDateBtn"
                    textId="policyExpDateTxt"
                    imgId="policyExpDateImg"
                    dateValue={carDetailsCopyState?.policyExpCopy}
                    placeholder="Expiry Date"
                    placeholderId="expPlaceholderId"
                    calendarReveal={ShowExpDateCalendar}
                    setCalendarReveal={setShowExpDateCalendar}
                  />
                </>
              )}
            </div>
          </div>

          <section className="btnContainer_pform ">
            <Botton
              text="Cancel"
              className="button btn_pForm  secondry_btn"
              event={
                switchFunctionsForMobile
                  ? handleCancelModalMobile
                  : handleCancelModal
              }
            />
            <Botton
              text="Save"
              className="button btn_pForm"
              event={
                switchFunctionsForMobile
                  ? (e) => handleSaveCarDetailsMobile(e, true)
                  : (e) => handleSaveCarDetails(e, true)
              }
            />
          </section>
        </div>
      )}

      {renderContent === "editPolDetails" && (
        <div className="editDetail_container editDetail_container_caseTwo ">
          <div className="editDetail_header">
            <p>Edit my car details</p>
            <img src={close} alt="" srcSet="" onClick={handleCancelModal} />
          </div>
          <div className=" input_group_editForm ">
            <div className="inputGropOne_form inputGrop ">
              {
                showCurrentPolicyType ?
                  <DropDown
                    dropDownMenu={policyTypeDropdown}
                    value={carDetailsCopyState?.policyTypeValueCopy}
                    name="policyTypeValueCopy"
                    classNameForHead="headTxtClassPform"
                    Head="Policy Type"
                    label="Policy Type"
                    arrowInGray={grayArrow}
                    inputId="cc"
                    clickEvent={(e) => console.log(e)}
                    handleChangeInput={(e) => console.log(e)}
                    headClick={(e) => setShowCCDropDown(!ShowCCDropDown)}
                    revealOptions={ShowCCDropDown}
                    afterTrueClass="dropDown_container NoBorderRadius"
                    className="dropDown_container"
                    valueSelectionEvent={handleCopiedDetails}
                    revealOptionsClasses=" dropDownOptions showdropDownOptions "
                    classNameTwo="dropDownOptions"
                    dropDownHeadClass="dropDownHead"
                    idForHead="ccHead"
                    idForHeadTxt="ccHeadTxt"
                    idForArrow="ccHeadArrow"
                    dropdownId="ccListDp"
                    idForParentObj="ccParentDp"
                  />
                  :
                  <DropDown
                    dropDownMenu={policyTypeList}
                    value={carDetailsCopyState?.prevPolicyTypeCopy}
                    name="prevPolicyTypeCopy"
                    classNameForHead="headTxtClassPform"
                    Head="Policy Type"
                    label="Policy Type"
                    arrowInGray={grayArrow}
                    inputId="cc"
                    clickEvent={(e) => console.log(e)}
                    handleChangeInput={(e) => console.log(e)}
                    headClick={(e) => setShowCCDropDown(!ShowCCDropDown)}
                    revealOptions={ShowCCDropDown}
                    afterTrueClass="dropDown_container NoBorderRadius"
                    className="dropDown_container"
                    valueSelectionEvent={handleCopiedDetails}
                    revealOptionsClasses=" dropDownOptions showdropDownOptions "
                    classNameTwo="dropDownOptions"
                    dropDownHeadClass="dropDownHead"
                    idForHead="ccHead"
                    idForHeadTxt="ccHeadTxt"
                    idForArrow="ccHeadArrow"
                    dropdownId="ccListDp"
                    idForParentObj="ccParentDp"
                  />
              }
            </div>

            <div className="inputGropOne_form inputGrop inputGrop-hide-in-mobile ">
              {
                carDetails?.PolicyTypeValue === "Own Damage" ? (
                  <>
                    <CustomDateField
                      className="pForm_datePicker pForm_datePicker-tab"
                      btnId="policyOdExpDateBtn"
                      textId="policyOdExpDateTxt"
                      imgId="policyOdExpDateImg"
                      dateValue={carDetailsCopyState?.odPolicyExpCopy}
                      placeholder="Own Damage Expiry Date"
                      placeholderId="odPlaceholderId"
                      calendarReveal={ShowOdExpDateCalendar}
                      setCalendarReveal={setShowOdExpDateCalendar}
                    />
                  </>
                ) : (
                  <>
                    <CustomDateField
                      className="pForm_datePicker pForm_datePicker-tab"
                      btnId="policyExpDateBtn"
                      textId="policyExpDateTxt"
                      imgId="policyExpDateImg"
                      dateValue={carDetailsCopyState?.policyExpCopy}
                      placeholder="Expiry Date"
                      placeholderId="expPlaceholderId"
                      calendarReveal={ShowExpDateCalendar}
                      setCalendarReveal={setShowExpDateCalendar}
                    />
                  </>
                )
              }
            </div>
          </div>

          <section className="btnContainer_pform ">
            <Botton
              text="Cancel"
              className="button btn_pForm  secondry_btn"
              event={
                switchFunctionsForMobile
                  ? handleCancelModalMobile
                  : handleCancelModal
              }
            />
            <Botton
              text="Save"
              className="button btn_pForm  "
              event={
                switchFunctionsForMobile
                  ? (e) => handleSaveCarDetailsMobile(e)
                  : (e) => handleSaveCarDetails(e)
              }
            />
          </section>
        </div>
      )}

      {renderContent === "editClaimDetails" && (
        <div className={
          hideNcbTitle
            ? "editDetail_container editDetail_container-claim editDetail_container-claim-height"
            : "editDetail_container editDetail_container-claim"
        }>
          {
            !hideNcbTitle && (
              <div className="editDetail_header">
                <p>Edit my (NCB) No Claim Bonus details</p>
                <img src={close} alt="" srcSet="" onClick={handleCancelModal} />
              </div>
            )
          }
          <div className="input_group_editForm">
            <div className="inputGropOne_form inputGrop inputGrop_caseTwo ">
              <p className="customLabel_pform customLabel_edit-page">
                Have you taken any Claim in your existing policy?
              </p>
              <div className="radioContainer-pform">
                <RadioButton
                  label="Yes"
                  className=""
                  id="claimYesCopy"
                  groupName="isClaimPrevPolicyCopy"
                  value="Yes"
                  changeEvent={(e) => handleCopiedDetails(e)}
                  isChecked={
                    carDetailsCopyState?.isClaimPrevPolicyCopy === true
                      ? true
                      : false
                  }
                />
                <RadioButton
                  label="No"
                  className=""
                  id="claimNoCopy"
                  groupName="isClaimPrevPolicyCopy"
                  value="No"
                  changeEvent={(e) => handleCopiedDetails(e)}
                  isChecked={
                    carDetailsCopyState?.isClaimPrevPolicyCopy === false
                      ? true
                      : false
                  }
                />
              </div>
            </div>
          </div>

          {carDetailsCopyState?.isClaimPrevPolicyCopy === false && (
            <div className="input_group_editForm input_group_editFormTwo  ">
              <div className="inputGropOne_form inputGrop inputGrop_caseTwo ">
                <p className="customLabel_pform customLabel_edit-page">
                  Previous NCB
                </p>
                <div className="ncb-btn-editform">
                  <Botton
                    text="0%"
                    className={
                      carDetailsCopyState?.prevNcbCopy == "0"
                        ? "activeNcbBtn ncbBtn-editForm"
                        : "ncbBtn-editForm"
                    }
                    event={(e) => handleCopiedDetails("0", "prevNcbCopy", "20")}
                  />
                  <Botton
                    text="20%"
                    className={
                      carDetailsCopyState?.prevNcbCopy == "20"
                        ? "activeNcbBtn ncbBtn-editForm"
                        : "ncbBtn-editForm"
                    }
                    event={(e) =>
                      handleCopiedDetails("20", "prevNcbCopy", "25")
                    }
                  />
                  <Botton
                    text="25%"
                    className={
                      carDetailsCopyState?.prevNcbCopy == "25"
                        ? "activeNcbBtn ncbBtn-editForm"
                        : "ncbBtn-editForm"
                    }
                    event={(e) =>
                      handleCopiedDetails("25", "prevNcbCopy", "35")
                    }
                  />
                  <Botton
                    text="35%"
                    className={
                      carDetailsCopyState?.prevNcbCopy == "35"
                        ? "activeNcbBtn ncbBtn-editForm"
                        : "ncbBtn-editForm"
                    }
                    event={(e) =>
                      handleCopiedDetails("35", "prevNcbCopy", "45")
                    }
                  />
                  <Botton
                    text="45%"
                    className={
                      carDetailsCopyState?.prevNcbCopy == "45"
                        ? "activeNcbBtn ncbBtn-editForm"
                        : "ncbBtn-editForm"
                    }
                    event={(e) =>
                      handleCopiedDetails("45", "prevNcbCopy", "50")
                    }
                  />
                  <Botton
                    text="50%"
                    className={
                      carDetailsCopyState?.prevNcbCopy == "50"
                        ? "activeNcbBtn ncbBtn-editForm"
                        : "ncbBtn-editForm"
                    }
                    event={(e) =>
                      handleCopiedDetails("50", "prevNcbCopy", "50")
                    }
                  />
                </div>
              </div>

              <div className="inputGropOne_form inputGrop inputGrop_caseTwo ">
                <p className="customLabel_pform customLabel_edit-page">
                  New NCB
                </p>
                <div className="ncb-btn-editform">
                  <Botton
                    text={carDetailsCopyState?.newNcbCopy + "%"}
                    className="activeNcbBtn ncbBtn-editForm"
                  />
                </div>
              </div>

              <p className="ncbMsg-editForm">
                Hey Congratulation! You are eligible for {""}
                {carDetailsCopyState?.newNcbCopy + "%"} new NCB Discount.
              </p>
            </div>
          )}

          {carDetailsCopyState?.isClaimPrevPolicyCopy === true && (
            <>
              <p className="err-msg-claim">
                Hey Sorry! as you have claim in your previous policy you are not
                applicable for NCB discount.
              </p>
            </>
          )}

          {
            isNcbFlowPopUp ? (
              <section className={
                hideNcbTitle
                  ? "btnContainer_pform btnContainer_pform-edit"
                  : "btnContainer_pform"
              }>
                <Botton
                  text="Cancel"
                  className="button btn_pForm  secondry_btn"
                  event={(e) => cancelNcbFlowData()}
                />
                <Botton
                  text="Save"
                  className="button btn_pForm  "
                  event={(e) => saveNcbFlowData()}
                />
              </section>
            ) : (
              <section className={
                hideNcbTitle
                  ? "btnContainer_pform btnContainer_pform-edit"
                  : "btnContainer_pform"
              }>
                <Botton
                  text="Cancel"
                  className="button btn_pForm  secondry_btn"
                  event={
                    switchFunctionsForMobile
                      ? handleCancelModalMobile
                      : handleCancelModal
                  }
                />
                <Botton
                  text="Save"
                  className="button btn_pForm  "
                  event={
                    switchFunctionsForMobile
                      ? (e) => handleSaveCarDetailsMobile(e)
                      : (e) => handleSaveCarDetails(e)
                  }
                />
              </section>
            )
          }
        </div>
      )}
    </div>
  );
};

export default EditDetails;
