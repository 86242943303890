import React from 'react'
import tick from "../../../../Assets/Images/success.svg";

const ActivePolicy = (props) => {

    const { policyDetails, handleDownloadPolicy } = props;

    return (
        <div className="ProfileCard-container">
            {policyDetails.map((data, key) => (
                <section className="NoPoliciesFound-container PoliciesFound-container ">
                    <div className="product-info-container">
                        <div className="product-info-left-container">
                            <div className="product-icon-container">
                                <img src={data.productIcon} alt="" srcSet="" />
                            </div>
                            <div className="product-insurer-container">
                                <p>
                                    {data.policyNumber} - {data.insuredProductInfo}
                                </p>
                                <p>{data.insurerName}</p>
                            </div>
                        </div>

                        <div className="product-info-right-container">
                            <p>
                                <span>Start:</span> {data.policyStartDate}
                            </p>
                            <p>
                                <span>End:</span> {data.policyEndDate}
                            </p>
                        </div>
                    </div>

                    <div className="seprator-policyCard"></div>

                    <div className="vehicleInfo-policyCard ">
                        <p className="textWithKey">
                            <span>Name: </span>
                            {data.policyHolderName}
                        </p>
                        <p className="textWithKey">
                            <span>Car Number: </span> {data.vehicleNumber}
                        </p>
                    </div>

                    <div className="breakup-contianer-policyCard">
                        <div className="breakup-contianer-part-policyCard">
                            <div className="breakup-contianer-part-left-policyCard">
                                <div className="txt-with-tick-policycard">
                                    <p className="textWithKey textWithKey-bold">
                                        <span>Invoice Cover</span> ₹11,222.41
                                    </p>
                                    <img src={tick} alt="" srcSet="" />
                                </div>
                                <div className="txt-with-tick-policycard">
                                    <p className="textWithKey textWithKey-bold">
                                        <span>PA Cover</span> ₹11,222.41
                                    </p>
                                    <img src={tick} alt="" srcSet="" />
                                </div>
                                <div className="txt-with-tick-policycard">
                                    <p className="textWithKey textWithKey-bold">
                                        <span>Zero Depreciation</span> ₹11,222.41
                                    </p>
                                    <img src={tick} alt="" srcSet="" />
                                </div>
                            </div>
                        </div>
                        <div className="breakup-contianer-part-policyCard">
                            <div className="breakup-contianer-part-left-policyCard">
                                {data.coverages.map((data, key) =>
                                    key <= 2 ? (
                                        <div key={key} className="txt-with-tick-policycard">
                                            <p className="text-color-one">{data}</p>
                                            <img src={tick} alt="" srcSet="" />
                                        </div>
                                    ) : (
                                        ""
                                    )
                                )}
                            </div>
                        </div>

                        <div className="breakup-contianer-part-policyCard">
                            <div className="breakup-contianer-part-left-policyCard">
                                <div className="txt-with-tick-policycard">
                                    <p className="text-color-one">Engine Protector</p>
                                    <img src={tick} alt="" srcSet="" />
                                </div>
                                <div className="txt-with-tick-policycard">
                                    <p className="text-color-one">Consumable</p>
                                    <img src={tick} alt="" srcSet="" />
                                </div>
                                <div className="txt-with-tick-policycard">
                                    <p className="text-color-one">Engine Protector</p>
                                    <img src={tick} alt="" srcSet="" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bottom-tabs-option-policyCard">
                        <div
                            className="tabs-option-policyCard"
                            onClick={(e) => handleDownloadPolicy(data.policyNumber)}
                        >
                            <p>Download Policy</p>
                        </div>
                        <div className="tabs-option-policyCard">
                            <p>Edit Policy</p>
                        </div>
                        <div className="tabs-option-policyCard">
                            <p>Initiate Claim</p>
                        </div>
                        <div className="tabs-option-policyCard">
                            <p>Track Claim</p>
                        </div>
                        <div className="tabs-option-policyCard">
                            <p>Garages</p>
                        </div>
                    </div>
                </section>
            ))}
        </div>
    )
}

export default ActivePolicy
