import React from "react";
import calender from "../../../Assets/Images/calender.svg";
import "./CustomDateField.css";

const CustomDateField = (props) => {
  const {
    dateValue,
    event,
    className,
    setCalendarReveal,
    btnId,
    textId,
    imgId,
    placeholder,
    placeholderId,
    isDisabled,
  } = props;

  const formattedDate = new Date(dateValue);
  const day = formattedDate.getDate();
  const month = formattedDate.toLocaleString("en-us", { month: "short" });
  const year = formattedDate.getFullYear();
  const formattedDateString = `${day}-${month}-${year}`;

  return (
    <>
      <div
        className={className}
        onClick={(e) => {
          if (!isDisabled) {
            setCalendarReveal(true);
          }
        }}
        id={btnId}
      >
        {formattedDateString !== "NaN-Invalid Date-NaN" &&
          formattedDateString && (
            <p className="headTxtClassPform headTxtClassPform-top">
              {placeholder}
            </p>
          )}
        {formattedDateString !== "NaN-Invalid Date-NaN" ? (
          <p id={textId}>{formattedDateString}</p>
        ) : (
          <p id={placeholderId} className="headTxtClassPform">
            {placeholder}
          </p>
        )}
        <img src={calender} alt="" srcSet="" id={imgId} />
      </div>
    </>
  );
};

export default CustomDateField;
