import React from "react";
import "./LandingPage.css";
import GetRegNo from "../RegNumberForm/GetRegNo";
import lady from "../../Assets/Images/lady.png";
import Card from "../Card/Card";
import Container from "../Container/Container";
import claim from "../../Assets/Images/claim.svg";
import emi from "../../Assets/Images/emi.svg";
import cashless from "../../Assets/Images/cashless.svg";
import assistance from "../../Assets/Images/assistance.svg";
import policy from "../../Assets/Images/policy.svg";
import refund from "../../Assets/Images/refund.svg";
import tracking from "../../Assets/Images/tracking.svg";
import standalone from "../../Assets/Images/standalone.svg";
import Testimonials from "../Testimonials/Testimonials";
import Partners from "../Partners/Partners";
import Accordian from "../Accordian/Accordian";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";

const LandingPage = (props) => {
  
  const {
    firstLot,
    secondLot,
    carQueries,
    bikeQueries,
    healthQueries,
    taxiQueries,
    testimonials,
  } = props;

  return (
    <>
      <Navbar componentPath="/" />
      
      {/* REGISTER NUMBER FORM */}

      <GetRegNo />

      {/* FETAURES SECTION ONE */}

      <div className="feature_container">
        <div className="feature_container_parent maxWidth ">
          <div className="mobile_feature_head">
            <h1 className="title">
              Why people <span>select us?</span>
            </h1>
            <div className="mobile_lady_div" id="mobile_lady_div">
              <img src={lady} alt="" srcSet="" />
            </div>
          </div>
          <div className="featureParts_container">
            <div className="featureParts_container_child featureParts_container_childOne ">
              <div className="buble_container_landing">
                <Container
                  title="IRDAI"
                  contentTitleMain="IRDAI Approved"
                  contentTitleChild="We are Licensed approved"
                  afterBreakTag="by IRDAI"
                  className="bubble_landing_content"
                />
                <Container
                  title="25"
                  spanTitle="K+"
                  contentTitleMain="25K+ Claims"
                  contentTitleChild="10 Lakh + Customers"
                  afterBreakTag="Claims"
                  className="bubble_landing_content"
                />
                <Container
                  title="10"
                  spanTitle="L+"
                  contentTitleMain="10 Lakh + Customers"
                  contentTitleChild="We have 10 Lakh +"
                  afterBreakTag="Customers"
                  className="bubble_landing_content"
                />
              </div>
              <div className="buble_container_landing">
                <Container
                  title="40"
                  spanTitle="+"
                  contentTitleMain="40+ Insurer companies"
                  contentTitleChild="We have 40+ Insurer"
                  afterBreakTag="companies"
                  className="bubble_landing_content"
                />
                <Container
                  title="500"
                  spanTitle="+"
                  contentTitleMain="500+ Experts"
                  contentTitleChild="We have 500+ Experts"
                  afterBreakTag=""
                  className="bubble_landing_content"
                />
                <Container
                  title="100"
                  spanTitle="%"
                  contentTitleMain="100% Security"
                  contentTitleChild="We have 100% data "
                  afterBreakTag="and payment security"
                  className="bubble_landing_content"
                />
              </div>
            </div>
            <div className="featureParts_container_child featureParts_container_childTwo ">
              <img src={lady} alt="" srcSet="" />
            </div>
          </div>
        </div>
      </div>

      {/* FEATURES SECTION TWO */}

      <div className="cards_container">
        <div className="cards_container_parent maxWidth">
          <p className="cardHead_title title">
            How we <span>Help You?</span>
          </p>
          <div className="card_div_container">
            <div className="card_container_child">
              <Card
                icons={claim}
                className="card_landing"
                classNameTwo="cardLanding_content"
                classNameThree="icon_container_landing"
                title="100% Claims"
                subtitle="Claim Guarantee"
                hoverContent="in insurance ensures that the policyholder will receive full compensation for covered losses without any deductions or exceptions."
              />
              <Card
                icons={emi}
                className="card_landing"
                classNameTwo="cardLanding_content"
                classNameThree="icon_container_landing"
                title="EMI Available"
                subtitle="EMI option available on Credit Cards"
              />
              <Card
                icons={cashless}
                className="card_landing"
                classNameTwo="cardLanding_content"
                classNameThree="icon_container_landing"
                title="100% Cashless"
                subtitle="Cashless Policies"
              />
              <Card
                icons={assistance}
                className="card_landing"
                classNameTwo="cardLanding_content"
                classNameThree="icon_container_landing"
                title="Claim Assistance"
                subtitle="Free Claim assistance for all Insurance"
              />
              <Card
                icons={policy}
                className="card_landing"
                classNameTwo="cardLanding_content"
                classNameThree="icon_container_landing"
                title="Policy Correction"
                subtitle="Free Online Policy Correction"
              />
              <Card
                icons={refund}
                className="card_landing"
                classNameTwo="cardLanding_content"
                classNameThree="icon_container_landing"
                title="100% Refund"
                subtitle="Free Policy Cancellation 100% Refund"
              />
              <Card
                icons={tracking}
                className="card_landing"
                classNameTwo="cardLanding_content"
                classNameThree="icon_container_landing"
                title="Live Tracking"
                subtitle="Free Claims Live Tracking"
              />
              <Card
                icons={standalone}
                className="card_landing"
                classNameTwo="cardLanding_content"
                classNameThree="icon_container_landing"
                title="Stand Alone"
                subtitle="Stand Alone Products"
              />
            </div>
          </div>
        </div>
      </div>

      {/* TESTIMONIALS CAROUSEL */}

      {/* <Testimonials content={testimonials} /> */}

      {/* PARTNERS SECTION */}

      <Partners firstLot={firstLot} secondLot={secondLot} component="landingPage" />

      {/* ACCORDIAN */}

      <Accordian
        carQueries={carQueries}
        bikeQueries={bikeQueries}
        healthQueries={healthQueries}
        taxiQueries={taxiQueries}
      />

      <Footer />
    </>
  );
};

export default LandingPage;
