import React, { useState, useEffect } from "react";
import "./GetRegNo.css";
import irdai from "../../Assets/Images/irdai.png";
import ProductSelect from "./ProductSelect";
import FormContainer from "./formContainer";
import car from "../../Assets/Images/car.svg";
import bike from "../../Assets/Images/bike.svg";

const GetRegNo = (props) => {
  const { style } = props;

  const [CarRegNo, setCarRegNo] = useState("");
  const [ShowCarContent, setShowCarContent] = useState(false);
  const [ShowProduct, setShowProduct] = useState("car");
  const [ProductTabClicked, setProductTabClicked] = useState(false);
  const [IsMobileMode, setIsMobileMode] = useState(false);

  localStorage.clear();
  sessionStorage.clear();

  const handleProduct = (product) => {
    setShowProduct(product);
    setCarRegNo("");
  };

  const handleMobileContent = (product) => {
    setShowProduct(product);
    setIsMobileMode(true)
  };

  const handleVehicleNumber = (value) => {
    const cleanValue = value.replace(/[^\w]/g, '').toUpperCase();
    setCarRegNo(cleanValue);
  };

  const HandleCarFormPopUp = () => {
    setShowCarContent(false);
  };

  return (
    <>
      <div className="getRegNo_container">
        <div className="getRegNo_parent maxWidth ">
          <div className="getRegNo_form">

            <ProductSelect
              clickEvent={handleMobileContent}
              handleProduct={handleProduct}
              productValue={ShowProduct}
              ShowCarContent={ShowCarContent}
              setProductTabClicked={setProductTabClicked}
            />

            {
              IsMobileMode && (
                <div className="width-mobile">
                  {
                    ShowProduct === "car" && (
                      <div className="active_prod_btn prod_btn mobile-product-btn">
                        <img src={car} alt="" srcSet="" />
                        <div>
                          <span>Car </span> Insurance
                        </div>
                      </div>
                    )
                  }
                  {
                    ShowProduct === "bike" && (
                      <div className="active_prod_btn prod_btn mobile-product-btn">
                        <img src={bike} alt="" srcSet="" />
                        <div>
                          <span>Bike </span> Insurance
                        </div>
                      </div>
                    )
                  }
                  <FormContainer
                    stateForMobileContent={ShowCarContent}
                    setShowCarContent={setShowCarContent}
                    productValue={ShowProduct}
                    ProductTabClicked={ProductTabClicked}
                    handleVehicleNumber={(e) => handleVehicleNumber(e)}
                    vehicleNumber={CarRegNo}
                    irdai={irdai}
                    irdaiContentOne={irdaiContentOne}
                    irdaiContentTwo={irdaiContentTwo}
                    mobileCLickEvent={(e) => HandleCarFormPopUp(e)}
                  />
                </div>
              )
            }

            <div className="width">
              {
                ShowProduct === "car" && (
                  <div className="active_prod_btn prod_btn mobile-product-btn">
                    <img src={car} alt="" srcSet="" />
                    <div>
                      <span>Car </span> Insurance
                    </div>
                  </div>
                )
              }
              {
                ShowProduct === "bike" && (
                  <div className="active_prod_btn prod_btn mobile-product-btn">
                    <img src={bike} alt="" srcSet="" />
                    <div>
                      <span>Bike </span> Insurance
                    </div>
                  </div>
                )
              }
              <FormContainer
                stateForMobileContent={ShowCarContent}
                setShowCarContent={setShowCarContent}
                productValue={ShowProduct}
                ProductTabClicked={ProductTabClicked}
                handleVehicleNumber={(e) => handleVehicleNumber(e)}
                vehicleNumber={CarRegNo}
                irdai={irdai}
                irdaiContentOne={irdaiContentOne}
                irdaiContentTwo={irdaiContentTwo}
                mobileCLickEvent={(e) => HandleCarFormPopUp(e)}
              />
            </div>

            <div className="irdai_container_head showInMobile irdai_container_head_mobile  ">
              <img src={irdai} alt="" srcSet="" />
              <p className="small_text irdai_small_text">
                Licensed approved by IRDAI Direct Broker Licence No. 762
              </p>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

{/* <div className="getRegNo_form_part getRegNo_form_part_two hideInMobile ">
              <div className="irdai_container">
                <div className="irdai_container_head showInMobileIrdai ">
                  <img src={irdai} alt="" srcSet="" />
                  <p className="small_text irdai_small_text">
                    Licensed approved by IRDAI Direct Broker Licence No. 762
                  </p>
                </div>
                <div className="getRegNo_highlight_container">
                  <div className="getRegNo_highlight_container_part">
                    {irdaiContentOne.map((index, key) => (
                      <div key={key} className="highlight_container_part_child">
                        <p className="numbers_getregno">
                          {index.number}
                          <span className="numbers_getregno_blue">
                            {index.inSpan}
                          </span>
                        </p>
                        <p className="small_text irdai_small_text">
                          {index.description}
                        </p>
                      </div>
                    ))}
                  </div>
                  <div className="getRegNo_highlight_container_part">
                    {irdaiContentTwo.map((index, key) => (
                      <div key={key} className="highlight_container_part_child">
                        <p className="numbers_getregno">
                          {index.number}
                          <span className="numbers_getregno_blue">
                            {index.inSpan}
                            {key === 1 ? (
                              <> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </>
                            ) : (
                              ""
                            )}
                          </span>
                        </p>
                        <p className="small_text irdai_small_text">
                          {index.description}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div> */}

const irdaiContentOne = [
  {
    number: 40,
    inSpan: "+",
    description: "Insurer companies",
  },
  {
    number: 10,
    inSpan: "Lakh +",
    description: "Customers",
  },
];

const irdaiContentTwo = [
  {
    number: 25,
    inSpan: "K +",
    description: "Claims served",
  },
  {
    number: 100,
    inSpan: " +",
    description: "experts   " + "",
  },
];

export default GetRegNo;
