import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import search from "../../Assets/Images/search.svg";
import Footer from "../Footer/Footer";
import InputFields from "../InputFields/InputFields";
import Botton from "../Button/Button";
import DropDown from "../DropDown/DropDown";
import grayArrow from "../../Assets/Images/grayArrow.svg";
import Select from "react-select";
import { updateVehicleInfoAction } from "../../redux/Reducers/commonVehicleFetchApiReducer";
import {
  getModelAction,
  getCarBrandAction,
  getFuelTypeAction,
  getVariantAction,
} from "../../redux/Actions/mmvAction";
import { getRtoAction } from "../../redux/Actions/stateDetails";
import {
  Routes,
  Route,
  useNavigate,
  Link,
  useLocation,
} from "react-router-dom";
import ModalInp from "./modalInp";
import Calender from "../InputFields/Calender";
import SearchSelect from "../DropDown/Search Select/SearchSelect";
import { NEW } from "../../utils/commonUtils";
import Navbar from "../Navbar/Navbar";
import backIcon from "../../Assets/Images/backIcon.svg";
import CustomDateField from "../InputFields/CustomDateField/CustomDateField";
import CustomCalendar from "../InputFields/Calendar/Calendar";
import CheckBox from "../Checkbox/CheckBox";

const FindMyCarDetails = (props) => {
  // const { sectionOne, sectionTwo } = props;
  const [sectionOne, setSectionOne] = useState([])
  const dispatch = useDispatch();
  const [ShowPolicyStartDate, setShowPolicyStartDate] = useState(false);
  const [PolStartDateClone, setPolStartDateClone] = useState(null);

  const handlePhoneNumber = (e) => {
    const { id, value, name } = e?.target || {};
    if (value.length <= 10) {
      setManualInpValue((prevItems) => ({
        ...prevItems,
        mobileNo: value,
      }));
    }
  };
  // getting details vehicle

  const getVehicleData = useSelector((state) => {
    return state?.getVehicleData?.data?.data;
  });

  const FuelType = getVehicleData?.fuelType;
  const modelDetails = getVehicleData?.model_details?.[0];

  // storing response object
  const getModelValue = useSelector((state) => {
    return state?.getModel;
  });
  const ModelList = getModelValue?.data?.data?.vehicelinfo;
  const uuid = getModelValue?.data?.data?.uuid;

  const getFuelTypeValue = useSelector((state) => {
    return state?.getFuel;
  });
  const fuelList = getFuelTypeValue?.data?.data?.vehicelinfo;

  const getVariantValue = useSelector((state) => {
    return state?.getVariant;
  });
  const variantList = getVariantValue?.data?.data?.vehicelinfo;

  const getRtoData = useSelector((state) => {
    return state?.getRtoDetails;
  });

  const rtoArray = getRtoData?.data?.data?.rtocity?.map((element) => {
    return {
      label: element?.rto + "-" + element?.city_name,
      value: element?.rto + "-" + element?.city_name,
    };
  });

  var today = new Date();
  var curHr = today.getHours();

  let greetingTitle = "Hello, Good Evening!";
  if (curHr < 12) {
    greetingTitle = "Hello, Good Morning!";
  } else if (curHr < 18) {
    greetingTitle = "Hello, Good Afternoon!";
  }

  // navigation
  const navigate = useNavigate();
  const location = useLocation();
  let vehicleNumber = location?.state?.vehicleNumber;
  let businessType = location?.state?.businessType;

  // useState
  const [MakeId, setMakeId] = useState("");
  const [SearchedTerm, setSearchedTerm] = useState("");
  const [ContentType, setContentType] = useState("Make");
  const [SearchedTermModel, setSearchedTermModel] = useState("");
  const [SearchedTermVarient, setSearchedTermVarient] = useState("");

  // state to show the bottoms
  const [ShowMakeBottom, setShowMakeBottom] = useState(false);

  // state for the dropdown
  const [ShowRtoList, setShowRtoList] = useState(false);
  const [RegYearList, setRegYearList] = useState(false);

  const currentYear = new Date().getFullYear();
  const yearOfRegistration = [];

  for (let year = 1990; year <= currentYear; year++) {
    yearOfRegistration.push(year);
  }

  const yearOfReg = yearOfRegistration?.map((data, key) => ({
    label: data,
    value: data,
  }));

  // Reverse the array
  yearOfReg.reverse();

  const manualInputs = {
    make: null,
    model: null,
    varient: null,
    fuelType: null,
    rto: null,
    cc: null,
    regn_dt: null,
    policyStartDate: null,
    businessType: businessType ? businessType : null,
    mobileNo: null,
    pincode: null,
    isPospSale: true,
  };

  const [ManualInpValue, setManualInpValue] = useState(manualInputs);
  const [errors, setErrors] = useState({});

  const [FmcError, setFmcError] = useState({
    makeErr: null,
    modelErr: null,
    varientErr: null,
    fuelTypeErr: null,
    rtoErr: null,
    policyDetailsErr: null,
  });

  useEffect(() => {
    if (businessType === "rollover") {
      setManualInpValue((prevItems) => ({
        ...prevItems,
        make: modelDetails?.Brand,
        model: modelDetails?.Model,
        varient: modelDetails?.Variant_Name,
        fuelType: modelDetails?.fuelType,
        // rto: null,
        cc: modelDetails?.displacement,
      }));
    }
  }, [getVehicleData]);

  const handleSearchQuery = (searchValue, type) => {
    switch (type) {
      case "make":
        setSearchedTerm(searchValue);
        break;

      case "model":
        setSearchedTermModel(searchValue);
        break;

      case "variant":
        setSearchedTermVarient(searchValue);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    (async function () {
      let carBrand = await dispatch(getCarBrandAction());
      setSectionOne(carBrand?.payload?.data?.vehicelinfo)
    })();
  }, [])

  let reorderedCarBrands = []

  if (SearchedTerm !== "") {
    reorderedCarBrands = SearchedTerm
      ? sectionOne?.filter((item) =>
        item?.make?.toLowerCase()?.includes(SearchedTerm?.toLowerCase())
      )
      : [];
  } else {
    reorderedCarBrands = sectionOne
  }

  let reorderedCarModels = []

  if (SearchedTermModel !== "") {
    reorderedCarModels = SearchedTermModel
      ? ModelList?.filter((item) =>
        item?.Model?.toLowerCase()?.includes(SearchedTermModel?.toLowerCase())
      )
      : [];
  } else {
    reorderedCarModels = ModelList
  }

  let reorderedCarVarients = []

  if (SearchedTermVarient !== "") {
    reorderedCarVarients = SearchedTermVarient
      ? variantList?.filter((item) =>
        item?.Variant?.toLowerCase()?.includes(SearchedTermVarient?.toLowerCase())
      )
      : [];
  } else {
    reorderedCarVarients = variantList
  }

  const handlePolicyStartDate = (e) => {
    const { name, value } = e?.target;
    setManualInpValue((prevItems) => ({
      ...prevItems,
      [name]: value,
    }));
  };

  const handleMakeData = (id, name) => {
    setMakeId(id);
    dispatch(getModelAction(id));
    setManualInpValue((prevItems) => ({
      ...prevItems,
      make: name,
    }));
    setFmcError((prevItems) => ({
      ...prevItems,
      makeErr: false,
      modelErr: false,
      fuelTypeErr: false,
    }));
    setContentType("Model");
  };

  const handleModelData = (name, key) => {
    let fuelReqObj = {
      id: MakeId,
      modelName: name,
    };
    setManualInpValue((prevItems) => ({
      ...prevItems,
      model: name,
    }));
    setFmcError((prevItems) => ({
      ...prevItems,
      makeErr: false,
      modelErr: false,
      fuelTypeErr: false,
      varientErr: false,
    }));

    dispatch(getFuelTypeAction(fuelReqObj));
    setContentType("FuelType");
  };

  const handleFuelTypeValue = (value) => {
    let variantReqObj = {
      id: MakeId,
      modelName: ManualInpValue?.model,
      selectedFuel: value,
    };
    setContentType("Varient");
    dispatch(getVariantAction(variantReqObj));
    dispatch(getRtoAction("rto"));
    setManualInpValue((prevItems) => ({
      ...prevItems,
      fuelType: value,
    }));
    setFmcError((prevItems) => ({
      ...prevItems,
      makeErr: false,
      varientErr: false,
      rtoErr: false,
    }));
  };

  const handleVarientData = (variant, cc) => {
    setManualInpValue((prevItems) => ({
      ...prevItems,
      varient: variant,
      cc: cc,
    }));
    setFmcError((prevItems) => ({
      ...prevItems,
      makeErr: false,
      varientErr: false,
      fuelTypeErr: false,
    }));
    setContentType("RTO");
  };

  const showBottomContent = (contentType) => {
    switch (contentType) {
      case "Make":
        setShowMakeBottom(!ShowMakeBottom);
    }
  };

  const handleRtoValue = (value) => {
    setManualInpValue((prevItems) => ({
      ...prevItems,
      rto: value?.value,
    }));
  };

  const handleRegYearValue = (e) => {
    setManualInpValue((prevItems) => ({
      ...prevItems,
      regn_dt: e.target.value,
    }));
    // setRegYear(value);
  };

  const handlePopUps = () => {
    if (ShowRtoList) {
      setShowRtoList(false);
    }

    if (RegYearList) {
      setRegYearList(false);
    }
  };

  let breadCrumb = [
    {
      name: "Make",
      head: "Company Brand:",
      // value: ManualInpValue?.make ? ManualInpValue?.make : "Select Make",
      value:
        ManualInpValue?.make === "Maruti"
          ? "Maruti Suzuki"
          : ManualInpValue?.make || "Select Make",
    },
    {
      name: "Model",
      head: "Brand Model:",
      value: ManualInpValue?.model ? ManualInpValue?.model : "Select Model",
    },
    {
      name: "FuelType",
      head: "Fuel Type:",
      value:
        ManualInpValue?.fuelType === "INTERNAL_LPG_CNG"
          ? "Internal Fitted CNG"
          : ManualInpValue?.fuelType || "Select Fuel Type",
    },
    {
      name: "Varient",
      head: "Variant:",
      value: ManualInpValue?.varient
        ? ManualInpValue?.varient + " - " + ManualInpValue?.cc + "cc"
        : "Select Variant",
    },
    {
      name: "RTO",
      secondName: "PolicyDetails",
      head: "RTO and year of Registration: ",
      value: ManualInpValue?.rto
        ? ManualInpValue?.rto + " and " + ManualInpValue?.regn_dt
        : "Select RTO",
    },
  ];

  const handleRoute = (vehicleInfo, e) => {
    const storeVehicleInfo = vehicleInfo;
    const isValid = validateModalInp(storeVehicleInfo);
    if (isValid) {
      const data = {
        data: {
          data: vehicleInfo,
        },
      };
      delete vehicleInfo.e
      dispatch(updateVehicleInfoAction(data));
      localStorage.setItem("isFindMyCar", true);
      localStorage.setItem("mobileNo", vehicleInfo.mobileNo);
      localStorage.setItem("pincode", vehicleInfo.pincode);
      navigate("/View-Plans?id=" + uuid, {
        state: {
          vehicleNumber: vehicleNumber,
          businessType: ManualInpValue?.businessType,
          isFindMyCar: true,
        },
      });
    }
  };

  const validateModalInp = (obj) => {
    const newErrors = {};

    if (businessType != "New") {
      if (obj.isPrevPolicyExp == null) {
        newErrors.isPrevPolicyExp = "First Name is required";
      }
      if (obj.isPrevPolicyExp === true) {
        if (obj.prevPolicyExpLastMonth === null) {
          newErrors.prevPolicyExpLastMonth = "First Name is required";
        }
      }

      if (obj.prevPolicyExpLastMonth !== true) {
        if (obj.isClaimPrevPolicy == null) {
          newErrors.isClaimPrevPolicy = "First Name is required";
        }
      }
    }

    if (obj.isCngLpgVehicleType === true) {
      if (obj.cngKitValue == "") {
        newErrors.cngKitValue = "CNG/LPG Kit Value is required";
      }
    }

    let regexMobile = /^[2-9]{1}[0-9]{9}$/;

    if (!obj.mobileNo) {
      newErrors.phoneNumber = "Phone Number is required";
    }

    if (!obj.rto) {
      newErrors.rto = "RTO is required";
    }

    if (!obj.regn_dt) {
      newErrors.regn_dt = "Registration year is required";
    }

    if (businessType === "New") {
      if (!obj.policyStartDate) {
        newErrors.policyStartDate = "Policy Start Date is required";
      }
    }

    if (obj.mobileNo && !regexMobile.test(obj.mobileNo)) {
      newErrors.phoneNumber = "Mobile number is Invalid";
    }

    if (!obj.pincode) {
      newErrors.pincode = "Pin code is required";
    }

    let pinregEx = /^[1-9]{1}[0-9]{2}[0-9]{3}$/;

    if (obj.pincode && !pinregEx.test(obj.pincode)) {
      newErrors.pincode = "Invalid pin code";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleRouteNew = (manualValues) => {
    const isValid = validateModalInp(manualValues);
    if (isValid) {
      const data = {
        data: {
          data: manualValues,
        },
      };
      delete manualValues.e
      dispatch(updateVehicleInfoAction(data));
      localStorage.setItem("mobileNo", manualValues.mobileNo);
      localStorage.setItem("pincode", manualValues.pincode);
      localStorage.setItem("isFindMyCar", true);
      navigate("/View-Plans?id=" + uuid, {
        state: {
          vehicleNumber: vehicleNumber,
          businessType: ManualInpValue?.businessType,
          isFindMyCar: true,
        },
      });
    }
  };

  const handleContentReveal = (content) => {
    switch (content) {
      case "Make":
        setContentType("Make");
        break;

      case "Model":
        if (ManualInpValue?.make != null) {
          if (ContentType === "Make") {
            if (ManualInpValue?.make != null) {
              setContentType("Model");
              setFmcError((prevItems) => ({
                ...prevItems,
                makeErr: false,
              }));
            } else {
              setContentType("Make");
            }
          } else {
            setContentType("Model");
            setFmcError((prevItems) => ({
              ...prevItems,
              makeErr: false,
            }));
          }
        } else {
          // alert("kakakaka")
          setFmcError((prevItems) => ({
            ...prevItems,
            makeErr: true,
          }));
        }
        break;

      case "FuelType":
        if (ManualInpValue?.make != null && ManualInpValue?.model != null) {
          setContentType("FuelType");
          setFmcError((prevItems) => ({
            ...prevItems,
            fuelTypeErr: false,
            makeErr: false,
          }));
        } else {
          setFmcError((prevItems) => ({
            ...prevItems,
            fuelTypeErr: true,
            makeErr: true,
          }));
        }
        break;

      case "Varient":
        if (
          ManualInpValue?.make != null &&
          ManualInpValue?.model != null &&
          ManualInpValue?.fuelType != null
        ) {
          setContentType("Varient");
          setFmcError((prevItems) => ({
            ...prevItems,
            varientErr: false,
            fuelTypeErr: false,
            makeErr: false,
          }));
        } else {
          setFmcError((prevItems) => ({
            ...prevItems,
            varientErr: true,
            fuelTypeErr: true,
            makeErr: true,
          }));
        }
        break;

      case "RTO":
        if (
          ManualInpValue?.make != null &&
          ManualInpValue?.model != null &&
          ManualInpValue?.fuelType != null &&
          ManualInpValue?.varient != null
        ) {
          setContentType("RTO");
          setFmcError((prevItems) => ({
            ...prevItems,
            varientErr: false,
            fuelTypeErr: false,
            makeErr: false,
            rtoErr: false,
          }));
        } else {
          setFmcError((prevItems) => ({
            ...prevItems,
            varientErr: true,
            fuelTypeErr: true,
            makeErr: true,
            rtoErr: true,
          }));
        }
        break;

      case "PolicyDetails":
        if (
          ManualInpValue?.make != null &&
          ManualInpValue?.model != null &&
          ManualInpValue?.fuelType != null &&
          ManualInpValue?.varient != null &&
          ManualInpValue?.rto != null &&
          ManualInpValue?.regn_dt != null
        ) {
          setContentType("PolicyDetails");
          setFmcError((prevItems) => ({
            ...prevItems,
            varientErr: false,
            fuelTypeErr: false,
            makeErr: false,
            rtoErr: false,
            policyDetailsErr: false,
          }));
        } else {
          setFmcError((prevItems) => ({
            ...prevItems,
            varientErr: true,
            fuelTypeErr: true,
            makeErr: true,
            rtoErr: true,
            policyDetailsErr: true,
          }));
        }
        break;

      default:
        break;
    }
  };

  const backToHome = () => {
    navigate("/");
  };

  const cancelCalendarValue = (name) => {
    setShowPolicyStartDate(false);
    alert(name);
  };

  const saveCalendarValue = (name) => {
    alert(name);
  };

  const handlePolStartDateClone = (value, name, emptyString) => {
    setPolStartDateClone(value);
  };

  const handleCheckboxChange = (checkboxName) => {
    setManualInpValue({
      ...ManualInpValue,
      [checkboxName]: !ManualInpValue[checkboxName],
    });
  };

  const handleRouteToFMC = () => {
    setContentType("Make");
  }

  const closeModal = () => {
    navigate("/");
  }

  return (
    <>
      <Navbar />
      <div className="FindMyCar_container" onClick={handlePopUps}>
        <div className="findMyCar_parent maxWidth">
          <div className="content_block-findMyCar">
            <div className="leftContent_findMyCar contentDivide_findMyCar ">
              <p className="leftContent_findMyCar_head">Your Car Details</p>
              {breadCrumb?.map((item, key) => (
                <div
                  key={key}
                  className={
                    ContentType === item.name || ContentType === item.secondName
                      ? "info_block_left-active info_block_left_findMyCar "
                      : "info_block_left_findMyCar"
                  }
                  onClick={(e) => handleContentReveal(item.name)}
                >
                  <p className="info_block_left_head">{item.head} </p>
                  <p className="info_block_left_desc">{item.value}</p>
                </div>
              ))}
            </div>
            <div
              className={
                ContentType === "PolicyDetails"
                  ? "rightContent_findMyCar height-brandContainer-findMyCar contentDivide_findMyCar"
                  : "rightContent_findMyCar  contentDivide_findMyCar"
              }
            >
              {ContentType !== "PolicyDetails" && (
                <div className="heading_rightSide_findMycar">
                  <p>{greetingTitle}</p>
                  <p className="info_block_left_desc">
                    We will help you to find your car details. Please help us
                    with few details.
                  </p>
                </div>
              )}

              <p className="mobile-back-fmc" onClick={(e) => backToHome()}>
                {" "}
                <img src={backIcon} alt="" srcSet="" /> Back to home{" "}
              </p>

              <div className="brandContainer-findMyCar">
                {ContentType === "Make" ? (
                  <>
                    <p className="title_brandCar">Select my Car Brand </p>
                    {FmcError?.makeErr === true && (
                      <p className="imp-msg ">
                        Please select your car Brand to proceed.
                      </p>
                    )}
                  </>
                ) : ContentType === "Model" ? (
                  <>
                    <p className="title_brandCar">Select my Car Model</p>
                    {FmcError?.fuelTypeErr === true && (
                      <p className="imp-msg ">
                        Please select your car Model to proceed.
                      </p>
                    )}
                  </>
                ) : ContentType === "FuelType" ? (
                  <>
                    <p className="title_brandCar">Select my Car Fuel Type</p>
                    {FmcError?.varientErr === true && (
                      <p className="imp-msg ">
                        Please select your car Fuel Type to proceed.
                      </p>
                    )}
                  </>
                ) : ContentType === "RTO" ? (
                  <>
                    <p className="title_brandCar">
                      Select RTO and year of car registration
                    </p>
                    {FmcError?.policyDetailsErr === true && (
                      // ManualInpValue.rto === null &&
                      <p className="imp-msg ">
                        Please select your RTO and Registration Year to proceed.
                      </p>
                    )}
                  </>
                ) : ContentType === "Varient" ? (
                  <>
                    <p className="title_brandCar">Select Variant</p>
                    {FmcError?.rtoErr === true && (
                      // ManualInpValue.varient === null &&
                      <p className="imp-msg ">
                        Please select your car Varient to proceed.
                      </p>
                    )}
                  </>
                ) : (
                  ""
                )}

                {ContentType === "Make" && (
                  <>
                    <div className="brandLogoContainer-input">
                      <img
                        src={search}
                        className="search-fmc"
                        alt=""
                        srcSet=""
                      />
                      <Botton
                        className="button fmc-btn fmc-btn-search"
                        text="Search"
                      />
                      <input
                        type="text"
                        className="fmcInput"
                        id="fmcInput"
                        name=""
                        value={SearchedTerm}
                        placeholder="Search your car brand"
                        onChange={(e) =>
                          handleSearchQuery(e.target.value, "make")
                        }
                      />
                    </div>

                    <div className="brandLogoContainer-findMyCar  logo-findmycar model-findmycar">
                      <div className="fmc-btn-container fmc-btn-container-varient">
                        {!ShowMakeBottom ? (
                          <>
                            {reorderedCarBrands?.map(
                              (item, key) =>
                                key < 9 && (
                                  <div
                                    className={
                                      ManualInpValue.make === item.make
                                        ? "model_tab model_tab-active"
                                        : "model_tab"
                                    }
                                    key={key}
                                    onClick={(e) =>
                                      handleMakeData(item.make, item.make)
                                    }
                                  >
                                    <p>{item.make}</p>
                                  </div>
                                )
                            )}
                          </>
                        ) : (
                          <>
                            {reorderedCarBrands?.map((item, key) => (
                              <div
                                className={
                                  ManualInpValue.make === item.make
                                    ? "model_tab model_tab-active"
                                    : "model_tab"
                                }
                                key={key}
                                onClick={(e) =>
                                  handleMakeData(item.make, item.make)
                                }
                              >
                                <p>{item.make}</p>
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                      <div className="btnContainer-fmc">
                        <Botton
                          className="secondry_btn secondry_btn-fmc"
                          text={ShowMakeBottom ? "Hide" : "View all 44 brands"}
                          event={(e) => showBottomContent("Make")}
                        />
                      </div>
                    </div>
                    <div className="btnContainer-fmc btnContainer-fmc-noPadding btnContainer-fmc-mobile">
                      <Botton
                        className="secondry_btn secondry_btn-fmc"
                        text="Back"
                        event={(e) => backToHome()}
                      />
                      <Botton
                        className="button secondry_btn-fmc"
                        text="Next"
                        event={(e) => handleContentReveal("Model")}
                      />
                    </div>
                  </>
                )}

                {ContentType === "Model" && (
                  <>
                    <div className="brandLogoContainer-input">
                      <img
                        src={search}
                        className="search-fmc"
                        alt=""
                        srcSet=""
                      />
                      <Botton
                        className=" button fmc-btn fmc-btn-search"
                        text="Search"
                      />
                      <input
                        type="text"
                        className="fmcInput"
                        id="fmcInput"
                        name=""
                        value={SearchedTermModel}
                        placeholder="Search your car model"
                        onChange={(e) =>
                          handleSearchQuery(e.target.value, "model")
                        }
                      />
                    </div>

                    <div className="brandLogoContainer-findMyCar logo-findmycar model-findmycar ">
                      <div
                        className={
                          getModelValue?.isLoading === true
                            ? " fmc-btn-container fmc-btn-container-varient blurEffect"
                            : "fmc-btn-container fmc-btn-container-varient"
                        }
                      >
                        {reorderedCarModels?.map((item, key) => (
                          <Botton
                            key={key}
                            className={
                              ManualInpValue?.model === item.Model
                                ? "model_tab model_tab-active"
                                : "model_tab"
                            }
                            text={item.Model}
                            event={(e) => handleModelData(item.Model, key)}
                          />
                        ))}
                      </div>
                    </div>
                    <div className="btnContainer-fmc btnContainer-fmc-noPadding btnContainer-fmc-mobile ">
                      <Botton
                        className="secondry_btn secondry_btn-fmc"
                        text="Back"
                        event={(e) => setContentType("Make")}
                      />
                      <Botton
                        className="button secondry_btn-fmc"
                        text="Next"
                        event={(e) => handleContentReveal("FuelType")}
                      />
                    </div>
                  </>
                )}

                {ContentType === "FuelType" && (
                  <>
                    <div
                      className={
                        getFuelTypeValue?.isLoading === true
                          ? " brandLogoContainer-findMyCar fuelTypeContainer-findMyCar blurEffect"
                          : "brandLogoContainer-findMyCar fuelTypeContainer-findMyCar"
                      }
                    >
                      {fuelList?.map((fuel, key) => (
                        <Botton
                          key={key}
                          text={
                            fuel.Fuel_Type === "INTERNAL_LPG_CNG"
                              ? "Internal Fitted CNG"
                              : fuel.Fuel_Type
                          }
                          className={
                            ManualInpValue?.fuelType === fuel.Fuel_Type
                              ? "fuelType-btn fuelType-btn-active "
                              : "fuelType-btn"
                          }
                          event={(e) => handleFuelTypeValue(fuel.Fuel_Type)}
                        />
                      ))}
                    </div>
                    <div className="btnContainer-fmc btnContainer-fmc-noPadding btnContainer-fmc-mobile">
                      <Botton
                        className="secondry_btn secondry_btn-fmc"
                        text="Back"
                        event={(e) => setContentType("Model")}
                      />
                      <Botton
                        className="button secondry_btn-fmc"
                        text="Next"
                        event={(e) => handleContentReveal("Varient")}
                      />
                    </div>
                  </>
                )}

                {ContentType === "Varient" && (
                  <>
                    <div className="brandLogoContainer-input">
                      <img
                        src={search}
                        className="search-fmc"
                        alt=""
                        srcSet=""
                      />
                      <Botton
                        className=" button fmc-btn fmc-btn-search"
                        text="Search"
                      />
                      <input
                        type="text"
                        className="fmcInput"
                        id="fmcInput"
                        name=""
                        value={SearchedTermVarient}
                        placeholder="Search your car Variant"
                        onChange={(e) =>
                          handleSearchQuery(e.target.value, "variant")
                        }
                      />
                    </div>

                    <div className="brandLogoContainer-findMyCar logo-findmycar model-findmycar  ">
                      <div
                        className={
                          getVariantValue?.isLoading === true
                            ? " fmc-btn-container fmc-btn-container-varient blurEffect"
                            : "fmc-btn-container fmc-btn-container-varient"
                        }
                      >
                        {reorderedCarVarients?.map((item, key) => (
                          <Botton
                            key={key}
                            className={
                              ManualInpValue?.varient === item.Variant
                                ? "model_tab variant_tab_fmc model_tab-active "
                                : "model_tab variant_tab_fmc "
                            }
                            text={
                              item.Variant +
                              " - " +
                              "(" +
                              item.Cubic_Capacity +
                              " cc)"
                            }
                            event={(e) =>
                              handleVarientData(
                                item.Variant,
                                item.Cubic_Capacity
                              )
                            }
                          />
                        ))}
                      </div>
                    </div>
                    <div className="btnContainer-fmc btnContainer-fmc-noPadding btnContainer-fmc-mobile">
                      <Botton
                        className="secondry_btn secondry_btn-fmc"
                        text="Back"
                        event={(e) => setContentType("FuelType")}
                      />
                      <Botton
                        className="button secondry_btn-fmc"
                        text="Next"
                        event={(e) => handleContentReveal("RTO")}
                      />
                    </div>
                  </>
                )}

                {ContentType === "RTO" && (
                  <div
                    className={
                      businessType === "New"
                        ? "brandLogoContainer-findMyCar fuelTypeContainer-findMyCar  flexDiv-findMyCar fuelTypeContainer-findMyCar-  "
                        : "brandLogoContainer-findMyCar fuelTypeContainer-findMyCar  flexDiv-findMyCar"
                    }
                  >
                    <div className="input_group_pform input_group-FMC ">
                      <div className="inputGropOne_form inputGrop ">
                        <SearchSelect
                          optionList={rtoArray}
                          handleSelect={handleRtoValue}
                          value={ManualInpValue?.rto}
                          ManualInpValue={ManualInpValue}
                          placeholder="Select RTO"
                          label="Select RTO"
                          errState={errors?.rto ? true : false}
                          errMsg={errors?.rto ? errors?.rto : ""}
                        />
                      </div>
                      <div className="inputGropOne_form inputGrop ">
                        <DropDown
                          dropDownMenu={yearOfReg}
                          handleChangeInput={(e) => console.log(e)}
                          value={ManualInpValue?.regn_dt}
                          name="RegYear"
                          placeHolder="Year of Registration"
                          arrowInGray={grayArrow}
                          Head="Year of Registration"
                          label="Registration Year"
                          labelclass="rmvLeft"
                          classNameForHead="headTxtClassPform"
                          inputId="state"
                          clickEvent={(e) => console.log(e)}
                          headClick={(e) => setRegYearList(!RegYearList)}
                          revealOptions={RegYearList}
                          afterTrueClass="dropDown_container NoBorderRadius"
                          className="dropDown_container"
                          valueSelectionEvent={(e) => handleRegYearValue(e)}
                          revealOptionsClasses=" dropDownOptions showdropDownOptions "
                          classNameTwo="dropDownOptions"
                          dropDownHeadClass="dropDownHead"
                          errState={errors?.regn_dt ? true : false}
                          errMsg={errors?.regn_dt ? errors?.regn_dt : ""}
                          errClassName={errors?.regn_dt ? "input-err" : ""}
                        />
                      </div>
                    </div>
                    {businessType === "New" && (
                      <div className="input_group_pform input_group-FMC ">
                        <div className="inputGropOne_form inputGrop ">
                          <label htmlFor="policyStartDate">
                            <Calender
                              className="pForm_datePicker"
                              placeholder="Policy Start Date"
                              name="policyStartDate"
                              id="policyStartDate"
                              label="Policy Start Date"
                              value={ManualInpValue?.policyStartDate}
                              changeEvent={(e) => handlePolicyStartDate(e)}
                              errClassName={
                                errors?.policyStartDate ? "input-err" : ""
                              }
                              errMsg={errors?.policyStartDate}
                            />
                          </label>
                          {/* <CustomDateField
                            className="pForm_datePicker pForm_datePicker-tab "
                            btnId="PolStartDateBtn"
                            textId="PolStartDateTxt"
                            imgId="PolStartDateImg"
                            dateValue={ManualInpValue?.policyStartDate}
                            placeholder="Policy Start Date"
                            placeholderId="PolStartDatePlaceholderId"
                            calendarReveal={ShowPolicyStartDate}
                            setCalendarReveal={setShowPolicyStartDate}
                          /> */}
                        </div>
                        <div className="inputGropOne_form inputGrop ">
                          <InputFields
                            value={ManualInpValue?.mobileNo}
                            placeholder="Phone Number"
                            id="mobileNo"
                            type="number"
                            name="mobileNo"
                            errState={errors?.phoneNumber ? true : false}
                            errMsg={errors?.phoneNumber}
                            errClassName="input-err"
                            onChangeEvent={(e) => handlePhoneNumber(e)}
                          />
                        </div>
                      </div>
                    )}

                    {businessType === "New" && (
                      <div className="input_group_pform input_group-FMC">
                        <div className="inputGropOne_form inputGrop ">
                          <InputFields
                            value={ManualInpValue?.pincode}
                            placeholder="Pin code"
                            id="pincode"
                            type="number"
                            name="pincode"
                            errState={errors?.pincode ? true : false}
                            errMsg={errors?.pincode}
                            errClassName="input-err"
                            onChangeEvent={(e) =>
                              setManualInpValue((prevItems) => ({
                                ...prevItems,
                                pincode: e.target.value,
                              }))
                            }
                          />
                        </div>
                        <div className="inputGropOne_form inputGrop inputGrop-fmc-new ">
                          {/* <div className="checkbox_container checkbox_container-modalinp"> */}
                          {/* <CheckBox
                            label="Sale for PoSP"
                            className="small_text small_textModal checkBox-label"
                            type="checkbox"
                            checked={ManualInpValue.isPospSale}
                            changeEvent={() =>
                              handleCheckboxChange("isPospSale")
                            }
                          /> */}
                          {/* </div> */}
                        </div>
                      </div>
                    )}

                    <div className="btnContainer-fmc btnContainer-fmc-rto btnContainer-fmc-noPadding ">
                      <Botton
                        className="secondry_btn secondry_btn-fmc secondry_btn-fmc-rto"
                        text="Back"
                        event={(e) => setContentType("Varient")}
                      />
                      {businessType === NEW ? (
                        <Botton
                          className="button secondry_btn-fmc secondry_btn-fmc-rto"
                          text="Check Premium"
                          event={(e) => handleRouteNew({ ...ManualInpValue })}
                        />
                      ) : (
                        <Botton
                          className="button secondry_btn-fmc secondry_btn-fmc-rto"
                          text="Next"
                          event={(e) => handleContentReveal("PolicyDetails")}
                        />
                      )}
                    </div>
                  </div>
                )}

                {ContentType === "PolicyDetails" && (
                  <ModalInp
                    ManualInpValue={ManualInpValue}
                    businessType={businessType}
                    clickEvent={handleRoute}
                    errors={errors}
                    uniqueClass="positionUnique"
                    vehicleType="4W"
                    make={ManualInpValue?.make}
                    model={ManualInpValue?.model}
                    varient={ManualInpValue?.varient}
                    fuelType={ManualInpValue?.fuelType}
                    regDate={ManualInpValue?.regn_dt}
                    handleRouteToFMC={handleRouteToFMC}
                    closeModal={closeModal}
                  />
                )}

                {/* {
                  ShowPolicyStartDate && (
                    <CustomCalendar
                      value={PolStartDateClone}
                      headText="Enter your Policy Start Date"
                      name="PolStartDateClone"
                      btnId="PolStartDateBtn"
                      textId="PolStartDateTxt"
                      imgId="PolStartDateImg"
                      placeholderId="PolStartDatePlaceholderId"
                      revealState={ShowPolicyStartDate}
                      setRevealState={setShowPolicyStartDate}
                      handleCopiedDetails={handlePolStartDateClone}
                      cancelCalendarValue={cancelCalendarValue}
                      saveCalendarValue={saveCalendarValue}
                      onClose={(e) => setShowPolicyStartDate(false)}
                    />
                  )
                } */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FindMyCarDetails;
