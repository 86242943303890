import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import "./Razorpay.css";
import success from "../../Assets/Images/paymentSucces.svg";
import Footer from "../Footer/Footer";
import Botton from "../Button/Button";
import { sbiPdfDownloadAction } from "../../redux/Actions/sbiApiAction";
import { digitKycStatusAction, digitPdfDownloadAction } from "../../redux/Actions/digitApiAction";

import { icicipdfDownloadAction } from "../../redux/Actions/iciciApiAction"
import { zunoPdfDownloadAction } from "../../redux/Actions/zunoApiAction"
import { ackopdfDownloadAction } from "../../redux/Actions/ackoApiAction"
import { tataPolicyDocumentAction } from "../../redux/Actions/tataApiAction"
import loader2 from "../../Assets/Loader/Loader.gif";
import "../RegNumberForm/loaderMain.css";
import Navbar from "../Navbar/Navbar";
import { combinePDFBuffers } from "../../utils/extLib"

const Redirect = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const paymentStatus = useSelector((store) => {
    return store.paymentWebhook;
  });

  const sbiQuickQuoteData = useSelector((state) => {
    return state?.sbiQuickQuote;
  });

  const search = useLocation().search;
  let companyName = new URLSearchParams(search).get("companyName");
  //used in shreeam
  let PolicyNumber = new URLSearchParams(search).get("PolicyNumber");
  const applicationId = new URLSearchParams(search).get("appID");
  const PolicyURL = new URLSearchParams(search).get("policyURL");
  const ResponseCode = new URLSearchParams(search).get("ResponseCode");
  let transaction_id = new URLSearchParams(search).get("transaction_id");
  let proposal_id = new URLSearchParams(search).get("proposal_id");
  let policy_number = new URLSearchParams(search).get("policy_number");

  if (companyName == 'digit') {
    transaction_id = new URLSearchParams(search).get("transactionNumber");
  }

  useEffect(() => {
    if (companyName == 'icici') {
      dispatch(
        icicipdfDownloadAction({
          data: {
            transactionId: transaction_id
          },
        })
      );
    }
    if (proposal_id) {
      dispatch(
        ackopdfDownloadAction({
          data: {
            proposal_id,
          }
        })
      )
    }
  }, []);

  if (proposal_id) {
    transaction_id = proposal_id;
    PolicyNumber = policy_number;
    companyName = "acko"
  }

  const downloadPdf = async () => {
    if (companyName == "sbi") {
      setLoading(true);
      try {
        const pdfData = await dispatch(
          sbiPdfDownloadAction({
            data: {
              PolicyNumber: PolicyNumber,
              requestId:
                sbiQuickQuoteData?.data?.body?.data?.body?.RequestHeader
                  ?.requestID,
            },
          })
        );

        setTimeout(() => {
          if (pdfData?.payload?.body?.data?.quote?.DocBase64) {
            const linkSource = `data:application/pdf;base64,${pdfData?.payload?.body?.data?.quote?.DocBase64}`;
            const downloadLink = document.createElement("a");
            const fileName = `${PolicyNumber}.pdf`;
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
            setLoading(false);
          } else {
            setLoading(false);
            alert("Something went wrong !!");
          }
        }, 20000);
      } catch (rejectedValueOrSerializedError) {
        setLoading(false);
        alert("Something went wrong !!");
      }
    } else if (companyName == "shreeram") {
      document.getElementById("shreeramdownload").click(); // Programmatically click the invisible button

    } else if (companyName == "digit") {
      setLoading(true)
      try {
        const checkKycStatus = await dispatch(
          digitKycStatusAction({
            data: {
              policyNumber: PolicyNumber,
            }
          })
        );
        if (checkKycStatus?.payload?.data?.quote['Motor-KYC status search Api']?.kycVerificationStatus == 'DONE') {
          const downloadPdf = await dispatch(
            digitPdfDownloadAction({
              data: {
                applicationId: applicationId,
              }
            })
          );
          let redirectUrl = downloadPdf?.payload?.data?.quote['Motor-Policy PDF (using application id)']?.schedulePath;
          setLoading(false)
          window.location.replace(redirectUrl);
        } else {
          //here we need to inform user that we are redirecting you to kyc after
          //after successfull completion of kyc you'll recive pdf of policy in 12 hours
          setLoading(false);
          alert("Please don't close this tab. We are redirecting you to digit kyc page. After successfull completion of kyc comeback to this page and click Download.")
          let redirectUrl = checkKycStatus?.payload?.data?.quote['Motor-KYC status search Api']?.link;
          window.open(redirectUrl, '_blank');
        }

      } catch (rejectedValueOrSerializedError) {
        setLoading(false)
        alert("Something went wrong !!")
      }
    } else if (companyName == "icici") {
      setLoading(true)
      try {
        let retryCount = 0;
        let retryPolicyDownload = async () => {
          const pdfData = await dispatch(
            icicipdfDownloadAction({
              data: {
                transactionId: transaction_id
              },
            })
          );
          if (pdfData?.payload?.data?.pdfBase64) {
            // let pdfMerge = await combinePDFBuffers(pdfData?.payload?.data?.pdfBase64);
            // const linkSource = `data:application/pdf;base64,${pdfMerge}`;
            const linkSource = `data:application/pdf;base64,${pdfData?.payload?.data?.pdfBase64}`;
            const downloadLink = document.createElement("a");
            const fileName = `ICICI_${pdfData?.payload?.data.policyNo}.pdf`;
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
            setLoading(false)
          } else {
            if (retryCount == 2) {
              setLoading(false)
              alert("Something went wrong !!")
            } else {
              retryCount++;
              retryPolicyDownload()
            }
          }
        }
        retryPolicyDownload();
      } catch (rejectedValueOrSerializedError) {
        setLoading(false)
        alert("Something went wrong !!")
      }
    } else if (companyName == "zuno") {
      setLoading(true)
      try {
        const pdfData = await dispatch(
          zunoPdfDownloadAction({
            data: {
              policyNumber: PolicyNumber
            },
          })
        );

        // setTimeout(() => {
        if (pdfData?.payload?.data?.quote) {
          let pdfMerge = await combinePDFBuffers(pdfData?.payload?.data?.quote);
          const linkSource = `data:application/pdf;base64,${pdfMerge}`;
          const downloadLink = document.createElement("a");
          const fileName = `ZUNO_${PolicyNumber}.pdf`;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          setLoading(false)
        } else {
          setLoading(false)
          alert("Something went wrong !!")
        }
        // }, 20000);
      } catch (error) {
        setLoading(false)
        alert("Something went wrong !!")
      }
    } else if (companyName == "acko" && proposal_id) {  //acko policy download
      setLoading(true)
      try {
        const pdfData = await dispatch(
          ackopdfDownloadAction({
            data: {
              proposal_id,
            },
          })
        );
        if (pdfData?.payload?.data?.pdfBase64) {
          //merge pdf 
          let pdfMerge = await combinePDFBuffers(pdfData?.payload?.data?.pdfBase64);
          const linkSource = `data:application/pdf;base64,${pdfMerge}`;
          const downloadLink = document.createElement("a");
          const fileName = `ACKO_${pdfData?.payload?.data.policyNo}.pdf`;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          setLoading(false)
        } else {
          setLoading(false)
          alert("Something went wrong !!")
        }
      } catch (rejectedValueOrSerializedError) {
        setLoading(false)
        alert("Something went wrong !!")
      }
    } else if (companyName == "tata") {
      setLoading(true)
      try {
        const pdfData = await dispatch(
          tataPolicyDocumentAction({
            data: {
              proposal_no: transaction_id,
            },
          })
        );
        if (pdfData?.payload?.data?.quote?.byteStream) {
          const linkSource = `data:application/pdf;base64,${pdfData?.payload?.data?.quote?.byteStream}`;
          const downloadLink = document.createElement("a");
          const fileName = `TATA_${PolicyNumber.split("-")[0]}.pdf`;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          setLoading(false)
        } else {
          setLoading(false)
          alert("Something went wrong !!")
        }
      } catch (rejectedValueOrSerializedError) {
        setLoading(false)
        alert("Something went wrong !!")
      }
    }
  };

  useEffect(() => {
    if (loading) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [loading]);

  return (
    <>
      <Navbar />
      <div id="cover-spin" style={{ display: loading ? "" : "none" }}>
        <img src={loader2} className="gifLoader"></img>
      </div>
      <div>
        <section className="redirectContainer">
          <div className="content-block-redirect">
            <img src={success} alt="" srcSet="" />
            <p className="msg-edirect-succes">Thank You!</p>
            <p className="msg-edirect">
              Your payment was successful. <br /> Transition Id is
              <b> {transaction_id}</b> and Policy Number is{" "}
              <b>{PolicyNumber}</b>. <br /> You can now download your policy.
            </p>
            <p className="shreeramdownload" hidden>
              <a id="shreeramdownload" href={PolicyURL} download={PolicyNumber}>Download</a>
            </p>

            <Botton
              text={loading ? "Downloading Policy..." : "Download Policy"}
              event={downloadPdf}
              className="button btn-redirect"
            />
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default Redirect;
