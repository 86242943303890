import React, { useState } from "react";
import Modal from "react-modal";
import digit from "../../Assets/Partners_logo/BOOKMYINSURANCE/digit.jpg";
import fail from "../../Assets/Images/failure.svg";
import success from "../../Assets/Images/success.svg";
import Botton from "../Button/Button";
import close from "../../Assets/Images/Close.svg";
import backIcon from "../../Assets/Images/backIcon.svg";
import { SBI, SHREERAM, DIGIT, ICICI, ZUNO, ACKO } from "../../utils/commonUtils";
import { carDetailsforProposal } from "../../utils/commonUtils";
import greenTick from "../../Assets/Images/greenTick.svg"
import redCross from "../../Assets/Images/redCross.svg"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

const PlanDetails = (props) => {
  const {
    bodyData,
    triggerTab,
    carDetails,
    companyImg,
    QuickQuote,
    companyName,
    handleBuyNow,
    setShowBottomTab,
    vehicleBreakupPrice,
    vehicleStructureDetails,
  } = props;

  let vehiclebreakup = carDetailsforProposal(companyName, QuickQuote, bodyData);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [ContentType, setContentType] = useState("premiumBreakup");

  let cover;
  let notCover;

  if (companyName == SBI) {
    notCover = Object?.keys(vehicleBreakupPrice)?.filter(
      (key) => vehicleBreakupPrice[key] === false
    );
    cover = Object?.keys(vehicleBreakupPrice)?.filter(
      (key) => vehicleBreakupPrice[key] !== false
    );
  }

  if (companyName == SHREERAM) {
    notCover = Object?.keys(vehicleBreakupPrice)?.filter(
      (key) => vehicleBreakupPrice[key] === false
    );
    cover = Object?.keys(vehicleBreakupPrice)?.filter(
      (key) => vehicleBreakupPrice[key] !== false
    );
  }

  if (companyName == DIGIT) {
    notCover = Object?.keys(vehicleBreakupPrice)?.filter(
      (key) => vehicleBreakupPrice[key] === false
    );
    cover = Object?.keys(vehicleBreakupPrice)?.filter(
      (key) => vehicleBreakupPrice[key] !== false
    );
  }

  if (companyName == ICICI) {
    notCover = Object?.keys(vehicleBreakupPrice)?.filter(
      (key) => vehicleBreakupPrice[key] === false
    );
    cover = Object?.keys(vehicleBreakupPrice)?.filter(
      (key) => vehicleBreakupPrice[key] !== false
    );
  }

  if (companyName == ACKO) {
    notCover = Object?.keys(vehicleBreakupPrice)?.filter(
      (key) => vehicleBreakupPrice[key] === false
    );
    cover = Object?.keys(vehicleBreakupPrice)?.filter(
      (key) => vehicleBreakupPrice[key] !== false
    );
  }

  if (companyName == ZUNO) {
    notCover = Object?.keys(vehicleBreakupPrice)?.filter(
      (key) => vehicleBreakupPrice[key] === false
    );
    cover = Object?.keys(vehicleBreakupPrice)?.filter(
      (key) => vehicleBreakupPrice[key] !== false
    );
  }

  function openModal() {
    setIsOpen(true);
    setShowBottomTab(false)
  }

  function closeModal() {
    setIsOpen(false);
    setShowBottomTab(true)
  }

  const menuList = [
    {
      id: 1,
      label: "Premium Breakup",
      params: "premiumBreakup"
    },
    {
      id: 3,
      label: "What's Covered",
      params: "coveredDetails"
    },
    {
      id: 4,
      label: "What's Not Covered",
      params: "notCoveredDetails"
    },
    {
      id: 2,
      label: "Addons Selected",
      params: "addonsSelected"
    },
  ]

  // COVERED LIST OBJECT ----- >

  let coveredListComprehensive = [
    {
      icon: greenTick,
      scene: "Accidents",
      sceneDescription: "Covers repair/replacement costs if your car is damaged in an accident.",
    },
    {
      icon: greenTick,
      scene: "Fire",
      sceneDescription: "Covers the damages caused due to self-ignition, explosion or lightning.",
    },
    {
      icon: greenTick,
      scene: "Theft",
      sceneDescription: "If your car is stolen, we will pay you the value of your car as per your policy.",
    },
    {
      icon: greenTick,
      scene: "Calamities",
      sceneDescription: "We will cover damage caused by natural or man-made calamities such as earthquakes, riots, etc.",
    },
  ]

  let coveredListZeroDepreciation = [
    {
      icon: greenTick,
      scene: "Accidents",
      sceneDescription: "Damages to the car due to an accident",
    },
    {
      icon: greenTick,
      scene: "Fire",
      sceneDescription: "In case your car catches fire, resulting from self-ignition, explosion or lightning",
    },
    {
      icon: greenTick,
      scene: "Theft",
      sceneDescription: "If your car is stolen, we will pay you the value as per policy of your car",
    },
    {
      icon: greenTick,
      scene: "Calamities",
      sceneDescription: "Damage due to natural or man made causes like earthquake, riots, etc",
    },
    {
      icon: greenTick,
      scene: "Third party losses",
      sceneDescription: "Death, injury or property damage to any third party arising from your car.",
    },
  ]

  let coveredListThirdParty = [
    {
      icon: greenTick,
      scene: "Third-party injury/death",
      sceneDescription: "We provide coverage for death/ injuries caused to individuals outside of your vehicle in accidents involving your car.",
    },
    {
      icon: greenTick,
      scene: "Third-party property",
      sceneDescription: "We also cover any damage/loss to third-party property.",
    },
  ]

  // NOT COVERED LIST OBJECT --->

  let notCoveredComprehensive = [
    {
      icon: redCross,
      scene: "Non-accident damages",
      sceneDescription: "Excludes coverage for natural wear and tear of parts like tyres, tubes, and engine",
    },
    {
      icon: redCross,
      scene: "Commercial usage of the car",
      sceneDescription: "Excludes coverage for damages incurred while using car for commercial purposes (e.g. deliveries, transportation for a fee).",
    },
    {
      icon: redCross,
      scene: "Pre-existing damages",
      sceneDescription: "Excludes coverage for any damage that was already present before the purchase of the policy.",
    },
    {
      icon: redCross,
      scene: "Illegal driving",
      sceneDescription: "This refers to any violation of traffic laws, such as driving without a valid license or driving under the influence of drugs, alcohol, etc.",
    },
  ]

  let notCoveredZeroDep = [
    {
      icon: redCross,
      scene: "Non-accident damages",
      sceneDescription: "Like depreciation, wear & tear, breakdown, failures & breakages, and deductibles.",
    },
    {
      icon: redCross,
      scene: "Commercial usage of the car",
      sceneDescription: "If the Car is registered as Private vehicle & if it is used for commercial purpose than the losses or liability due to accident during such usage is not covered in this policy.",
    },
    {
      icon: redCross,
      scene: "Pre-existing damagesNon-accident damages",
      sceneDescription: "Pre-existing Damages as found on vehicle inspection.",
    },
    {
      icon: redCross,
      scene: "Illegal driving",
      sceneDescription: "Damage due to the car driven by a person without a valid driving license or under influence of liquor/drugs.",
    },
    {
      icon: redCross,
      scene: "Normal wear and tear of tyres, tubes and engine",
      sceneDescription: "Not included unless the tyre, tubes & engine are damaged due to an accident.",
    },
    {
      icon: redCross,
      scene: "Indirect damages or liability due to your car accident",
      sceneDescription: "Indirect damage or liability arising due to your car accident",
    },
  ]

  let notCoveredThirdParty = [
    {
      icon: redCross,
      scene: "Own car damages",
      sceneDescription: "Damages to your own car are not covered",
    },
    {
      icon: redCross,
      scene: "Illegal driving",
      sceneDescription: "This refers to any violation of traffic laws, such as driving without a valid license or driving under the influence of drugs, alcohol, etc",
    },
  ]

  // addons list obj 

  let checkboxTrueFalseObject = {
    paCover: false,
    zeroDep: false,
    consumables: false,
    roadAssistance: false,
    engineProtection: false,
    invoiceCover: false,
    keyLockReplacement: false,
    ncbProtection: false,
    driverCover: false,
    passengerCover: false,
    accessories: false,
    tyreProtection: false,
    emiProtection: false,
    lossofPersonalBelongings: false,
    dailyAllowance: false,
    shreeramProtect: false,
  };

  for (let addon of carDetails?.coverage[0]) {
    if (addon.name in checkboxTrueFalseObject) {
      checkboxTrueFalseObject[addon.name] = addon.checked ? true : false;
    }
  }

  return (
    <>
      <p onClick={openModal}>{triggerTab}</p>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        id=""
      >
        <div className="modal_container modal-plandetails">
          <div className="leftSide_modal part_modal_container leftSide_modal-plandetails ">
            <div className="ins-card-planDetails">
              <img src={companyImg} alt="" srcSet="" />
              <p>₹ {vehiclebreakup?.DuePremium?.toLocaleString('en-IN')}
                <span>Price inclusive of GST</span>
              </p>
            </div>
            <div className="breadcrumb-container-plandetails">
              {
                menuList?.map((data, key) => (
                  <div
                    className={
                      ContentType === data.params
                        ? "breadCrumb-planDetails breadCrumb-planDetails-active"
                        : "breadCrumb-planDetails "
                    }
                    key={key}
                    onClick={(e) => setContentType(data.params)}
                  >
                    <p>{data.label}</p>
                  </div>
                ))
              }
            </div>
          </div>
          <div className="rightSide_modal rightSide_modal-plandetails part_modal_container ">
            <img
              src={close}
              className="closeImg_modal closeImg_modal-plandetails"
              alt=""
              srcSet=""
              onClick={closeModal}
            />

            {ContentType === "premiumBreakup" && (
              <section className="section-planDetails">
                <p className="title-rightside-plandetails">
                  Premium Breakup of this policy.
                </p>

                <div className="coveredDetails-plandetails coveredDetails-plandetails-breakup">

                  {/* OWN DAMAGE COVER'S DISPLAYED HERE! */}

                  {
                    bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                    "Third Party".toUpperCase() && (
                      <div className="details-covered breakup-head-planDetails ">
                        <p>Own Damage Cover (A)</p>
                        <p className="">
                          Cover Value(IDV): <span>{Math.round(vehiclebreakup?.idv).toLocaleString('en-IN')}</span>
                        </p>
                      </div>
                    )
                  }

                  {companyName == SHREERAM ? (
                    bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                      "Third Party".toUpperCase() ? (
                      <div className="details-covered details-covered-breakup-section">
                        <p>Own Damage:</p>
                        <p className="amt-breakup-plandetails">
                          ₹{" "} {Math.round(vehiclebreakup?.basicOd).toLocaleString('en-IN') || ""}
                        </p>
                      </div>
                    ) : (
                      ""
                    )
                  ) : bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                    "Third Party".toUpperCase() ? (
                    <div className="details-covered details-covered-breakup-section">
                      <p>Own Damage:</p>
                      <p className="amt-breakup-plandetails">
                        ₹{" "}
                        {companyName == SBI
                          ? (
                            Math.round(vehiclebreakup?.OD_After_Disc +
                              vehiclebreakup?.OD_NCBAmount).toLocaleString('en-IN')
                          ) || ""
                          : ((companyName == DIGIT) ? Math.round(vehiclebreakup?.OD_After_Disc).toLocaleString('en-IN') :

                            Math.round(vehiclebreakup?.OD_After_Disc + vehiclebreakup?.OD_NCBAmount).toLocaleString('en-IN')
                          )}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}

                  {bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                    "Third Party".toUpperCase() ? (
                    <div className="details-covered details-covered-breakup-section ">
                      <p>NCB Discount</p>
                      <p className="amt-breakup-plandetails">
                        -₹ {Math.round(vehiclebreakup?.OD_NCBAmount).toLocaleString('en-IN')}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* this should not present when typeOfBusiness === "New" */}

                  {bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                    "Third Party".toUpperCase() ? (
                    <div className="details-covered details-covered-breakup-section ">
                      <p>Basic Own Damage (After NCB Discount):</p>
                      <p className="amt-breakup-plandetails">
                        ₹ {(companyName == DIGIT) ? Math.round(vehiclebreakup?.OD_After_Disc - vehiclebreakup?.OD_NCBAmount).toLocaleString('en-IN') : (Math.round(vehiclebreakup?.OD_After_Disc).toLocaleString('en-IN') || "")}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}

                  {companyName == SHREERAM ? (
                    bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                      "Third Party".toUpperCase() ? (
                      <div className="details-covered details-covered-breakup-section ">
                        <p>De Tarrif Dicount:</p>
                        <p className="amt-breakup-plandetails">
                          ₹ {Math.round(vehiclebreakup?.deTarrifDiscount).toLocaleString('en-IN') || ""}
                        </p>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  {companyName == SHREERAM ? (
                    bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                      "Third Party".toUpperCase() ? (
                      <div className="details-covered details-covered-breakup-section ">
                        <p>Motor Protection:</p>
                        <p className="amt-breakup-plandetails">
                          ₹ {Math.round(vehiclebreakup?.shreeRamProtect).toLocaleString('en-IN') || ""}
                        </p>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  {bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                    "Third Party".toUpperCase() ? (
                    vehiclebreakup?.zeroDep ? (
                      <div className="details-covered details-covered-breakup-section ">
                        <p>Zero Dep:</p>
                        <p className="amt-breakup-plandetails">
                          ₹ {Math.round(vehiclebreakup?.zeroDep).toLocaleString('en-IN') || "0"}
                        </p>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  {bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                    "Third Party".toUpperCase() ? (
                    vehiclebreakup?.roadAssistance ? (
                      <div className="details-covered details-covered-breakup-section ">
                        <p>Road Side Assistance: </p>
                        <p className="amt-breakup-plandetails">
                          ₹ {Math.round(vehiclebreakup?.roadAssistance).toLocaleString('en-IN') || "0"}
                        </p>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  {(bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                    "Third Party".toUpperCase() && companyName == ACKO) ? (
                    vehiclebreakup?.rsaKeyProtectCombo ? (
                      <div className="details-covered details-covered-breakup-section ">
                        <p>RSA & Key Protect Combo: </p>
                        <p className="amt-breakup-plandetails">
                          ₹ {Math.round(vehiclebreakup?.rsaKeyProtectCombo).toLocaleString('en-IN') || "0"}
                        </p>
                      </div>
                    ) : null
                  ) : null}

                  {bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                    "Third Party".toUpperCase() ? (
                    vehiclebreakup?.consumables ? (
                      <div className="details-covered details-covered-breakup-section ">
                        <p>Consumables: </p>
                        <p className="amt-breakup-plandetails">
                          ₹ {Math.round(vehiclebreakup?.consumables).toLocaleString('en-IN') || "0"}
                        </p>
                      </div>
                    ) : null
                  ) : null}

                  {bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                    "Third Party".toUpperCase() ? (
                    vehiclebreakup?.engineProtection ? (
                      <div className="details-covered details-covered-breakup-section ">
                        <p>Engine Protection: </p>
                        <p className="amt-breakup-plandetails">
                          ₹ {Math.round(vehiclebreakup?.engineProtection).toLocaleString('en-IN') || "0"}
                        </p>
                      </div>
                    ) : null
                  ) : null}

                  {bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                    "Third Party".toUpperCase() ? (
                    vehiclebreakup?.tyreProtection ? (
                      <div className="details-covered details-covered-breakup-section ">
                        <p>Tyre Protection: </p>
                        <p className="amt-breakup-plandetails">
                          ₹ {Math.round(vehiclebreakup?.tyreProtection).toLocaleString('en-IN') || "0"}
                        </p>
                      </div>
                    ) : null
                  ) : null}

                  {bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                    "Third Party".toUpperCase() ? (
                    vehiclebreakup?.invoiceCover ? (
                      <div className="details-covered details-covered-breakup-section ">
                        <p>Return to Invoice: </p>
                        <p className="amt-breakup-plandetails">
                          ₹ {Math.round(vehiclebreakup?.invoiceCover).toLocaleString('en-IN') || "0"}
                        </p>
                      </div>
                    ) : null
                  ) : null}

                  {bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                    "Third Party".toUpperCase() ? (
                    vehiclebreakup?.keyLockReplacement ? (
                      <div className="details-covered details-covered-breakup-section ">
                        <p>Key & Lock Replacement: </p>
                        <p className="amt-breakup-plandetails">
                          ₹ {Math.round(vehiclebreakup?.keyLockReplacement).toLocaleString('en-IN') || "0"}
                        </p>
                      </div>
                    ) : null
                  ) : null}

                  {bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                    "Third Party".toUpperCase() ? (
                    vehiclebreakup?.ncbProtection ? (
                      <div className="details-covered details-covered-breakup-section ">
                        <p>NCB Protection: </p>
                        <p className="amt-breakup-plandetails">
                          ₹ {Math.round(vehiclebreakup?.ncbProtection).toLocaleString('en-IN') || "0"}
                        </p>
                      </div>
                    ) : null
                  ) : null}

                  {bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                    "Third Party".toUpperCase() ? (
                    vehiclebreakup?.dailyAllowance ? (
                      <div className="details-covered details-covered-breakup-section ">
                        <p>Daily Allowance: </p>
                        <p className="amt-breakup-plandetails">
                          ₹ {Math.round(vehiclebreakup?.dailyAllowance).toLocaleString('en-IN') || "0"}
                        </p>
                      </div>
                    ) : null
                  ) : null}

                  {bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                    "Third Party".toUpperCase() ? (
                    vehiclebreakup?.personalBelogging ? (
                      <div className="details-covered details-covered-breakup-section ">
                        <p>Loss of Belongings: </p>
                        <p className="amt-breakup-plandetails">
                          ₹ {Math.round(vehiclebreakup?.personalBelogging).toLocaleString('en-IN') || "0"}
                        </p>
                      </div>
                    ) : null
                  ) : null}

                  {/* {bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                    "Third Party".toUpperCase() ? (
                    vehiclebreakup?.emiProtection ? (
                      <div className="details-covered details-covered-breakup-section ">
                        <p>EMI Protection: </p>
                        <p className="amt-breakup-plandetails">
                          ₹ {vehiclebreakup?.emiProtection || "0"}
                        </p>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )} */}

                  {bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                    "Third Party".toUpperCase() ? (
                    vehiclebreakup?.accessories ? (
                      <div className="details-covered details-covered-breakup-section ">
                        <p>Accessories: </p>
                        <p className="amt-breakup-plandetails">(Included)</p>
                      </div>
                    ) : null
                  ) : null}

                  {bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                    "Third Party".toUpperCase() ? (
                    vehiclebreakup?.odExternalCngLpg || vehiclebreakup?.odInternalCngLpg ? (
                      <div className="details-covered details-covered-breakup-section ">
                        <p>CNG Fitted: </p>
                        <p className="amt-breakup-plandetails">(Included)</p>
                      </div>
                    ) : null
                  ) : null}

                  {bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                    "Third Party".toUpperCase() ? (
                    <div className="details-covered details-covered-breakup-section details-covered-breakup-section-highlighted ">
                      <p>Total Own Damage: </p>
                      <p className="amt-breakup-plandetails">
                        ₹ {(companyName == DIGIT) ? (Math.round(vehiclebreakup?.OD_TotalPremium - vehiclebreakup?.OD_NCBAmount).toLocaleString('en-IN')) : (Math.round(vehiclebreakup?.OD_TotalPremium).toLocaleString('en-IN') || "0")}
                      </p>
                    </div>
                  ) : null}

                  {/* THIRD PARTY ADDONS LIST STARTS FROM HERE! */}

                  {
                    bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                    "Own Damage".toUpperCase() && (
                      <div className="details-covered breakup-head-planDetails ">
                        <p style={{ color: "#1A1B1D" }} >Third Party Cover (B)</p>
                      </div>
                    )
                  }

                  {bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                    "Own Damage".toUpperCase() ? (
                    <div className="details-covered details-covered-breakup-section ">
                      <p>Basic TP:  </p>
                      <p className="amt-breakup-plandetails">
                        ₹ {Math.round(vehiclebreakup?.basicTP).toLocaleString('en-IN') || "0"}
                      </p>
                    </div>
                  ) : null}

                  {bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                    "Own Damage".toUpperCase() ? (
                    vehiclebreakup?.passengerCover ? (
                      <div className="details-covered details-covered-breakup-section ">
                        <p>Passenger Cover: </p>
                        <p className="amt-breakup-plandetails">
                          ₹ {Math.round(vehiclebreakup?.passengerCover).toLocaleString('en-IN') || "0"}
                        </p>
                      </div>
                    ) : null
                  ) : null}

                  {bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                    "Own Damage".toUpperCase() ? (
                    vehiclebreakup?.paOwnerDriver ? (
                      <div className="details-covered details-covered-breakup-section ">
                        <p>PA Cover: </p>
                        <p className="amt-breakup-plandetails">
                          ₹ {Math.round(vehiclebreakup?.paOwnerDriver).toLocaleString('en-IN') || "0"}
                        </p>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  {bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                    "Own Damage".toUpperCase() ? (
                    vehiclebreakup?.driverCover ? (
                      <div className="details-covered details-covered-breakup-section ">
                        <p>Driver Cover: </p>
                        <p className="amt-breakup-plandetails">
                          ₹ {Math.round(vehiclebreakup?.driverCover).toLocaleString('en-IN') || "0"}
                        </p>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  {bodyData?.data?.PolicyTypeValue.toUpperCase() ==
                    "Third Party".toUpperCase() || bodyData?.data?.PolicyTypeValue.toUpperCase() ==
                    "Comprehensive".toUpperCase() ? (
                    vehiclebreakup?.tpCngLpg ? (
                      <div className="details-covered details-covered-breakup-section ">
                        <p>CNG Fitted: </p>
                        <p className="amt-breakup-plandetails">(Included)</p>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  {bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                    "Own Damage".toUpperCase() ? (
                    vehiclebreakup?.paidDriver ? (
                      <div className="details-covered details-covered-breakup-section ">
                        <p>Paid Driver: </p>
                        <p className="amt-breakup-plandetails">
                          ₹ {Math.round(vehiclebreakup?.paidDriver).toLocaleString('en-IN') || "0"}
                        </p>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  {bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                    "Own Damage".toUpperCase() ? (
                    <div className="details-covered details-covered-breakup-section details-covered-breakup-section-highlighted ">
                      <p>Total Third Party: </p>
                      <p className="amt-breakup-plandetails">
                        ₹ {Math.round(vehiclebreakup?.TP_TotalPremium).toLocaleString('en-IN')}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* TOTAL PREMIUM SECTION - (A + B) */}

                  <div className="details-covered breakup-head-planDetails ">
                    <p style={{ color: "#1A1B1D" }} >Total Premium</p>
                  </div>

                  <div className="details-covered details-covered-breakup-section ">
                    <p>Net Payable (A+B):  </p>
                    <p className="amt-breakup-plandetails">
                      ₹ {Math.round(vehiclebreakup?.netPayble).toLocaleString('en-IN')}
                    </p>
                  </div>

                  <div className="details-covered details-covered-breakup-section ">
                    <p>GST:</p>
                    <p className="amt-breakup-plandetails">
                      ₹ {Math.round(vehiclebreakup?.TGST).toLocaleString('en-IN')}
                    </p>
                  </div>

                  <div className="details-covered details-covered-breakup-section details-covered-breakup-section-highlighted ">
                    <p>Gross Premium: </p>
                    <p className="amt-breakup-plandetails">
                      ₹ {Math.round(vehiclebreakup?.DuePremium).toLocaleString('en-IN')}
                    </p>
                  </div>
                </div>
              </section>
            )}

            {ContentType === "addonsSelected" && (
              <>
                <section className="section-planDetails">
                  <div className="covered-details-container">

                    <p className="covered-details-container-title">Selected Addon's</p>

                    {cover?.map((coveredKeys) => {
                      return (
                        <>
                          {
                            checkboxTrueFalseObject?.zeroDep && (
                              <>
                                {coveredKeys == "zeroDep" &&
                                  bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                                  "Third Party".toUpperCase() ? (
                                  <div className="list-coverages-">
                                    <div className="title-list-coverages">
                                      <img src={success} alt="" />
                                      <p>Zero Depreciation</p>
                                    </div>
                                    <p>A zero dep cover is an add-on in car insurance under which we won't charge you for depreciation during the claim settlement.</p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            )
                          }

                          {
                            checkboxTrueFalseObject?.shreeramProtect && (
                              <>
                                {(coveredKeys == "shreeramProtect" && companyName == SHREERAM) &&
                                  bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                                  "Third Party".toUpperCase() ? (
                                  <div className="list-coverages-">
                                    <div className="title-list-coverages">
                                      <img src={success} alt="" />
                                      <p>Motor Protection</p>
                                    </div>
                                    <p>In case of any motor accident arising out of the use of the Insured Vehicle, We will provide you an advocate to guide and/or defend you from police and proceedings in the court of judicial magistrate only.</p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            )
                          }

                          {
                            checkboxTrueFalseObject?.paCover && (
                              <>
                                {coveredKeys == "paCover" &&
                                  bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                                  "Own Damage".toUpperCase() ? (
                                  <div className="list-coverages-">
                                    <div className="title-list-coverages">
                                      <img src={success} alt="" />
                                      <p>PA Cover</p>
                                    </div>
                                    <p>A PA cover under the motor insurance policy will pay for the compensation in case of bodily injuries, death or any permanent disability resulting due to an accident.</p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            )
                          }

                          {
                            checkboxTrueFalseObject?.consumables && (
                              <>
                                {coveredKeys == "consumables" &&
                                  bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                                  "Third Party".toUpperCase() ? (
                                  <div className="list-coverages-">
                                    <div className="title-list-coverages">
                                      <img src={success} alt="" />
                                      <p>Consumables</p>
                                    </div>
                                    <p>Covers expenses incurred towards "Consumables" (nut and bolt, screw, washers, grease, lubricants clip, ac gas, bearings, distilled water, engine oil, oil filter, fuel filter, break oil and the like) arising out of damage to the vehicle.</p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            )
                          }

                          {
                            checkboxTrueFalseObject?.roadAssistance && (
                              <>
                                {coveredKeys == "roadAssistance" &&
                                  bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                                  "Third Party".toUpperCase() ? (
                                  <div className="list-coverages-">
                                    <div className="title-list-coverages">
                                      <img src={success} alt="" />
                                      <p>24/7 Road Assistance</p>
                                    </div>
                                    <p>Road side assistance provides support for basic on-road breakdown situations like tyre change, battery jump start, emergency fuel, medical assistance etc which are not covered under Insurance. As the price is very nominal, it is a good to have add-on.</p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            )
                          }

                          {
                            checkboxTrueFalseObject?.engineProtection && (
                              <>
                                {coveredKeys == "engineProtection" &&
                                  bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                                  "Third Party".toUpperCase() ? (
                                  <div className="list-coverages-">
                                    <div className="title-list-coverages">
                                      <img src={success} alt="" />
                                      <p>Engine Protector</p>
                                    </div>
                                    <p>When the Engine of the car is submerged in a water logged area, using or cranking the engine can result in engine ceasing. This is not covered under regular Insurance. Engine protector covers such non-accidental exclusions related to your engine. It is a must buy for luxury cars where engine is very costly & is placed at low ground clearance.</p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            )
                          }

                          {
                            checkboxTrueFalseObject?.invoiceCover && (
                              <>
                                {coveredKeys == "invoiceCover" &&
                                  bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                                  "Third Party".toUpperCase() ? (
                                  <div className="list-coverages-">
                                    <div className="title-list-coverages">
                                      <img src={success} alt="" />
                                      <p>Invoice Cover</p>
                                    </div>
                                    <p>In case of theft or total damage to your car, you are only eligible for reimbursement up to the Insured declared value of your car, which will be very less than the Invoice value. In case of such an event, selecting Invoice cover makes you eligible for full Invoice amount reimbursement.</p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            )
                          }

                          {
                            checkboxTrueFalseObject?.keyLockReplacement && (
                              <>
                                {coveredKeys == "keyLockReplacement" &&
                                  bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                                  "Third Party".toUpperCase() ? (
                                  <div className="list-coverages-">
                                    <div className="title-list-coverages">
                                      <img src={success} alt="" />
                                      <p>Key and Lock Replacement</p>
                                    </div>
                                    <p>In case your keys are lost or stolen, you have to request a new one from the manufacturer. In most cases, you may even need to replace the locks, which is another added expenditure. Key and Lock Replacement Cover covers the expenses incurred for procuring a new key. It is a must buy for high end and luxury cars as the new set of keys can be very expensive.</p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            )
                          }

                          {
                            checkboxTrueFalseObject?.ncbProtection && (
                              <>
                                {coveredKeys == "ncbProtection" &&
                                  bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                                  "Third Party".toUpperCase() ? (
                                  <div className="list-coverages-">
                                    <div className="title-list-coverages">
                                      <img src={success} alt="" />
                                      <p>NCB Protection</p>
                                    </div>
                                    <p>An NCB Protect cover gives you extra cushioning. It keeps the bonus component intact even if some claims (depending upon the terms and conditions) are raised during the policy period. You can opt for NCB protection cover to benefit from the renewal discount irrespective of a claim (or claims) during the policy period</p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            )
                          }

                          {
                            checkboxTrueFalseObject?.driverCover && (
                              <>
                                {coveredKeys == "driverCover" &&
                                  bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                                  "Own Damage".toUpperCase() ? (
                                  <div className="list-coverages-">
                                    <div className="title-list-coverages">
                                      <img src={success} alt="" />
                                      <p>Driver Cover</p>
                                    </div>
                                    <p>It covers medical costs incurred in the treatment of the injured driver and passengers. It gives financial aid in case of the death of owner-driver/passengers. It provides disability liability cover to the owner-driver and passengers of an insured car.</p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            )
                          }

                          {
                            checkboxTrueFalseObject?.passengerCover && (
                              <>
                                {coveredKeys == "passengerCover" &&
                                  bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                                  "Own Damage".toUpperCase() ? (
                                  <div className="list-coverages-">
                                    <div className="title-list-coverages">
                                      <img src={success} alt="" />
                                      <p>Passenger Cover</p>
                                    </div>
                                    <p>Passenger cover in car insurance is financial coverage for passengers who are travelling in the insured's car. While it is necessary to have the personal accident cover for the owner-driver of the car, covering passengers is not mandatory.</p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            )
                          }

                          {
                            checkboxTrueFalseObject?.accessories && (
                              <>
                                {coveredKeys == "accessories" &&
                                  bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                                  "Third Party".toUpperCase() ? (
                                  <div className="list-coverages-">
                                    <div className="title-list-coverages">
                                      <img src={success} alt="" />
                                      <p>Accessories</p>
                                    </div>
                                    <p>Cover your car's extra fitted electrical and non-electrical accessories.</p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            )
                          }

                          {/* {coveredKeys == "odExternalCngLpg" &&
                            bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                            "Third Party".toUpperCase() ? (
                            <div className="list-coverages-">
                              <div className="title-list-coverages">
                                <img src={success} alt="" />
                                <p>External Fitted CNG</p>
                              </div>
                              <p>If you plan to install an external CNG kit in your car, inform your insurer beforehand, as your existing insurance may not cover it.</p>
                            </div>
                          ) : (
                            ""
                          )}

                          {coveredKeys == "tpCngLpg" &&
                            bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                            "Own Damage".toUpperCase() ? (
                            <div className="list-coverages-">
                              <div className="title-list-coverages">
                                <img src={success} alt="" />
                                <p>CNG Fitted</p>
                              </div>
                              <p>CNG kits include a fuel switch for drivers to switch between petrol/diesel and CNG modes. The CNG cylinder, serving as the fuel tank, stores compressed natural gas and is typically installed at the rear of the vehicle.</p>
                            </div>
                          ) : (
                            ""
                          )}

                          {coveredKeys == "odInternalCngLpg" &&
                            bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                            "third party".toUpperCase() ? (
                            <div className="list-coverages-">
                              <div className="title-list-coverages">
                                <img src={success} alt="" />
                                <p>CNG Fitted</p>
                              </div>
                              <p>CNG kits include a fuel switch for drivers to switch between petrol/diesel and CNG modes. The CNG cylinder, serving as the fuel tank, stores compressed natural gas and is typically installed at the rear of the vehicle.</p>
                            </div>
                          ) : (
                            ""
                          )} */}

                          {
                            checkboxTrueFalseObject?.lossofPersonalBelongings && (
                              <>
                                {coveredKeys == "lossofPersonalBelongings" &&
                                  bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                                  "Third Party".toUpperCase() ? (
                                  <div className="list-coverages-">
                                    <div className="title-list-coverages">
                                      <img src={success} alt="" />
                                      <p>Loss of Belongings</p>
                                    </div>
                                    <p>Personal belongings add on provides you coverage against such belongings kept in your car. While theft of a car is covered, theft of the items kept in the car is not. Personal belongings might include items such as laptop, smartphone, camera, musical instruments, etc.</p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            )
                          }

                          {/* {coveredKeys == "emiProtection" &&
                          bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                          "Third Party".toUpperCase() ? (
                          <div className="details-covered">
                            <p>EMI Protection</p>
                            <img src={success} />
                          </div>
                        ) : (
                          ""
                        )} */}

                          {
                            checkboxTrueFalseObject?.tyreProtection && (
                              <>
                                {
                                  coveredKeys == "tyreProtection" &&
                                    bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                                    "Third Party".toUpperCase() ? (
                                    <div className="list-coverages-">
                                      <div className="title-list-coverages">
                                        <img src={success} alt="" />
                                        <p>Tyre Protection</p>
                                      </div>
                                      <p>A tyre protection cover is a car insurance add-on cover that gives coverage for the repair and replacement cost of tyres. This add-on cover provides insurance cover for accidental loss and damage or cuts to the tyres in addition to the labour charges incurred during the repair or replacement of tyres.</p>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                              </>
                            )
                          }

                          {
                            checkboxTrueFalseObject?.dailyAllowance && (
                              <>
                                {coveredKeys == "dailyAllowance" &&
                                  bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                                  "Third Party".toUpperCase() ? (
                                  <div className="list-coverages-">
                                    <div className="title-list-coverages">
                                      <img src={success} alt="" />
                                      <p>Daily Allowance</p>
                                    </div>
                                    <p>The daily allowance add-on cover for car insurance can be purchased with a maximum coverage duration. It basically means that the insurance company will pay you the compensation for a fixed number of days that is mentioned in the policy document or till the time your car gets repaired, whichever happens earlier.</p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            )
                          }
                        </>
                      );
                    })}

                    {notCover?.map((coveredKeys) => {
                      return (
                        <>
                          {
                            checkboxTrueFalseObject?.zeroDep && (
                              <>
                                {coveredKeys == "zeroDep" &&
                                  bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                                  "Third Party".toUpperCase() ? (
                                  <div className="list-coverages-">
                                    <div className="title-list-coverages title-list-notCovered">
                                      <img src={fail} alt="" />
                                      <p>Zero Depreciation</p>
                                    </div>
                                    <p>sceneA zero dep cover is an add-on in car insurance under which we won't charge you for depreciation during the claim settlement.</p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            )
                          }

                          {
                            checkboxTrueFalseObject?.shreeramProtect && (
                              <>
                                {(coveredKeys == "shreeramProtect" && companyName == SHREERAM) &&
                                  bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                                  "Third Party".toUpperCase() ? (
                                  <div className="list-coverages-">
                                    <div className="title-list-coverages title-list-notCovered">
                                      <img src={fail} alt="" />
                                      <p>Motor Protection</p>
                                    </div>
                                    <p>A zero dep cover is an add-on in car insurance under which we won't charge you for depreciation during the claim settlement.</p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            )
                          }

                          {
                            checkboxTrueFalseObject?.paCover && (
                              <>
                                {coveredKeys == "paCover" &&
                                  bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                                  "Own Damage".toUpperCase() ? (
                                  <div className="list-coverages-">
                                    <div className="title-list-coverages title-list-notCovered">
                                      <img src={fail} alt="" />
                                      <p>PA Cover</p>
                                    </div>
                                    <p>A PA cover under the motor insurance policy will pay for the compensation in case of bodily injuries, death or any permanent disability resulting due to an accident.</p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            )
                          }

                          {
                            checkboxTrueFalseObject?.consumables && (
                              <>
                                {coveredKeys == "consumables" &&
                                  bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                                  "Third Party".toUpperCase() ? (
                                  <div className="list-coverages-">
                                    <div className="title-list-coverages title-list-notCovered">
                                      <img src={fail} alt="" />
                                      <p>Consumables</p>
                                    </div>
                                    <p>Covers expenses incurred towards "Consumables" (nut and bolt, screw, washers, grease, lubricants clip, ac gas, bearings, distilled water, engine oil, oil filter, fuel filter, break oil and the like) arising out of damage to the vehicle.</p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            )
                          }

                          {
                            checkboxTrueFalseObject?.roadAssistance && (
                              <>
                                {coveredKeys == "roadAssistance" &&
                                  bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                                  "Third Party".toUpperCase() ? (
                                  <div className="list-coverages-">
                                    <div className="title-list-coverages title-list-notCovered">
                                      <img src={fail} alt="" />
                                      <p>24/7 Road Assistance</p>
                                    </div>
                                    <p>Covers expenses incurred towards "Consumables" (nut and bolt, screw, washers, grease, lubricants clip, ac gas, bearings, distilled water, engine oil, oil filter, fuel filter, break oil and the like) arising out of damage to the vehicle.</p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            )
                          }

                          {
                            checkboxTrueFalseObject?.engineProtection && (
                              <>
                                {coveredKeys == "engineProtection" &&
                                  bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                                  "Third Party".toUpperCase() ? (
                                  <div className="list-coverages-">
                                    <div className="title-list-coverages title-list-notCovered">
                                      <img src={fail} alt="" />
                                      <p>Engine Protector</p>
                                    </div>
                                    <p>When the Engine of the car is submerged in a water logged area, using or cranking the engine can result in engine ceasing. This is not covered under regular Insurance. Engine protector covers such non-accidental exclusions related to your engine. It is a must buy for luxury cars where engine is very costly & is placed at low ground clearance.</p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            )
                          }

                          {
                            checkboxTrueFalseObject?.invoiceCover && (
                              <>
                                {coveredKeys == "invoiceCover" &&
                                  bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                                  "Third Party".toUpperCase() ? (
                                  <div className="list-coverages-">
                                    <div className="title-list-coverages title-list-notCovered">
                                      <img src={fail} alt="" />
                                      <p>Invoice Cover</p>
                                    </div>
                                    <p>In case of theft or total damage to your car, you are only eligible for reimbursement up to the Insured declared value of your car, which will be very less than the Invoice value. In case of such an event, selecting Invoice cover makes you eligible for full Invoice amount reimbursement.</p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            )
                          }

                          {
                            checkboxTrueFalseObject?.keyLockReplacement && (
                              <>
                                {coveredKeys == "keyLockReplacement" &&
                                  bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                                  "Third Party".toUpperCase() ? (
                                  <div className="list-coverages-">
                                    <div className="title-list-coverages title-list-notCovered">
                                      <img src={fail} alt="" />
                                      <p>Key and Lock Replacement</p>
                                    </div>
                                    <p>In case your keys are lost or stolen, you have to request a new one from the manufacturer. In most cases, you may even need to replace the locks, which is another added expenditure. Key and Lock Replacement Cover covers the expenses incurred for procuring a new key. It is a must buy for high end and luxury cars as the new set of keys can be very expensive.</p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            )
                          }

                          {
                            checkboxTrueFalseObject?.ncbProtection && (
                              <>
                                {coveredKeys == "ncbProtection" &&
                                  bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                                  "Third Party".toUpperCase() ? (
                                  <div className="list-coverages-">
                                    <div className="title-list-coverages title-list-notCovered">
                                      <img src={fail} alt="" />
                                      <p>NCB Protection</p>
                                    </div>
                                    <p>An NCB Protect cover gives you extra cushioning. It keeps the bonus component intact even if some claims (depending upon the terms and conditions) are raised during the policy period. You can opt for NCB protection cover to benefit from the renewal discount irrespective of a claim (or claims) during the policy period</p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            )
                          }

                          {
                            checkboxTrueFalseObject?.passengerCover && (
                              <>
                                {coveredKeys == "passengerCover" &&
                                  bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                                  "Own Damage".toUpperCase() ? (
                                  <div className="list-coverages-">
                                    <div className="title-list-coverages title-list-notCovered">
                                      <img src={fail} alt="" />
                                      <p>Passenger Cover</p>
                                    </div>
                                    <p>Passenger cover in car insurance is financial coverage for passengers who are travelling in the insured's car. While it is necessary to have the personal accident cover for the owner-driver of the car, covering passengers is not mandatory.</p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            )
                          }

                          {
                            checkboxTrueFalseObject?.accessories && (
                              <>
                                {coveredKeys == "accessories" &&
                                  bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                                  "Third Party".toUpperCase() ? (
                                  <div className="list-coverages-">
                                    <div className="title-list-coverages title-list-notCovered">
                                      <img src={fail} alt="" />
                                      <p>Accessories</p>
                                    </div>
                                    <p>Cover your car's extra fitted electrical and non-electrical accessories.</p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            )
                          }

                          {/* {coveredKeys == "odExternalCngLpg" &&
                          bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                          "Third Party".toUpperCase() ? (
                          <div className="details-covered">
                            <p>External Fitted CNG</p>
                            <img src={fail} />
                          </div>
                        ) : (
                          ""
                        )} */}

                          {
                            checkboxTrueFalseObject?.lossofPersonalBelongings && (
                              <>
                                {coveredKeys == "lossofPersonalBelongings" &&
                                  bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                                  "Third Party".toUpperCase() ? (
                                  <div className="list-coverages-">
                                    <div className="title-list-coverages title-list-notCovered">
                                      <img src={fail} alt="" />
                                      <p>Loss of Belongings</p>
                                    </div>
                                    <p>Personal belongings add on provides you coverage against such belongings kept in your car. While theft of a car is covered, theft of the items kept in the car is not. Personal belongings might include items such as laptop, smartphone, camera, musical instruments, etc.</p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            )
                          }

                          {/* {coveredKeys == "emiProtection" &&
                          bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                          "Third Party".toUpperCase() ? (
                          <div className="details-covered">
                            <p>EMI Protection</p>
                            <img src={fail} />
                          </div>
                        ) : (
                          ""
                        )} */}

                          {
                            checkboxTrueFalseObject?.tyreProtection && (
                              <>
                                {coveredKeys == "tyreProtection" &&
                                  bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                                  "Third Party".toUpperCase() ? (
                                  <div className="list-coverages-">
                                    <div className="title-list-coverages title-list-notCovered">
                                      <img src={fail} alt="" />
                                      <p>Tyre Protection</p>
                                    </div>
                                    <p>A tyre protection cover is a car insurance add-on cover that gives coverage for the repair and replacement cost of tyres. This add-on cover provides insurance cover for accidental loss and damage or cuts to the tyres in addition to the labour charges incurred during the repair or replacement of tyres.</p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            )
                          }

                          {
                            checkboxTrueFalseObject?.dailyAllowance && (
                              <>
                                {coveredKeys == "dailyAllowance" &&
                                  bodyData?.data?.PolicyTypeValue.toUpperCase() !==
                                  "Third Party".toUpperCase() ? (
                                  <div className="list-coverages-">
                                    <div className="title-list-coverages title-list-notCovered">
                                      <img src={fail} alt="" />
                                      <p>Daily Allowance</p>
                                    </div>
                                    <p>The daily allowance add-on cover for car insurance can be purchased with a maximum coverage duration. It basically means that the insurance company will pay you the compensation for a fixed number of days that is mentioned in the policy document or till the time your car gets repaired, whichever happens earlier.</p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            )
                          }

                        </>
                      );
                    })}
                  </div>
                </section>
              </>
            )}

            {ContentType === "coveredDetails" && (
              <div className="covered-details-container">

                {/* Comprehensive cover list */}

                {
                  carDetails.PolicyTypeValue === "Comprehensive" && (
                    <div className="plan-coverages-container">
                      <p className="covered-details-container-title">Comprehensive Plan</p>
                      <p className="covered-details-container-desc">Covers damage to your car and others.</p>
                      {
                        coveredListComprehensive?.map((data, key) => (
                          <div key={key} className="list-coverages-">
                            <div className="title-list-coverages">
                              <img src={greenTick} alt="" />
                              <p>{data.scene}</p>
                            </div>
                            <p>{data.sceneDescription}</p>
                          </div>
                        ))
                      }
                    </div>
                  )
                }

                {/* Third Party Only */}

                {
                  carDetails.PolicyTypeValue === "Third Party" && (
                    <div className="plan-coverages-container">
                      <p className="covered-details-container-title">Third Party Only</p>
                      <p className="covered-details-container-desc">Covers damages to others property.</p>
                      {
                        coveredListThirdParty?.map((data, key) => (
                          <div key={key} className="list-coverages-">
                            <div className="title-list-coverages">
                              <img src={greenTick} alt="" />
                              <p>{data.scene}</p>
                            </div>
                            <p>{data.sceneDescription}</p>
                          </div>
                        ))
                      }
                    </div>
                  )
                }

                {/* zero deprecitation */}

                {/* <div className="plan-coverages-container">
                  <p className="covered-details-container-title">Zero Depreciation</p>
                  <p className="covered-details-container-desc">Includes all coverages of a standard zero depreciation plan with special claim benefits such as free pick up & drop and assured 3-day, certified repairs. You need to notify us first in case of damage to your vehicle.</p>
                  {
                    coveredListZeroDepreciation?.map((data, key) => (
                      <div key={key} className="list-coverages-">
                        <div className="title-list-coverages">
                          <img src={greenTick} alt="" />
                          <p>{data.scene}</p>
                        </div>
                        <p>{data.sceneDescription}</p>
                      </div>
                    ))
                  }
                </div> */}

              </div>
            )}

            {ContentType === "notCoveredDetails" && (
              <div className="covered-details-container">

                {/* Comprehensive cover list */}

                {
                  carDetails.PolicyTypeValue === "Comprehensive" && (
                    <div className="plan-coverages-container">
                      <p className="covered-details-container-title">Comprehensive</p>
                      {
                        notCoveredComprehensive?.map((data, key) => (
                          <div key={key} className="list-coverages-">
                            <div className="title-list-coverages title-list-notCovered">
                              <img src={data.icon} alt="" />
                              <p>{data.scene}</p>
                            </div>
                            <p>{data.sceneDescription}</p>
                          </div>
                        ))
                      }
                    </div>
                  )
                }

                {/* Third Party Only */}

                {
                  carDetails.PolicyTypeValue === "Third Party" && (
                    <div className="plan-coverages-container">
                      <p className="covered-details-container-title">Third Party Only</p>
                      {
                        notCoveredThirdParty?.map((data, key) => (
                          <div key={key} className="list-coverages-">
                            <div className="title-list-coverages">
                              <img src={data.icon} alt="" />
                              <p>{data.scene}</p>
                            </div>
                            <p>{data.sceneDescription}</p>
                          </div>
                        ))
                      }
                    </div>
                  )
                }

                {/* zero deprecitation */}

                {/* <div className="plan-coverages-container">
                  <p className="covered-details-container-title">Zero Depreciation</p>
                  {
                    notCoveredZeroDep?.map((data, key) => (
                      <div key={key} className="list-coverages-">
                        <div className="title-list-coverages">
                          <img src={data.icon} alt="" />
                          <p>{data.scene}</p>
                        </div>
                        <p>{data.sceneDescription}</p>
                      </div>
                    ))
                  }
                </div> */}

              </div>
            )}

            <section className="btnContainer_pform btnContainer-planDetails">
              <Botton
                text="Back"
                className="button btn_pForm  secondry_btn"
                event={closeModal}
              />
              <Botton
                text="Buy Now"
                className="button btn_pForm  "
                event={() => {
                  handleBuyNow();
                  closeModal();
                }}
              />
            </section>
          </div >
        </div >
      </Modal >
    </>
  );
};

export default PlanDetails;