import { createAsyncThunk } from "@reduxjs/toolkit";

export const transactionURLAction = createAsyncThunk(
    "transactionURLAction",
    async (bodyData) => {
      const response = await fetch(
        `${process.env.REACT_APP_INSERT_TRANSACTION_LINK}`,
        {
          method: "POST",
          body: JSON.stringify(bodyData),
          headers: {
            "Content-type": "application/json; charset=UTF-8"
          },
        }
      );
      return response.json();
    }
  );