import React, { useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Testimonials.css";
import Botton from "../Button/Button";

const Testimonials = (props) => {
  const { content } = props;
  const slider = React.useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);  
  const totalSlides = content?.length;

  const handleArrowNext = () => {
    slider.current.slickNext();
  };

  const handleArrowPrev = () => {
    slider.current.slickPrev();
  };

  const sliderSettings = {
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 912,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="testimonial_container">
        <div className="testimonial_parent_container maxWidth ">
          <h1 className="title">
            What our happy clients <span>speaks about us.</span>
          </h1>
          <div className="testimonial_card_container">
            <Slider
              ref={(c) => (slider.current = c)}
              {...sliderSettings}
              className="slider_testimonial_container"
              beforeChange={(oldIndex, newIndex) => setCurrentSlide(newIndex)}
              afterChange={(currentIndex) => setCurrentSlide(currentIndex)}
            >
              {content.map((data, key) => (
                <div key={key} className="testimonial_card_parent">
                  <div className="testimonial_card_child">
                    <img
                      src={data.quotes}
                      className="quotes_testimonial"
                      alt=""
                    />
                    <p className="testimonial_content">{data.message}</p>
                    <div className="testimonial_profile_container">
                      <div className="img_testimonial">
                        <img src={data.image} alt="" />
                      </div>
                      <div className="name_container_testimonials">
                        <p className="name_testimonial">{data.name}</p>
                        <p className="desg_testimonial small_text">
                          {data.designation}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
            <div className="controls_testimonial">
              <Botton
                icons={content[0].forward}
                className=" controller_carousel controller_carousel_right "
                name="testimonial"
                aria-label="testimonial"
                event={() => handleArrowPrev()}
              />
              <Botton
                icons={content[0].forward}
                className="controller_carousel controller_carousel_left "
                name="testimonial"
                aria-label="testimonial"
                event={() => handleArrowNext()}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonials;
