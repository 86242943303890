import {
    ackoQuickQuoteAction,
    ackoProposalAction,
    ackopdfDownloadAction
} from "../Actions/ackoApiAction";

import { createSlice } from "@reduxjs/toolkit";

export const ackoQuickQuoteReducer = createSlice({
    name: "ackoQuikQuote",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ackoQuickQuoteAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ackoQuickQuoteAction.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(ackoQuickQuoteAction.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
    reducers: {
        resetackoQuoteAction: (state, action) => {
            state.data = undefined
        }
    }
});

export const { resetackoQuoteAction } = ackoQuickQuoteReducer.actions;

export const ackoProposalReducer = createSlice({
    name: "ackoProposal",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    reducers: {
        resetackoProposal: (state) => {
            state.isLoading = false;
            state.data = null;
            state.isError = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(ackoProposalAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ackoProposalAction.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(ackoProposalAction.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
});

export const { resetackoProposal } = ackoProposalReducer.actions;

export const ackopdfDownloadReducer = createSlice({
    name: "ackopdfDownload",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    reducers: {
        resetackopdfDownload: (state) => {
            state.isLoading = false;
            state.data = null;
            state.isError = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(ackopdfDownloadAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ackopdfDownloadAction.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(ackopdfDownloadAction.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
});

export const { resetackopdfDownload } = ackopdfDownloadReducer.actions;
