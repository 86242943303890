import { closestMatch } from "closest-match";
import CryptoJS from "crypto-js";
import moment from "moment";

export const calculatePrevNcbNewNcb = (
  regDate,
  isPrevPolicyClaim,
  businessType,
  prevPolicyExpLastMonth
) => {
  let currentDate = new Date().getFullYear();
  let rDate =
    businessType == ROLLOVER ? new Date(regDate).getFullYear() : regDate;

  let difference = currentDate - rDate;

  if (!prevPolicyExpLastMonth && !isPrevPolicyClaim) {
    switch (difference) {
      case 0:
        return {
          newNcb: "20",
          prevNcb: "0",
        };
      case 1:
        return {
          newNcb: "20",
          prevNcb: "0",
        };
      case 2:
        return {
          newNcb: "25",
          prevNcb: "20",
        };
      case 3:
        return {
          newNcb: "35",
          prevNcb: "25",
        };
      case 4:
        return {
          newNcb: "45",
          prevNcb: "35",
        };
      case 5:
        return {
          newNcb: "50",
          prevNcb: "45",
        };
    }

    if (difference > 5) {
      return {
        newNcb: "50",
        prevNcb: "45",
      };
    }

    if (businessType === "New") {
      return {
        newNcb: "0",
        prevNcb: "0",
      };
    }
  }

  return {
    newNcb: "0",
    prevNcb: "0",
  };
};

export const getPolicyType = (regDate, expiryDate) => {
  let carsAge = moment().diff(new Date(regDate), 'years', true).toFixed(1);
  if (carsAge <= 3.5) {
    return "Bundled";
  } else {
    return "Comprehensive";
  }
};

export const getPolicyTypeBike = (regDate, expiryDate) => {
  let bikeAge = moment().diff(new Date(regDate), 'years', true).toFixed(1);
  if (bikeAge <= 5.5) {
    return "Bundled";
  } else {
    return "Comprehensive";
  }
};

export const getPolicyExpiryDate = (
  isPrevPolicyExp,
  prevPolicyExpLastMonth
) => {
  // "2023-06-24"
  let currentDate = new Date();

  if (prevPolicyExpLastMonth) {
    let date = currentDate.setDate(currentDate.getDate() - 90);
    return new Date(date).toISOString();
  }

  if (isPrevPolicyExp) {
    let date = currentDate.setDate(currentDate.getDate() - 1);
    return new Date(date).toISOString();
  }

  if (!isPrevPolicyExp) {
    let date = currentDate.setDate(currentDate.getDate() + 1);
    return new Date(date).toISOString();
  }

  return new Date().toISOString();
};

const getAddOnForSBI = (quoteData) => {
  let sbiPrice = {
    paCover: false, // PA -  Owner Driver PA + Unnamed Passenger + PA  Paid Driver
    zeroDep: false,
    consumables: false,
    roadAssistance: false,
    engineProtection: false,
    invoiceCover: false,
    keyLockReplacement: false,
    ncbProtection: false,
    driverCover: false,
    passengerCover: false,
    paOwnerDriver: false,
    accessories: false,
    totalPremium: false,
    basicPremium: false,
    od: false,
    basicOd: false,
    idv: false,
    nonElectrical: false,
    electrical: false,
    odExternalCngLpg: false,
    odInternalCngLpg: false,
    basicTP: false,
    tpCngLpg: false,
    tyreProtection: false,
    dailyAllowance: false,
    personalBelogging: false,
    emiProtection: false,
    BeforeVatPremium: false,
    ncbProtection: false,
    DuePremium: false,
    TGST: false,
    OD_TotalPremium: false,
    TP_TotalPremium: false,
    OD_After_Disc: false,
    OD_NCBAmount: false,
    paidDriver: false,
  };
  const { PolicyLobList, BeforeVatPremium, DuePremium, TGST } =
    quoteData?.data?.body?.data?.quote || {};
  for (
    let index = 0;
    index < PolicyLobList[0]?.PolicyRiskList[0]?.PolicyCoverageList?.length;
    index++
  ) {
    const element =
      PolicyLobList[0]?.PolicyRiskList[0]?.PolicyCoverageList?.[index];
    sbiPrice.idv = PolicyLobList[0]?.PolicyRiskList[0]?.IDV_User;
    sbiPrice.basicTP = PolicyLobList[0]?.PolicyRiskList[0]?.TP_BasePremium;
    sbiPrice.BeforeVatPremium = BeforeVatPremium;
    sbiPrice.DuePremium = DuePremium;
    sbiPrice.TGST = TGST;
    sbiPrice.OD_TotalPremium =
      PolicyLobList[0]?.PolicyRiskList[0]?.OD_TotalPremium;
    sbiPrice.TP_TotalPremium =
      PolicyLobList[0]?.PolicyRiskList[0]?.TP_TotalPremium;
    sbiPrice.OD_After_Disc = PolicyLobList[0]?.PolicyRiskList[0]?.OD_After_Disc;
    sbiPrice.OD_NCBAmount = PolicyLobList[0]?.PolicyRiskList[0]?.OD_NCBAmount;

    switch (element?.ProductElementCode) {
      case "C101066":
        sbiPrice.paCover = element?.AnnualPremium;
        break;
      case "C101072":
        sbiPrice.zeroDep = element?.AnnualPremium;
        break;
      case "C101111":
        sbiPrice.consumables = element?.AnnualPremium;
        break;
      case "C101069":
        sbiPrice.roadAssistance = element?.PolicyBenefitList[0]?.AnnualPremium;
        break;
      case "C101108":
        sbiPrice.engineProtection = element?.AnnualPremium;
        break;
      case "C101073":
        sbiPrice.keyLockReplacement = element?.AnnualPremium;
        break;
      case "C101067":
        sbiPrice.invoiceCover = element?.AnnualPremium;
        break;
      case "C101069":
        sbiPrice.roadAssistance = element?.AnnualPremium;
        break;
      case "C101064":
        sbiPrice.od = element?.AnnualPremium;
        break;
      case "C101065":
        sbiPrice.basicTP = PolicyLobList[0].PolicyRiskList[0].TP_BasePremium;
        break;
      case "C101110":
        sbiPrice.tyreProtection = element?.AnnualPremium;
        break;
      case "C101074":
        sbiPrice.dailyAllowance = element?.AnnualPremium;
        break;
      case "C101075":
        sbiPrice.personalBelogging = element?.AnnualPremium;
        break;
      case "C101109":
        sbiPrice.emiProtection = element?.AnnualPremium;
        break;
      case "C101068":
        sbiPrice.ncbProtection = element?.AnnualPremium;
        break;
      default:
        break;
    }

    for (let index = 0; index < element?.PolicyBenefitList?.length; index++) {
      const coveragePolicy = element?.PolicyBenefitList[index];
      //OD SUB-Addons
      switch (coveragePolicy?.ProductElementCode) {
        // Own Damage Basic
        case "B00002":
          sbiPrice.basicOd = coveragePolicy?.AnnualPremium;
          break;
        // Non Electrical Accessories
        case "B00003":
          sbiPrice.accessories += coveragePolicy?.AnnualPremium;
          break;
        // Electrical Accessories
        case "B00004":
          sbiPrice.accessories += coveragePolicy?.AnnualPremium;
          break;
        // external CNG /LPG Kit - Own Damage
        case "B00005":
          sbiPrice.odExternalCngLpg = coveragePolicy?.AnnualPremium;
          break;
        // internal CNG /LPG Kit - Own Damage
        case "B00006":
          sbiPrice.odInternalCngLpg = coveragePolicy?.AnnualPremium;
          break;
        // TP external CNG /LPG Kit
        case "B00010":
          sbiPrice.tpCngLpg = coveragePolicy?.AnnualPremium;
          break;
        // SUB PA COVER
        case "B00015": // PA -  Owner Driver
          sbiPrice.paOwnerDriver = coveragePolicy?.GrossPremium;
          break;
        case "B00016": // PA - Passenger cover
          sbiPrice.passengerCover = coveragePolicy?.GrossPremium;
          break;
        case "B00027": // PA - Driver cover
          sbiPrice.driverCover = coveragePolicy?.GrossPremium;
          break;
        case "B00013": // Paid Driver cover
          sbiPrice.paidDriver = coveragePolicy?.GrossPremium;
          break;
        default:
          break;
      }
    }
  }
  return sbiPrice;
};

const getAddOnForDigit = (quoteData) => {
  let DigitPrice = {
    paCover: false, // PA -  Owner Driver PA + Unnamed Passenger + PA  Paid Driver
    zeroDep: false,
    consumables: false,
    roadAssistance: false,
    engineProtection: false,
    invoiceCover: false,
    keyLockReplacement: false,
    ncbProtection: false,
    driverCover: false,
    passengerCover: false,
    paOwnerDriver: false,
    accessories: false,
    totalPremium: false,
    basicPremium: false,
    od: false,
    basicOd: false,
    idv: false,
    nonElectrical: false,
    electrical: false,
    odExternalCngLpg: false,
    odInternalCngLpg: false,
    basicTP: false,
    tpCngLpg: false,
    tyreProtection: false,
    dailyAllowance: false,
    personalBelogging: false,
    emiProtection: false,
    BeforeVatPremium: false,
    ncbProtection: false,
    DuePremium: false,
    TGST: false,
    OD_TotalPremium: false,
    TP_TotalPremium: false,
    OD_After_Disc: false,
    OD_NCBAmount: false,
  };
  const digitQuoteData = quoteData?.data?.data?.quote?.["Motor-Quick Quote"];
  DigitPrice.idv = digitQuoteData?.vehicle?.vehicleIDV?.idv;
  DigitPrice.OD_After_Disc = Number(
    digitQuoteData?.contract?.coverages?.ownDamage?.netPremium?.slice(4)
  );
  DigitPrice.OD_NCBAmount = Number(
    digitQuoteData?.contract?.coverages?.ownDamage?.discount?.discounts[0]?.discountAmount?.slice(
      4
    )
  );
  DigitPrice.basicTP =
    digitQuoteData?.contract?.coverages?.thirdPartyLiability?.netPremium?.slice(
      4
    );
  DigitPrice.BeforeVatPremium = digitQuoteData?.netPremium?.slice(4);
  DigitPrice.DuePremium = digitQuoteData?.grossPremium?.slice(4);
  DigitPrice.TGST = digitQuoteData?.serviceTax?.totalTax?.slice(4);
  DigitPrice.zeroDep = digitQuoteData?.contract?.coverages?.addons
    ?.partsDepreciation?.selection
    ? digitQuoteData?.contract?.coverages?.addons?.partsDepreciation?.netPremium?.slice(
      4
    )
    : 0;
  DigitPrice.roadAssistance = digitQuoteData?.contract?.coverages?.addons
    ?.roadSideAssistance?.selection
    ? digitQuoteData?.contract?.coverages?.addons?.roadSideAssistance?.netPremium?.slice(
      4
    )
    : 0;
  DigitPrice.consumables = digitQuoteData?.contract?.coverages?.addons
    ?.consumables?.selection
    ? digitQuoteData?.contract?.coverages?.addons?.consumables?.netPremium?.slice(
      4
    )
    : 0;
  DigitPrice.engineProtection = digitQuoteData?.contract?.coverages?.addons
    ?.engineProtection?.selection
    ? digitQuoteData?.contract?.coverages?.addons?.engineProtection?.netPremium?.slice(
      4
    )
    : 0;
  DigitPrice.tyreProtection = digitQuoteData?.contract?.coverages?.addons
    ?.tyreProtection?.selection
    ? digitQuoteData?.contract?.coverages?.addons?.tyreProtection?.netPremium?.slice(
      4
    )
    : 0;
  DigitPrice.invoiceCover = digitQuoteData?.contract?.coverages?.addons
    ?.returnToInvoice?.selection
    ? digitQuoteData?.contract?.coverages?.addons?.returnToInvoice?.netPremium?.slice(
      4
    )
    : 0;
  DigitPrice.keyLockReplacement = digitQuoteData?.contract?.coverages?.addons
    ?.keyAndLockProtect?.selection
    ? digitQuoteData?.contract?.coverages?.addons?.keyAndLockProtect?.netPremium?.slice(
      4
    )
    : 0;
  DigitPrice.personalBelogging = digitQuoteData?.contract?.coverages?.addons
    ?.personalBelonging?.selection
    ? digitQuoteData?.contract?.coverages?.addons?.personalBelonging?.netPremium?.slice(
      4
    )
    : 0;
  DigitPrice.OD_TotalPremium =
    DigitPrice.OD_After_Disc +
    Number(DigitPrice.zeroDep) +
    Number(DigitPrice.roadAssistance) +
    Number(DigitPrice.consumables) +
    Number(DigitPrice.engineProtection) +
    Number(DigitPrice.tyreProtection) +
    Number(DigitPrice.invoiceCover) +
    Number(DigitPrice.keyLockReplacement) +
    Number(DigitPrice.personalBelogging);
  DigitPrice.OD_TotalPremium = DigitPrice.OD_TotalPremium
    ? DigitPrice.OD_TotalPremium.toFixed(2)
    : DigitPrice.OD_TotalPremium;
  DigitPrice.paOwnerDriver = digitQuoteData?.contract?.coverages
    ?.personalAccident?.selection
    ? digitQuoteData?.contract?.coverages?.personalAccident?.netPremium?.slice(
      4
    )
    : 0;
  DigitPrice.driverCover = digitQuoteData?.contract?.coverages?.unnamedPA
    ?.unnamedPaidDriver?.selection
    ? digitQuoteData?.contract?.coverages?.unnamedPA?.unnamedPaidDriver?.netPremium?.slice(
      4
    )
    : 0;
  DigitPrice.passengerCover = digitQuoteData?.contract?.coverages?.unnamedPA
    ?.unnamedPax?.selection
    ? digitQuoteData?.contract?.coverages?.unnamedPA?.unnamedPax?.netPremium?.slice(
      4
    )
    : 0;
  DigitPrice.TP_TotalPremium =
    Number(DigitPrice.basicTP) +
    Number(DigitPrice.paOwnerDriver) +
    Number(DigitPrice.driverCover) +
    Number(DigitPrice.passengerCover);

  DigitPrice.accessories =
    (digitQuoteData?.contract?.coverages?.accessories?.electrical?.selection
      ? parseInt(
        digitQuoteData?.contract?.coverages?.accessories?.electrical
          ?.insuredAmount
      )
      : 0) +
    (digitQuoteData?.contract?.coverages?.accessories?.nonElectrical?.selection
      ? parseInt(
        digitQuoteData?.contract?.coverages?.accessories?.nonElectrical
          ?.insuredAmount
      )
      : 0);

  DigitPrice.odExternalCngLpg = digitQuoteData?.contract?.coverages?.accessories
    ?.cng?.selection
    ? parseInt(
      digitQuoteData?.contract?.coverages?.accessories?.cng?.insuredAmount
    )
    : 0;

  DigitPrice.tpCngLpg = digitQuoteData?.contract?.coverages?.accessories?.cng
    ?.selection
    ? parseInt(
      digitQuoteData?.contract?.coverages?.accessories?.cng?.insuredAmount
    )
    : 0;

  return DigitPrice;
};

const getAddOnForShreeRam = (quoteData) => {
  let shreeRamPrice = {
    paCover: false, // PA -  Owner Driver PA + Unnamed Passenger + PA  Paid Driver
    zeroDep: 0,
    consumables: 0,
    roadAssistance: false,
    engineProtection: 0,
    invoiceCover: false,
    keyLockReplacement: false,
    ncbProtection: false,
    driverCover: false,
    llEmployee: false,
    passengerCover: false,
    paOwnerDriver: false,
    accessories: 0,
    totalPremium: false,
    basicPremium: false,
    od: false,
    basicOd: false,
    idv: false,
    nonElectrical: false,
    electrical: false,
    odExternalCngLpg: false,
    odInternalCngLpg: false,
    basicTP: false,
    tpCngLpg: false,
    tyreProtection: 0,
    dailyAllowance: false,
    personalBelogging: false,
    emiProtection: false,
    BeforeVatPremium: false,
    ncbProtection: false,
    DuePremium: false,
    TGST: false,
    OD_TotalPremium: false,
    TP_TotalPremium: false,
    OD_After_Disc: false,
    OD_NCBAmount: 0,
    tarrifDicount: 0,
    shreeRamProtect: false
  };
  const shreeRamQuoteData = quoteData?.data?.data?.quote?.GetQuotResult;
  shreeRamPrice.idv = shreeRamQuoteData?.VehicleIDV;

  for (let index = 0; index < shreeRamQuoteData?.CoverDtlList.length; index++) {
    let premium = shreeRamQuoteData.CoverDtlList[index].Premium;
    let switchCaseMatchForAllYear = shreeRamQuoteData.CoverDtlList[
      index
    ].CoverDesc.replace(/ - (1|2|3|4) Year/g, "");
    switch (switchCaseMatchForAllYear) {
      case "Basic Premium - OD":
        shreeRamPrice.basicOd += premium ? Number(premium) : 0;
        break;
      case "NCB Discount  - OD":
        shreeRamPrice.OD_NCBAmount += premium ? Number(premium) : 0;
        break;
      case "De-Tariff Discount - OD":
        shreeRamPrice.tarrifDicount += premium ? Number(premium) : 0;
        break;
      case "Basic Premium - TP":
        shreeRamPrice.basicTP += Number(premium);
        break;
      case "Total Premium":
        shreeRamPrice.BeforeVatPremium += Number(premium);
        break;
      case "Total Amount":
        shreeRamPrice.DuePremium += Number(premium);
        break;
      case "Legal Liability To Employees - TP":
        shreeRamPrice.llEmployee += Number(premium);
        break;
      case "GR42-Outbuilt CNG/LPG-Kit-Cover - OD":
        shreeRamPrice.odExternalCngLpg += Number(premium);
        break;

      case "GR42-Outbuilt CNG/LPG-Kit-Cover - TP":
        shreeRamPrice.tpCngLpg += Number(premium);
        break;

      case "IGST(18.00%)":
        shreeRamPrice.TGST += Number(premium);
        break;
      case "Depreciation Deduction Waiver (Nil Depreciation) - OD":
        shreeRamPrice.zeroDep += Number(premium);
        break;
      case "Nil Depreciation Loading - OD":
        shreeRamPrice.zeroDep += Number(premium);
        break;
      case "Road Side Assistance - OD":
        shreeRamPrice.roadAssistance += Number(premium);
        break;
      case "Consumable - OD":
        shreeRamPrice.consumables += Number(premium);
        break;
      case "Consumable Loading - OD":
        shreeRamPrice.consumables += Number(premium);
        break;
      case "Engine Protector - OD":
        shreeRamPrice.engineProtection += Number(premium);
        break;
      case "Engine Protector Loading - OD":
        shreeRamPrice.engineProtection += Number(premium);
        break;
      case "Return to Invoice - OD":
        shreeRamPrice.invoiceCover += Number(premium);
        break;
      case "Key Replacement - OD":
        shreeRamPrice.keyLockReplacement += Number(premium);
        break;
      case "Personal Belonging - OD":
        shreeRamPrice.personalBelogging += Number(premium);
        break;
      case "GR41-Cover For Electrical and Electronic Accessories - OD":
        shreeRamPrice.accessories += Number(premium);
        break;
      case "Cover For Non Electrical Accessories - OD":
        shreeRamPrice.accessories += Number(premium);
        break;
      case "OD Total":
        shreeRamPrice.OD_TotalPremium += Number(premium);
        break;
      case "GR36B2-PA Cover For Passengers (Un-Named Persons) - TP":
        shreeRamPrice.passengerCover += Number(premium);
        break;
      case "GR36A-PA FOR OWNER DRIVER - TP":
        shreeRamPrice.paOwnerDriver += Number(premium);
        break;
      case "Legal Liability Coverages For Paid Driver - TP":
        shreeRamPrice.driverCover += Number(premium);
        break;
      case "TP Total":
        shreeRamPrice.TP_TotalPremium += Number(premium);
        break;
      case "Motor Protection - OD":
        shreeRamPrice.shreeRamProtect = Number(premium);
        break;
      default:
        // Handle other cases or leave empty if not needed
        break;
    }
  }
  shreeRamPrice.OD_After_Disc =
    shreeRamPrice.basicOd +
    shreeRamPrice.accessories -
    shreeRamPrice.tarrifDicount -
    shreeRamPrice.OD_NCBAmount;
  // shreeRamPrice.OD_TotalPremium = shreeRamPrice.OD_After_Disc + shreeRamPrice.zeroDep + shreeRamPrice.roadAssistance +
  //   shreeRamPrice.consumables + shreeRamPrice.engineProtection + shreeRamPrice.tyreProtection + shreeRamPrice.invoiceCover +
  //   shreeRamPrice.keyLockReplacement + shreeRamPrice.personalBelogging + shreeRamPrice.accessories;
  //shreeRamPrice.TP_TotalPremium = shreeRamPrice.basicTP + shreeRamPrice.paOwnerDriver + shreeRamPrice.driverCover + shreeRamPrice.passengerCover;

  return shreeRamPrice;
};

const getAddOnForICICI = (quoteData) => {
  let iciciPrice = {
    paCover: false, // PA -  Owner Driver PA + Unnamed Passenger + PA  Paid Driver
    zeroDep: 0,
    consumables: 0,
    roadAssistance: false,
    engineProtection: 0,
    invoiceCover: false,
    keyLockReplacement: false,
    ncbProtection: false,
    driverCover: false,
    passengerCover: false,
    paOwnerDriver: false,
    accessories: 0,
    totalPremium: false,
    basicPremium: false,
    od: false,
    basicOd: false,
    idv: false,
    nonElectrical: false,
    electrical: false,
    odExternalCngLpg: false,
    odInternalCngLpg: false,
    basicTP: false,
    tpCngLpg: false,
    tyreProtection: 0,
    dailyAllowance: false,
    personalBelogging: false,
    emiProtection: false,
    BeforeVatPremium: false,
    ncbProtection: false,
    DuePremium: false,
    TGST: false,
    OD_TotalPremium: false,
    TP_TotalPremium: false,
    OD_After_Disc: false,
    OD_NCBAmount: 0,
  };
  const iciciQuoteData = quoteData?.data?.data?.quote;
  iciciPrice.idv = iciciQuoteData?.generalInformation?.depriciatedIDV;
  iciciPrice.basicOd = iciciQuoteData?.riskDetails?.basicOD + iciciQuoteData?.riskDetails?.breakinLoadingAmount;
  iciciPrice.OD_NCBAmount = iciciQuoteData?.riskDetails?.bonusDiscount;
  iciciPrice.basicTP = iciciQuoteData?.riskDetails?.basicTP;
  iciciPrice.BeforeVatPremium = iciciQuoteData?.packagePremium
    ? iciciQuoteData?.packagePremium
    : iciciQuoteData?.totalLiabilityPremium;
  iciciPrice.DuePremium = iciciQuoteData?.finalPremium;
  iciciPrice.odExternalCngLpg = iciciQuoteData?.riskDetails?.biFuelKitOD;
  iciciPrice.tpCngLpg = iciciQuoteData?.riskDetails?.biFuelKitTP;
  iciciPrice.TGST = iciciQuoteData?.totalTax;
  iciciPrice.zeroDep = iciciQuoteData?.riskDetails?.zeroDepreciation;
  iciciPrice.roadAssistance = iciciQuoteData?.riskDetails?.roadSideAssistance;
  iciciPrice.consumables = iciciQuoteData?.riskDetails?.consumables;
  iciciPrice.engineProtection = iciciQuoteData?.riskDetails?.engineProtect;
  iciciPrice.invoiceCover = iciciQuoteData?.riskDetails?.returnToInvoice;
  iciciPrice.keyLockReplacement = iciciQuoteData?.riskDetails?.keyProtect;
  iciciPrice.tyreProtection = iciciQuoteData?.riskDetails?.tyreProtect;
  iciciPrice.personalBelogging =
    iciciQuoteData?.riskDetails?.lossOfPersonalBelongings;
  iciciPrice.accessories += iciciQuoteData?.riskDetails?.electricalAccessories;
  iciciPrice.accessories +=
    iciciQuoteData?.riskDetails?.nonElectricalAccessories;
  iciciPrice.OD_TotalPremium = iciciQuoteData?.totalOwnDamagePremium;
  iciciPrice.passengerCover =
    iciciQuoteData?.riskDetails?.paCoverForUnNamedPassenger;
  iciciPrice.paOwnerDriver = iciciQuoteData?.riskDetails?.paCoverForOwnerDriver;
  iciciPrice.driverCover = iciciQuoteData?.riskDetails?.paidDriver;
  iciciPrice.TP_TotalPremium = iciciQuoteData?.totalLiabilityPremium;
  iciciPrice.basicOd += iciciPrice.odExternalCngLpg + iciciPrice.accessories;
  iciciPrice.OD_After_Disc = iciciPrice.basicOd - iciciPrice.OD_NCBAmount;
  iciciPrice.basicTP += iciciPrice.tpCngLpg;

  return iciciPrice;
};

const getAddOnForACKO = (quoteData, bodyData) => {
  // checking for checkbox as acko does not has selection option
  let checkboxTrueFalseObject = {
    paCover: false,
    zeroDep: false,
    consumables: false,
    roadAssistance: false,
    engineProtection: false,
    invoiceCover: false,
    keyLockReplacement: false,
    ncbProtection: false,
    driverCover: false,
    passengerCover: false,
    accessories: false,
    tyreProtection: false,
    emiProtection: false,
    lossofPersonalBelongings: false,
    dailyAllowance: false,
  };

  for (let addon of bodyData?.data?.coverage[0]) {
    if (addon.name in checkboxTrueFalseObject) {
      checkboxTrueFalseObject[addon.name] = addon.checked ? true : false;
    }
  }

  let ackoPlanId = "";
  if (bodyData?.data?.PolicyTypeValue === "Comprehensive") {
    ackoPlanId = "car_comprehensive";
  } else if (bodyData?.data?.PolicyTypeValue === "Bundled") {
    ackoPlanId = "car_bundled";
  } else if (bodyData?.data?.PolicyTypeValue === "Third Party") {
    ackoPlanId = "car_tp";
  } else if (bodyData?.data?.PolicyTypeValue === "Own Damage") {
    ackoPlanId = "car_od";
  }

  let ackoPrice = {
    paCover: false, // PA -  Owner Driver PA + Unnamed Passenger + PA  Paid Driver
    zeroDep: 0,
    consumables: 0,
    roadAssistance: false,
    rsaKeyProtectCombo: false,
    engineProtection: 0,
    invoiceCover: false,
    keyLockReplacement: false,
    ncbProtection: false,
    driverCover: false,
    passengerCover: false,
    paOwnerDriver: false,
    accessories: 0,
    totalPremium: false,
    basicPremium: false,
    od: false,
    basicOd: false,
    idv: false,
    nonElectrical: false,
    electrical: false,
    odExternalCngLpg: false,
    odInternalCngLpg: false,
    basicTP: false,
    tpCngLpg: false,
    tyreProtection: 0,
    dailyAllowance: false,
    personalBelogging: false,
    emiProtection: false,
    BeforeVatPremium: false,
    ncbProtection: false,
    DuePremium: false,
    TGST: 0,
    OD_TotalPremium: false,
    TP_TotalPremium: false,
    OD_After_Disc: false,
    OD_NCBAmount: 0,
    addOnsData: [],
    planID: "",
  };
  const ackoQuoteData = quoteData?.data?.data?.quote?.insured[0];

  let ackoPlan = {};
  if (ackoQuoteData?.plans) {
    ackoPlan = ackoQuoteData?.plans.find((item) => {
      return item.id == ackoPlanId;
    });
  }

  function getAddonPrice(addonData, plan_id) {
    if (addonData) {
      let addOnprice = addonData.find((item) => {
        return item.id == plan_id;
      });
      return {
        netpremium: addOnprice?.price?.net_premium
          ? addOnprice?.price?.net_premium
          : 0,
        gross_premium: addOnprice?.price?.gross_premium
          ? addOnprice?.price?.gross_premium
          : 0,
        gst: addOnprice?.price?.gst ? addOnprice?.price?.gst : 0,
        discount: addOnprice?.price?.discount?.ncb_discount
          ? addOnprice?.price?.discount?.ncb_discount
          : 0,
      };
    } else {
      return {};
    }
  }

  //calculating to send accdons in proposal
  function getAddOnForProposalBody(addOndata, selectedAddon) {
    let ackoAddOn = [];
    if (addOndata?.length) {
      for (let index = 0; index < addOndata?.length; index++) {
        const element = addOndata[index];
        switch (element.id) {
          case "zero_depreciation_car":
            if (checkboxTrueFalseObject.zeroDep) {
              ackoAddOn.push(element);
            }
            break;
          case "rsa_outstation_keyprotect_combo_car":
            if (
              checkboxTrueFalseObject.keyLockReplacement ||
              checkboxTrueFalseObject.roadAssistance
            ) {
              ackoAddOn.push(element);
            }
            break;
          case "consumables_car":
            if (checkboxTrueFalseObject.consumables) {
              ackoAddOn.push(element);
            }
            break;
          case "engine_protect_car":
            if (checkboxTrueFalseObject.engineProtection) {
              ackoAddOn.push(element);
            }
            break;
          case "invoice_car":
            if (checkboxTrueFalseObject.invoiceCover) {
              ackoAddOn.push(element);
            }
            break;
          case "ncb_protect_car":
            if (checkboxTrueFalseObject.ncbProtection) {
              ackoAddOn.push(element);
            }
            break;
          case "personal_belongings_damage_car":
            if (checkboxTrueFalseObject.lossofPersonalBelongings) {
              ackoAddOn.push(element);
            }
            break;
          case "electrical_accessories_own_damage_basic_car":
            if (checkboxTrueFalseObject.accessories) {
              ackoAddOn.push(element);
            }
            break;
          case "non_electrical_accessories_own_damage_basic_car":
            if (checkboxTrueFalseObject.accessories) {
              ackoAddOn.push(element);
            }
            break;
          case "pa_unnamed_passenger_car":
            if (checkboxTrueFalseObject.passengerCover) {
              ackoAddOn.push(element);
            }
            break;
          case "pa_owner_car":
            if (checkboxTrueFalseObject.paCover) {
              ackoAddOn.push(element);
            }
            break;
          case "legal_liability_car":
            if (checkboxTrueFalseObject.driverCover) {
              ackoAddOn.push(element);
            }
            break;
          default:
            break;
        }
      }
    }
    return ackoAddOn;
  }

  ackoPrice.idv = ackoQuoteData?.idv?.values[0].idv_amount;

  ackoPrice.basicOd = getAddonPrice(
    ackoPlan?.covers,
    "own_damage_basic_car"
  ).netpremium;
  ackoPrice.OD_NCBAmount = getAddonPrice(
    ackoPlan?.covers,
    "own_damage_basic_car"
  ).discount;
  ackoPrice.OD_NCBAmount = ackoPrice.OD_NCBAmount
    ? parseInt(ackoPrice.OD_NCBAmount)
    : 0;
  //ackoPrice.basicOd += ackoPrice.OD_NCBAmount;
  ackoPrice.basicTP = getAddonPrice(ackoPlan?.covers, "tp_car").netpremium;
  ackoPrice.TGST += ackoPlan?.total_price.gst;

  ackoPrice.odExternalCngLpg = getAddonPrice(ackoPlan?.covers, "own_damage_cng_car").netpremium;
  ackoPrice.tpCngLpg = getAddonPrice(ackoPlan?.covers, "tp_cng_car").netpremium;
  //ackoPrice.roadAssistance = getAddonPrice(ackoPlan?.addons, "rsa_outstation_keyprotect_combo_car").netpremium;
  //ackoPrice.keyLockReplacement = getAddonPrice(ackoPlan?.addons, "rsa_outstation_keyprotect_combo_car").netpremium;

  ackoPrice.zeroDep = checkboxTrueFalseObject.zeroDep
    ? getAddonPrice(ackoPlan?.addons, "zero_depreciation_car").netpremium
    : false;
  ackoPrice.TGST += checkboxTrueFalseObject.zeroDep
    ? getAddonPrice(ackoPlan?.addons, "zero_depreciation_car").gst
    : 0;

  ackoPrice.rsaKeyProtectCombo =
    checkboxTrueFalseObject.keyLockReplacement ||
      checkboxTrueFalseObject.roadAssistance
      ? getAddonPrice(ackoPlan?.addons, "rsa_outstation_keyprotect_combo_car")
        .netpremium
      : false;
  ackoPrice.TGST +=
    checkboxTrueFalseObject.keyLockReplacement ||
      checkboxTrueFalseObject.roadAssistance
      ? getAddonPrice(ackoPlan?.addons, "rsa_outstation_keyprotect_combo_car")
        .gst
      : 0;

  ackoPrice.consumables = checkboxTrueFalseObject.consumables
    ? getAddonPrice(ackoPlan?.addons, "consumables_car").netpremium
    : false;
  ackoPrice.TGST += checkboxTrueFalseObject.consumables
    ? getAddonPrice(ackoPlan?.addons, "consumables_car").gst
    : 0;

  ackoPrice.engineProtection = checkboxTrueFalseObject.engineProtection
    ? getAddonPrice(ackoPlan?.addons, "engine_protect_car").netpremium
    : false;
  ackoPrice.TGST += checkboxTrueFalseObject.engineProtection
    ? getAddonPrice(ackoPlan?.addons, "engine_protect_car").gst
    : 0;

  ackoPrice.invoiceCover = checkboxTrueFalseObject.invoiceCover
    ? getAddonPrice(ackoPlan?.addons, "invoice_car").netpremium
    : false;
  ackoPrice.TGST += checkboxTrueFalseObject.invoiceCover
    ? getAddonPrice(ackoPlan?.addons, "invoice_car").gst
    : 0;

  ackoPrice.ncbProtection = checkboxTrueFalseObject.ncbProtection
    ? getAddonPrice(ackoPlan?.addons, "ncb_protect_car").netpremium
    : false;
  ackoPrice.TGST += checkboxTrueFalseObject.ncbProtection
    ? getAddonPrice(ackoPlan?.addons, "ncb_protect_car").gst
    : 0;

  ackoPrice.personalBelogging = checkboxTrueFalseObject.lossofPersonalBelongings
    ? getAddonPrice(ackoPlan?.addons, "personal_belongings_damage_car")
      .netpremium
    : false;
  ackoPrice.TGST += checkboxTrueFalseObject.lossofPersonalBelongings
    ? getAddonPrice(ackoPlan?.addons, "personal_belongings_damage_car").gst
    : 0;

  //electrical accessories
  ackoPrice.accessories += checkboxTrueFalseObject.accessories
    ? getAddonPrice(
      ackoPlan?.addons,
      "electrical_accessories_own_damage_basic_car"
    ).netpremium
    : false;
  ackoPrice.TGST += checkboxTrueFalseObject.accessories
    ? getAddonPrice(
      ackoPlan?.addons,
      "electrical_accessories_own_damage_basic_car"
    ).gst
    : 0;

  //non electrical accesories
  ackoPrice.accessories += checkboxTrueFalseObject.accessories
    ? getAddonPrice(
      ackoPlan?.addons,
      "non_electrical_accessories_own_damage_basic_car"
    ).netpremium
    : false;
  ackoPrice.TGST += checkboxTrueFalseObject.accessories
    ? getAddonPrice(
      ackoPlan?.addons,
      "non_electrical_accessories_own_damage_basic_car"
    ).gst
    : 0;

  ackoPrice.passengerCover = checkboxTrueFalseObject.passengerCover
    ? getAddonPrice(ackoPlan?.addons, "pa_unnamed_passenger_car").netpremium
    : false;
  ackoPrice.TGST += checkboxTrueFalseObject.passengerCover
    ? getAddonPrice(ackoPlan?.addons, "pa_unnamed_passenger_car").gst
    : 0;

  ackoPrice.paOwnerDriver = checkboxTrueFalseObject.paCover
    ? getAddonPrice(ackoPlan?.addons, "pa_owner_car").netpremium
    : false;
  ackoPrice.TGST += checkboxTrueFalseObject.paCover
    ? getAddonPrice(ackoPlan?.addons, "pa_owner_car").gst
    : 0;

  ackoPrice.driverCover = checkboxTrueFalseObject.driverCover
    ? getAddonPrice(ackoPlan?.addons, "legal_liability_car").netpremium
    : false;
  ackoPrice.TGST += checkboxTrueFalseObject.driverCover
    ? getAddonPrice(ackoPlan?.addons, "legal_liability_car").gst
    : 0;

  ackoPrice.TP_TotalPremium =
    ackoPrice.basicTP +
    ackoPrice.tpCngLpg +
    ackoPrice.passengerCover +
    ackoPrice.paOwnerDriver +
    ackoPrice.driverCover;

  // ackoPrice.basicOd += ackoPrice.odExternalCngLpg + ackoPrice.accessories;
  // ackoPrice.basicTP += ackoPrice.tpCngLpg;

  ackoPrice.addOnsData = getAddOnForProposalBody(ackoPlan?.addons, ackoPrice);
  ackoPrice.planID = ackoPlanId;

  ackoPrice.OD_After_Disc = parseInt(ackoPrice.basicOd);
  ackoPrice.OD_TotalPremium =
    ackoPrice.basicOd +
    ackoPrice.odExternalCngLpg +
    ackoPrice.zeroDep +
    ackoPrice.rsaKeyProtectCombo +
    ackoPrice.consumables +
    ackoPrice.engineProtection +
    ackoPrice.invoiceCover +
    ackoPrice.ncbProtection +
    ackoPrice.personalBelogging +
    ackoPrice.accessories;

  ackoPrice.BeforeVatPremium =
    ackoPrice.OD_TotalPremium + ackoPrice.TP_TotalPremium;
  ackoPrice.DuePremium =
    ackoPrice.OD_TotalPremium + ackoPrice.TP_TotalPremium + ackoPrice.TGST;

  return ackoPrice;
};

const getAddOnForACKOBike = (quoteData, bodyData) => {
  // checking for checkbox as acko does not has selection option
  let checkboxTrueFalseObject = {
    paCover: false,
    zeroDep: false,
    consumables: false,
    roadAssistance: false,
    engineProtection: false,
    invoiceCover: false,
    keyLockReplacement: false,
    ncbProtection: false,
    driverCover: false,
    passengerCover: false,
    accessories: false,
    tyreProtection: false,
    emiProtection: false,
    lossofPersonalBelongings: false,
    dailyAllowance: false,
  };

  for (let addon of bodyData?.data?.coverage[0]) {
    if (addon.name in checkboxTrueFalseObject) {
      checkboxTrueFalseObject[addon.name] = addon.checked ? true : false;
    }
  }

  let ackoPlanId = "";
  if (bodyData?.data?.PolicyTypeValue === "Comprehensive") {
    ackoPlanId = "bike_comprehensive";
  } else if (bodyData?.data?.PolicyTypeValue === "Bundled") {
    ackoPlanId = "bike_bundled";
  } else if (bodyData?.data?.PolicyTypeValue === "Third Party") {
    ackoPlanId = "bike_tp";
  } else if (bodyData?.data?.PolicyTypeValue === "Own Damage") {
    ackoPlanId = "bike_od";
  }

  let ackoPrice = {
    paCover: false, // PA -  Owner Driver PA + Unnamed Passenger + PA  Paid Driver
    zeroDep: 0,
    consumables: 0,
    roadAssistance: false,
    rsaKeyProtectCombo: false,
    engineProtection: 0,
    invoiceCover: false,
    keyLockReplacement: false,
    ncbProtection: false,
    driverCover: false,
    passengerCover: false,
    paOwnerDriver: false,
    accessories: 0,
    totalPremium: false,
    basicPremium: false,
    od: false,
    basicOd: false,
    idv: false,
    nonElectrical: false,
    electrical: false,
    odExternalCngLpg: false,
    odInternalCngLpg: false,
    basicTP: false,
    tpCngLpg: false,
    tyreProtection: 0,
    dailyAllowance: false,
    personalBelogging: false,
    emiProtection: false,
    BeforeVatPremium: false,
    ncbProtection: false,
    DuePremium: false,
    TGST: 0,
    OD_TotalPremium: false,
    TP_TotalPremium: false,
    OD_After_Disc: false,
    OD_NCBAmount: 0,
    addOnsData: [],
    planID: "",
  };
  const ackoQuoteData = quoteData?.data?.data?.quote?.insured[0];

  let ackoPlan = {};
  if (ackoQuoteData?.plans) {
    ackoPlan = ackoQuoteData?.plans.find((item) => {
      return item.id == ackoPlanId;
    });
  }

  function getAddonPrice(addonData, plan_id) {
    if (addonData) {
      let addOnprice = addonData.find((item) => {
        return item.id == plan_id;
      });
      return {
        netpremium: addOnprice?.price?.net_premium
          ? addOnprice?.price?.net_premium
          : 0,
        gross_premium: addOnprice?.price?.gross_premium
          ? addOnprice?.price?.gross_premium
          : 0,
        gst: addOnprice?.price?.gst ? addOnprice?.price?.gst : 0,
        discount: addOnprice?.price?.discount?.ncb_discount
          ? addOnprice?.price?.discount?.ncb_discount
          : 0,
      };
    } else {
      return {};
    }
  }

  //calculating to send accdons in proposal
  function getAddOnForProposalBody(addOndata, selectedAddon) {
    let ackoAddOn = [];
    if (addOndata?.length) {
      for (let index = 0; index < addOndata?.length; index++) {
        const element = addOndata[index];
        switch (element.id) {
          case "zero_depreciation_two_wheeler":
            if (checkboxTrueFalseObject.zeroDep) {
              ackoAddOn.push(element);
            }
            break;
          case "personal_belongings_damage_two_wheeler":
            if (checkboxTrueFalseObject.lossofPersonalBelongings) {
              ackoAddOn.push(element);
            }
            break;
          case "pa_owner_two_wheeler":
            if (checkboxTrueFalseObject.paCover) {
              ackoAddOn.push(element);
            }
            break;
          default:
            break;
        }
      }
    }
    return ackoAddOn;
  }

  ackoPrice.idv = ackoQuoteData?.idv?.values[0].idv_amount;

  ackoPrice.basicOd = getAddonPrice(
    ackoPlan?.covers,
    "own_damage_two_wheeler"
  ).netpremium;
  ackoPrice.OD_NCBAmount = getAddonPrice(
    ackoPlan?.covers,
    "own_damage_two_wheeler"
  ).discount;
  ackoPrice.OD_NCBAmount = ackoPrice.OD_NCBAmount
    ? parseInt(ackoPrice.OD_NCBAmount)
    : 0;
  //ackoPrice.basicOd += ackoPrice.OD_NCBAmount;
  ackoPrice.basicTP = getAddonPrice(ackoPlan?.covers, "tp_two_wheeler").netpremium;
  ackoPrice.TGST += ackoPlan?.total_price.gst;

  ackoPrice.zeroDep = checkboxTrueFalseObject.zeroDep
    ? getAddonPrice(ackoPlan?.addons, "zero_depreciation_two_wheeler").netpremium
    : false;
  ackoPrice.TGST += checkboxTrueFalseObject.zeroDep
    ? getAddonPrice(ackoPlan?.addons, "zero_depreciation_two_wheeler").gst
    : 0;

  ackoPrice.personalBelogging = checkboxTrueFalseObject.lossofPersonalBelongings
    ? getAddonPrice(ackoPlan?.addons, "personal_belongings_damage_two_wheeler")
      .netpremium
    : false;
  ackoPrice.TGST += checkboxTrueFalseObject.lossofPersonalBelongings
    ? getAddonPrice(ackoPlan?.addons, "personal_belongings_damage_two_wheeler").gst
    : 0;

  ackoPrice.paOwnerDriver = checkboxTrueFalseObject.paCover
    ? getAddonPrice(ackoPlan?.addons, "pa_owner_two_wheeler").netpremium
    : false;
  ackoPrice.TGST += checkboxTrueFalseObject.paCover
    ? getAddonPrice(ackoPlan?.addons, "pa_owner_two_wheeler").gst
    : 0;

  ackoPrice.TP_TotalPremium =
    ackoPrice.basicTP +
    ackoPrice.paOwnerDriver;

  ackoPrice.addOnsData = getAddOnForProposalBody(ackoPlan?.addons, ackoPrice);
  ackoPrice.planID = ackoPlanId;

  ackoPrice.OD_After_Disc = parseInt(ackoPrice.basicOd);
  ackoPrice.OD_TotalPremium =
    ackoPrice.basicOd +
    ackoPrice.zeroDep +
    ackoPrice.personalBelogging;

  ackoPrice.BeforeVatPremium =
    ackoPrice.OD_TotalPremium + ackoPrice.TP_TotalPremium;
  ackoPrice.DuePremium =
    ackoPrice.OD_TotalPremium + ackoPrice.TP_TotalPremium + ackoPrice.TGST;

  return ackoPrice;
};

const getAddOnForZUNO = (quoteData) => {
  let zunoPrice = {
    paCover: false, // PA -  Owner Driver PA + Unnamed Passenger + PA  Paid Driver
    zeroDep: 0,
    consumables: 0,
    roadAssistance: false,
    engineProtection: 0,
    invoiceCover: false,
    keyLockReplacement: false,
    ncbProtection: false,
    driverCover: false,
    passengerCover: false,
    paOwnerDriver: false,
    accessories: 0,
    totalPremium: false,
    basicPremium: false,
    od: false,
    basicOd: false,
    idv: false,
    nonElectrical: false,
    electrical: false,
    odExternalCngLpg: false,
    odInternalCngLpg: false,
    basicTP: false,
    tpCngLpg: false,
    tyreProtection: 0,
    dailyAllowance: false,
    personalBelogging: false,
    emiProtection: false,
    BeforeVatPremium: false,
    DuePremium: false,
    TGST: false,
    OD_TotalPremium: false,
    TP_TotalPremium: false,
    OD_After_Disc: false,
    OD_NCBAmount: 0,
  };
  const zunoQuoteData = quoteData?.data?.data?.quote;
  let electricalZuno = 0, nonElectricalZuno = 0, odExternalCngLpg = 0, odInternalCngLpg = 0, ncbDiscountZuno = 0, passengerZuno = 0, driverZuno = 0, tpCngLpgZuno = 0 //  Calculate their value
  // for electricalZuno, nonElectricalZuno, ncbDiscountZuno, odExternalCngLpg
  if (zunoQuoteData?.premiumDetails?.totalODPremium > 0 && zunoQuoteData?.premiumDetails?.totalTPPremium > 0) {
    if ((zunoQuoteData?.contractDetails[0]?.coverage?.subCoverage)?.length > 0) {
      for (let item of zunoQuoteData?.contractDetails[0]?.coverage
        ?.subCoverage) {
        if (item.salesProductTemplateId == "MOSCMF02") {
          electricalZuno = item.grossPremium;
        } else if (item.salesProductTemplateId == "MOSCMF01") {
          nonElectricalZuno = item.grossPremium;
        } else if (item.salesProductTemplateId == "MOSCMF03") {
          odExternalCngLpg = item.grossPremium;
        } else if (item.salesProductTemplateId == "MOSCMF04") {
          odInternalCngLpg = item.grossPremium;
        }
      }
    }
  } else if (zunoQuoteData?.premiumDetails?.totalODPremium > 0 && zunoQuoteData?.premiumDetails?.totalTPPremium == 0 && (zunoQuoteData?.contractDetails[0]?.coverage?.subCoverage)?.length > 0) {
    for (let item of zunoQuoteData?.contractDetails[0]?.coverage?.subCoverage) {
      if (item.salesProductTemplateId == "MOSCMF02") {
        electricalZuno = item.grossPremium;
      } else if (item.salesProductTemplateId == "MOSCMF01") {
        nonElectricalZuno = item.grossPremium;
      } else if (item.salesProductTemplateId == "MOSCMF03") {
        odExternalCngLpg = item.grossPremium;
      } else if (item.salesProductTemplateId == "MOSCMF04") {
        odInternalCngLpg = item.grossPremium;
      }
    }
  } else if (zunoQuoteData?.premiumDetails?.totalTPPremium == 0 && Array.isArray(zunoQuoteData?.contractDetails?.coverage?.subCoverage)) {
    for (let item of zunoQuoteData?.contractDetails?.coverage?.subCoverage) {
      if (item.salesProductTemplateId == "MOSCMF02") {
        electricalZuno = item.grossPremium;
      } else if (item.salesProductTemplateId == "MOSCMF01") {
        nonElectricalZuno = item.grossPremium;
      } else if (item.salesProductTemplateId == "MOSCMF03") {
        odExternalCngLpg = item.grossPremium;
      } else if (item.salesProductTemplateId == "MOSCMF04") {
        odInternalCngLpg = item.grossPremium;
      }
    }
  }

  // ncb discount
  ncbDiscountZuno = Array.isArray(zunoQuoteData?.contractDetails) ?
    zunoQuoteData?.contractDetails[0]?.coverage?.coverageSurchargesOrDiscounts
      ?.amount : zunoQuoteData?.contractDetails?.coverage?.coverageSurchargesOrDiscounts
      ?.amount;

  // for passengerZuno, driverZuno , tpCngLpgZuno
  let contractDetailsLength = Array.isArray(zunoQuoteData?.contractDetails)
    ? (zunoQuoteData?.contractDetails).length - 1
    : 0;
  if (zunoQuoteData?.premiumDetails?.totalODPremium > 0 && contractDetailsLength > 0) {
    if (
      (zunoQuoteData?.contractDetails[contractDetailsLength]?.coverage
        ?.subCoverage).length > 0
    ) {
      for (let item of zunoQuoteData?.contractDetails[contractDetailsLength]
        ?.coverage?.subCoverage) {
        if (item.salesProductTemplateId == "MOSCMF24") {
          passengerZuno = item.totalPremium;
        } else if (item.salesProductTemplateId == "MOSCMF20") {
          driverZuno = item.totalPremium;
        } else if (item.salesProductTemplateId == "MOSCMF17") {
          tpCngLpgZuno = item.totalPremium;
        }
      }
    }
  } else if (zunoQuoteData?.premiumDetails?.totalODPremium == 0 && contractDetailsLength == 0) {
    if ((zunoQuoteData?.contractDetails?.coverage?.subCoverage)?.length > 0) {
      for (let item of zunoQuoteData?.contractDetails?.coverage?.subCoverage) {
        if (item.salesProductTemplateId == "MOSCMF24") {
          passengerZuno = item.totalPremium;
        } else if (item.salesProductTemplateId == "MOSCMF20") {
          driverZuno = item.totalPremium;
        } else if (item.salesProductTemplateId == "MOSCMF17") {
          tpCngLpgZuno = item.totalPremium;
        }
      }
    }
  } else if (zunoQuoteData?.premiumDetails?.totalODPremium == 0 && contractDetailsLength > 0) {
    if (
      (zunoQuoteData?.contractDetails[contractDetailsLength]?.coverage
        ?.subCoverage)?.length > 0
    ) {
      for (let item of zunoQuoteData?.contractDetails[contractDetailsLength]
        ?.coverage?.subCoverage) {
        if (item.salesProductTemplateId == "MOSCMF24") {
          passengerZuno = item.totalPremium;
        } else if (item.salesProductTemplateId == "MOSCMF20") {
          driverZuno = item.totalPremium;
        } else if (item.salesProductTemplateId == "MOSCMF17") {
          tpCngLpgZuno = item.totalPremium;
        }
      }
    }
  }

  zunoPrice.idv =
    zunoQuoteData?.premiumDetails?.totalTPPremium > 0
      ? zunoQuoteData?.contractDetails[0]?.insuredObject?.systemIdv
      : zunoQuoteData?.premiumDetails?.totalTPPremium == 0
        ? zunoQuoteData?.contractDetails?.insuredObject?.systemIdv
        : "";
  zunoPrice.basicOd =
    zunoQuoteData?.premiumDetails?.totalODPremium -
    Number(electricalZuno) -
    Number(nonElectricalZuno) +
    Number(ncbDiscountZuno);
  zunoPrice.OD_NCBAmount = ncbDiscountZuno > 0 ? Number(ncbDiscountZuno) : 0;
  zunoPrice.basicTP =
    zunoQuoteData?.premiumDetails?.totalTPPremium -
    Number(passengerZuno) -
    Number(driverZuno);
  zunoPrice.BeforeVatPremium = zunoQuoteData?.premiumDetails?.netTotalPremium;
  zunoPrice.DuePremium = zunoQuoteData?.premiumDetails?.grossTotalPremium;
  zunoPrice.odExternalCngLpg = odExternalCngLpg;
  zunoPrice.odInternalCngLpg = odInternalCngLpg;
  zunoPrice.tpCngLpg = Number(tpCngLpgZuno);
  zunoPrice.TGST = zunoQuoteData?.premiumDetails?.gst;
  zunoPrice.zeroDep = (zunoQuoteData?.premiumDetails?.individualAddOnPremium?.zeroDepreciation > 0) ? zunoQuoteData?.premiumDetails?.individualAddOnPremium?.zeroDepreciation : "";
  zunoPrice.roadAssistance = (zunoQuoteData?.premiumDetails?.individualAddOnPremium?.basicRoadAssistance > 0) ? zunoQuoteData?.premiumDetails?.individualAddOnPremium?.basicRoadAssistance : "";
  zunoPrice.consumables = (zunoQuoteData?.premiumDetails?.individualAddOnPremium?.consumableCover > 0) ? zunoQuoteData?.premiumDetails?.individualAddOnPremium?.consumableCover : "";
  zunoPrice.engineProtection = (zunoQuoteData?.premiumDetails?.individualAddOnPremium?.engineProtect > 0) ? zunoQuoteData?.premiumDetails?.individualAddOnPremium?.engineProtect : "";
  zunoPrice.invoiceCover = (zunoQuoteData?.premiumDetails?.individualAddOnPremium?.invoiceValueProtect > 0) ? zunoQuoteData?.premiumDetails?.individualAddOnPremium?.invoiceValueProtect : "";
  zunoPrice.keyLockReplacement = (zunoQuoteData?.premiumDetails?.individualAddOnPremium?.keyReplacement > 0) ? zunoQuoteData?.premiumDetails?.individualAddOnPremium?.keyReplacement : "";
  zunoPrice.personalBelogging = (zunoQuoteData?.premiumDetails?.individualAddOnPremium?.lossOfPersonalBelongings > 0) ? zunoQuoteData?.premiumDetails?.individualAddOnPremium?.lossOfPersonalBelongings : "";
  zunoPrice.ncbProtection = (zunoQuoteData?.premiumDetails?.individualAddOnPremium?.protectionOfNCB > 0) ? zunoQuoteData?.premiumDetails?.individualAddOnPremium?.protectionOfNCB : "";
  zunoPrice.accessories += electricalZuno;
  zunoPrice.accessories += nonElectricalZuno;
  zunoPrice.OD_TotalPremium = zunoQuoteData?.premiumDetails?.totalODPremium + zunoQuoteData?.premiumDetails?.totalAddOnPremium
  zunoPrice.paOwnerDriver = zunoQuoteData?.premiumDetails?.totalPApremium;
  zunoPrice.passengerCover = Number(passengerZuno);
  zunoPrice.driverCover = Number(driverZuno);
  zunoPrice.TP_TotalPremium = zunoQuoteData?.premiumDetails?.totalTPPremium + Number(zunoPrice?.paOwnerDriver);
  zunoPrice.OD_After_Disc =
    zunoQuoteData?.premiumDetails?.totalODPremium -
    Number(electricalZuno) -
    Number(nonElectricalZuno);

  return zunoPrice;
};

//tata bike
const getAddOnForTATABike = (quoteData, bodyData) => {
  let tataPrice = {
    paCover: false,
    zeroDep: false,
    consumables: 0,
    roadAssistance: false,
    rsaKeyProtectCombo: false,
    engineProtection: 0,
    invoiceCover: false,
    keyLockReplacement: false,
    ncbProtection: false,
    driverCover: false,
    passengerCover: false,
    paOwnerDriver: false,
    accessories: 0,
    totalPremium: false,
    basicPremium: false,
    od: false,
    basicOd: false,
    idv: false,
    nonElectrical: false,
    electrical: false,
    odExternalCngLpg: false,
    odInternalCngLpg: false,
    basicTP: false,
    tpCngLpg: false,
    tyreProtection: 0,
    dailyAllowance: false,
    personalBelogging: false,
    emiProtection: false,
    BeforeVatPremium: false,
    ncbProtection: false,
    DuePremium: false,
    TGST: 0,
    OD_TotalPremium: false,
    TP_TotalPremium: false,
    OD_After_Disc: false,
    OD_NCBAmount: 0,
  };
  const tataQuoteData = quoteData?.data?.data?.quote?.data;
  tataPrice.idv = tataQuoteData?.data?.baseIDV
  tataPrice.basicOd = Number(tataQuoteData?.premium_break_up?.total_od_premium?.od?.basic_od)
  tataPrice.OD_NCBAmount = Number(tataQuoteData?.premium_break_up?.total_od_premium?.od?.basic_od) - Number(tataQuoteData?.premium_break_up?.total_od_premium?.total_od)
  tataPrice.basicTP = tataQuoteData?.premium_break_up?.total_tp_premium?.basic_tp_prem
  tataPrice.BeforeVatPremium = tataQuoteData?.premium_break_up?.net_premium
  tataPrice.DuePremium = tataQuoteData?.premium_break_up?.final_premium
  tataPrice.TGST = Number(tataQuoteData?.premium_break_up?.sgst_prem) + Number(tataQuoteData?.premium_break_up?.cgst_prem)
  tataPrice.zeroDep = tataQuoteData?.premium_break_up?.total_addOns?.dep_reimburse_prem > 0 ? tataQuoteData?.premium_break_up?.total_addOns?.dep_reimburse_prem : false
  tataPrice.OD_TotalPremium = Number(tataQuoteData?.premium_break_up?.total_od_premium?.total_od) + Number(tataQuoteData?.premium_break_up?.total_addOns?.dep_reimburse_prem)
  tataPrice.paCover = tataQuoteData?.premium_break_up?.total_tp_premium?.pa_cover_prem > 0 ? tataQuoteData?.premium_break_up?.total_tp_premium?.pa_cover_prem : false
  tataPrice.TP_TotalPremium = tataQuoteData?.premium_break_up?.total_tp_premium?.total_tp_prem
  tataPrice.OD_After_Disc = Number(tataQuoteData?.premium_break_up?.total_od_premium?.total_od)
  return tataPrice;
};

export const carDetailsforProposal = (companyName, quoteData, bodyData) => {
  let quote = {};
  if (companyName === SBI && quoteData?.data?.body?.data?.quote) {
    let sbiTotalPriceData = getAddOnForSBI(quoteData);
    quote = [quoteData?.data?.body?.data?.quote].map((data) => {
      return {
        make: bodyData?.data?.make,
        model: bodyData?.data?.model,
        varient: bodyData?.data?.varient,
        cc: bodyData?.data?.cc,
        od: sbiTotalPriceData?.od,
        basicOd: sbiTotalPriceData?.basicOd,
        totalAddon: "",
        totalTp: "",
        basicTp: sbiTotalPriceData?.basicTP,
        netPayble: sbiTotalPriceData?.BeforeVatPremium,
        gst: "",
        grossPremium: sbiTotalPriceData?.DuePremium,
        idv: sbiTotalPriceData?.idv,
        DuePremium: sbiTotalPriceData?.DuePremium,
        TGST: sbiTotalPriceData?.TGST,
        OD_TotalPremium: sbiTotalPriceData?.OD_TotalPremium,
        TP_TotalPremium: sbiTotalPriceData?.TP_TotalPremium,
        OD_After_Disc: sbiTotalPriceData?.OD_After_Disc,
        OD_NCBAmount: sbiTotalPriceData?.OD_NCBAmount,
        zeroDep: sbiTotalPriceData?.zeroDep,
        roadAssistance: sbiTotalPriceData.roadAssistance,
        consumables: sbiTotalPriceData?.consumables,
        engineProtection: sbiTotalPriceData?.engineProtection,
        tyreProtection: sbiTotalPriceData?.tyreProtection,
        invoiceCover: sbiTotalPriceData?.invoiceCover,
        keyLockReplacement: sbiTotalPriceData?.keyLockReplacement,
        ncbProtection: sbiTotalPriceData?.ncbProtection,
        dailyAllowance: sbiTotalPriceData?.dailyAllowance,
        personalBelogging: sbiTotalPriceData?.personalBelogging,
        emiProtection: sbiTotalPriceData?.emiProtection,
        basicTP: sbiTotalPriceData?.basicTP,
        paCover: sbiTotalPriceData?.paCover,
        paOwnerDriver: sbiTotalPriceData?.paOwnerDriver,
        driverCover: sbiTotalPriceData?.driverCover,
        passengerCover: sbiTotalPriceData?.passengerCover,
        accessories: sbiTotalPriceData?.accessories,
        odExternalCngLpg: sbiTotalPriceData?.odExternalCngLpg,
        odInternalCngLpg: sbiTotalPriceData?.odInternalCngLpg,
        tpCngLpg: sbiTotalPriceData?.tpCngLpg,
        paidDriver: sbiTotalPriceData?.paidDriver,
      };
    });
  } else if (companyName === DIGIT && quoteData?.data?.data?.quote) {
    let digitTotalPriceData = getAddOnForDigit(quoteData);
    quote = [quoteData?.data?.body?.data?.quote].map((data) => {
      return {
        make: bodyData?.data?.make,
        model: bodyData?.data?.model,
        varient: bodyData?.data?.varient,
        cc: bodyData?.data?.cc,
        od: digitTotalPriceData?.od,
        basicOd: digitTotalPriceData?.basicOd,
        totalAddon: "",
        totalTp: "",
        basicTp: digitTotalPriceData?.basicTP,
        netPayble: digitTotalPriceData?.BeforeVatPremium,
        gst: "",
        grossPremium: digitTotalPriceData?.DuePremium,
        idv: digitTotalPriceData?.idv,
        DuePremium: digitTotalPriceData?.DuePremium,
        TGST: digitTotalPriceData?.TGST,
        OD_TotalPremium: digitTotalPriceData?.OD_TotalPremium,
        TP_TotalPremium: digitTotalPriceData?.TP_TotalPremium,
        OD_After_Disc: digitTotalPriceData?.OD_After_Disc,
        OD_NCBAmount: digitTotalPriceData?.OD_NCBAmount,
        zeroDep: digitTotalPriceData?.zeroDep,
        roadAssistance: digitTotalPriceData.roadAssistance,
        consumables: digitTotalPriceData?.consumables,
        engineProtection: digitTotalPriceData?.engineProtection,
        tyreProtection: digitTotalPriceData?.tyreProtection,
        invoiceCover: digitTotalPriceData?.invoiceCover,
        keyLockReplacement: digitTotalPriceData?.keyLockReplacement,
        ncbProtection: digitTotalPriceData?.ncbProtection,
        dailyAllowance: digitTotalPriceData?.dailyAllowance,
        personalBelogging: digitTotalPriceData?.personalBelogging,
        emiProtection: digitTotalPriceData?.emiProtection,
        basicTP: digitTotalPriceData?.basicTP,
        paCover: digitTotalPriceData?.paCover,
        paOwnerDriver: digitTotalPriceData?.paOwnerDriver,
        driverCover: digitTotalPriceData?.driverCover,
        passengerCover: digitTotalPriceData?.passengerCover,
        accessories: digitTotalPriceData.accessories,
        odExternalCngLpg: digitTotalPriceData?.odExternalCngLpg,
        tpCngLpg: digitTotalPriceData?.tpCngLpg,
      };
    });
  } else if (companyName === SHREERAM && quoteData?.data?.data?.quote) {
    let shreeRamTotalPriceData = getAddOnForShreeRam(quoteData);
    quote = [quoteData?.data?.body?.data?.quote].map((data) => {
      return {
        make: bodyData?.data?.make,
        model: bodyData?.data?.model,
        varient: bodyData?.data?.varient,
        cc: bodyData?.data?.cc,
        od: shreeRamTotalPriceData?.od,
        basicOd: shreeRamTotalPriceData?.basicOd,
        totalAddon: "",
        totalTp: "",
        basicTp: shreeRamTotalPriceData?.basicTP,
        netPayble: shreeRamTotalPriceData?.BeforeVatPremium,
        gst: "",
        grossPremium: shreeRamTotalPriceData?.DuePremium,
        idv: shreeRamTotalPriceData?.idv,
        DuePremium: shreeRamTotalPriceData?.DuePremium,
        TGST: shreeRamTotalPriceData?.TGST,
        OD_TotalPremium: shreeRamTotalPriceData?.OD_TotalPremium,
        TP_TotalPremium: shreeRamTotalPriceData?.TP_TotalPremium,
        deTarrifDiscount: shreeRamTotalPriceData.tarrifDicount,
        OD_After_Disc: shreeRamTotalPriceData?.OD_After_Disc,
        OD_NCBAmount: shreeRamTotalPriceData?.OD_NCBAmount,
        zeroDep: shreeRamTotalPriceData?.zeroDep,
        llEmployee: shreeRamTotalPriceData?.llEmployee,
        odExternalCngLpg: shreeRamTotalPriceData?.odExternalCngLpg,
        tpCngLpg: shreeRamTotalPriceData?.tpCngLpg,
        roadAssistance: shreeRamTotalPriceData.roadAssistance,
        consumables: shreeRamTotalPriceData?.consumables,
        engineProtection: shreeRamTotalPriceData?.engineProtection,
        tyreProtection: shreeRamTotalPriceData?.tyreProtection,
        invoiceCover: shreeRamTotalPriceData?.invoiceCover,
        keyLockReplacement: shreeRamTotalPriceData?.keyLockReplacement,
        ncbProtection: shreeRamTotalPriceData?.ncbProtection,
        dailyAllowance: shreeRamTotalPriceData?.dailyAllowance,
        personalBelogging: shreeRamTotalPriceData?.personalBelogging,
        emiProtection: shreeRamTotalPriceData?.emiProtection,
        basicTP: shreeRamTotalPriceData?.basicTP,
        paCover: shreeRamTotalPriceData?.paCover,
        paOwnerDriver: shreeRamTotalPriceData?.paOwnerDriver,
        driverCover: shreeRamTotalPriceData?.driverCover,
        passengerCover: shreeRamTotalPriceData?.passengerCover,
        accessories: shreeRamTotalPriceData.accessories,
        shreeRamProtect: shreeRamTotalPriceData.shreeRamProtect
      };
    });
  } else if (companyName === ICICI && quoteData?.data?.data?.quote) {
    let iciciTotalPriceData = getAddOnForICICI(quoteData);
    quote = [quoteData?.data?.body?.data?.quote].map((data) => {
      return {
        make: bodyData?.data?.make,
        model: bodyData?.data?.model,
        varient: bodyData?.data?.varient,
        cc: bodyData?.data?.cc,
        od: iciciTotalPriceData?.od,
        basicOd: iciciTotalPriceData?.basicOd,
        totalAddon: "",
        totalTp: "",
        basicTp: iciciTotalPriceData?.basicTP,
        netPayble: iciciTotalPriceData?.BeforeVatPremium,
        gst: "",
        grossPremium: iciciTotalPriceData?.DuePremium,
        idv: iciciTotalPriceData?.idv,
        DuePremium: iciciTotalPriceData?.DuePremium,
        TGST: iciciTotalPriceData?.TGST,
        OD_TotalPremium: iciciTotalPriceData?.OD_TotalPremium,
        TP_TotalPremium: iciciTotalPriceData?.TP_TotalPremium,
        deTarrifDiscount: iciciTotalPriceData.tarrifDicount,
        OD_After_Disc: iciciTotalPriceData?.OD_After_Disc,
        OD_NCBAmount: iciciTotalPriceData?.OD_NCBAmount,
        zeroDep: iciciTotalPriceData?.zeroDep,
        llEmployee: iciciTotalPriceData?.llEmployee,
        odExternalCngLpg: iciciTotalPriceData?.odExternalCngLpg,
        tpCngLpg: iciciTotalPriceData?.tpCngLpg,
        roadAssistance: iciciTotalPriceData.roadAssistance,
        consumables: iciciTotalPriceData?.consumables,
        engineProtection: iciciTotalPriceData?.engineProtection,
        tyreProtection: iciciTotalPriceData?.tyreProtection,
        invoiceCover: iciciTotalPriceData?.invoiceCover,
        keyLockReplacement: iciciTotalPriceData?.keyLockReplacement,
        ncbProtection: iciciTotalPriceData?.ncbProtection,
        dailyAllowance: iciciTotalPriceData?.dailyAllowance,
        personalBelogging: iciciTotalPriceData?.personalBelogging,
        emiProtection: iciciTotalPriceData?.emiProtection,
        basicTP: iciciTotalPriceData?.basicTP,
        paCover: iciciTotalPriceData?.paCover,
        paOwnerDriver: iciciTotalPriceData?.paOwnerDriver,
        driverCover: iciciTotalPriceData?.driverCover,
        passengerCover: iciciTotalPriceData?.passengerCover,
        accessories: iciciTotalPriceData.accessories,
      };
    });
  } else if (companyName === ZUNO && quoteData?.data?.data?.quote) {
    let zunoTotalPriceData = getAddOnForZUNO(quoteData);
    quote = [quoteData?.data?.body?.data?.quote].map((data) => {
      return {
        make: bodyData?.data?.make,
        model: bodyData?.data?.model,
        varient: bodyData?.data?.varient,
        cc: bodyData?.data?.cc,
        od: zunoTotalPriceData?.od,
        basicOd: zunoTotalPriceData?.basicOd,
        totalAddon: "",
        totalTp: "",
        basicTp: zunoTotalPriceData?.basicTP,
        netPayble: zunoTotalPriceData?.BeforeVatPremium,
        gst: "",
        grossPremium: (zunoTotalPriceData?.DuePremium).toFixed(2),
        idv: zunoTotalPriceData?.idv,
        DuePremium: (zunoTotalPriceData?.DuePremium).toFixed(2),
        TGST: zunoTotalPriceData?.TGST,
        OD_TotalPremium: zunoTotalPriceData?.OD_TotalPremium,
        TP_TotalPremium: zunoTotalPriceData?.TP_TotalPremium,
        deTarrifDiscount: zunoTotalPriceData.tarrifDicount,
        OD_After_Disc: zunoTotalPriceData?.OD_After_Disc,
        OD_NCBAmount: zunoTotalPriceData?.OD_NCBAmount,
        zeroDep: zunoTotalPriceData?.zeroDep,
        llEmployee: zunoTotalPriceData?.llEmployee,
        odExternalCngLpg: zunoTotalPriceData?.odExternalCngLpg,
        odInternalCngLpg: zunoTotalPriceData?.odInternalCngLpg,
        tpCngLpg: zunoTotalPriceData?.tpCngLpg,
        roadAssistance: zunoTotalPriceData.roadAssistance,
        consumables: zunoTotalPriceData?.consumables,
        engineProtection: zunoTotalPriceData?.engineProtection,
        tyreProtection: zunoTotalPriceData?.tyreProtection,
        invoiceCover: zunoTotalPriceData?.invoiceCover,
        keyLockReplacement: zunoTotalPriceData?.keyLockReplacement,
        ncbProtection: zunoTotalPriceData?.ncbProtection,
        dailyAllowance: zunoTotalPriceData?.dailyAllowance,
        personalBelogging: zunoTotalPriceData?.personalBelogging,
        emiProtection: zunoTotalPriceData?.emiProtection,
        basicTP: zunoTotalPriceData?.basicTP,
        paCover: zunoTotalPriceData?.paCover,
        paOwnerDriver: zunoTotalPriceData?.paOwnerDriver,
        driverCover: zunoTotalPriceData?.driverCover,
        passengerCover: zunoTotalPriceData?.passengerCover,
        accessories: zunoTotalPriceData.accessories,
      };
    });
  } else if (companyName === ACKO && quoteData?.data?.data?.quote) {
    let ackoTotalPriceData = getAddOnForACKO(quoteData, bodyData);
    quote = [quoteData?.data?.data?.quote?.insured].map((data) => {
      return {
        make: bodyData?.data?.make,
        model: bodyData?.data?.model,
        varient: bodyData?.data?.varient,
        cc: bodyData?.data?.cc,
        od: ackoTotalPriceData?.od,
        basicOd: ackoTotalPriceData?.basicOd,
        totalAddon: "",
        totalTp: "",
        basicTp: ackoTotalPriceData?.basicTP,
        netPayble: ackoTotalPriceData?.BeforeVatPremium,
        gst: "",
        grossPremium: ackoTotalPriceData?.DuePremium,
        idv: ackoTotalPriceData?.idv,
        DuePremium: ackoTotalPriceData?.DuePremium,
        TGST: ackoTotalPriceData?.TGST,
        OD_TotalPremium: ackoTotalPriceData?.OD_TotalPremium,
        TP_TotalPremium: ackoTotalPriceData?.TP_TotalPremium,
        deTarrifDiscount: ackoTotalPriceData.tarrifDicount,
        OD_After_Disc: ackoTotalPriceData?.OD_After_Disc,
        OD_NCBAmount: ackoTotalPriceData?.OD_NCBAmount,
        zeroDep: ackoTotalPriceData?.zeroDep,
        llEmployee: ackoTotalPriceData?.llEmployee,
        odExternalCngLpg: ackoTotalPriceData?.odExternalCngLpg,
        tpCngLpg: ackoTotalPriceData?.tpCngLpg,
        roadAssistance: ackoTotalPriceData.roadAssistance,
        consumables: ackoTotalPriceData?.consumables,
        engineProtection: ackoTotalPriceData?.engineProtection,
        tyreProtection: ackoTotalPriceData?.tyreProtection,
        invoiceCover: ackoTotalPriceData?.invoiceCover,
        keyLockReplacement: ackoTotalPriceData?.keyLockReplacement,
        rsaKeyProtectCombo: ackoTotalPriceData.rsaKeyProtectCombo,
        ncbProtection: ackoTotalPriceData?.ncbProtection,
        dailyAllowance: ackoTotalPriceData?.dailyAllowance,
        personalBelogging: ackoTotalPriceData?.personalBelogging,
        emiProtection: ackoTotalPriceData?.emiProtection,
        basicTP: ackoTotalPriceData?.basicTP,
        paCover: ackoTotalPriceData?.paCover,
        paOwnerDriver: ackoTotalPriceData?.paOwnerDriver,
        driverCover: ackoTotalPriceData?.driverCover,
        passengerCover: ackoTotalPriceData?.passengerCover,
        accessories: ackoTotalPriceData.accessories,
        addOnsData: ackoTotalPriceData.addOnsData,
        planId: ackoTotalPriceData.planID,
      };
    });
  }

  if (companyName == DIGIT) {
    console.log("privateqoute", quote[0]);
  }
  return quote[0];
};

export const bikeDetailsforProposal = (companyName, quoteData, bodyData) => {
  let quote = {};
  if (companyName === ACKO && quoteData?.data?.data?.quote) {
    let ackoTotalPriceData = getAddOnForACKOBike(quoteData, bodyData);
    quote = [quoteData?.data?.data?.quote?.insured].map((data) => {
      return {
        make: bodyData?.data?.make,
        model: bodyData?.data?.model,
        varient: bodyData?.data?.varient,
        cc: bodyData?.data?.cc,
        od: ackoTotalPriceData?.od,
        basicOd: ackoTotalPriceData?.basicOd,
        totalAddon: "",
        totalTp: "",
        basicTp: ackoTotalPriceData?.basicTP,
        netPayble: ackoTotalPriceData?.BeforeVatPremium,
        gst: "",
        grossPremium: ackoTotalPriceData?.DuePremium,
        idv: ackoTotalPriceData?.idv,
        DuePremium: ackoTotalPriceData?.DuePremium,
        TGST: ackoTotalPriceData?.TGST,
        OD_TotalPremium: ackoTotalPriceData?.OD_TotalPremium,
        TP_TotalPremium: ackoTotalPriceData?.TP_TotalPremium,
        deTarrifDiscount: ackoTotalPriceData.tarrifDicount,
        OD_After_Disc: ackoTotalPriceData?.OD_After_Disc,
        OD_NCBAmount: ackoTotalPriceData?.OD_NCBAmount,
        zeroDep: ackoTotalPriceData?.zeroDep,
        llEmployee: ackoTotalPriceData?.llEmployee,
        odExternalCngLpg: ackoTotalPriceData?.odExternalCngLpg,
        tpCngLpg: ackoTotalPriceData?.tpCngLpg,
        roadAssistance: ackoTotalPriceData.roadAssistance,
        consumables: ackoTotalPriceData?.consumables,
        engineProtection: ackoTotalPriceData?.engineProtection,
        tyreProtection: ackoTotalPriceData?.tyreProtection,
        invoiceCover: ackoTotalPriceData?.invoiceCover,
        keyLockReplacement: ackoTotalPriceData?.keyLockReplacement,
        rsaKeyProtectCombo: ackoTotalPriceData.rsaKeyProtectCombo,
        ncbProtection: ackoTotalPriceData?.ncbProtection,
        dailyAllowance: ackoTotalPriceData?.dailyAllowance,
        personalBelogging: ackoTotalPriceData?.personalBelogging,
        emiProtection: ackoTotalPriceData?.emiProtection,
        basicTP: ackoTotalPriceData?.basicTP,
        paCover: ackoTotalPriceData?.paCover,
        paOwnerDriver: ackoTotalPriceData?.paOwnerDriver,
        driverCover: ackoTotalPriceData?.driverCover,
        passengerCover: ackoTotalPriceData?.passengerCover,
        accessories: ackoTotalPriceData.accessories,
        addOnsData: ackoTotalPriceData.addOnsData,
        planId: ackoTotalPriceData.planID,
      };
    });
  } else if (companyName === TATA && quoteData?.data?.data?.quote) {
    let tataTotalPriceData = getAddOnForTATABike(quoteData, bodyData);
    quote = [quoteData?.data?.data?.quote?.data].map((data) => {
      return {
        make: bodyData?.data?.make,
        model: bodyData?.data?.model,
        varient: bodyData?.data?.varient,
        cc: bodyData?.data?.cc,
        od: tataTotalPriceData?.od,
        basicOd: tataTotalPriceData?.basicOd,
        totalAddon: "",
        totalTp: "",
        basicTp: tataTotalPriceData?.basicTP,
        netPayble: tataTotalPriceData?.BeforeVatPremium,
        gst: "",
        grossPremium: tataTotalPriceData?.DuePremium,
        idv: tataTotalPriceData?.idv,
        DuePremium: tataTotalPriceData?.DuePremium,
        TGST: tataTotalPriceData?.TGST,
        OD_TotalPremium: tataTotalPriceData?.OD_TotalPremium,
        TP_TotalPremium: tataTotalPriceData?.TP_TotalPremium,
        deTarrifDiscount: tataTotalPriceData.tarrifDicount,
        OD_After_Disc: tataTotalPriceData?.OD_After_Disc,
        OD_NCBAmount: tataTotalPriceData?.OD_NCBAmount,
        zeroDep: tataTotalPriceData?.zeroDep,
        llEmployee: tataTotalPriceData?.llEmployee,
        odExternalCngLpg: tataTotalPriceData?.odExternalCngLpg,
        tpCngLpg: tataTotalPriceData?.tpCngLpg,
        roadAssistance: tataTotalPriceData.roadAssistance,
        consumables: tataTotalPriceData?.consumables,
        engineProtection: tataTotalPriceData?.engineProtection,
        tyreProtection: tataTotalPriceData?.tyreProtection,
        invoiceCover: tataTotalPriceData?.invoiceCover,
        keyLockReplacement: tataTotalPriceData?.keyLockReplacement,
        rsaKeyProtectCombo: tataTotalPriceData.rsaKeyProtectCombo,
        ncbProtection: tataTotalPriceData?.ncbProtection,
        dailyAllowance: tataTotalPriceData?.dailyAllowance,
        personalBelogging: tataTotalPriceData?.personalBelogging,
        emiProtection: tataTotalPriceData?.emiProtection,
        basicTP: tataTotalPriceData?.basicTP,
        paCover: tataTotalPriceData?.paCover,
        paOwnerDriver: tataTotalPriceData?.paCover,
        driverCover: tataTotalPriceData?.driverCover,
        passengerCover: tataTotalPriceData?.passengerCover,
        accessories: tataTotalPriceData.accessories,
      };
    });
  }
  return quote[0];
};

export const calculateMaxAndMinIdv = (maxIdvArray, minIdvArray) => { };

export const calculateDate = (
  prevoiusPolicyType,
  prevoiusYearEndDate,
  typeOfBusiness,
  regDate
) => {
  if (
    prevoiusPolicyType == "Comprehensive" ||
    prevoiusPolicyType == "Third Party"
  ) {
    let preYearStartDate = new Date(prevoiusYearEndDate); // prevoius year start date for comperhensive / TP
    preYearStartDate.setFullYear(preYearStartDate.getFullYear() - 1);
    preYearStartDate.setDate(preYearStartDate.getDate() + 1);

    let effectiveStartDate = new Date(prevoiusYearEndDate); // current policy start date for comperhensive / TP
    effectiveStartDate.setDate(effectiveStartDate.getDate() + 1);

    let effectiveEndDate = new Date(effectiveStartDate); // current policy end date for comperhensive / TP
    effectiveEndDate.setFullYear(effectiveEndDate.getFullYear() + 1);
    effectiveEndDate.setDate(effectiveEndDate.getDate() - 1);
    return {
      prevoiusYearEndDate,
      preYearStartDate,
      effectiveStartDate,
      effectiveEndDate,
    };
  } else if (
    prevoiusPolicyType == "Bundled (3Y TP + 1Y OD)" ||
    prevoiusPolicyType === "Bundled"
  ) {
    let prevoiusPolicyTPEndDate = new Date(regDate); // current policy start date for bundled
    prevoiusPolicyTPEndDate.setFullYear(
      prevoiusPolicyTPEndDate.getFullYear() + 3
    );
    prevoiusPolicyTPEndDate.setDate(prevoiusPolicyTPEndDate.getDate());

    return {
      prevoiusYearEndDate,
      prevoiusPolicyTPEndDate,
    };
  }

  // for new car
  else if (typeOfBusiness?.toUpperCase() === "NEW") {
    let effectiveStartDate = new Date(regDate);
    let tpEndDate = new Date(effectiveStartDate);
    tpEndDate.setFullYear(tpEndDate.getFullYear() + 3);
    tpEndDate.setDate(tpEndDate.getDate() - 1);

    let effectiveEndDate = new Date(effectiveStartDate); // end date
    effectiveEndDate.setFullYear(effectiveEndDate.getFullYear() + 1);
    effectiveEndDate.setDate(effectiveEndDate.getDate() - 1);
    return {
      effectiveStartDate,
      effectiveEndDate, // od end date
      tpEndDate,
    };
  }
};

export const calculateDateBike = (
  prevoiusPolicyType,
  prevoiusYearEndDate,
  typeOfBusiness,
  regDate
) => {
  if (
    prevoiusPolicyType == "Comprehensive" ||
    prevoiusPolicyType == "Third Party"
  ) {
    let preYearStartDate = new Date(prevoiusYearEndDate); // prevoius year start date for comperhensive / TP
    preYearStartDate.setFullYear(preYearStartDate.getFullYear() - 1);
    preYearStartDate.setDate(preYearStartDate.getDate() + 1);

    let effectiveStartDate = new Date(prevoiusYearEndDate); // current policy start date for comperhensive / TP
    effectiveStartDate.setDate(effectiveStartDate.getDate() + 1);

    let effectiveEndDate = new Date(effectiveStartDate); // current policy end date for comperhensive / TP
    effectiveEndDate.setFullYear(effectiveEndDate.getFullYear() + 1);
    effectiveEndDate.setDate(effectiveEndDate.getDate() - 1);
    return {
      prevoiusYearEndDate,
      preYearStartDate,
      effectiveStartDate,
      effectiveEndDate,
    };
  } else if (
    prevoiusPolicyType == "Bundled (3Y TP + 1Y OD)" ||
    prevoiusPolicyType === "Bundled"
  ) {
    let prevoiusPolicyTPEndDate = new Date(regDate); // current policy start date for bundled
    prevoiusPolicyTPEndDate.setFullYear(
      prevoiusPolicyTPEndDate.getFullYear() + 5
    );
    prevoiusPolicyTPEndDate.setDate(prevoiusPolicyTPEndDate.getDate());

    return {
      prevoiusYearEndDate,
      prevoiusPolicyTPEndDate,
    };
  }

  // for new car
  else if (typeOfBusiness?.toUpperCase() === "NEW") {
    let effectiveStartDate = new Date(regDate);
    let tpEndDate = new Date(effectiveStartDate);
    tpEndDate.setFullYear(tpEndDate.getFullYear() + 3);
    tpEndDate.setDate(tpEndDate.getDate() - 1);

    let effectiveEndDate = new Date(effectiveStartDate); // end date
    effectiveEndDate.setFullYear(effectiveEndDate.getFullYear() + 1);
    effectiveEndDate.setDate(effectiveEndDate.getDate() - 1);
    return {
      effectiveStartDate,
      effectiveEndDate, // od end date
      tpEndDate,
    };
  }
};

export const sbiAutoKycDataMethod = (autoKycResponse, cityList) => {
  if (
    autoKycResponse?.data?.body?.data?.data?.A99ResponseData?.CKYCDownloadResult
      ?.CKYCStatus === "CKYCSuccess"
  ) {
    let {
      CKYCStatus,
      CKYCCorAdd1,
      CKYCCorAdd2,
      CKYCCorAdd3,
      CKYCCorAddCity,
      CKYCCorAddDistrict,
      CKYCCorAddPin,
      CKYCCorAddState,
      CKYCDOB,
      CKYCFullName,
      CKYCFatherFirstName,
      CKYCFatherLastName,
      CKYCNamePrefix,
      CKYCFatherNamePrefix,
      CKYCGender,
      CKYCPAN,
      CKYCPerAddState,
      CKYCEmailAdd,
      CKYCMobileNumber,
      CKYCFirstName,
      CKYCLastName,
      CKYCNumber,
      RequestId,
    } =
      autoKycResponse?.data?.body?.data?.data?.A99ResponseData
        ?.CKYCDownloadResult;

    let kycData = {
      kycStatus: CKYCStatus,
      companyName: CKYCFullName,
      firstName: CKYCFirstName,
      lastName: CKYCLastName,
      email: CKYCEmailAdd,
      phoneNumber: CKYCMobileNumber,
      addressOne: CKYCCorAdd1,
      addressTwo: CKYCCorAdd2 + " " + CKYCCorAdd3 || "",
      dob: new Date(CKYCDOB),
      state: CKYCPerAddState,
      city: CKYCCorAddCity,
      pincode: CKYCCorAddPin,
      kycPanNumber: CKYCPAN,
      gender: CKYCGender,
      kycTitle: CKYCNamePrefix,
      district: CKYCCorAddDistrict,
      stateCode: CKYCCorAddState,
      fatherTitle: CKYCFatherNamePrefix,
      fatherFirstName: CKYCFatherFirstName,
      fatherLastName: CKYCFatherLastName,
      closestMatchCity: closestMatch(CKYCCorAddCity, cityList),
      ckycResponseNumber: CKYCNumber,
      ckycUniqueId: RequestId,
    };
    return kycData;
  }
};

export const generateUnique16DigitNumber = () => {
  const uniqueNumber = `${Date.now()}${Math.floor(Math.random() * 10000)}`
    .padEnd(16, "0")
    .slice(0, 16);
  return uniqueNumber;
};

export const generateCutomerId = (name) => {
  const uniqueNumber = `${Date.now()}${Math.floor(Math.random() * 10000)}`
    .padEnd(9, "0")
    .slice(0, 9);
  name = name.slice(0, 4);
  const orderId = "BMI" + name.toUpperCase() + uniqueNumber;
  return orderId;
};

export const generateCheckSum = (body) => {
  const hmac = CryptoJS.HmacSHA256(body, "m3u4ubBC6Xmo5iOXxnfCBCyIq72vwmKs");
  let checkSum = CryptoJS.enc.Hex.stringify(hmac);
  return checkSum.toUpperCase();
};

export const findKeyValue = (obj, key) => {
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      if (prop === key) {
        return obj[prop];
      } else if (typeof obj[prop] === 'object') {
        const result = findKeyValue(obj[prop], key);
        if (result !== undefined) {
          return result;
        }
      }
    }
  }
};

// BUSINESSTYPE

export const ROLLOVER = "rollover";
export const NEW = "New";
export const RENEWAL = "Renewal";
export const FINDMYCAR = "FindMyCar";
export const FINDMYBIKE = "FindMyBike";

// COMPANY NAME

export const SBI = "sbi";
export const DIGIT = "digit";
export const SHREERAM = "shreeram";
export const ZUNO = "zuno";
export const RAHEJA = "raheja";
export const ICICI = "icici";
export const ACKO = "acko";
export const TATA = "tata";

// current year declaration

export const currentYear = new Date().getFullYear();

export const rtoState = [
  {
    state_name: "ANDAMAN AND NICOBAR ISLANDS",
    stateId: "AN",
  },
  {
    state_name: "ANDHRA PRADESH",
    stateId: "AP",
  },
  {
    state_name: "ARUNACHAL PRADESH",
    stateId: "AR",
  },
  {
    state_name: "ASSAM",
    stateId: "AS",
  },
  {
    state_name: "BIHAR",
    stateId: "BR",
  },
  {
    state_name: "CHANDIGARH",
    stateId: "CG",
  },
  {
    state_name: "CHATTISGARH",
    stateId: "CH",
  },
  {
    state_name: "DADRA AND NAGAR HAVELI",
    stateId: "DN",
  },
  {
    state_name: "DAMAN AND DIU",
    stateId: "DD",
  },
  {
    state_name: "DELHI",
    stateId: "DL",
  },
  {
    state_name: "GOA",
    stateId: "GA",
  },
  {
    state_name: "GUJARAT",
    stateId: "GJ",
  },
  {
    state_name: "HARYANA",
    stateId: "HR",
  },
  {
    state_name: "HIMACHAL PRADESH",
    stateId: "HP",
  },
  {
    state_name: "JAMMU AND KASHMIR",
    stateId: "JK",
  },
  {
    state_name: "JHARKHAND",
    stateId: "JH",
  },
  {
    state_name: "KARNATAKA",
    stateId: "KA",
  },
  {
    state_name: "KERALA",
    stateId: "KL",
  },
  {
    state_name: "LADAKH",
    stateId: "LA",
  },
  {
    state_name: "LAKSHADWEEP",
    stateId: "LD",
  },
  {
    state_name: "MADHYA PRADESH",
    stateId: "MP",
  },
  {
    state_name: "MAHARASHTRA",
    stateId: "MH",
  },
  {
    state_name: "MANIPUR",
    stateId: "MN",
  },
  {
    state_name: "MEGHALAYA",
    stateId: "ML",
  },
  {
    state_name: "MIZORAM",
    stateId: "MZ",
  },
  {
    state_name: "NAGALAND",
    stateId: "NL",
  },
  {
    state_name: "ORISSA",
    stateId: "OR",
  },
  {
    state_name: "PONDICHERRY",
    stateId: "PY",
  },
  {
    state_name: "PUNJAB",
    stateId: "PB",
  },
  {
    state_name: "RAJASTHAN",
    stateId: "RJ",
  },
  {
    state_name: "SIKKIM",
    stateId: "SK",
  },
  {
    state_name: "TAMIL NADU",
    stateId: "TN",
  },
  {
    state_name: "TELANGANA",
    stateId: "TS",
  },
  {
    state_name: "TRIPURA",
    stateId: "TR",
  },
  {
    state_name: "UTTAR PRADESH",
    stateId: "UP",
  },
  {
    state_name: "UTTARAKHAND",
    stateId: "UK",
  },
  {
    state_name: "WEST BENGAL",
    stateId: "WB",
  },
];
