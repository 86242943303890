import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link, useLocation } from "react-router-dom";
import car from "../../../../Assets/Images/car.svg";
import bike from "../../../../Assets/Images/bike.svg";
import health from "../../../../Assets/Images/health.svg";
import taxi from "../../../../Assets/Images/taxi.svg";
import grayArrow from "../../../../Assets/Images/grayArrow.svg";
import "./PolicyCard.css";
import Botton from "../../../Button/Button";
import InputFields from "../../../InputFields/InputFields";
import DropDown from "../../../DropDown/DropDown";
import SearchSelect from "../../../DropDown/Search Select/SearchSelect";
import { getInsurerList } from "../../../../redux/Actions/stateDetails";
import Calender from "../../../InputFields/Calender";

const NoPoliciesFound = (props) => {
  const {
    IsUploadPolicyAttempted,
    handleUploadPolicy,
    handleInputChange,
    FormValue,
    ShowPolicyTypeList,
    setShowPolicyTypeList,
    handleUploadedPolicyInsurer,
    handleCancelPolicyUpload,
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let tabList = [
    {
      icon: car,
      product: "Car",
      insurance: "Insurance",
      content:
        "Car insurance is a type of motor policy which provides protection against theft, third-party damages, man-made and natural calamities. With car insurance, you can get financial and legal protection against the damages caused by your car. A comprehensive car insurance policy also provides protection against damages caused to your own car. The Motor Vehicles Act of 1988 makes it mandatory for every car owner to insure his car with a third-party insurance policy.",
    },
    {
      icon: bike,
      product: "Bike",
      insurance: "Insurance",
      content:
        "Bike insurance, also known as two-wheeler insurance is a type of insurance plan that provides financial protection to your bike in case it is lost, stolen, or faces any kind of damage due to an unforeseen circumstance. In fact, third-party bike insurance is a must-have insurance policy as per the Motor Vehicles Act of 1988.",
    },
    {
      icon: health,
      product: "Health",
      insurance: "Insurance",
      content:
        "Health insurance is an agreement between the insurer and the policyholder whereby an insurance company agrees to reimburse for medical costs incurred by the policyholder during the tenure of the policy. According to the policy terms, the insured may incur medical expenses if they get ill or meet an unfortunate accident that leads to treatment at the hospital.",
    },
    {
      icon: taxi,
      product: "Taxi",
      insurance: "Insurance",
      content:
        "Taxi insurance is a commercial auto insurance specifically designed for taxi operators. It provides coverage for accidents, vehicle damage, and liability, addressing the unique risks associated with running a taxi or rideshare service. Compliance with local regulations is crucial for taxi operators, making this insurance a vital aspect of their business operations.",
    },
  ];

  let policyTypeList = [
    { value: "Comprehensive", label: "Comprehensive" },
    { value: "Third Party", label: "Third Party" },
    { value: "Bundled (3Y TP + 1Y OD)", label: "Bundled" },
  ];

  useEffect(() => {
    dispatch(getInsurerList("insurename"));
  }, []);

  const getInsurerListResponse = useSelector((state) => {
    return state?.getInsurerDetails?.data?.data?.result;
  });

  const insurerList = getInsurerListResponse?.map((data) => {
    return {
      label: data?.name_of_insurance_company,
      value: data?.name_of_insurance_company,
    };
  });

  const handleHomePage = () => {
    navigate("/");
  };

  return (
    <>
      {!IsUploadPolicyAttempted && (
        <section className="NoPoliciesFound-container">
          {tabList.map((data, key) => (
            <div key={key} className="NoPoliciesFound-tabs">
              <div className="NoPoliciesFound-tabs-title">
                <img src={data.icon} alt="" srcSet="" />
                <p>
                  <span>{data.product}</span> {data.insurance}
                </p>
              </div>
              <div className="NoPoliciesFound-tabs-content">
                <p>{data.content}</p>
              </div>
              <div className="NoPoliciesFound-tabs-btns">
                <Botton
                  text="Check Premium"
                  className="button btn_pForm btn_clientAcc  "
                  event={handleHomePage}
                />
                <Botton
                  text="Upload Policy"
                  className="button btn_pForm btn_clientAcc  secondry_btn"
                  event={(e) => handleUploadPolicy(data.product)}
                />
              </div>
            </div>
          ))}
        </section>
      )}

      {IsUploadPolicyAttempted && (
        <div className="ProfileCard-container">
          <div className="input_group_pform">
            <div className="inputGropOne_form inputGrop ">
              <DropDown
                dropDownMenu={policyTypeList}
                value={FormValue?.uploadedPolicyType}
                name="uploadedPolicyType"
                classNameForHead="headTxtClassPform"
                Head="Policy Type"
                label="Policy Type"
                arrowInGray={grayArrow}
                inputId="cc"
                clickEvent={(e) => console.log(e)}
                handleChangeInput={(e) => console.log(e)}
                headClick={(e) => setShowPolicyTypeList(!ShowPolicyTypeList)}
                revealOptions={ShowPolicyTypeList}
                afterTrueClass="dropDown_container NoBorderRadius"
                className="dropDown_container"
                valueSelectionEvent={handleInputChange}
                revealOptionsClasses=" dropDownOptions showdropDownOptions "
                classNameTwo="dropDownOptions"
                dropDownHeadClass="dropDownHead"
              />
            </div>
            <div className="inputGropOne_form inputGrop ">
              <InputFields
                value={FormValue.uploadedPolicyNumber}
                placeholder="Enter Policy Number"
                id="uploadedPolicyNumber"
                type="text"
                name="uploadedPolicyNumber"
                onChangeEvent={(e) => handleInputChange(e)}
              />
            </div>
          </div>

          <div className="input_group_pform">
            <div className="inputGropOne_form inputGrop ">
              <SearchSelect
                optionList={insurerList}
                handleSelect={handleUploadedPolicyInsurer}
                value={FormValue?.uploadedPolicyInsurer}
                placeholder="Previous Policy Insurer"
                labelClass="modifiy-label-css"
                label="Insurance Company"
                // errState={errors?.prevPolicyNameInsurer ? true : false}
                // errMsg={errors?.prevPolicyNameInsurer}
                // errClassName="input-err"
              />
            </div>
            <div className="inputGropOne_form inputGrop ">
              <div className="inputGropOne_form inputGrop inputGrop-profile ">
                <div className="inputGropOne_form inputGrop inputGropKyc-custom ">
                  <p>Upload Document</p>
                  <input
                    type="file"
                    className="hideRealUploadBtn"
                    id="uploadImg-Aadhar"
                    accept="image/*, application/pdf"
                    name="uploadedPolicyDocument"
                    onChange={(e) => handleInputChange(e)}
                    hidden
                  />
                  <label className="customLabel-kyc" htmlFor="uploadImg-Aadhar">
                    <p className="btn-kyc-">upload</p>
                  </label>
                </div>
                <p>File cannot be more then 1MB. (JPG or PNG)</p>
              </div>
            </div>
          </div>

          <div className="input_group_pform">
            <div className="inputGropOne_form inputGrop ">
              <Calender
                className="pForm_datePicker"
                placeholder="Select Policy Start Date"
                name="uploadedPolicyStartDate"
                id="uploadedPolicyStartDate"
                value={FormValue.uploadedPolicyStartDate}
                label="Policy Start Date"
                changeEvent={(e) => handleInputChange(e)}
              />
            </div>
            <div className="inputGropOne_form inputGrop ">
              <Calender
                className="pForm_datePicker"
                placeholder="Select Policy End Date"
                name="uploadedPolicyEndDate"
                id="uploadedPolicyEndDate"
                value={FormValue.uploadedPolicyEndDate}
                label="Policy End Date"
                changeEvent={(e) => handleInputChange(e)}
              />
            </div>
          </div>

          <section className="btnContainer_pform">
            <Botton
              text="Cancel"
              className="button btn_pForm  secondry_btn"
              event={handleCancelPolicyUpload}
            />
            <Botton
              text="Upload"
              className="button btn_pForm"
              event={(e) => alert("Upload Btn Clicked")}
            />
          </section>
        </div>
      )}
    </>
  );
};

export default NoPoliciesFound;
