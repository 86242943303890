import { createAsyncThunk } from "@reduxjs/toolkit";

export const ackoQuickQuoteAction = createAsyncThunk(
  "ackoQuikQuote",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_ACKO_QUICK_QUOTE_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);


export const ackoProposalAction = createAsyncThunk(
  "ackoProposal",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_ACKO_PROPOSAL_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const ackopdfDownloadAction = createAsyncThunk(
  "ackopdfDownload",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_ACKO_PDF_DOWNLOAD_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);