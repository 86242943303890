import React, { useEffect, useState } from "react";
import user from "../../Assets/Images/userIcon.svg";
import InputFields from "../InputFields/InputFields";
import Botton from "../Button/Button";
import DropDown from "../DropDown/DropDown";
import grayArrow from "../../Assets/Images/grayArrow.svg";
import loader2 from "../../Assets/Loader/Loader.gif";
import "../RegNumberForm/loaderMain.css";
import { useLocation, Link, useNavigate, useHistory } from "react-router-dom";
import backIcon from "../../Assets/Images/backIcon.svg";
import { useSelector } from "react-redux";
import ButtonLoader from "../Pages/Loader/Button Loader/ButtonLoader";

const KycForm = (props) => {
  const {
    kycAadharNumber,
    kycPanNumber,
    Image,
    kycAadharImg,
    kycPanImg,
    HandleInputChange,
    handleKycToggle,
    kycStatus,
    AadharImgName,
    AadharImgBackName,
    PanImgName,
    CINImgName,
    ProfileImgName,
    kycAPI,
    handleBackBtn,
    AutoKYCrejected,
    isDisabledButton,
    CompanyName,
    carOwner,
    errors,
    typeOfKycDocument,
    companyName,
    CertificateOfIncorporationNumber,
    formData,
  } = props;

  const navigate = useNavigate();

  const [KYCmethod, setKYCmethod] = useState(null);
  const [ShowKYClist, setShowKYClist] = useState(false);
  const search = useLocation().search;
  const transactionId = new URLSearchParams(search).get("id");

  const handleKycMethod = (e) => {
    setKYCmethod(e.target.value);
    HandleInputChange(e);
  };

  let vehicleType = localStorage.getItem("vehicleType");

  let transactionStatus = useSelector((state) => {
    return state.getTransactionStatus;
  });

  // IMP: here if company is other than sbi then we need both pan and aadhar in dropdown in all case

  let kycMethodsList;

  if (CompanyName == "sbi") {
    if (!AutoKYCrejected) {
      if (carOwner === "individualOwner") {
        kycMethodsList = [
          { label: "PAN Card", value: "PAN Card" },
          { label: "Aadhar Card", value: "Aadhar Card" },
        ];
      } else {
        kycMethodsList = [
          { label: "PAN Card", value: "PAN Card" }
        ];
      }
    } else {
      if (carOwner === "individualOwner") {
        kycMethodsList = [{ label: "Aadhar Card", value: "Aadhar Card" }];
      } else {
        kycMethodsList = [
          {
            label: "Certificate of Incorporation",
            value: "Certificate of Incorporation",
          },
        ];
      }
    }
  } else if (CompanyName == "shreeram" || CompanyName == "icici") {
    if (carOwner === "individualOwner") {
      kycMethodsList = [
        { label: "PAN Card", value: "PAN Card" },
        { label: "Aadhar Card", value: "Aadhar Card" },
      ];
    } else {
      kycMethodsList = [
        { label: "PAN Card", value: "PAN Card" },
        {
          label: "Certificate of Incorporation",
          value: "Certificate of Incorporation",
        },
      ];
    }
  } else if (CompanyName == "digit" || CompanyName == "acko") {
    if (carOwner === "individualOwner") {
      kycMethodsList = [
        { label: "PAN Card", value: "PAN Card" },
      ];
    } else {
      kycMethodsList = [
        { label: "PAN Card", value: "PAN Card" }
      ];
    }
  } else if (CompanyName == "zuno") {
    if (!AutoKYCrejected) {
      if (carOwner === "individualOwner") {
        kycMethodsList = [
          { label: "PAN Card", value: "PAN Card" },
        ];
      } else {
        kycMethodsList = [
          { label: "PAN Card", value: "PAN Card" }
        ];
      }
    }
  } else if (CompanyName == "tata") {
    if (!AutoKYCrejected) {
      if (carOwner === "individualOwner") {
        kycMethodsList = [
          { label: "Aadhar Card", value: "Aadhar Card" },
        ];
      } else {
        kycMethodsList = [
          {
            label: "Certificate of Incorporation",
            value: "Certificate of Incorporation",
          },
        ];
      }
    } else if (AutoKYCrejected) {
      if (carOwner === "individualOwner") {
        kycMethodsList = [{ label: "Aadhar Card", value: "Aadhar Card" }];
      } else {
        kycMethodsList = [
          {
            label: "Certificate of Incorporation",
            value: "Certificate of Incorporation",
          },
        ];
      }
    }
  }

  useEffect(() => {
    if (AutoKYCrejected && carOwner === "individualOwner") {
      setKYCmethod("Aadhar Card");
    } else if (AutoKYCrejected && carOwner !== "individualOwner") {
      setKYCmethod("Certificate of Incorporation");
    }
  }, [AutoKYCrejected]);

  const handlePopUp = () => {
    if (ShowKYClist) {
      setShowKYClist(false);
    }
  };

  useEffect(() => {
    if (isDisabledButton) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [isDisabledButton]);

  return (
    <>
      <div id="cover-spin" style={{ display: isDisabledButton ? "" : "none" }}>
        <img src={loader2} className="gifLoader"></img>
      </div>

      <div className="titleContainer">
        <p>Update your KYC</p>
        <p>All fields are mandatory</p>
      </div>

      <form action="" className="kycFormContainer" onClick={handlePopUp}>
        <div className="kyc_container_photo_conteent">
          {Image ? (
            <div className="kyc_container_photo_img">
              <img src={Image} className="profileImg-kyc" alt="" srcSet="" />
            </div>
          ) : (
            <div className="label-container">
              <label
                htmlFor="uploadImg"
                className={
                  errors.kycProfileImg
                    ? "kyc_container_photo kyc_container_photo-err "
                    : "kyc_container_photo"
                }
              >
                <input
                  type="file"
                  className="hideRealUploadBtn"
                  id="uploadImg"
                  accept="image/*, application/pdf"
                  name="kycProfileImg"
                  onChange={(e) => HandleInputChange(e)}
                  hidden
                />
                <img src={user} alt="" srcSet="" />
                <p>
                  Upload your <br /> Photo.
                </p>
              </label>
              {errors.kycProfileImg ? (
                <p className="err-msg">{errors.kycProfileImg}</p>
              ) : (
                ""
              )}
            </div>
          )}

          <div className="kyc_container_content">
            <div className="kyc_info_container">
              <p className="title_kyc_pf">Update KYC</p>
              <p className="small_text">
                From January 1, 2023, KYC authentication has been a mandatory
                process to purchase insurance policies from all insurance
                providers in India.
              </p>
              <p className="title_kyc_pf">
                Your details are safe with us. You can complete your KYC in 1
                min.
              </p>
            </div>
          </div>

          <p className="bottomInfo_kyc">
            {ProfileImgName ? (
              <span className="kyc-succes-upload">Uploaded</span>
            ) : (
              <>File cannot be more then 1MB. (JPG or PNG)</>
            )}
          </p>
        </div>

        {/* CHOOSE KYC METHODS */}

        <div className="input_group_pform input_group_pform-flex ">
          <div className="inputGropOne_form inputGrop ">
            {AutoKYCrejected && companyName === "icici" && carOwner != "individualOwner" ?
              (
                ''
              ) :
              (companyName === 'shreeram' ? '' :
                <DropDown
                  dropDownMenu={kycMethodsList}
                  value={typeOfKycDocument}
                  name="typeOfKycDocument"
                  classNameForHead="headTxtClassPform"
                  Head={
                    AutoKYCrejected
                      ? "Select Method To Upload Document"
                      : "Select Kyc Method"
                  }
                  label={
                    AutoKYCrejected ? "Select Kyc Method" : "Select Kyc Method"
                  }
                  labelclass="rmvLeft"
                  arrowInGray={grayArrow}
                  inputId="nomineeRelation"
                  clickEvent={(e) => console.log(e)}
                  headClick={(e) => setShowKYClist(!ShowKYClist)}
                  revealOptions={ShowKYClist}
                  afterTrueClass="dropDown_container NoBorderRadius"
                  className="dropDown_container"
                  valueSelectionEvent={handleKycMethod}
                  revealOptionsClasses=" dropDownOptions showdropDownOptions "
                  classNameTwo="dropDownOptions"
                  dropDownHeadClass="dropDownHead"
                  idForHead="relHead"
                  idForHeadTxt="relHeadTxt"
                  idForArrow="relHeadArrow"
                  dropdownId="relListDp"
                  idForParentObj="relParentDp"
                  errState={errors?.typeOfKycDocument ? true : false}
                  errMsg={errors?.typeOfKycDocument}
                  errClassName="input-err"
                />)}
          </div>
          <div className="inputGropOne_form inputGrop ">
          </div>
        </div>

        {KYCmethod !== null && !AutoKYCrejected && (
          <div className="input_group_pform  ">
            {formData?.typeOfKycDocument === "Aadhar Card" ? (
              <div className="inputGropOne_form inputGrop ">
                <InputFields
                  value={kycAadharNumber}
                  placeholder="Aadhar Number"
                  id="kycAadharNumber"
                  type="number"
                  name="kycAadharNumber"
                  onChangeEvent={(e) => HandleInputChange(e)}
                  errState={errors?.kycAadharNumber ? true : false}
                  errMsg={errors?.kycAadharNumber}
                  errClassName="input-err"
                />
              </div>
            ) : (
              formData?.typeOfKycDocument === "PAN Card" ?
                <div className="inputGropOne_form inputGrop ">
                  <InputFields
                    value={kycPanNumber}
                    placeholder="PAN Number"
                    id="kycPanNumberr"
                    type="text"
                    name="kycPanNumber"
                    className="uppercaseText"
                    onChangeEvent={(e) => HandleInputChange(e)}
                    errState={errors?.kycPanNumber ? true : false}
                    errMsg={errors?.kycPanNumber}
                    errClassName="input-err"
                  />
                </div>
                :
                <div className="inputGropOne_form inputGrop ">
                  <InputFields
                    value={CertificateOfIncorporationNumber}
                    placeholder="Certificate of Incorporation Number"
                    id="kycPanNumberr"
                    type="text"
                    name="CertificateOfIncorporationNumber"
                    className="uppercaseText"
                    onChangeEvent={(e) => HandleInputChange(e)}
                    errState={errors?.CertificateOfIncorporationNumber ? true : false}
                    errMsg={errors?.CertificateOfIncorporationNumber}
                    errClassName="input-err"
                  />
                </div>
            )}
          </div>
        )}

        {companyName === "shreeram" && (
          <>
            {carOwner == "individualOwner" && (
              <>
                <div className="input_group_pform input_group_pform-flex ">
                  <div id="">
                    <div className="inputGropOne_form inputGrop inputGropKyc-custom ">
                      <p>Upload PAN Photo (POI)</p>
                      <input
                        type="file"
                        className="hideRealUploadBtn"
                        id="uploadImg-PAN"
                        accept="image/*, application/pdf"
                        name="kycPanImg"
                        onChange={(e) => HandleInputChange(e)}
                        hidden
                      />
                      <label
                        className="customLabel-kyc"
                        htmlFor="uploadImg-PAN"
                      >
                        <p className="btn-kyc-">upload</p>
                      </label>
                    </div>
                    <p>{PanImgName}</p>
                    {errors.kycPanImg ? (
                      <p className="err-msg">{errors.kycPanImg}</p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="inputGropOne_form inputGrop ">
                    <InputFields
                      value={kycPanNumber}
                      placeholder="PAN Number"
                      id="kycPanNumbe"
                      type="text"
                      name="kycPanNumber"
                      className="uppercaseText"
                      onChangeEvent={(e) => HandleInputChange(e)}
                      errState={errors?.kycPanNumber ? true : false}
                      errMsg={errors?.kycPanNumber}
                      errClassName="input-err"
                    />
                  </div>
                </div>
                <div className="input_group_pform input_group_pform-flex ">
                  <div id="">
                    <div className="inputGropOne_form inputGrop inputGropKyc-custom ">
                      <p>Front Aadhar Photo (POA)</p>
                      <input
                        type="file"
                        className="hideRealUploadBtn"
                        id="uploadImg-Aadhar"
                        accept="image/*, application/pdf"
                        name="kycAadharImg"
                        onChange={(e) => HandleInputChange(e)}
                        hidden
                      />
                      <label
                        className="customLabel-kyc"
                        htmlFor="uploadImg-Aadhar"
                      >
                        <p className="btn-kyc-">upload</p>
                      </label>
                    </div>
                    <p>{AadharImgName}</p>
                    {errors.kycAadharImg ? (
                      <p className="err-msg">{errors.kycAadharImg}</p>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="inputGropOne_form inputGrop ">
                    <InputFields
                      value={kycAadharNumber}
                      placeholder="Aadhar Number"
                      id="kycAadharNer"
                      type="number"
                      name="kycAadharNumber"
                      onChangeEvent={(e) => HandleInputChange(e)}
                      errState={errors?.kycAadharNumber ? true : false}
                      errMsg={errors?.kycAadharNumber}
                      errClassName="input-err"
                    />
                  </div>
                </div>
                <div className="input_group_pform input_group_pform-flex ">
                  <div id="">
                    <div
                      className="inputGropOne_form inputGrop inputGropKyc-custom "
                    >
                      <p>Back Aadhar Photo (POA)</p>
                      <input
                        type="file"
                        className="hideRealUploadBtn"
                        id="uploadImg-Aadhar-Back"
                        accept="image/*, application/pdf"
                        name="kycAadharImgBack"
                        onChange={(e) => HandleInputChange(e)}
                        hidden
                      />
                      <label
                        className="customLabel-kyc"
                        htmlFor="uploadImg-Aadhar-Back"
                      >
                        <p className="btn-kyc-">upload</p>
                      </label>
                    </div>
                    <p>{AadharImgBackName}</p>
                    {errors.kycAadharImgBack ? (
                      <p className="err-msg">{errors.kycAadharImgBack}</p>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="inputGropOne_form inputGrop ">
                    <InputFields
                      value={formData.fathersName}
                      placeholder="Father Name"
                      id="fathersName"
                      type="text"
                      name="fathersName"
                      onChangeEvent={(e) => HandleInputChange(e)}
                      errState={errors?.fathersName ? true : false}
                      errMsg={errors?.fathersName}
                      errClassName="input-err"
                    />
                  </div>
                </div>
              </>
            )}
            {carOwner != "individualOwner" && (
              <>
                <div className="input_group_pform input_group_pform-flex ">
                  <div id="">
                    <div className="inputGropOne_form inputGrop inputGropKyc-custom ">
                      <p>Upload PAN Photo</p>
                      <input
                        type="file"
                        className="hideRealUploadBtn"
                        id="uploadImg-PAN"
                        accept="image/*, application/pdf"
                        name="kycPanImg"
                        onChange={(e) => HandleInputChange(e)}
                        hidden
                      />
                      <label
                        className="customLabel-kyc"
                        htmlFor="uploadImg-PAN"
                      >
                        <p className="btn-kyc-">upload</p>
                      </label>
                    </div>
                    <p>{PanImgName}</p>
                    {errors.kycPanImg ? (
                      <p className="err-msg">{errors.kycPanImg}</p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="inputGropOne_form inputGrop ">
                    <InputFields
                      value={kycPanNumber}
                      placeholder="PAN Number"
                      id="kycPanNumbe"
                      type="text"
                      name="kycPanNumber"
                      className="uppercaseText"
                      onChangeEvent={(e) => HandleInputChange(e)}
                      errState={errors?.kycPanNumber ? true : false}
                      errMsg={errors?.kycPanNumber}
                      errClassName="input-err"
                    />
                  </div>
                </div>
                <div className="input_group_pform input_group_pform-flex ">
                  <div id="">
                    <div className="inputGropOne_form inputGrop inputGropKyc-custom ">
                      <p>Certificate of Incorporation</p>
                      <input
                        type="file"
                        className="hideRealUploadBtn"
                        id="uploadImg-CIN"
                        accept="image/*, application/pdf"
                        name="CertificateOfIncorporationFile"
                        onChange={(e) => HandleInputChange(e)}
                        hidden
                      />
                      <label
                        className="customLabel-kyc"
                        htmlFor="uploadImg-CIN"
                      >
                        <p className="btn-kyc-">upload</p>
                      </label>
                    </div>
                    <p>{CINImgName}</p>
                    {errors.CertificateOfIncorporationFile ? (
                      <p className="err-msg">
                        {errors.CertificateOfIncorporationFile}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="inputGropOne_form inputGrop ">
                    <InputFields
                      value={CertificateOfIncorporationNumber}
                      placeholder="Certificate of Incorporation Number"
                      id="CertificateOfIncorporationNumber"
                      type="text"
                      name="CertificateOfIncorporationNumber"
                      className="uppercaseText"
                      onChangeEvent={(e) => HandleInputChange(e)}
                      errState={
                        errors?.CertificateOfIncorporationNumber
                          ? true
                          : false
                      }
                      errMsg={errors?.CertificateOfIncorporationNumber}
                      errClassName="input-err"
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}

        {AutoKYCrejected && companyName === "icici" && carOwner != "individualOwner" && (
          <div className="kyc-dummy">
            <div className="input_group_pform  input_group_pform-kyc ">
              <div className="kyc-inp-with-err">
                <div className="inputGropOne_form inputGrop inputGropKyc-custom ">
                  <p>Upload PAN Photo</p>
                  <input
                    type="file"
                    className="hideRealUploadBtn"
                    id="uploadImg-PAN"
                    accept="image/*, application/pdf"
                    name="kycPanImg"
                    onChange={(e) => HandleInputChange(e)}
                    hidden
                  />
                  <label
                    className="customLabel-kyc"
                    htmlFor="uploadImg-PAN"
                  >
                    <p className="btn-kyc-">upload</p>
                  </label>
                </div>
                <p>{PanImgName}</p>
                {errors.kycPanImg ? (
                  <p className="err-msg">{errors.kycPanImg}</p>
                ) : (
                  ""
                )}
              </div>

              <div className="inputGropOne_form inputGrop ">
                <InputFields
                  value={kycPanNumber}
                  placeholder="PAN Number"
                  id="kycPanNumbe"
                  type="text"
                  name="kycPanNumber"
                  className="uppercaseText"
                  onChangeEvent={(e) => HandleInputChange(e)}
                  errState={errors?.kycPanNumber ? true : false}
                  errMsg={errors?.kycPanNumber}
                  errClassName="input-err"
                />
              </div>
            </div>
            <div className="input_group_pform input_group_pform-kyc ">

              <div className="kyc-inp-with-err">
                <div className="inputGropOne_form inputGrop inputGropKyc-custom ">
                  <p>CIN Photo</p>
                  <input
                    type="file"
                    className="hideRealUploadBtn"
                    id="uploadImg-CIN"
                    accept="image/*, application/pdf"
                    name="CertificateOfIncorporationFile"
                    onChange={(e) => HandleInputChange(e)}
                    hidden
                  />
                  <label
                    className="customLabel-kyc"
                    htmlFor="uploadImg-CIN"
                  >
                    <p className="btn-kyc-">upload</p>
                  </label>
                </div>
                <p>{CINImgName}</p>
                {errors.CertificateOfIncorporationFile ? (
                  <p className="err-msg">
                    {errors.CertificateOfIncorporationFile}
                  </p>
                ) : (
                  ""
                )}
              </div>

              <div className="inputGropOne_form inputGrop ">
                <InputFields
                  value={CertificateOfIncorporationNumber}
                  placeholder="CIN Number"
                  id="CertificateOfIncorporationNumber"
                  type="text"
                  name="CertificateOfIncorporationNumber"
                  className="uppercaseText"
                  onChangeEvent={(e) => HandleInputChange(e)}
                  errState={
                    errors?.CertificateOfIncorporationNumber
                      ? true
                      : false
                  }
                  errMsg={errors?.CertificateOfIncorporationNumber}
                  errClassName="input-err"
                />
              </div>
            </div>
          </div>
        )}
        
        {typeOfKycDocument !== "" && (
          <>
            {AutoKYCrejected && (
              <div className="input_group_pform input_group_pform-kyc ">
                {formData?.typeOfKycDocument === "Aadhar Card" && companyName != "shreeram" && companyName != "tata" && (
                  <>
                    <div id="">
                      <div className="inputGropOne_form inputGrop inputGropKyc-custom ">
                        <p>Front Aadhar Photo</p>
                        <input
                          type="file"
                          className="hideRealUploadBtn"
                          id="uploadImg-Aadhar"
                          accept="image/*, application/pdf"
                          name="kycAadharImg"
                          onChange={(e) => HandleInputChange(e)}
                          hidden
                        />
                        <label
                          className="customLabel-kyc"
                          htmlFor="uploadImg-Aadhar"
                        >
                          <p className="btn-kyc-">upload</p>
                        </label>
                      </div>
                      <p>{AadharImgName}</p>
                      {errors.kycAadharImg ? (
                        <p className="err-msg">{errors.kycAadharImg}</p>
                      ) : (
                        ""
                      )}

                      <div
                        style={{ marginTop: 34 }}
                        className="inputGropOne_form inputGrop inputGropKyc-custom "
                      >
                        <p>Back Aadhar Photo</p>
                        <input
                          type="file"
                          className="hideRealUploadBtn"
                          id="uploadImg-Aadhar-Back"
                          accept="image/*, application/pdf"
                          name="kycAadharImgBack"
                          onChange={(e) => HandleInputChange(e)}
                          hidden
                        />
                        <label
                          className="customLabel-kyc"
                          htmlFor="uploadImg-Aadhar-Back"
                        >
                          <p className="btn-kyc-">upload</p>
                        </label>
                      </div>
                      <p>{AadharImgBackName}</p>
                      {errors.kycAadharImgBack ? (
                        <p className="err-msg">{errors.kycAadharImgBack}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="inputGropOne_form inputGrop ">
                      <InputFields
                        value={kycAadharNumber}
                        placeholder="Aadhar Number"
                        id="kycAadharNer"
                        type="number"
                        name="kycAadharNumber"
                        onChangeEvent={(e) => HandleInputChange(e)}
                        errState={errors?.kycAadharNumber ? true : false}
                        errMsg={errors?.kycAadharNumber}
                        errClassName="input-err"
                      />
                    </div>
                  </>
                )}

                {
                  formData?.typeOfKycDocument === "Aadhar Card" && companyName === "tata" && (
                    <div className="input_group_pform input_group_pform-kyc input_group_pform-kyc-tata">
                      <div id="">
                        <div className="inputGropOne_form inputGrop inputGropKyc-custom ">
                          <p> Aadhar Photo</p>
                          <input
                            type="file"
                            className="hideRealUploadBtn"
                            id="uploadImg-Aadhar"
                            accept="image/*, application/pdf"
                            name="kycAadharImg"
                            onChange={(e) => HandleInputChange(e)}
                            hidden
                          />
                          <label
                            className="customLabel-kyc"
                            htmlFor="uploadImg-Aadhar"
                          >
                            <p className="btn-kyc-">upload</p>
                          </label>
                        </div>
                        <p>{AadharImgName}</p>
                        {errors.kycAadharImg ? (
                          <p className="err-msg">{errors.kycAadharImg}</p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div>
                      </div>
                    </div>
                  )
                }

                {formData?.typeOfKycDocument === "PAN Card" &&
                  (!AutoKYCrejected || CompanyName != "sbi" || CompanyName != "icici") ? (
                  <>
                    <div id="">
                      <div className="inputGropOne_form inputGrop inputGropKyc-custom ">
                        <p>Upload PAN Photo</p>
                        <input
                          type="file"
                          className="hideRealUploadBtn"
                          id="uploadImg-PAN"
                          accept="image/*, application/pdf"
                          name="kycPanImg"
                          onChange={(e) => HandleInputChange(e)}
                          hidden
                        />
                        <label
                          className="customLabel-kyc"
                          htmlFor="uploadImg-PAN"
                        >
                          <p className="btn-kyc-">upload</p>
                        </label>
                      </div>
                      {errors.kycPanImg ? (
                        <p className="err-msg">{errors.kycPanImg}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="inputGropOne_form inputGrop ">
                      <InputFields
                        value={kycPanNumber}
                        placeholder="PAN Number"
                        id="kycPanNumbe"
                        type="text"
                        name="kycPanNumber"
                        className="uppercaseText"
                        onChangeEvent={(e) => HandleInputChange(e)}
                        errState={errors?.kycPanNumber ? true : false}
                        errMsg={errors?.kycPanNumber}
                        errClassName="input-err"
                      />
                    </div>
                  </>
                ) : (formData?.typeOfKycDocument === "Certificate of Incorporation" && CompanyName != "icici") ? (
                  <>
                    <div id="">
                      <div className="inputGropOne_form inputGrop inputGropKyc-custom ">
                        <p>Certificate of Incorporation</p>
                        <input
                          type="file"
                          className="hideRealUploadBtn"
                          id="uploadImg-CIN"
                          accept="image/*, application/pdf"
                          name="CertificateOfIncorporationFile"
                          onChange={(e) => HandleInputChange(e)}
                          hidden
                        />
                        <label
                          className="customLabel-kyc"
                          htmlFor="uploadImg-CIN"
                        >
                          <p className="btn-kyc-">upload</p>
                        </label>
                      </div>
                      <p>{CINImgName}</p>
                      {errors.CertificateOfIncorporationFile ? (
                        <p className="err-msg">
                          {errors.CertificateOfIncorporationFile}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="inputGropOne_form inputGrop ">
                      <InputFields
                        value={CertificateOfIncorporationNumber}
                        placeholder="Certificate of Incorporation Number"
                        id="CertificateOfIncorporationNumber"
                        type="text"
                        name="CertificateOfIncorporationNumber"
                        className="uppercaseText"
                        onChangeEvent={(e) => HandleInputChange(e)}
                        errState={
                          errors?.CertificateOfIncorporationNumber
                            ? true
                            : false
                        }
                        errMsg={errors?.CertificateOfIncorporationNumber}
                        errClassName="input-err"
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}

                <div className="msg-kyc">
                  {formData?.typeOfKycDocument === "Aadhar Card" && (
                    <p className="bottomInfo_kyc-two">
                      {AadharImgName && AadharImgBackName ? (
                        <span className="kyc-succes-upload">
                          Front and back Aadhar uploaded successfully
                        </span>
                      ) : (
                        <>File cannot be more then 1MB. (JPG or PNG)</>
                      )}
                    </p>
                  )}

                  {formData?.typeOfKycDocument === "PAN Card" && (
                    <p className="bottomInfo_kyc-two bottomInfo_kyc-three ">
                      {PanImgName ? (
                        // PanImgName
                        <span className="kyc-succes-upload">Uploaded</span>
                      ) : (
                        <>File cannot be more then 1MB. (JPG or PNG)</>
                      )}
                    </p>
                  )}

                  {formData?.typeOfKycDocument === "Certificate of Incorporation" && (
                    <p className="bottomInfo_kyc-two bottomInfo_kyc-three ">
                      {CINImgName ? (
                        // PanImgName
                        <span className="kyc-succes-upload">Uploaded</span>
                      ) : (
                        <>File cannot be more then 1MB. (JPG or PNG)</>
                      )}
                    </p>
                  )}
                </div>
              </div>
            )}
          </>
        )}

        <section className="btnContainer_pform">
          <Botton
            text="Cancel"
            className="button btn_pForm  secondry_btn"
            event={() => navigate(vehicleType == "2W" ? "/View-Plans-Bike?id=" + transactionId : "/View-Plans?id=" + transactionId)}
          />
          {transactionStatus?.isLoading ?
            <ButtonLoader className="btn_pForm" />
            : <Botton
              text={isDisabledButton ? "Loading..." : "Update KYC"}
              className="button btn_pForm"
              isDisabledButton={isDisabledButton}
              event={handleKycToggle}
            />}
        </section>
      </form>
    </>
  );
};

export default KycForm;
