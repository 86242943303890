import React from "react";
import car from "../../Assets/Images/car.svg";
import bike from "../../Assets/Images/bike.svg";
import health from "../../Assets/Images/health.svg";
import taxi from "../../Assets/Images/taxi.svg";
import Botton from "../Button/Button";

const ProductSelect = (props) => {
  const {
    clickEvent,
    ClassName,
    Id,
    style,
    handleProduct,
    productValue,
    ShowCarContent,
    setProductTabClicked,
  } = props;

  return (
    <div>
      <div className="product_selction_getregno hideInMobile ">
        <Botton
          text="Insurance"
          spanTxt="Car"
          className={
            productValue === "car" ? "active_prod_btn prod_btn" : "prod_btn"
          }
          name="getRegNoFormMobile"
          icons={car}
          event={(e) => { handleProduct("car"); setProductTabClicked(true); }}

        />
        {/* <Botton
          text="Insurance"
          spanTxt="Bike"
          className={
            productValue === "bike" ? "active_prod_btn prod_btn" : "prod_btn"
          }
          name="getRegNoFormMobile"
          icons={bike}
          event={(e) => { handleProduct("bike"); setProductTabClicked(true); }}
        /> */}
        {/* <Botton
          text="Insurance"
          spanTxt="Health"
          className={
            productValue === "health" ? "active_prod_btn prod_btn" : "prod_btn"
          }
          name="getRegNoFormMobile"
          icons={health}
          event={(e) => { handleProduct("health"); setProductTabClicked(true); }}
        />
        <Botton
          text="Insurance"
          spanTxt="Taxi"
          className={
            productValue === "taxi" ? "active_prod_btn prod_btn" : "prod_btn"
          }
          name="getRegNoFormMobile"
          icons={taxi}
          event={(e) => { handleProduct("taxi"); setProductTabClicked(true); }}
        /> */}
      </div>
      <div className="product_selction_getregno showInMobile ">
        <div
          // className={ShowCarContent === true ? "active_prod_btn prod_btn prod_btn-fullWidth" : "active_prod_btn prod_btn"}
          className="active_prod_btn prod_btn prod_btn-mobile-view"
          onClick={(e) => clickEvent("car")}
        >
          <img src={car} alt="" srcSet="" />
          <div>
            <span>Car </span> Insurance
          </div>
        </div>

        {/* <div className="active_prod_btn prod_btn prod_btn-mobile-view "
          onClick={(e) => clickEvent("bike")}>

          <img src={bike} alt="" srcSet="" />
          <div>
            <span>Bike </span> Insurance
          </div>
        </div> */}

        {/* <div className="active_prod_btn prod_btn prod_btn-mobile-view ">
          <img src={health} alt="" srcSet="" />
          <div>
            <span>Health </span> Insurance
          </div>
        </div>

        <div className="active_prod_btn prod_btn prod_btn-mobile-view ">
          <img src={taxi} alt="" srcSet="" />
          <div>
            <span>Taxi </span> Insurance
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ProductSelect;
