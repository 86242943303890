import React from 'react'
import DropDown from '../../../DropDown/DropDown';
import InputFields from '../../../InputFields/InputFields';
import Botton from '../../../Button/Button';
import RadioButton from '../../../RadioButton/RadioButton';
import Calender from '../../../InputFields/Calender';
import CustomTimePicker from '../../../InputFields/TimePicker/TimePicker';
import SearchSelect from '../../../DropDown/Search Select/SearchSelect';

const IncidentDetail = (props) => {

    const {
        ClaimFormValues,
        handleFormValue,
        handleStateSelect,
        handleCitySelect,
        toggleColapsible,
    } = props;
    
    const stateList = [
        { label: "Husband", value: "Husband" },
        { label: "Father", value: "Father" },
        { label: "Mother", value: "Mother" },
        { label: "Son", value: "Son" },
        { label: "Daughter", value: "Daughter" },
        { label: "Brother", value: "Brother" },
        { label: "Sister", value: "Sister" },
        { label: "Other", value: "Other" },
    ];

    const cityList = [
        { label: "Husband", value: "Husband" },
        { label: "Father", value: "Father" },
        { label: "Mother", value: "Mother" },
        { label: "Son", value: "Son" },
        { label: "Daughter", value: "Daughter" },
        { label: "Brother", value: "Brother" },
        { label: "Sister", value: "Sister" },
        { label: "Other", value: "Other" },
    ];

    return (
        <>
            <form className="formSection-pform  formSection-claimIntimation" action="" >
                <div className="input_group_pform">
                    <div className="inputGropOne_form inputGrop ">
                        <p className="customLabel_pform">
                            Who was the person driving the car
                        </p>
                        <div className="radioContainer-pform">
                            <RadioButton
                                label="Self"
                                className=""
                                id="isSelfInspectionYes"
                                groupName="driver"
                                value="Self"
                                changeEvent={(e) => handleFormValue(e)}
                            />
                            <RadioButton
                                label="Others"
                                className=""
                                id="isSelfInspectionNo"
                                groupName="driver"
                                value="Others"
                                changeEvent={(e) => handleFormValue(e)}
                            />
                        </div>
                    </div>
                    <div className="inputGropOne_form inputGrop ">
                    </div>
                </div>

                {ClaimFormValues.driver === "Others" && (
                    <div className="input_group_pform">
                        <div className="inputGropOne_form inputGrop ">
                            <InputFields
                                // value={addressOne}
                                placeholder="Address Line 1"
                                id="addressOne"
                                type="text"
                                name="addressOne"
                            // errState={errors?.addressOne ? true : false}
                            // errMsg={errors?.addressOne}
                            // errClassName="input-err"
                            // onChangeEvent={(e) => HandleInputChange(e)}
                            />
                        </div>
                        <div className="inputGropOne_form inputGrop ">
                            <InputFields
                                // value={addressTwo}
                                placeholder="Address Line 2"
                                id="addressTwo"
                                type="text"
                                name="addressTwo"
                            // errState={errors?.addressTwo ? true : false}
                            // errMsg={errors?.addressTwo}
                            // errClassName="input-err"
                            // onChangeEvent={(e) => HandleInputChange(e)}
                            />
                        </div>
                    </div>)}

                <div className="input_group_pform">
                    <div className="inputGropOne_form inputGrop ">
                        <Calender
                            className="pForm_datePicker"
                            placeholder="Incident Date"
                            name="incidentDate"
                            id="incidentDate"
                            value={ClaimFormValues.incidentDate}
                            label="Incident Date"
                            changeEvent={(e) => handleFormValue(e)}
                        />
                    </div>
                    <div className="inputGropOne_form inputGrop ">
                        {/* <CustomTimePicker /> */}
                        <Calender
                            className="pForm_datePicker"
                            placeholder="Incident Date"
                            name="incidentDate"
                            id="incidentDate"
                            value={ClaimFormValues.incidentDate}
                            label="Incident Date"
                            changeEvent={(e) => handleFormValue(e)}
                        />
                    </div>
                </div>

                <div className="input_group_pform">
                    <div className="inputGropOne_form inputGrop ">
                        <InputFields
                            value={ClaimFormValues.incidentArea}
                            placeholder="Area"
                            id="Area"
                            type="text"
                            name="incidentArea"
                            onChangeEvent={(e) => handleFormValue(e)}
                        />
                    </div>
                    <div className="inputGropOne_form inputGrop ">
                        <SearchSelect
                            optionList={stateList}
                            handleSelect={handleStateSelect}
                            value={ClaimFormValues.incidentState}
                            placeholder="State"
                            label="State"
                            name="incidentState"
                        />
                    </div>
                </div>

                <div className="input_group_pform">
                    <div className="inputGropOne_form inputGrop ">
                        <SearchSelect
                            optionList={stateList}
                            handleSelect={handleCitySelect}
                            value={ClaimFormValues.incidentCity}
                            placeholder="City"
                            label="City"
                            name="incidentCity"
                        />
                    </div>
                    <div className="inputGropOne_form inputGrop ">
                        <InputFields
                            value={ClaimFormValues.incidentPincode}
                            placeholder="Pin Code"
                            id="incidentPincode"
                            type="text"
                            name="incidentPincode"
                            onChangeEvent={(e) => handleFormValue(e)}
                        />
                    </div>
                </div>

                <div className="input_group_pform">
                    <div className="inputGropOne_form inputGrop ">
                        <SearchSelect
                            optionList={stateList}
                            handleSelect={handleStateSelect}
                            value={ClaimFormValues.incidentState}
                            placeholder="Select Claim Reason"
                            label="Claim Reason"
                            name="claimReason"
                        />
                    </div>
                    <div className="inputGropOne_form inputGrop ">
                        <SearchSelect
                            optionList={stateList}
                            handleSelect={handleStateSelect}
                            value={ClaimFormValues.claimReasonCategory}
                            placeholder="Claim Reason Sub-Category"
                            label="Claim Reason Sub-Category"
                            name="claimReasonCategory"
                        />
                    </div>
                </div>

                <div className="input_group_pform">
                    <div className="inputGropOne_form inputGrop ">
                        <InputFields
                            value={ClaimFormValues.lossDescription}
                            placeholder="Loss Description"
                            id="lossDescription"
                            type="text"
                            name="lossDescription"
                            onChangeEvent={(e) => handleFormValue(e)}
                        />
                    </div>
                </div>

                <section className="btnContainer_pform">
                    <Botton
                        text="Cancel"
                        className="button btn_pForm  secondry_btn"
                        event={(e) => console.log("vehicleDetails")}
                    />
                    <Botton
                        text="Save"
                        className="button btn_pForm  "
                        event={(e) => toggleColapsible("vehicleDetails")}
                    />
                </section>

            </form>
        </>
    )
}

export default IncidentDetail
