import React, { useState, useEffect } from "react";
import car from "../../../../Assets/Images/car.svg";
import bike from "../../../../Assets/Images/bike.svg";
import health from "../../../../Assets/Images/health.svg";
import taxi from "../../../../Assets/Images/taxi.svg";
import grayArrow from "../../../../Assets/Images/grayArrow.svg";
import tick from "../../../../Assets/Images/success.svg";
import Botton from "../../../Button/Button";
import ActivePolicy from "./ActivePolicy";
import YetToStart from "./YetToStart";
import ExpiredPolicy from "./ExpiredPolicy";
import RenewalPolicy from "./RenewalPolicy";

const PolicyFound = (props) => {

  const { handleInputChange, FormValue } = props;
  // Yet to start Policies, Expired Policies, Upcoming Renewal, Active Policies
  const [ActivePolicyTab, setActivePolicyTab] = useState("Active Policies");

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [ActivePolicyTab])


  let tabList = [
    "Active Policies",
    "Yet to start Policies",
    "Expired Policies",
    "Upcoming Renewal",
  ];

  let coveragesFromDB = [
    "Zero Depreciation",
    "Consumables",
    "Consumables",
    "Consumables",
    "Consumables",
  ];

  let policyDetails = [
    {
      productIcon: car,
      policyNumber: "DUMMYPOLICY0000001",
      insuredProductInfo: "Private Car Policy Insurance",
      insurerName: "HDFC Ergo",
      policyStartDate: "24 Jan 2023",
      policyEndDate: "24 Jan 2023",
      policyHolderName: "Vaibhav Dubey",
      vehicleNumber: "MH 02 CR 3380",
      coverages: coveragesFromDB,
    },
    {
      productIcon: car,
      policyNumber: "DUMMYPOLICY0000002",
      insuredProductInfo: "Private Car Policy Insurance",
      insurerName: "TATA AIG",
      policyStartDate: "2 Jan 2023",
      policyEndDate: "2 Jan 2023",
      policyHolderName: "Shubh Trivedi",
      vehicleNumber: "MH 04 CB 7897",
      coverages: coveragesFromDB,
    },
    {
      productIcon: car,
      policyNumber: "DUMMYPOLICY0000003",
      insuredProductInfo: "Private Car Policy Insurance",
      insurerName: "SBI General Insurance",
      policyStartDate: "24 Jan 2023",
      policyEndDate: "24 Jan 2023",
      policyHolderName: "Rohit Pandey",
      vehicleNumber: "MH 04 CA 9090",
      coverages: coveragesFromDB,
    },
  ];

  const handleDownloadPolicy = (policyNumber) => {
    alert(policyNumber);
  };

  return (
    <>
      <div className="policyTypeTab">
        {tabList.map((tabItems, key) => (
          <Botton
            text={tabItems}
            className={
              ActivePolicyTab == tabItems
                ? "accordian_button accordian_button-policy-section active_accordian_btn"
                : "accordian_button accordian_button-policy-section"
            }
            value="Comprehensive"
            name="PolicyTypeValue"
            event={(e) => setActivePolicyTab(tabItems)}
          />
        ))}
      </div>

      {ActivePolicyTab === "Active Policies" && (
        <ActivePolicy
          policyDetails={policyDetails}
          handleDownloadPolicy={handleDownloadPolicy}
        />
      )}

      {ActivePolicyTab === "Yet to start Policies" && (
        <YetToStart
          FormValue={FormValue}
          handleInputChange={handleInputChange}
        />
      )}

      {ActivePolicyTab === "Expired Policies" && (
        <ExpiredPolicy
          handleDownloadPolicy={handleDownloadPolicy}
        />
      )}

      {ActivePolicyTab === "Upcoming Renewal" && (
        <RenewalPolicy />
      )}
    </>
  );
};

export default PolicyFound;
