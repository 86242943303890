import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import digit from "../../Assets/Partners_logo/BOOKMYINSURANCE/digit.jpg";
import zuno from "../../Assets/Partners_logo/BOOKMYINSURANCE/zuno.jpg";
import shreeRam from "../../Assets/Partners_logo/BOOKMYINSURANCE/shriram.jpg";
import icici from "../../Assets/Partners_logo/BOOKMYINSURANCE/icici lombard.jpg";
import sbi from "../../Assets/Partners_logo/BOOKMYINSURANCE/SBI.jpg";
import acko from "../../Assets/Partners_logo/BOOKMYINSURANCE/acko.jpg";
import Botton from "../Button/Button";
import success from "../../Assets/Images/success.svg";
import fail from "../../Assets/Images/failure.svg";
import {
  zunoFullQuoteAction,
  zunoQuickQuoteAction,
} from "../../redux/Actions/zunoApiAction";
import { shreeRamQuickQuoteAction } from "../../redux/Actions/shreeRamApiAction";
import { digitQuickQuoteAction } from "../../redux/Actions/digitApiAction";
import { sbiQuickQuoteAction } from "../../redux/Actions/sbiApiAction";
import { Routes, Route, useNavigate, Link } from "react-router-dom";
import PlanDetails from "./PlanDetails";
import {
  SBI,
  RAHEJA,
  SHREERAM,
  ZUNO,
  DIGIT,
  ICICI,
  ACKO,
  findKeyValue
} from "../../utils/commonUtils";
import { getCompanyName } from "../../redux/Reducers/companyNameReducer";
import ButtonLoader from "../Pages/Loader/Button Loader/ButtonLoader";
import SkeletonLoader from "../Pages/Loader/Skeleton loader/SkeletonLoader";
import inspection from "../../Assets/Images/inspection.svg"
import noInspection from "../../Assets/Images/noInspection.png"

const Plancard = (props) => {
  const navigate = useNavigate();
  let maxIdv = [];
  let minIdv = [];

  const inspectDetails = useSelector((state) => {
    return state?.inspectDetails?.data?.isInspectionReq
  })

  const {
    planType,
    openModal,
    carDetails,
    setShowBottomTab,
    ShowSkeleton
  } = props;

  let checkboxTrueFalseObject = {
    paCover: false,
    zeroDep: false,
    consumables: false,
    roadAssistance: false,
    engineProtection: false,
    invoiceCover: false,
    keyLockReplacement: false,
    ncbProtection: false,
    driverCover: false,
    passengerCover: false,
    accessories: false,
    tyreProtection: false,
    emiProtection: false,
    lossofPersonalBelongings: false,
    dailyAllowance: false,
    shreeramProtect: false,
  };

  for (let addon of carDetails?.coverage[0]) {
    if (addon.name in checkboxTrueFalseObject) {
      checkboxTrueFalseObject[addon.name] = addon.checked ? true : false;
    }
  }

  const handleBuyNow = (logo, policyData, companyName) => {
    dispatch(
      getCompanyName({
        companyName: companyName,
      })
    );
    openModal(logo, policyData, companyName);
  };

  let loading = !carDetails?.isPrevPolicyExp ? "Loading..." : "";

  const dispatch = useDispatch();

  const sbiQuickQuote = useSelector((state) => {
    return state?.sbiQuickQuote;
  });
  const sbiQuickQuoteLoading = sbiQuickQuote?.isLoading;

  const getBodyData = useSelector((state) => {
    return state?.bodyData;
  });

  let sbiPrice = {
    paCover: false,
    zeroDep: false,
    consumables: false,
    roadAssistance: false,
    engineProtection: false,
    invoiceCover: false,
    keyLockReplacement: false,
    ncbProtection: false,
    driverCover: false,
    passengerCover: false,
    accessories: false,
    totalPremium: false,
    basicPremium: false,
    idv: false,
    duePremium: false,
    tyreProtection: false,
    emiProtection: false,
    lossofPersonalBelongings: false,
    dailyAllowance: false,
    odExternalCngLpg: false,
    odInternalCngLpg: false,
    tpCngLpg: false,
  };

  if (
    sbiQuickQuote?.data?.body?.data?.quote?.PolicyLobList?.length &&
    sbiQuickQuote?.data?.body?.data?.quote?.PolicyLobList[0]?.PolicyRiskList[0]
      ?.PolicyCoverageList?.length
  ) {
    sbiPrice.idv =
      sbiQuickQuote?.data?.body?.data?.quote?.PolicyLobList[0]?.PolicyRiskList[0]?.IDV_User;
    sbiPrice.duePremium =
      sbiQuickQuote?.data?.body?.data?.quote?.BeforeVatPremium;

    if (planType === "Third Party") {
      sbiPrice.basicPremium =
        sbiQuickQuote?.data?.body?.data?.quote?.PolicyLobList[0]?.PolicyRiskList[0]?.TP_BasePremium;
    } else if (planType === "Own Damage") {
      sbiPrice.basicPremium =
        sbiQuickQuote?.data?.body?.data?.quote?.PolicyLobList[0]?.PolicyRiskList[0]?.OD_After_Disc; // od after ncb discount
    } // PolicyLobList[0]?.PolicyRiskList[0]?.OD_After_Disc;
    else {
      sbiPrice.basicPremium =
        sbiQuickQuote?.data?.body?.data?.quote?.PolicyLobList[0]
          ?.PolicyRiskList[0]?.OD_After_Disc +
        sbiQuickQuote?.data?.body?.data?.quote?.PolicyLobList[0]
          ?.PolicyRiskList[0]?.TP_BasePremium; // od after ncb discount + basic tp
    }

    for (
      let index = 0;
      index <
      sbiQuickQuote?.data?.body?.data?.quote?.PolicyLobList[0]
        ?.PolicyRiskList[0]?.PolicyCoverageList?.length;
      index++
    ) {
      const element =
        sbiQuickQuote?.data?.body?.data?.quote?.PolicyLobList[0]
          ?.PolicyRiskList[0]?.PolicyCoverageList?.[index];
      switch (element?.ProductElementCode) {
        // case "C101066":
        //   sbiPrice.paCover = element?.AnnualPremium;
        //   break;
        case "C101072":
          sbiPrice.zeroDep = element?.AnnualPremium;
          break;
        case "C101111":
          sbiPrice.consumables = element?.AnnualPremium;
          break;
        case "C101069":
          sbiPrice.roadAssistance =
            element?.PolicyBenefitList[0]?.AnnualPremium;
          break;
        case "C101108":
          sbiPrice.engineProtection = element?.AnnualPremium;
          break;
        case "C101073":
          sbiPrice.keyLockReplacement = element?.AnnualPremium;
          break;
        case "C101067":
          sbiPrice.invoiceCover = element?.AnnualPremium;
          break;
        case "C101069":
          sbiPrice.roadAssistance = element?.AnnualPremium;
          break;
        case "C101110":
          sbiPrice.tyreProtection = element?.AnnualPremium;
          break;
        case "C101109":
          sbiPrice.emiProtection = element?.AnnualPremium;
          break;
        case "C101075":
          sbiPrice.lossofPersonalBelongings = element?.AnnualPremium;
          break;
        case "C101074":
          sbiPrice.dailyAllowance = element?.AnnualPremium;
          break;
        case "C101068":
          sbiPrice.ncbProtection =
            element?.AnnualPremium === 0 ? false : element?.AnnualPremium;
          break;
        default:
          break;
      }

      for (let index = 0; index < element?.PolicyBenefitList?.length; index++) {
        const coveragePolicy = element?.PolicyBenefitList[index];
        switch (coveragePolicy?.ProductElementCode) {
          case "B00016": // PA - Passenger cover
            sbiPrice.passengerCover = coveragePolicy?.AnnualPremium;
            break;
          case "B00027": // PA - Driver cover
            sbiPrice.driverCover = coveragePolicy?.AnnualPremium;
            break;
          case "B00015": //PA - Owner Driver
            sbiPrice.paCover = coveragePolicy?.GrossPremium;
            break;
          case "B00004": //electrical
            sbiPrice.accessories += coveragePolicy?.AnnualPremium;
            break;
          case "B00003": // accessories
            sbiPrice.accessories += coveragePolicy?.AnnualPremium;
            break;
          case "B00005": // external fitted CNG
            sbiPrice.odExternalCngLpg = coveragePolicy?.AnnualPremium;
            break;
          case "B00006": // internal fitted CNG
            sbiPrice.odInternalCngLpg = coveragePolicy?.AnnualPremium;
            break;
          case "B00010":
            sbiPrice.tpCngLpg = coveragePolicy?.AnnualPremium;
            break;
          default:
            break;
        }
      }
    }
  }

  //shree ram set value
  const shreeRamQuote = useSelector((state) => {
    return state?.shreeRamQuickQuote;
  });
  const shreeRamQuotePrice = shreeRamQuote?.data?.data?.quote?.GetQuotResult;
  const shreeRamQuoteLoading = shreeRamQuote?.isLoading;

  let shreeRamPrice = {
    paCover: false,
    zeroDep: false,
    zeroDepLoad: false,
    consumables: false,
    consumablesLoad: false,
    roadAssistance: false,
    engineProtection: false,
    engineProtectionLaod: false,
    invoiceCover: false,
    keyLockReplacement: false,
    ncbProtection: false,
    driverCover: false,
    passengerCover: false,
    accessories: false,
    electricAccessories: false,
    nonElectricAccessories: false,
    totalPremium: false,
    basicPremium: 0,
    deTarrifAmount: 0,
    ncbDiscount: 0,
    idv: false,
    duePremium: false,
    tyreProtection: false,
    emiProtection: false,
    lossofPersonalBelongings: false,
    dailyAllowance: false,
    odExternalCngLpg: false,
    tpExternalCngLpg: false,
    shreeramProtect: false
  };

  for (
    let index = 0;
    index < shreeRamQuotePrice?.CoverDtlList.length;
    index++
  ) {
    let premium = shreeRamQuotePrice.CoverDtlList[index].Premium;
    let switchCaseMatchForAllYear = shreeRamQuotePrice.CoverDtlList[
      index
    ].CoverDesc.replace(/ - (1|2|3|4) Year/g, "");
    switch (switchCaseMatchForAllYear) {
      case "Total Premium":
        shreeRamPrice.totalPremium += Number(premium);
        break;
      case "Depreciation Deduction Waiver (Nil Depreciation) - OD":
        shreeRamPrice.zeroDep += Number(premium);
        break;
      case "Nil Depreciation Loading - OD":
        shreeRamPrice.zeroDepLoad += Number(premium);
        break;
      case "Engine Protector - OD":
        shreeRamPrice.engineProtection += Number(premium);
        break;
      case "Engine Protector Loading - OD":
        shreeRamPrice.engineProtectionLaod += Number(premium);
        break;
      case "Consumable - OD":
        shreeRamPrice.consumables += Number(premium);
        break;
      case "Consumable Loading - OD":
        shreeRamPrice.consumablesLoad += Number(premium);
        break;
      case "Key Replacement - OD":
        shreeRamPrice.keyLockReplacement += Number(premium);
        break;
      case "Road Side Assistance - OD":
        shreeRamPrice.roadAssistance += Number(premium);
        break;
      case "GR42-Outbuilt CNG/LPG-Kit-Cover - OD":
        shreeRamPrice.odExternalCngLpg += Number(premium);
        break;
      case "GR36B2-PA Cover For Passengers (Un-Named Persons) - TP":
        shreeRamPrice.passengerCover += Number(premium);
        break;
      case "GR36A-PA FOR OWNER DRIVER - TP":
        shreeRamPrice.paCover += Number(premium);
        break;
      case "Legal Liability Coverages For Paid Driver - TP":
        shreeRamPrice.driverCover += Number(premium);
        break;
      case "GR42-Outbuilt CNG/LPG-Kit-Cover - TP":
        shreeRamPrice.tpExternalCngLpg += Number(premium);
        break;
      case "GR41-Cover For Electrical and Electronic Accessories - OD":
        shreeRamPrice.accessories += Number(premium);
        shreeRamPrice.electricAccessories += Number(premium);
        break;
      case "Cover For Non Electrical Accessories - OD":
        shreeRamPrice.accessories += Number(premium);
        shreeRamPrice.nonElectricAccessories += Number(premium);
        break;
      case "Return to Invoice - OD":
        shreeRamPrice.invoiceCover += Number(premium);
        break;
      case "Personal Belonging - OD":
        shreeRamPrice.lossofPersonalBelongings += Number(premium);
        break;
      case "Personal Belonging - OD":
        shreeRamPrice.lossofPersonalBelongings += Number(premium);
        break;
      case "Basic Premium - OD":
        shreeRamPrice.basicPremium += Number(premium);
        break;
      case "Basic Premium - TP":
        shreeRamPrice.basicPremium += Number(premium);
        break;
      case "De-Tariff Discount - OD":
        shreeRamPrice.deTarrifAmount += Number(premium);
        break;
      case "NCB Discount  - OD":
        shreeRamPrice.ncbDiscount += Number(premium);
        break;
      case "Motor Protection - OD":
        shreeRamPrice.shreeramProtect += Number(premium);
        break;
      default:
        // Handle other cases or leave empty if not needed
        break;
    }
  }

  shreeRamPrice.basicPremium =
    (shreeRamPrice.basicPremium + shreeRamPrice.accessories) -
    shreeRamPrice.deTarrifAmount -
    shreeRamPrice.ncbDiscount;
  // digit
  const digitQuote = useSelector((state) => {
    return state?.digitQuickQuote;
  });
  const digitQuotePrice = digitQuote?.data?.data?.quote?.["Motor-Quick Quote"];
  const digitQuoteLoading = digitQuote?.isLoading;
  const digitContractCovereage = digitQuotePrice?.contract?.coverages;
  const digitIDV = digitQuotePrice?.vehicle?.vehicleIDV;

  const digitPrice = {
    paCover:
      digitContractCovereage?.["personalAccident"]?.["selection"] &&
        digitContractCovereage?.["personalAccident"]?.["coverAvailability"] !==
        "NOT_AVAILABLE"
        ? digitContractCovereage?.["personalAccident"]?.["netPremium"]
        : false,
    zeroDep:
      digitContractCovereage?.["addons"]?.["partsDepreciation"]?.[
        "selection"
      ] &&
        digitContractCovereage?.["addons"]?.["partsDepreciation"]?.[
        "coverAvailability"
        ] !== "NOT_AVAILABLE"
        ? digitContractCovereage?.["addons"]?.["partsDepreciation"]?.[
        "netPremium"
        ]
        : false,
    consumables:
      digitContractCovereage?.["addons"]?.["consumables"]?.["selection"] &&
        digitContractCovereage?.["addons"]?.["consumables"]?.[
        "coverAvailability"
        ] !== "NOT_AVAILABLE"
        ? digitContractCovereage?.["addons"]?.["consumables"]?.["netPremium"]
        : false,
    roadAssistance:
      digitContractCovereage?.["addons"]?.["roadSideAssistance"]?.[
        "selection"
      ] &&
        digitContractCovereage?.["addons"]?.["roadSideAssistance"]?.[
        "coverAvailability"
        ] !== "NOT_AVAILABLE"
        ? digitContractCovereage?.["addons"]?.["roadSideAssistance"]?.[
        "netPremium"
        ]
        : false,
    engineProtection:
      digitContractCovereage?.["addons"]?.["engineProtection"]?.["selection"] &&
        digitContractCovereage?.["addons"]?.["engineProtection"]?.[
        "coverAvailability"
        ] !== "NOT_AVAILABLE"
        ? digitContractCovereage?.["addons"]?.["engineProtection"]?.[
        "netPremium"
        ]
        : false,
    invoiceCover:
      digitContractCovereage?.["addons"]?.["returnToInvoice"]?.["selection"] &&
        digitContractCovereage?.["addons"]?.["returnToInvoice"]?.[
        "coverAvailability"
        ] !== "NOT_AVAILABLE"
        ? digitContractCovereage?.["addons"]?.["returnToInvoice"]?.[
        "netPremium"
        ]
        : false,
    keyLockReplacement:
      digitContractCovereage?.["addons"]?.["keyAndLockProtect"]?.[
        "selection"
      ] &&
        digitContractCovereage?.["addons"]?.["keyAndLockProtect"]?.[
        "coverAvailability"
        ] !== "NOT_AVAILABLE"
        ? digitContractCovereage?.["addons"]?.["keyAndLockProtect"]?.[
        "netPremium"
        ]
        : false,
    driverCover: digitContractCovereage?.["unnamedPA"]?.["unnamedPaidDriver"]?.[
      "selection"
    ]
      ? digitContractCovereage?.["unnamedPA"]?.["unnamedPaidDriver"]?.[
      "netPremium"
      ]
      : false,
    passengerCover: digitContractCovereage?.["unnamedPA"]?.["unnamedPax"]?.[
      "selection"
    ]
      ? digitContractCovereage?.["unnamedPA"]?.["unnamedPax"]?.["netPremium"]
      : false,
    accessories:
      digitContractCovereage?.["accessories"]?.["cng"]?.["selection"] ||
        digitContractCovereage?.["accessories"]?.["electrical"]?.["selection"] ||
        digitContractCovereage?.["accessories"]?.["nonElectrical"]?.["selection"]
        ? true
        : false,
    tyreProtection:
      digitContractCovereage?.["addons"]?.["tyreProtection"]?.["selection"] &&
        digitContractCovereage?.["addons"]?.["tyreProtection"]?.[
        "coverAvailability"
        ] !== "NOT_AVAILABLE"
        ? digitContractCovereage?.["addons"]?.["tyreProtection"]?.["netPremium"]
        : false,
    rimProtection:
      digitContractCovereage?.["addons"]?.["rimProtection"]?.["selection"] &&
        digitContractCovereage?.["addons"]?.["rimProtection"]?.[
        "coverAvailability"
        ] !== "NOT_AVAILABLE"
        ? digitContractCovereage?.["addons"]?.["rimProtection"]?.["netPremium"]
        : false,
    personalBelonging:
      digitContractCovereage?.["addons"]?.["personalBelonging"]?.[
        "selection"
      ] &&
        digitContractCovereage?.["addons"]?.["personalBelonging"]?.[
        "coverAvailability"
        ] !== "NOT_AVAILABLE"
        ? digitContractCovereage?.["addons"]?.["personalBelonging"]?.[
        "netPremium"
        ]
        : false,
    emiProtection: false,
    dailyAllowance: false,
    ncbProtection: false,
    totalPremium: digitQuotePrice?.grossPremium?.slice(4),
    duePremium: digitQuotePrice?.netPremium?.slice(4),
    //basicPremium: digitQuotePrice?.netPremium
  };

  let OD_BasicPremium =
    digitContractCovereage?.thirdPartyLiability?.netPremium?.slice(4);
  let TP_BasicPremium = digitContractCovereage?.ownDamage?.netPremium?.slice(4);
  if (planType === "Third Party") {
    digitPrice.basicPremium = Math.round(OD_BasicPremium ? Number(OD_BasicPremium) : 0);
  } else if (planType === "Own Damage") {
    digitPrice.basicPremium = Math.round(TP_BasicPremium ? (Number(TP_BasicPremium) - ((digitContractCovereage?.ownDamage?.ncbDiscountWithZeroDepWithPreInspection ? (Number(digitContractCovereage?.ownDamage?.ncbDiscountWithZeroDepWithPreInspection.slice(4))) : 0))) : 0); // od after ncb discount
  } else {
    digitPrice.basicPremium =
      Math.round((OD_BasicPremium ? Number(OD_BasicPremium) : 0) +
        (TP_BasicPremium ? Number(TP_BasicPremium) : 0) -
        (digitContractCovereage?.ownDamage?.ncbDiscountWithZeroDep ? (Number(digitContractCovereage?.ownDamage?.ncbDiscountWithZeroDep.slice(4))) : 0));
  }

  const iciciQuote = useSelector((state) => {
    return state?.iciciQuickQuote;
  });
  const iciciQuotePrice = iciciQuote?.data?.data?.quote;
  const iciciQuoteLoading = iciciQuote?.isLoading;
  //const digitContractCovereage = digitQuotePrice?.contract?.coverages;
  const iciciIDV = iciciQuotePrice?.generalInformation?.depriciatedIDV;

  const iciciPrice = {
    paCover: iciciQuotePrice?.riskDetails?.paCoverForOwnerDriver
      ? iciciQuotePrice?.riskDetails?.paCoverForOwnerDriver
      : false,
    zeroDep: iciciQuotePrice?.riskDetails?.zeroDepreciation
      ? iciciQuotePrice?.riskDetails?.zeroDepreciation
      : false,
    consumables: iciciQuotePrice?.riskDetails?.consumables
      ? iciciQuotePrice?.riskDetails?.consumables
      : false,
    roadAssistance: iciciQuotePrice?.riskDetails?.roadSideAssistance
      ? iciciQuotePrice?.riskDetails?.roadSideAssistance
      : false,
    engineProtection: iciciQuotePrice?.riskDetails?.engineProtect
      ? iciciQuotePrice?.riskDetails?.engineProtect
      : false,
    invoiceCover: iciciQuotePrice?.riskDetails?.returnToInvoice
      ? iciciQuotePrice?.riskDetails?.returnToInvoice
      : false,
    keyLockReplacement: iciciQuotePrice?.riskDetails?.keyProtect
      ? iciciQuotePrice?.riskDetails?.keyProtect
      : false,
    driverCover: iciciQuotePrice?.riskDetails?.paidDriver
      ? iciciQuotePrice?.riskDetails?.paidDriver
      : false,
    passengerCover: iciciQuotePrice?.riskDetails?.paCoverForUnNamedPassenger
      ? iciciQuotePrice?.riskDetails?.paCoverForUnNamedPassenger
      : false,
    accessories:
      iciciQuotePrice?.riskDetails?.electricalAccessories ||
        iciciQuotePrice?.riskDetails?.nonElectricalAccessories
        ? true
        : false,
    tyreProtection: iciciQuotePrice?.riskDetails?.tyreProtect
      ? iciciQuotePrice?.riskDetails?.tyreProtect
      : false,
    rimProtection: false,
    personalBelonging: iciciQuotePrice?.riskDetails?.lossOfPersonalBelongings
      ? iciciQuotePrice?.riskDetails?.lossOfPersonalBelongings
      : false,
    emiProtection: false,
    dailyAllowance: false,
    ncbProtection: false,
    totalPremium: iciciQuotePrice?.finalPremium,
    duePremium: iciciQuotePrice?.packagePremium
      ? iciciQuotePrice?.packagePremium
      : iciciQuotePrice?.totalLiabilityPremium,
  };

  //adding od and tp basic price
  iciciPrice.basicPremium =
    (iciciQuotePrice?.riskDetails?.basicOD
      ? iciciQuotePrice?.riskDetails?.basicOD
      : 0) +
    (iciciQuotePrice?.riskDetails?.biFuelKitOD
      ? iciciQuotePrice?.riskDetails?.biFuelKitOD
      : 0) +
    (iciciQuotePrice?.riskDetails?.electricalAccessories
      ? iciciQuotePrice?.riskDetails?.electricalAccessories
      : 0) +
    (iciciQuotePrice?.riskDetails?.nonElectricalAccessories
      ? iciciQuotePrice?.riskDetails?.nonElectricalAccessories
      : 0) +
    iciciQuotePrice?.riskDetails?.basicTP +
    iciciQuotePrice?.riskDetails?.biFuelKitTP;

  let loadingAmt = iciciQuotePrice?.riskDetails?.breakinLoadingAmount ? iciciQuotePrice?.riskDetails?.breakinLoadingAmount : 0;
  let ncbDiscount = iciciQuotePrice?.riskDetails?.bonusDiscount ? iciciQuotePrice?.riskDetails?.bonusDiscount : 0;

  //minus ncb
  iciciPrice.basicPremium = (iciciPrice.basicPremium + loadingAmt) - ncbDiscount;
  iciciPrice.basicPremium = iciciPrice.basicPremium ? iciciPrice.basicPremium : "NA";
  iciciPrice.duePremium = iciciPrice.duePremium ? iciciPrice.duePremium : 0;
  iciciPrice.odExternalCngLpg = iciciQuotePrice?.riskDetails?.biFuelKitOD;
  iciciPrice.tpCngLpg = iciciQuotePrice?.riskDetails?.biFuelKitTP;

  //Handle Acko Quote 
  const ackoQuote = useSelector((state) => {
    return state?.ackoQuickQuote;
  });

  const ackoQuotePrice = ackoQuote?.data?.data?.quote?.insured[0];
  const ackoQuoteLoading = ackoQuote?.isLoading;
  const ackoIDV = ackoQuotePrice?.idv?.values[0].idv_amount;
  const ackoPrice = {}
  let ackoPlanId = ''
  if (planType === "Comprehensive") {
    ackoPlanId = 'car_comprehensive'
  } else if (planType === "Bundled") {
    ackoPlanId = 'car_bundled'
  } else if (planType === "Third Party") {
    ackoPlanId = 'car_tp'
  } else if (planType === "Own Damage") {
    ackoPlanId = 'car_od'
  }

  let ackoPlan = {};

  if (ackoQuotePrice?.plans) {
    ackoPlan = ackoQuotePrice?.plans.find(item => {
      return item.id == ackoPlanId
    })
  }

  function getAddonPrice(addonData, plan_id) {
    if (addonData) {
      let addOnprice = addonData.find(item => {
        return item.id == plan_id
      })
      return addOnprice?.price?.net_premium ? addOnprice?.price?.net_premium : false
    } else {
      return false
    }
  }

  let ackoisPiRequired = false;
  ackoisPiRequired = ackoPlan?.is_pi_required;

  //check for addons is_pi_flag_req
  if (!ackoisPiRequired) {
    if (ackoPlan?.addons?.length) {
      for (let index = 0; index < ackoPlan?.addons?.length; index++) {
        const element = ackoPlan?.addons[index];
        switch (element.id) {
          case "zero_depreciation_car":
            if (checkboxTrueFalseObject.zeroDep && element.is_pi_required) {
              ackoisPiRequired = true
            }
            break;
          case "rsa_outstation_keyprotect_combo_car":
            if (
              (checkboxTrueFalseObject.keyLockReplacement ||
                checkboxTrueFalseObject.roadAssistance) && element.is_pi_required
            ) {
              ackoisPiRequired = true
            }
            break;
          case "consumables_car":
            if (checkboxTrueFalseObject.consumables && element.is_pi_required) {
              ackoisPiRequired = true
            }
            break;
          case "engine_protect_car":
            if (checkboxTrueFalseObject.engineProtection && element.is_pi_required) {
              ackoisPiRequired = true
            }
            break;
          case "invoice_car":
            if (checkboxTrueFalseObject.invoiceCover && element.is_pi_required) {
              ackoisPiRequired = true
            }
            break;
          case "ncb_protect_car":
            if (checkboxTrueFalseObject.ncbProtection && element.is_pi_required) {
              ackoisPiRequired = true
            }
            break;
          case "personal_belongings_damage_car":
            if (checkboxTrueFalseObject.lossofPersonalBelongings && element.is_pi_required) {
              ackoisPiRequired = true
            }
            break;
          case "electrical_accessories_own_damage_basic_car":
            if (checkboxTrueFalseObject.accessories && element.is_pi_required) {
              ackoisPiRequired = true
            }
            break;
          case "non_electrical_accessories_own_damage_basic_car":
            if (checkboxTrueFalseObject.accessories && element.is_pi_required) {
              ackoisPiRequired = true
            }
            break;
          case "pa_unnamed_passenger_car":
            if (checkboxTrueFalseObject.passengerCover && element.is_pi_required) {
              ackoisPiRequired = true
            }
            break;
          case "pa_owner_car":
            if (checkboxTrueFalseObject.paCover && element.is_pi_required) {
              ackoisPiRequired = true
            }
            break;
          case "legal_liability_car":
            if (checkboxTrueFalseObject.driverCover && element.is_pi_required) {
              ackoisPiRequired = true
            }
            break;
          default:
            break;
        }
      }
    }
  }

  ackoPrice.basicPremium = ackoPlan?.total_price?.net_premium
  ackoPrice.paCover = checkboxTrueFalseObject.paCover ? getAddonPrice(ackoPlan?.addons, "pa_owner_car") : false;
  ackoPrice.zeroDep = checkboxTrueFalseObject.zeroDep ? getAddonPrice(ackoPlan?.addons, "zero_depreciation_car") : false;
  ackoPrice.consumables = checkboxTrueFalseObject.consumables ? getAddonPrice(ackoPlan?.addons, "consumables_car") : false;
  ackoPrice.roadAssistance = checkboxTrueFalseObject.roadAssistance ? getAddonPrice(ackoPlan?.addons, "rsa_outstation_keyprotect_combo_car") : false;
  ackoPrice.engineProtection = checkboxTrueFalseObject.engineProtection ? getAddonPrice(ackoPlan?.addons, "engine_protect_car") : false;
  ackoPrice.invoiceCover = checkboxTrueFalseObject.invoiceCover ? getAddonPrice(ackoPlan?.addons, "invoice_car") : false;
  ackoPrice.keyLockReplacement = checkboxTrueFalseObject.keyLockReplacement ? getAddonPrice(ackoPlan?.addons, "rsa_outstation_keyprotect_combo_car") : false;
  ackoPrice.driverCover = checkboxTrueFalseObject.driverCover ? getAddonPrice(ackoPlan?.addons, "legal_liability_car") : false;
  ackoPrice.passengerCover = checkboxTrueFalseObject.passengerCover ? getAddonPrice(ackoPlan?.addons, "pa_unnamed_passenger_car") : false;
  ackoPrice.accessories = checkboxTrueFalseObject.accessories ? getAddonPrice(ackoPlan?.addons, "electrical_accessories_own_damage_basic_car") : false;
  ackoPrice.accessories = checkboxTrueFalseObject.accessories ? getAddonPrice(ackoPlan?.addons, "non_electrical_accessories_own_damage_basic_car") : false;
  ackoPrice.personalBelonging = checkboxTrueFalseObject.lossofPersonalBelongings ? getAddonPrice(ackoPlan?.addons, "personal_belongings_damage_car") : false;
  ackoPrice.ncbProtection = checkboxTrueFalseObject.ncbProtection ? getAddonPrice(ackoPlan?.addons, "ncb_protect_car") : false;
  ackoPrice.tyreProtection = false;
  ackoPrice.rimProtection = false;
  ackoPrice.emiProtection = false;
  ackoPrice.dailyAllowance = false;
  ackoPrice.odExternalCngLpg = getAddonPrice(ackoPlan?.covers, "own_damage_cng_car");
  ackoPrice.tpCngLpg = getAddonPrice(ackoPlan?.covers, "tp_cng_car");

  ackoPrice.duePremium = ackoPlan?.total_price?.net_premium + ackoPrice.paCover +
    ackoPrice.zeroDep + ackoPrice.consumables + (ackoPrice.roadAssistance || ackoPrice.keyLockReplacement)
    + ackoPrice.engineProtection + ackoPrice.invoiceCover + ackoPrice.driverCover + ackoPrice.passengerCover
    + ackoPrice.accessories + ackoPrice.personalBelonging + ackoPrice.ncbProtection;

  // **************Zuno Quick quote *************//

  // get response from store
  const zunoQuickQuote = useSelector((state) => {
    return state?.zunoQuickQuote;
  });

  // set zuno response to variable
  const {
    premiumDetails,
    policyLevelDetails,
    contractDetails,
    commisionDetails,
  } = zunoQuickQuote?.data?.data?.quote || {};

  // zuno quick quote loading
  const zunoQuickQuoteLoading = zunoQuickQuote?.isLoading;
  const zunoIDV = findKeyValue(contractDetails, "systemIdv")

  //  Calculate their value
  let zunoElectricAccessories = 0,
    zunononElectricAccessories = 0,
    zunoPassengerCover = 0,
    zunoDriverCover = 0,
    zunoExtrnalCng = 0;
  if (premiumDetails?.totalODPremium > 0 && premiumDetails?.totalTPPremium > 0) {
    if ((contractDetails[0]?.coverage?.subCoverage)?.length > 0) {
      for (let item of contractDetails[0]?.coverage?.subCoverage) {
        if (item.salesProductTemplateId == "MOSCMF02") {
          zunoElectricAccessories = item.grossPremium;
        } else if (item.salesProductTemplateId == "MOSCMF01") {
          zunononElectricAccessories = item.grossPremium;
        } else if (item.salesProductTemplateId == "MOSCMF03") {
          zunoExtrnalCng = item.grossPremium
        }
      }
    }
  } else if (premiumDetails?.totalODPremium > 0 && premiumDetails?.totalTPPremium == 0 && (contractDetails[0]?.coverage?.subCoverage)?.length > 0) {
    for (let item of contractDetails[0]?.coverage?.subCoverage) {
      if (item.salesProductTemplateId == "MOSCMF02") {
        zunoElectricAccessories = item.grossPremium;
      } else if (item.salesProductTemplateId == "MOSCMF01") {
        zunononElectricAccessories = item.grossPremium;
      } else if (item.salesProductTemplateId == "MOSCMF03") {
        zunoExtrnalCng = item.grossPremium
      }
    }
  } else if (premiumDetails?.totalTPPremium == 0 && Array.isArray(contractDetails?.coverage?.subCoverage)) {
    for (let item of contractDetails?.coverage?.subCoverage) {
      if (item.salesProductTemplateId == "MOSCMF02") {
        zunoElectricAccessories = item.grossPremium;
      } else if (item.salesProductTemplateId == "MOSCMF01") {
        zunononElectricAccessories = item.grossPremium;
      } else if (item.salesProductTemplateId == "MOSCMF03") {
        zunoExtrnalCng = item.grossPremium
      }
    }
  }

  // for passengerZuno, driverZuno , tpCngLpgZuno
  let contractDetailsLength = Array.isArray(contractDetails) ? contractDetails?.length - 1 : 0;
  if (premiumDetails?.totalODPremium > 0 && contractDetailsLength > 0) {
    if ((contractDetails[contractDetailsLength]?.coverage?.subCoverage)?.length > 0) {
      for (let item of contractDetails[contractDetailsLength]?.coverage
        ?.subCoverage) {
        if (item.salesProductTemplateId == "MOSCMF24") {
          zunoPassengerCover = item.totalPremium;
        } else if (item.salesProductTemplateId == "MOSCMF20") {
          zunoDriverCover = item.totalPremium;
        } else if (item.salesProductTemplateId == "MOSCMF17") {
          zunoExtrnalCng = item.totalPremium;
        }
      }
    }
  } else if (premiumDetails?.totalODPremium == 0 && contractDetailsLength == 0) {
    if ((contractDetails?.coverage?.subCoverage)?.length > 0) {
      for (let item of contractDetails?.coverage?.subCoverage) {
        if (item.salesProductTemplateId == "MOSCMF24") {
          zunoPassengerCover = item.totalPremium;
        } else if (item.salesProductTemplateId == "MOSCMF20") {
          zunoDriverCover = item.totalPremium;
        } else if (item.salesProductTemplateId == "MOSCMF17") {
          zunoExtrnalCng = item.totalPremium;
        }
      }
    }
  } else if (premiumDetails?.totalODPremium == 0 && contractDetailsLength > 0) {
    if ((contractDetails[contractDetailsLength]?.coverage?.subCoverage)?.length > 0) {
      for (let item of contractDetails[contractDetailsLength]?.coverage
        ?.subCoverage) {
        if (item.salesProductTemplateId == "MOSCMF24") {
          zunoPassengerCover = item.totalPremium;
        } else if (item.salesProductTemplateId == "MOSCMF20") {
          zunoDriverCover = item.totalPremium;
        } else if (item.salesProductTemplateId == "MOSCMF17") {
          zunoExtrnalCng = item.totalPremium;
        }
      }
    }
  }

  // Zuno price
  const zunoPrice = {
    paCover: (premiumDetails?.totalPApremium > 0)
      ? premiumDetails?.totalPApremium
      : false,
    zeroDep: (premiumDetails?.individualAddOnPremium?.zeroDepreciation > 0)
      ? premiumDetails?.individualAddOnPremium?.zeroDepreciation
      : false,
    consumables: (premiumDetails?.individualAddOnPremium?.consumableCover > 0)
      ? premiumDetails?.individualAddOnPremium?.consumableCover
      : false,
    roadAssistance: (premiumDetails?.individualAddOnPremium?.basicRoadAssistance > 0)
      ? premiumDetails?.individualAddOnPremium?.basicRoadAssistance
      : false,
    engineProtection: (premiumDetails?.individualAddOnPremium?.engineProtect > 0)
      ? premiumDetails?.individualAddOnPremium?.engineProtect
      : false,
    invoiceCover: (premiumDetails?.individualAddOnPremium?.invoiceValueProtect > 0)
      ? premiumDetails?.individualAddOnPremium?.invoiceValueProtect
      : false,
    keyLockReplacement: (premiumDetails?.individualAddOnPremium?.keyReplacement > 0)
      ? premiumDetails?.individualAddOnPremium?.keyReplacement
      : false,
    odExternalCngLpg: Number(zunoExtrnalCng > 0) ? zunoExtrnalCng : false,
    driverCover: Number(zunoDriverCover) > 0 ? zunoDriverCover : false,
    passengerCover: Number(zunoPassengerCover) > 0 ? zunoPassengerCover : false,
    accessories:
      Number(zunoElectricAccessories) + Number(zunononElectricAccessories) > 0
        ? Number(zunoElectricAccessories) + Number(zunononElectricAccessories)
        : false,
    tyreProtection: (premiumDetails?.individualAddOnPremium?.tyreProtect > 0)
      ? premiumDetails?.individualAddOnPremium?.tyreProtect
      : false,
    rimProtection: false,
    lossofPersonalBelongings: (premiumDetails?.individualAddOnPremium?.lossOfPersonalBelongings > 0)
      ? premiumDetails?.individualAddOnPremium?.lossOfPersonalBelongings
      : false,
    emiProtection: false,
    dailyAllowance: false,
    ncbProtection: (premiumDetails?.individualAddOnPremium?.protectionOfNCB > 0)
      ? premiumDetails?.individualAddOnPremium?.protectionOfNCB
      : false,
    totalPremium: premiumDetails?.grossTotalPremium,
    duePremium: premiumDetails?.netTotalPremium,
    basicPremium:
      premiumDetails?.totalODPremium +
      premiumDetails?.totalTPPremium -
      Number(zunoElectricAccessories) -
      Number(zunononElectricAccessories) -
      Number(zunoDriverCover) -
      Number(zunoPassengerCover),
  };

  // to display amount with commas use this in future:-----> .toLocaleString('en-IN')

  return (
    <>
      <section className="plancard_main_container">
        {planType === "Comprehensive" || planType === "Bundled" ? (
          <>
            {/* *********************acko******************  */}

            {/* <div className="plancard_card">
              <div className="padding_breakup_container">
                <div className="plancard_card_img_holder">
                  <img src={acko} alt="" srcSet="" />
                </div>
                {
                  (planType !== "Bundled" && planType !== "Third Party") && (inspectDetails === true && !ackoisPiRequired) && (
                    <div className="inspection_tab inspection_tab-green">
                      <img src={noInspection} alt="" srcSet="" />
                      <p>No Inspection Required</p>
                    </div>
                  )
                }

                {
                  ackoisPiRequired === true && (planType !== "Bundled" && planType !== "Third Party") && (
                    <div className="inspection_tab">
                      <img src={inspection} alt="" srcSet="" />
                      <p>Inspection Required</p>
                    </div>
                  )
                }

                <div className="premiumContainer_pc">
                  <p className="idv_title_pc">
                    Cover Value(IDV) :{" "}
                    {ackoQuoteLoading ||
                      ackoPrice.duePremium === undefined ||
                      ackoPrice.duePremium === 0 ||
                      ShowSkeleton || isNaN(ackoPrice.duePremium) ? (
                      <SkeletonLoader
                        variant="rectangular"
                        width={120}
                        height={15}
                      />
                    ) : (
                      <span>₹ {ackoIDV?.toLocaleString('en-IN')}</span>
                    )}
                  </p>

                  {ackoQuoteLoading ||
                    ackoPrice.duePremium === undefined ||
                    ackoPrice.duePremium === 0 ||
                    ShowSkeleton || isNaN(ackoPrice.duePremium) ? (
                    <ButtonLoader />
                  ) : (
                    <Botton
                      text={`₹ ${ackoQuoteLoading ? "...Loading" : (isNaN(ackoPrice.duePremium) ? 0 : ackoPrice.duePremium?.toLocaleString('en-IN'))}`}
                      className="buyNowBtn_pc"
                      name="Plan Card"
                      spanTxt="Buy Now"
                      event={(e) =>
                        ackoPrice.duePremium
                          ? handleBuyNow(acko, "", ACKO)
                          : ""
                      }
                    />
                  )}
                </div>
                <div className="breakup_container">
                  {ackoQuoteLoading || ackoPrice?.basicPremium === NaN ||
                    ackoPrice.duePremium === 0 ||
                    ShowSkeleton || isNaN(ackoPrice.duePremium)
                    ? (
                      <SkeletonLoader
                        variant="rectangular"
                        height={15}
                      />
                    ) : (
                      <div className="planHeadValueContainer">
                        <div className="planHead">
                          <p>
                            Basic Price{" "} {
                              ackoPrice?.basicPremium ? (
                                <span>
                                  ₹{" "}
                                  {ackoQuoteLoading
                                    ? "...Loading"
                                    : Math.round(ackoPrice?.basicPremium)?.toLocaleString('en-IN')}
                                </span>
                              ) : (<span>0</span>)
                            }
                          </p>
                        </div>
                      </div>
                    )}
                  {checkboxTrueFalseObject.paCover ? (
                    <>
                      {ackoQuoteLoading ||
                        ackoPrice.duePremium === undefined ||
                        ackoPrice.duePremium === 0 ||
                        ShowSkeleton || isNaN(ackoPrice.duePremium) ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              Personal Accident Cover
                              <span>
                                ₹{" "}
                                {ackoQuoteLoading
                                  ? "...Loading"
                                  : Math.round(ackoPrice.paCover)?.toLocaleString('en-IN') || 0}
                              </span>
                            </p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={ackoPrice.paCover ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {ackoPrice.tpCngLpg && getBodyData.data.isCngLpgVehicleType ? (
                    <>
                      {ackoQuoteLoading ||
                        ackoPrice.duePremium === undefined ||
                        ackoPrice.duePremium === 0 ||
                        ShowSkeleton || isNaN(ackoPrice.duePremium) ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>External Fitted CNG</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={ackoPrice?.tpCngLpg ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.zeroDep ? (
                    <>
                      {ackoQuoteLoading ||
                        ackoPrice.duePremium === undefined ||
                        ackoPrice.duePremium === 0 ||
                        ShowSkeleton || isNaN(ackoPrice.duePremium) ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              Zero Depreciation{" "}
                              <span className="bold_premium">
                                ₹{" "}
                                {ackoQuoteLoading
                                  ? "...Loading"
                                  : Math.round(ackoPrice?.zeroDep)?.toLocaleString('en-IN') || 0}
                              </span>
                            </p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={ackoPrice?.zeroDep ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {
                    checkboxTrueFalseObject.shreeramProtect ? (
                      <>
                        {ackoQuoteLoading ||
                          ackoPrice.duePremium === undefined ||
                          ackoPrice.duePremium === 0 ||
                          ShowSkeleton || isNaN(ackoPrice.duePremium) ? (
                          <SkeletonLoader
                            variant="rectangular"
                            height={15}
                          />
                        ) : (
                          <div className="planHeadValueContainer">
                            <div className="planHead">
                              <p>Motor Protection</p>
                            </div>
                            <div className="result_pc">
                              <img
                                src={fail}
                                alt=""
                                srcSet=""
                              />
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  {checkboxTrueFalseObject.engineProtection ? (
                    <>
                      {ackoQuoteLoading ||
                        ackoPrice.duePremium === undefined ||
                        ackoPrice.duePremium === 0 ||
                        ShowSkeleton || isNaN(ackoPrice.duePremium) ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Engine Protector</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={ackoPrice?.engineProtection ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.consumables ? (
                    <>
                      {ackoQuoteLoading ||
                        ackoPrice.duePremium === undefined ||
                        ackoPrice.duePremium === 0 ||
                        ShowSkeleton || isNaN(ackoPrice.duePremium) ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Consumable</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={ackoPrice?.consumables ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.keyLockReplacement || checkboxTrueFalseObject.roadAssistance ? (
                    <>
                      {ackoQuoteLoading ||
                        ackoPrice.duePremium === undefined ||
                        ackoPrice.duePremium === 0 ||
                        ShowSkeleton || isNaN(ackoPrice.duePremium) ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Key and Lock Replacement</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={(ackoPrice?.keyLockReplacement || ackoPrice?.roadAssistance) ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.invoiceCover ? (
                    <>
                      {ackoQuoteLoading ||
                        ackoPrice.duePremium === undefined ||
                        ackoPrice.duePremium === 0 ||
                        ShowSkeleton || isNaN(ackoPrice.duePremium) ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Invoice Cover</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={ackoPrice?.invoiceCover ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.keyLockReplacement || checkboxTrueFalseObject.roadAssistance ? (
                    <>
                      {ackoQuoteLoading ||
                        ackoPrice.duePremium === undefined ||
                        ackoPrice.duePremium === 0 ||
                        ShowSkeleton || isNaN(ackoPrice.duePremium) ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>24x7 Roadside Assistance</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={(ackoPrice?.keyLockReplacement || ackoPrice?.roadAssistance) ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.tyreProtection ? (
                    <>
                      {ackoQuoteLoading ||
                        ackoPrice.duePremium === undefined ||
                        ackoPrice.duePremium === 0 ||
                        ShowSkeleton || isNaN(ackoPrice.duePremium) ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Tyre Protection</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={ackoPrice?.tyreProtection ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {ackoPrice.emiProtection ? (
                    <>
                      {ackoQuoteLoading ||
                        ackoPrice.duePremium === undefined ||
                        ackoPrice.duePremium === 0 ||
                        ShowSkeleton || isNaN(ackoPrice.duePremium) ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>{ackoQuoteLoading ? loading : "EMI Protection"}</p>
                          </div>
                          <div className="result_pc">
                            <img src={success} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.emiProtection &&
                    !ackoPrice.emiProtection ? (
                    <>
                      {ackoQuoteLoading ||
                        ackoPrice.duePremium === undefined ||
                        ackoPrice.duePremium === 0 ||
                        ShowSkeleton || isNaN(ackoPrice.duePremium) ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>{ackoQuoteLoading ? loading : "EMI Protection"}</p>
                          </div>
                          <div className="result_pc">
                            <img src={fail} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.lossofPersonalBelongings ? (
                    <>
                      {ackoQuoteLoading ||
                        ackoPrice.duePremium === undefined ||
                        ackoPrice.duePremium === 0 ||
                        ShowSkeleton || isNaN(ackoPrice.duePremium) ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Loss of Personal Belongings</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={ackoPrice?.personalBelonging ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {ackoPrice.dailyAllowance ? (
                    <>
                      {ackoQuoteLoading ||
                        ackoPrice.duePremium === undefined ||
                        ackoPrice.duePremium === 0 ||
                        ShowSkeleton || isNaN(ackoPrice.duePremium) ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>{ackoQuoteLoading ? loading : "Daily Allowance"}</p>
                          </div>
                          <div className="result_pc">
                            <img src={success} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.dailyAllowance &&
                    !ackoPrice.dailyAllowance ? (
                    <>
                      {ackoQuoteLoading ||
                        ackoPrice.duePremium === undefined ||
                        ackoPrice.duePremium === 0 ||
                        ShowSkeleton || isNaN(ackoPrice.duePremium) ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>{ackoQuoteLoading ? loading : "Daily Allowance"}</p>
                          </div>
                          <div className="result_pc">
                            <img src={fail} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {ackoPrice.passengerCover ? (
                    <>
                      {ackoQuoteLoading ||
                        ackoPrice.duePremium === undefined ||
                        ackoPrice.duePremium === 0 ||
                        ShowSkeleton || isNaN(ackoPrice.duePremium) ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>{ackoQuoteLoading ? loading : "Passenger Cover"}</p>
                          </div>
                          <div className="result_pc">
                            <img src={success} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.passengerCover &&
                    !ackoPrice.passengerCover ? (
                    <>
                      {ackoQuoteLoading ||
                        ackoPrice.duePremium === undefined ||
                        ackoPrice.duePremium === 0 ||
                        ShowSkeleton || isNaN(ackoPrice.duePremium) ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>{ackoQuoteLoading ? loading : "Passenger Cover"}</p>
                          </div>
                          <div className="result_pc">
                            <img src={fail} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.ncbProtection ? (
                    <>
                      {ackoQuoteLoading ||
                        ackoPrice.duePremium === undefined ||
                        ackoPrice.duePremium === 0 ||
                        ShowSkeleton || isNaN(ackoPrice.duePremium) ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>NCB Protection Cover</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={ackoPrice?.ncbProtection ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.accessories ? (
                    <>
                      {ackoQuoteLoading ||
                        ackoPrice.duePremium === undefined ||
                        ackoPrice.duePremium === 0 ||
                        ShowSkeleton || isNaN(ackoPrice.duePremium) ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Accessories</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={ackoPrice?.accessories ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {ackoPrice.driverCover ? (
                    <>
                      {ackoQuoteLoading ||
                        ackoPrice.duePremium === undefined ||
                        ackoPrice.duePremium === 0 ||
                        ShowSkeleton || isNaN(ackoPrice.duePremium) ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>{ackoQuoteLoading ? loading : "Driver Cover"}</p>
                          </div>
                          <div className="result_pc">
                            <img src={success} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.driverCover &&
                    !ackoPrice.driverCover ? (
                    <>
                      {ackoQuoteLoading ||
                        ackoPrice.duePremium === undefined ||
                        ackoPrice.duePremium === 0 ||
                        ShowSkeleton || isNaN(ackoPrice.duePremium) ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>{ackoQuoteLoading ? loading : "Driver Cover"}</p>
                          </div>
                          <div className="result_pc">
                            <img src={fail} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="bottomOptions_planCard">
                <div className="option_div_pc option_div_pc_border">
                  {!ackoQuoteLoading && ackoPrice.duePremium && !ShowSkeleton && !isNaN(ackoPrice.duePremium) ? (
                    <PlanDetails
                      QuickQuote={ackoQuote}
                      triggerTab="Plan Details"
                      handleBuyNow={(e) =>
                        ackoPrice.duePremium
                          ? handleBuyNow(acko, "", ACKO)
                          : ""
                      }
                      companyImg={acko}
                      companyName={ACKO}
                      vehicleBreakupPrice={ackoPrice}
                      bodyData={getBodyData}
                      setShowBottomTab={setShowBottomTab}
                      carDetails={carDetails}
                    />
                  ) : ("")}
                </div>
                <div className="option_div_pc">
                  <p>Cashless Garages</p>
                </div>
              </div>
            </div> */}

            {/* **********************zuno******************** */}

            {/* <div className="plancard_card">
              <div className="padding_breakup_container">
                <div className="plancard_card_img_holder">
                  <img src={zuno} alt="" srcSet="" />
                </div>
                {
                  inspectDetails === true && (planType !== "Bundled" && planType !== "Third Party") && (
                    <div className="inspection_tab">
                      <img src={inspection} alt="" srcSet="" />
                      <p>Inspection Required</p>
                    </div>
                  )
                }
                <div className="premiumContainer_pc">
                  <p className="idv_title_pc">
                    Cover Value(IDV) :{" "}
                    {zunoQuickQuoteLoading ||
                      zunoPrice.duePremium === undefined ||
                      zunoPrice.duePremium === 0 ||
                      ShowSkeleton ? (
                      <SkeletonLoader
                        variant="rectangular"
                        width={120}
                        height={15}
                      />
                    ) : (
                      <span>₹ {Math.round(zunoIDV)?.toLocaleString('en-IN')}</span>
                    )}
                  </p>
                  {zunoQuickQuoteLoading ||
                    zunoPrice?.duePremium === undefined ||
                    zunoPrice.duePremium === 0 ? (
                    <ButtonLoader />
                  ) : (
                    <Botton
                      text={`₹ ${zunoQuickQuoteLoading
                        ? loading
                        : Math.round(Number(zunoPrice?.duePremium))?.toLocaleString('en-IN')
                        }`}
                      className="buyNowBtn_pc"
                      name="Plan Card"
                      spanTxt="Buy Now"
                      event={(e) => handleBuyNow(zuno, "", ZUNO)}
                    />
                  )}
                </div>
                <div className="breakup_container">
                  {zunoQuickQuoteLoading ||
                    zunoPrice.duePremium === undefined ||
                    zunoPrice.duePremium === 0 ||
                    ShowSkeleton ? (
                    <SkeletonLoader
                      variant="rectangular"
                      height={15}
                    />
                  ) : (
                    <div className="planHeadValueContainer">
                      <div className="planHead">
                        <p>
                          Basic Price
                          <span>
                            ₹{" "}
                            {zunoQuickQuoteLoading
                              ? loading
                              : zunoPrice?.basicPremium
                                ? Math.round(zunoPrice?.basicPremium)?.toLocaleString('en-IN')
                                : 0}
                          </span>
                        </p>
                      </div>
                    </div>
                  )}
                  {checkboxTrueFalseObject.paCover ? (
                    <>
                      {zunoQuickQuoteLoading ||
                        zunoPrice.duePremium === undefined ||
                        zunoPrice.duePremium === 0 ||
                        premiumDetails?.totalPApremium == 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              Personal Accident Cover
                              <span>
                                ₹{" "}
                                {zunoQuickQuoteLoading
                                  ? loading
                                  : Math.round(zunoPrice?.paCover)?.toLocaleString('en-IN') || 0}
                              </span>
                            </p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={zunoPrice?.paCover ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.zeroDep ? (
                    <>
                      {zunoQuickQuoteLoading ||
                        zunoPrice.duePremium === undefined ||
                        zunoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              Zero Depreciation{" "}
                              <span className="bold_premium">
                                ₹{" "}
                                {zunoQuickQuoteLoading
                                  ? loading
                                  : Math.round(zunoPrice?.zeroDep)?.toLocaleString('en-IN') || 0}
                              </span>
                            </p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={zunoPrice?.zeroDep ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {
                    checkboxTrueFalseObject.shreeramProtect ? (
                      <>
                        {zunoQuickQuoteLoading ||
                          zunoPrice.duePremium === undefined ||
                          zunoPrice.duePremium === 0 ||
                          ShowSkeleton ? (
                          <SkeletonLoader
                            variant="rectangular"
                            height={15}
                          />
                        ) : (
                          <div className="planHeadValueContainer">
                            <div className="planHead">
                              <p>Motor Protection</p>
                            </div>
                            <div className="result_pc">
                              <img
                                src={fail}
                                alt=""
                                srcSet=""
                              />
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  {checkboxTrueFalseObject.engineProtection ? (
                    <>
                      {zunoQuickQuoteLoading ||
                        zunoPrice.duePremium === undefined ||
                        zunoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Engine Protector</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={zunoPrice?.engineProtection ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.consumables ? (
                    <>
                      {zunoQuickQuoteLoading ||
                        zunoPrice.duePremium === undefined ||
                        zunoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Consumable</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={zunoPrice?.consumables ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.keyLockReplacement ? (
                    <>
                      {zunoQuickQuoteLoading ||
                        zunoPrice.duePremium === undefined ||
                        zunoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Key and Lock Replacement</p>
                          </div>

                          <div className="result_pc">
                            <img
                              src={
                                zunoPrice?.keyLockReplacement ? success : fail
                              }
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.invoiceCover ? (
                    <>
                      {zunoQuickQuoteLoading ||
                        zunoPrice.duePremium === undefined ||
                        zunoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Invoice Cover</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={zunoPrice?.invoiceCover ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.roadAssistance ? (
                    <>
                      {zunoQuickQuoteLoading ||
                        zunoPrice.duePremium === undefined ||
                        zunoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>24x7 Roadside Assistance</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={zunoPrice?.roadAssistance ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.tyreProtection ? (
                    <>
                      {zunoQuickQuoteLoading ||
                        zunoPrice.duePremium === undefined ||
                        zunoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Tyre Protection</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={zunoPrice?.tyreProtection ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.emiProtection ? (
                    <>
                      {zunoQuickQuoteLoading ||
                        zunoPrice.duePremium === undefined ||
                        zunoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>EMI Protection</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={zunoPrice?.emiProtection ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.lossofPersonalBelongings ? (
                    <>
                      {zunoQuickQuoteLoading ||
                        zunoPrice.duePremium === undefined ||
                        zunoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Loss of Personal Belongings</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={
                                zunoPrice?.lossofPersonalBelongings ? success : fail
                              }
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.dailyAllowance ? (
                    <>
                      {zunoQuickQuoteLoading ||
                        zunoPrice.duePremium === undefined ||
                        zunoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Daily Allowance</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={zunoPrice?.dailyAllowance ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.passengerCover ? (
                    <>
                      {zunoQuickQuoteLoading ||
                        zunoPrice.duePremium === undefined ||
                        zunoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Passenger Cover</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={zunoPrice?.passengerCover ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {zunoPrice.odExternalCngLpg && getBodyData.data.isCngLpgVehicleType ? (
                    <>
                      {zunoQuickQuoteLoading ||
                        zunoPrice.duePremium === undefined ||
                        zunoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>External Fitted CNG</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={zunoPrice?.odExternalCngLpg ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.ncbProtection ? (
                    <>
                      {zunoQuickQuoteLoading ||
                        zunoPrice.duePremium === undefined ||
                        zunoPrice.duePremium === 0 ||
                        ShowSkeleton
                        ? (
                          <SkeletonLoader
                            variant="rectangular"
                            height={15}
                          />
                        ) : (
                          <div className="planHeadValueContainer">
                            <div className="planHead">
                              <p>NCB Protection Cover</p>
                            </div>
                            <div className="result_pc">
                              <img
                                src={zunoPrice?.ncbProtection ? success : fail}
                                alt=""
                                srcSet=""
                              />
                            </div>
                          </div>
                        )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.accessories ? (
                    <>
                      {zunoQuickQuoteLoading ||
                        zunoPrice.duePremium === undefined ||
                        zunoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Accessories</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={zunoPrice?.accessories ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.driverCover ? (
                    <>
                      {zunoQuickQuoteLoading ||
                        zunoPrice.duePremium === undefined ||
                        zunoPrice.duePremium === 0 ||
                        zunoDriverCover == false ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Driver Cover</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={zunoPrice?.driverCover ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="bottomOptions_planCard">
                <div className="option_div_pc option_div_pc_border ">
                  {!zunoQuickQuoteLoading && zunoPrice.duePremium && !ShowSkeleton ? (
                    <PlanDetails
                      QuickQuote={zunoQuickQuote}
                      triggerTab="Plan Details"
                      handleBuyNow={(e) => handleBuyNow(zuno, "", ZUNO)}
                      companyImg={zuno}
                      companyName={ZUNO}
                      vehicleBreakupPrice={zunoPrice}
                      bodyData={getBodyData}
                      setShowBottomTab={setShowBottomTab}
                      carDetails={carDetails}
                    />
                  ) : ("")}
                </div>
                <div className="option_div_pc">
                  <p>Cashless Garages</p>
                </div>
              </div>
            </div> */}

            {/* *********************shreeram*************** */}

            <div className="plancard_card">
              <div className="padding_breakup_container">
                <div className="plancard_card_img_holder">
                  <img src={shreeRam} alt="" srcSet="" />
                </div>
                {
                  inspectDetails === true && (planType !== "Bundled" && planType !== "Third Party") && (
                    <div className="inspection_tab">
                      <img src={inspection} alt="" srcSet="" />
                      <p>Inspection Required</p>
                    </div>
                  )
                }
                <div className="premiumContainer_pc">
                  <p className="idv_title_pc">
                    Cover Value(IDV) :{" "}
                    {shreeRamQuoteLoading ||
                      shreeRamPrice?.totalPremium === undefined ||
                      shreeRamPrice?.totalPremium === 0 ||
                      ShowSkeleton || shreeRamPrice?.totalPremium === false ? (
                      <SkeletonLoader
                        variant="rectangular"
                        width={120}
                        height={15}
                      />
                    ) : (
                      <span>₹ {Math.round(shreeRamQuotePrice?.VehicleIDV)?.toLocaleString('en-IN')}</span>
                    )}
                  </p>
                  {shreeRamQuoteLoading ||
                    shreeRamPrice?.totalPremium === "" ||
                    ShowSkeleton || shreeRamPrice?.totalPremium === false ? (
                    <ButtonLoader />
                  ) : (
                    <Botton
                      text={`₹ ${!shreeRamQuoteLoading
                        ? shreeRamPrice?.totalPremium
                          ? Math.round(shreeRamPrice?.totalPremium)?.toLocaleString('en-IN')
                          : ""
                        : "...Loading"
                        }`}
                      className="buyNowBtn_pc"
                      name="Plan Card"
                      spanTxt="Buy Now"
                      event={(e) => handleBuyNow(shreeRam, " ", SHREERAM)}
                    />
                  )}
                </div>
                <div className="breakup_container">
                  {shreeRamQuoteLoading ||
                    shreeRamPrice?.totalPremium === undefined ||
                    shreeRamPrice?.totalPremium === 0 ||
                    ShowSkeleton || shreeRamPrice?.totalPremium === false ? (
                    <SkeletonLoader
                      variant="rectangular"
                      height={15}
                    />
                  ) : (
                    <div className="planHeadValueContainer">
                      <div className="planHead">
                        <p>
                          Basic Price{" "}
                          <span>
                            ₹{" "}
                            {!shreeRamQuoteLoading
                              ? shreeRamPrice?.basicPremium
                                ? (Math.round(shreeRamPrice?.basicPremium)?.toLocaleString('en-IN'))
                                : 0
                              : "...Loading"}
                          </span>
                        </p>
                      </div>
                    </div>
                  )}
                  {checkboxTrueFalseObject.paCover ? (
                    <>
                      {shreeRamQuoteLoading ||
                        shreeRamPrice?.totalPremium === undefined ||
                        shreeRamPrice?.totalPremium === 0 ||
                        ShowSkeleton || shreeRamPrice?.totalPremium === false ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              Personal Accident Cover
                              <span>
                                ₹{" "}
                                {!shreeRamQuoteLoading
                                  ? shreeRamPrice?.paCover
                                    ? Math.round(shreeRamPrice?.paCover)?.toLocaleString('en-IN')
                                    : 0
                                  : "...Loading"}
                              </span>
                            </p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={shreeRamPrice?.paCover ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.zeroDep ? (
                    <>
                      {shreeRamQuoteLoading ||
                        shreeRamPrice?.totalPremium === undefined ||
                        shreeRamPrice?.totalPremium === 0 ||
                        ShowSkeleton || shreeRamPrice?.totalPremium === false ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              Zero Depreciation{" "}
                              <span className="bold_premium">
                                ₹{" "}
                                {!shreeRamQuoteLoading
                                  ? shreeRamPrice?.zeroDep
                                    ? Math.round(shreeRamPrice?.zeroDep)?.toLocaleString('en-IN')
                                    : 0
                                  : "...Loading"}
                              </span>
                            </p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={shreeRamPrice?.zeroDep ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {
                    checkboxTrueFalseObject.shreeramProtect ? (
                      <>
                        {shreeRamQuoteLoading ||
                          shreeRamPrice?.totalPremium === undefined ||
                          shreeRamPrice?.totalPremium === 0 ||
                          ShowSkeleton || shreeRamPrice?.totalPremium === false ? (
                          <SkeletonLoader
                            variant="rectangular"
                            height={15}
                          />
                        ) : (
                          <div className="planHeadValueContainer">
                            <div className="planHead">
                              <p>Motor Protection</p>
                            </div>
                            <div className="result_pc">
                              <img
                                src={
                                  shreeRamPrice?.shreeramProtect ? success : fail
                                }
                                alt=""
                                srcSet=""
                              />
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      ""
                    )}

                  {checkboxTrueFalseObject.engineProtection ? (
                    <>
                      {shreeRamQuoteLoading ||
                        shreeRamPrice?.totalPremium === undefined ||
                        shreeRamPrice?.totalPremium === 0 ||
                        ShowSkeleton || shreeRamPrice?.totalPremium === false ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Engine Protector</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={
                                shreeRamPrice?.engineProtection ? success : fail
                              }
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.consumables ? (
                    <>
                      {shreeRamQuoteLoading ||
                        shreeRamPrice?.totalPremium === undefined ||
                        shreeRamPrice?.totalPremium === 0 ||
                        ShowSkeleton || shreeRamPrice?.totalPremium === false ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Consumable</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={shreeRamPrice?.consumables ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.keyLockReplacement ? (
                    <>
                      {shreeRamQuoteLoading ||
                        shreeRamPrice?.totalPremium === undefined ||
                        shreeRamPrice?.totalPremium === 0 ||
                        ShowSkeleton || shreeRamPrice?.totalPremium === false ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Key and Lock Replacement</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={
                                shreeRamPrice?.keyLockReplacement
                                  ? success
                                  : fail
                              }
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.invoiceCover ? (
                    <>
                      {shreeRamQuoteLoading ||
                        shreeRamPrice?.totalPremium === undefined ||
                        shreeRamPrice?.totalPremium === 0 ||
                        ShowSkeleton || shreeRamPrice?.totalPremium === false ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Invoice Cover</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={shreeRamPrice?.invoiceCover ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.roadAssistance ? (
                    <>
                      {shreeRamQuoteLoading ||
                        shreeRamPrice?.totalPremium === undefined ||
                        shreeRamPrice?.totalPremium === 0 ||
                        ShowSkeleton || shreeRamPrice?.totalPremium === false ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>24x7 Roadside Assistance</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={
                                shreeRamPrice?.roadAssistance ? success : fail
                              }
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.tyreProtection ? (
                    <>
                      {shreeRamQuoteLoading ||
                        shreeRamPrice?.totalPremium === undefined ||
                        shreeRamPrice?.totalPremium === 0 ||
                        ShowSkeleton || shreeRamPrice?.totalPremium === false ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Tyre Protection</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={
                                shreeRamPrice?.tyreProtection ? success : fail
                              }
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.emiProtection ? (
                    <>
                      {shreeRamQuoteLoading ||
                        shreeRamPrice?.totalPremium === undefined ||
                        shreeRamPrice?.totalPremium === 0 ||
                        ShowSkeleton || shreeRamPrice?.totalPremium === false ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>EMI Protection</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={
                                shreeRamPrice?.emiProtection ? success : fail
                              }
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.lossofPersonalBelongings ? (
                    <>
                      {shreeRamQuoteLoading ||
                        shreeRamPrice?.totalPremium === undefined ||
                        shreeRamPrice?.totalPremium === 0 ||
                        ShowSkeleton || shreeRamPrice?.totalPremium === false ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Loss of Personal Belongings</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={
                                shreeRamPrice?.lossofPersonalBelongings
                                  ? success
                                  : fail
                              }
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.dailyAllowance ? (
                    <>
                      {shreeRamQuoteLoading ||
                        shreeRamPrice?.totalPremium === undefined ||
                        shreeRamPrice?.totalPremium === 0 ||
                        ShowSkeleton || shreeRamPrice?.totalPremium === false ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Daily Allowance</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={
                                shreeRamPrice?.dailyAllowance ? success : fail
                              }
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.passengerCover ? (
                    <>
                      {shreeRamQuoteLoading ||
                        shreeRamPrice?.totalPremium === undefined ||
                        shreeRamPrice?.totalPremium === 0 ||
                        ShowSkeleton || shreeRamPrice?.totalPremium === false ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Passenger Cover</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={
                                shreeRamPrice?.passengerCover ? success : fail
                              }
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {shreeRamPrice.odExternalCngLpg ? (
                    <>
                      {shreeRamQuoteLoading ||
                        shreeRamPrice?.totalPremium === undefined ||
                        shreeRamPrice?.totalPremium === 0 ||
                        ShowSkeleton || shreeRamPrice?.totalPremium === false ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>External Fitted CNG</p>
                          </div>
                          <div className="result_pc">
                            <img
                              // src={sbiPrice.odExternalCngLpg ? success : fail}
                              src={success}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.ncbProtection ? (
                    <>
                      {shreeRamQuoteLoading ||
                        shreeRamPrice?.totalPremium === undefined ||
                        shreeRamPrice?.totalPremium === 0 ||
                        ShowSkeleton || shreeRamPrice?.totalPremium === false ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>NCB Protection Cover</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={
                                shreeRamPrice?.ncbProtection ? success : fail
                              }
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.accessories ? (
                    <>
                      {shreeRamQuoteLoading ||
                        shreeRamPrice?.totalPremium === undefined ||
                        shreeRamPrice?.totalPremium === 0 ||
                        ShowSkeleton || shreeRamPrice?.totalPremium === false ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Accessories</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={shreeRamPrice?.accessories ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.driverCover ? (
                    <>
                      {shreeRamQuoteLoading ||
                        shreeRamPrice?.totalPremium === undefined ||
                        shreeRamPrice?.totalPremium === 0 ||
                        ShowSkeleton || shreeRamPrice?.totalPremium === false ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Driver Cover</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={shreeRamPrice?.driverCover ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="bottomOptions_planCard">
                <div className="option_div_pc option_div_pc_border ">
                  {!shreeRamQuoteLoading && shreeRamPrice?.totalPremium && !ShowSkeleton && shreeRamPrice?.totalPremium !== false ? (
                    <PlanDetails
                      QuickQuote={shreeRamQuote}
                      triggerTab="Plan Details"
                      handleBuyNow={(e) => handleBuyNow(shreeRam, " ", SHREERAM)}
                      companyImg={shreeRam}
                      companyName={SHREERAM}
                      vehicleBreakupPrice={shreeRamPrice}
                      bodyData={getBodyData}
                      setShowBottomTab={setShowBottomTab}
                      carDetails={carDetails}
                    />
                  ) : ("")}
                </div>
                <div className="option_div_pc">
                  <p>Cashless Garages</p>
                </div>
              </div>
            </div>

            {/* ********************sbi******************** */}

            <div className="plancard_card">
              <div className="padding_breakup_container">
                <div className="plancard_card_img_holder">
                  <img src={sbi} alt="" srcSet="" />
                </div>
                {
                  inspectDetails === true && (planType !== "Bundled" && planType !== "Third Party") && (
                    <div className="inspection_tab">
                      <img src={inspection} alt="" srcSet="" />
                      <p>Inspection Required</p>
                    </div>
                  )
                }
                <div className="premiumContainer_pc">
                  <p className="idv_title_pc">
                    Cover Value(IDV) :
                    {sbiQuickQuoteLoading ||
                      sbiPrice.duePremium === undefined ||
                      sbiPrice.duePremium === 0 ||
                      !sbiPrice.idv ||
                      ShowSkeleton ? (
                      <SkeletonLoader
                        variant="rectangular"
                        width={120}
                        height={15}
                      />
                    ) : (
                      <span>₹ {Math.round(sbiPrice.idv)?.toLocaleString('en-IN')}</span>
                    )}
                  </p>
                  {sbiQuickQuoteLoading ||
                    sbiPrice.duePremium === "" ||
                    sbiPrice.duePremium === false ||
                    ShowSkeleton ? (
                    <ButtonLoader />
                  ) : (
                    <Botton
                      text={`₹ ${!sbiQuickQuoteLoading
                        ? sbiPrice.duePremium
                          ? Math.round(sbiPrice.duePremium)?.toLocaleString('en-IN')
                          : ""
                        : loading
                        }`}
                      className="buyNowBtn_pc"
                      name="Plan Card"
                      spanTxt="Buy Now"
                      event={(e) =>
                        !sbiQuickQuoteLoading
                          ? sbiPrice.duePremium
                            ? handleBuyNow(sbi, sbiQuickQuote, SBI)
                            : ""
                          : ""
                      }
                    />
                  )}
                </div>
                <div className="breakup_container">
                  {sbiQuickQuoteLoading ||
                    sbiPrice.duePremium === false ||
                    sbiPrice.duePremium === 0 ||
                    ShowSkeleton ? (
                    <SkeletonLoader
                      variant="rectangular"
                      height={15}
                    />
                  ) : (
                    <div className="planHeadValueContainer">
                      <div className="planHead">
                        <p>
                          Basic Price
                          <span>
                            {`₹ ${!sbiQuickQuoteLoading
                              ? sbiPrice.basicPremium
                                ? Math.round(sbiPrice.basicPremium)?.toLocaleString('en-IN')
                                : 0
                              : loading
                              }`}
                          </span>
                        </p>
                      </div>
                    </div>
                  )}

                  {sbiPrice?.paCover ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        !sbiPrice.idv ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              Personal Accident Cover
                              <span>
                                ₹{" "}
                                {sbiQuickQuoteLoading
                                  ? loading
                                  : Math.round(sbiPrice?.paCover)?.toLocaleString('en-IN') || 0}
                              </span>
                            </p>
                          </div>
                          <div className="result_pc">
                            <img src={sbiPrice?.paCover ? success : fail} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.zeroDep ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        !sbiPrice.idv ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              Zero Depreciation
                              <span className="bold_premium">
                                ₹{" "}
                                {sbiQuickQuoteLoading
                                  ? loading
                                  : Math.round(sbiPrice.zeroDep)?.toLocaleString('en-IN') || 0}
                              </span>
                            </p>
                          </div>
                          <div className="result_pc">
                            <img src={sbiPrice.zeroDep ? success : fail} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {
                    checkboxTrueFalseObject.shreeramProtect ? (
                      <>
                        {sbiQuickQuoteLoading ||
                          sbiPrice.duePremium === undefined ||
                          sbiPrice.duePremium === 0 ||
                          ShowSkeleton ? (
                          <SkeletonLoader
                            variant="rectangular"
                            height={15}
                          />
                        ) : (
                          <div className="planHeadValueContainer">
                            <div className="planHead">
                              <p>Motor Protection</p>
                            </div>
                            <div className="result_pc">
                              <img
                                src={fail}
                                alt=""
                                srcSet=""
                              />
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  {checkboxTrueFalseObject.engineProtection ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        !sbiPrice.idv ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Engine Protector</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={sbiPrice.engineProtection ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.consumables ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        !sbiPrice.idv ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Consumable</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={sbiPrice.consumables ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.keyLockReplacement ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        !sbiPrice.idv ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Key and Lock Replacement</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={sbiPrice.keyLockReplacement ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.invoiceCover ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        sbiPrice.duePremium === false ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Invoice Cover</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={sbiPrice.invoiceCover ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.roadAssistance ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        sbiPrice.duePremium === false ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>24x7 Roadside Assistance</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={sbiPrice.roadAssistance ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.tyreProtection ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        !sbiPrice.idv ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Tyre Protection</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={sbiPrice.tyreProtection ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.emiProtection ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        !sbiPrice.idv ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>EMI Protection</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={sbiPrice.emiProtection ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.lossofPersonalBelongings ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        !sbiPrice.idv ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Loss of Personal Belongings</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={
                                sbiPrice.lossofPersonalBelongings
                                  ? success
                                  : fail
                              }
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.dailyAllowance ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        !sbiPrice.idv ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Daily Allowance</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={sbiPrice.dailyAllowance ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.passengerCover ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        !sbiPrice.idv ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Passenger Cover</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={sbiPrice.passengerCover ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.accessories ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        !sbiPrice.idv ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Accessories</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={sbiPrice.accessories ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.ncbProtection ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        !sbiPrice.idv ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>NCB Protection Cover</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={sbiPrice.ncbProtection ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.driverCover ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        sbiPrice.duePremium === false ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Driver Cover</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={sbiPrice.driverCover ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {sbiPrice.odExternalCngLpg ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        !sbiPrice.idv ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>External Fitted CNG</p>
                          </div>
                          <div className="result_pc">
                            <img
                              // src={sbiPrice.odExternalCngLpg ? success : fail}
                              src={success}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="bottomOptions_planCard">
                <div className="option_div_pc option_div_pc_border ">
                  {!sbiQuickQuoteLoading && sbiPrice.duePremium && !ShowSkeleton ? (
                    <PlanDetails
                      QuickQuote={sbiQuickQuote}
                      triggerTab="Plan Details"
                      handleBuyNow={(e) =>
                        !sbiQuickQuoteLoading
                          ? sbiPrice.duePremium
                            ? handleBuyNow(sbi, sbiQuickQuote, SBI)
                            : ""
                          : ""
                      }
                      companyImg={sbi}
                      companyName={SBI}
                      vehicleBreakupPrice={sbiPrice}
                      bodyData={getBodyData}
                      setShowBottomTab={setShowBottomTab}
                      carDetails={carDetails}
                    />
                  ) : ("")}
                </div>
                <div className="option_div_pc">
                  <p>Cashless Garages</p>
                </div>
              </div>
            </div>

            {/* *********************digit****************** */}

            <div className="plancard_card">
              <div className="padding_breakup_container">
                <div className="plancard_card_img_holder">
                  <img src={digit} alt="" srcSet="" />
                </div>
                {
                  inspectDetails === true && (planType !== "Bundled" && planType !== "Third Party") && (
                    <div className="inspection_tab">
                      <img src={inspection} alt="" srcSet="" />
                      <p>Inspection Required</p>
                    </div>
                  )
                }
                <div className="premiumContainer_pc">
                  <p className="idv_title_pc">
                    Cover Value(IDV) :
                    {digitQuoteLoading ||
                      digitPrice.duePremium === undefined ||
                      digitPrice.duePremium === 0 ||
                      !digitIDV?.idv ||
                      ShowSkeleton ? (
                      <SkeletonLoader
                        variant="rectangular"
                        width={120}
                        height={15}
                      />
                    ) : (
                      <span>₹ {Math.round(digitIDV?.idv)?.toLocaleString('en-IN')}</span>
                    )}
                  </p>
                  {digitQuoteLoading || digitPrice.duePremium === undefined ? (
                    <ButtonLoader />
                  ) : (
                    <Botton
                      text={`₹ ${digitQuoteLoading ? "...Loading" : Math.round(Number(digitPrice.duePremium))?.toLocaleString('en-IN')
                        }`}
                      className="buyNowBtn_pc"
                      name="Plan Card"
                      spanTxt="Buy Now"
                      event={(e) => handleBuyNow(digit, "", DIGIT)}
                    />
                  )}
                </div>
                <div className="breakup_container">
                  {digitQuoteLoading ||
                    digitPrice.duePremium === undefined ||
                    digitPrice.duePremium === 0 ||
                    ShowSkeleton ? (
                    <SkeletonLoader
                      variant="rectangular"
                      height={15}
                    />
                  ) : (
                    <div className="planHeadValueContainer">
                      <div className="planHead">
                        <p>
                          Basic Price
                          <span>
                            ₹{" "}
                            {digitQuoteLoading
                              ? "...Loading"
                              : digitPrice?.basicPremium || 0}
                          </span>
                        </p>
                      </div>
                    </div>
                  )}
                  {checkboxTrueFalseObject.paCover ? (
                    <>
                      {digitQuoteLoading ||
                        digitPrice.duePremium === undefined ||
                        digitPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              Personal Accident Cover
                              <span>
                                ₹{" "}
                                {digitQuoteLoading
                                  ? "...Loading"
                                  : digitPrice?.paCover || 0}
                              </span>
                            </p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={digitPrice?.paCover ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.zeroDep ? (
                    <>
                      {digitQuoteLoading ||
                        digitPrice.duePremium === undefined ||
                        digitPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              Zero Depreciation
                              <span className="bold_premium">
                                ₹{" "}
                                {digitQuoteLoading
                                  ? "...Loading"
                                  : digitPrice?.zeroDep || 0}
                              </span>
                            </p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={digitPrice?.zeroDep ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {
                    checkboxTrueFalseObject.shreeramProtect ? (
                      <>
                        {digitQuoteLoading ||
                          digitPrice.duePremium === undefined ||
                          digitPrice.duePremium === 0 ||
                          ShowSkeleton ? (
                          <SkeletonLoader
                            variant="rectangular"
                            height={15}
                          />
                        ) : (
                          <div className="planHeadValueContainer">
                            <div className="planHead">
                              <p>Motor Protection</p>
                            </div>
                            <div className="result_pc">
                              <img
                                src={fail}
                                alt=""
                                srcSet=""
                              />
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  {checkboxTrueFalseObject.engineProtection ? (
                    <>
                      {digitQuoteLoading ||
                        digitPrice.duePremium === undefined ||
                        digitPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Engine Protector</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={digitPrice?.engineProtection ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.consumables ? (
                    <>
                      {digitQuoteLoading ||
                        digitPrice.duePremium === undefined ||
                        digitPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Consumable</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={digitPrice?.consumables ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.keyLockReplacement ? (
                    <>
                      {digitQuoteLoading ||
                        digitPrice.duePremium === undefined ||
                        digitPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Key and Lock Replacement</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={digitPrice?.keyLockReplacement ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.invoiceCover ? (
                    <>
                      {digitQuoteLoading ||
                        digitPrice.duePremium === undefined ||
                        digitPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Invoice Cover</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={digitPrice?.invoiceCover ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.roadAssistance ? (
                    <>
                      {digitQuoteLoading ||
                        digitPrice.duePremium === undefined ||
                        digitPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>24x7 Roadside Assistance</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={digitPrice?.roadAssistance ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.tyreProtection ? (
                    <>
                      {digitQuoteLoading ||
                        digitPrice.duePremium === undefined ||
                        digitPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Tyre Protection</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={digitPrice?.tyreProtection ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {/* {checkboxTrueFalseObject.tyreProtection ? (
                    <>
                      {digitQuoteLoading ||
                        digitPrice.duePremium === undefined ||
                        digitPrice.duePremium === 0 ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Rim Protection</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={digitPrice?.tyreProtection ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )} */}
                  {digitPrice.emiProtection ? (
                    <>
                      {digitQuoteLoading ||
                        digitPrice.duePremium === undefined ||
                        digitPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>{digitQuoteLoading ? loading : "EMI Protection"}</p>
                          </div>
                          <div className="result_pc">
                            <img src={success} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.emiProtection &&
                    !digitPrice.emiProtection ? (
                    <>
                      {digitQuoteLoading ||
                        digitPrice.duePremium === undefined ||
                        digitPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>{digitQuoteLoading ? loading : "EMI Protection"}</p>
                          </div>
                          <div className="result_pc">
                            <img src={fail} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.lossofPersonalBelongings ? (
                    <>
                      {digitQuoteLoading ||
                        digitPrice.duePremium === undefined ||
                        digitPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Loss of Personal Belongings</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={digitPrice?.personalBelonging ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {digitPrice.dailyAllowance ? (
                    <>
                      {digitQuoteLoading ||
                        digitPrice.duePremium === undefined ||
                        digitPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>{digitQuoteLoading ? loading : "Daily Allowance"}</p>
                          </div>
                          <div className="result_pc">
                            <img src={success} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.dailyAllowance &&
                    !digitPrice.dailyAllowance ? (
                    <>
                      {digitQuoteLoading ||
                        digitPrice.duePremium === undefined ||
                        digitPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>{digitQuoteLoading ? loading : "Daily Allowance"}</p>
                          </div>
                          <div className="result_pc">
                            <img src={fail} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.passengerCover && digitPrice.passengerCover ? (
                    <>
                      {digitQuoteLoading ||
                        digitPrice.duePremium === undefined ||
                        digitPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>{digitQuoteLoading ? loading : "Passenger Cover"}</p>
                          </div>
                          <div className="result_pc">
                            <img src={success} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.passengerCover &&
                    !digitPrice.passengerCover ? (
                    <>
                      {digitQuoteLoading ||
                        digitPrice.duePremium === undefined ||
                        digitPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>{digitQuoteLoading ? loading : "Passenger Cover"}</p>
                          </div>
                          <div className="result_pc">
                            <img src={fail} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.ncbProtection ? (
                    <>
                      {digitQuoteLoading ||
                        digitPrice.duePremium === undefined ||
                        digitPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>NCB Protection Cover</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={digitPrice?.ncbProtection ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.accessories ? (
                    <>
                      {digitQuoteLoading ||
                        digitPrice.duePremium === undefined ||
                        digitPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Accessories</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={digitPrice?.accessories ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {digitPrice.driverCover ? (
                    <>
                      {digitQuoteLoading ||
                        digitPrice.duePremium === undefined ||
                        digitPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>{digitQuoteLoading ? loading : "Driver Cover"}</p>
                          </div>
                          <div className="result_pc">
                            <img src={success} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.driverCover &&
                    !digitPrice.driverCover ? (
                    <>
                      {digitQuoteLoading ||
                        digitPrice.duePremium === undefined ||
                        digitPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>{digitQuoteLoading ? loading : "Driver Cover"}</p>
                          </div>
                          <div className="result_pc">
                            <img src={fail} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="bottomOptions_planCard">
                <div className="option_div_pc option_div_pc_border ">
                  {!digitQuoteLoading && digitPrice.duePremium && !ShowSkeleton ? (
                    <PlanDetails
                      QuickQuote={digitQuote}
                      triggerTab="Plan Details"
                      handleBuyNow={(e) => handleBuyNow(digit, "", DIGIT)}
                      companyImg={digit}
                      companyName={DIGIT}
                      vehicleBreakupPrice={digitPrice}
                      bodyData={getBodyData}
                      setShowBottomTab={setShowBottomTab}
                      carDetails={carDetails}
                    />) : ("")}
                </div>
                <div className="option_div_pc">
                  <p>Cashless Garages</p>
                </div>
              </div>
            </div>

            {/* *********************icici******************  */}

            <div className="plancard_card">
              <div className="padding_breakup_container">
                <div className="plancard_card_img_holder">
                  <img src={icici} alt="" srcSet="" />
                </div>
                {
                  inspectDetails === true && (planType !== "Bundled" && planType !== "Third Party") && (
                    <div className="inspection_tab">
                      <img src={inspection} alt="" srcSet="" />
                      <p>Inspection Required</p>
                    </div>
                  )
                }
                <div className="premiumContainer_pc">
                  <p className="idv_title_pc">
                    Cover Value(IDV) :{" "}
                    {iciciQuoteLoading ||
                      iciciPrice.duePremium === undefined ||
                      iciciPrice.duePremium === 0 ||
                      ShowSkeleton ? (
                      <SkeletonLoader
                        variant="rectangular"
                        width={120}
                        height={15}
                      />
                    ) : (
                      <span>₹ {Math.round(iciciIDV)?.toLocaleString('en-IN')}</span>
                    )}
                  </p>

                  {iciciQuoteLoading ||
                    iciciPrice.duePremium === undefined ||
                    iciciPrice.duePremium === 0 ||
                    ShowSkeleton ? (
                    <ButtonLoader />
                  ) : (
                    <Botton
                      text={`₹ ${iciciQuoteLoading ? "...Loading" : Math.round(iciciPrice.duePremium)?.toLocaleString('en-IN')
                        }`}
                      className="buyNowBtn_pc"
                      name="Plan Card"
                      spanTxt="Buy Now"
                      event={(e) =>
                        iciciPrice.duePremium
                          ? handleBuyNow(icici, "", ICICI)
                          : ""
                      }
                    />
                  )}
                </div>
                <div className="breakup_container">
                  {iciciQuoteLoading || isNaN(iciciPrice?.basicPremium) ||
                    iciciPrice.duePremium === 0 ||
                    ShowSkeleton
                    ? (
                      <SkeletonLoader
                        variant="rectangular"
                        height={15}
                      />
                    ) : (
                      <div className="planHeadValueContainer">
                        <div className="planHead">
                          <p>
                            Basic Price
                            <span>
                              ₹{" "}
                              {iciciQuoteLoading
                                ? "...Loading"
                                : Math.round(iciciPrice?.basicPremium)?.toLocaleString('en-IN') || 0}
                            </span>
                          </p>
                        </div>
                      </div>
                    )}
                  {checkboxTrueFalseObject.paCover ? (
                    <>
                      {iciciQuoteLoading ||
                        iciciPrice.duePremium === undefined ||
                        iciciPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              Personal Accident Cover
                              <span>
                                ₹{" "}
                                {iciciQuoteLoading
                                  ? "...Loading"
                                  : Math.round(iciciPrice?.paCover)?.toLocaleString('en-IN') || 0}
                              </span>
                            </p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={iciciPrice?.paCover ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {iciciPrice.odExternalCngLpg && getBodyData.data.isCngLpgVehicleType ? (
                    <>
                      {iciciQuoteLoading ||
                        iciciPrice.duePremium === undefined ||
                        iciciPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>External Fitted CNG</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={iciciPrice?.odExternalCngLpg ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.zeroDep ? (
                    <>
                      {iciciQuoteLoading ||
                        iciciPrice.duePremium === undefined ||
                        iciciPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              Zero Depreciation
                              <span className="bold_premium">
                                ₹{" "}
                                {iciciQuoteLoading
                                  ? "...Loading"
                                  : Math.round(iciciPrice?.zeroDep)?.toLocaleString('en-IN') || 0}
                              </span>
                            </p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={iciciPrice?.zeroDep ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {
                    checkboxTrueFalseObject.shreeramProtect ? (
                      <>
                        {iciciQuoteLoading ||
                          iciciPrice.duePremium === undefined ||
                          iciciPrice.duePremium === 0 ||
                          ShowSkeleton ? (
                          <SkeletonLoader
                            variant="rectangular"
                            height={15}
                          />
                        ) : (
                          <div className="planHeadValueContainer">
                            <div className="planHead">
                              <p>Motor Protection</p>
                            </div>
                            <div className="result_pc">
                              <img
                                src={fail}
                                alt=""
                                srcSet=""
                              />
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  {checkboxTrueFalseObject.engineProtection ? (
                    <>
                      {iciciQuoteLoading ||
                        iciciPrice.duePremium === undefined ||
                        iciciPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Engine Protector</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={iciciPrice?.engineProtection ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.consumables ? (
                    <>
                      {iciciQuoteLoading ||
                        iciciPrice.duePremium === undefined ||
                        iciciPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Consumable</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={iciciPrice?.consumables ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.keyLockReplacement ? (
                    <>
                      {iciciQuoteLoading ||
                        iciciPrice.duePremium === undefined ||
                        iciciPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Key and Lock Replacement</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={iciciPrice?.keyLockReplacement ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.invoiceCover ? (
                    <>
                      {iciciQuoteLoading ||
                        iciciPrice.duePremium === undefined ||
                        iciciPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Invoice Cover</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={iciciPrice?.invoiceCover ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.roadAssistance ? (
                    <>
                      {iciciQuoteLoading ||
                        iciciPrice.duePremium === undefined ||
                        iciciPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>24x7 Roadside Assistance</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={iciciPrice?.roadAssistance ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.tyreProtection ? (
                    <>
                      {iciciQuoteLoading ||
                        iciciPrice.duePremium === undefined ||
                        iciciPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Tyre Protection</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={iciciPrice?.tyreProtection ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {iciciPrice.emiProtection ? (
                    <>
                      {iciciQuoteLoading ||
                        iciciPrice.duePremium === undefined ||
                        iciciPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>{iciciQuoteLoading ? loading : "EMI Protection"}</p>
                          </div>
                          <div className="result_pc">
                            <img src={success} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.emiProtection &&
                    !iciciPrice.emiProtection ? (
                    <>
                      {iciciQuoteLoading ||
                        iciciPrice.duePremium === undefined ||
                        iciciPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>{iciciQuoteLoading ? loading : "EMI Protection"}</p>
                          </div>
                          <div className="result_pc">
                            <img src={fail} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.lossofPersonalBelongings ? (
                    <>
                      {iciciQuoteLoading ||
                        iciciPrice.duePremium === undefined ||
                        iciciPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Loss of Personal Belongings</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={iciciPrice?.personalBelonging ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {iciciPrice.dailyAllowance ? (
                    <>
                      {iciciQuoteLoading ||
                        iciciPrice.duePremium === undefined ||
                        iciciPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>{iciciQuoteLoading ? loading : "Daily Allowance"}</p>
                          </div>
                          <div className="result_pc">
                            <img src={success} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.dailyAllowance &&
                    !iciciPrice.dailyAllowance ? (
                    <>
                      {iciciQuoteLoading ||
                        iciciPrice.duePremium === undefined ||
                        iciciPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>{iciciQuoteLoading ? loading : "Daily Allowance"}</p>
                          </div>
                          <div className="result_pc">
                            <img src={fail} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {iciciPrice.passengerCover ? (
                    <>
                      {iciciQuoteLoading ||
                        iciciPrice.duePremium === undefined ||
                        iciciPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>{iciciQuoteLoading ? loading : "Passenger Cover"}</p>
                          </div>
                          <div className="result_pc">
                            <img src={success} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.passengerCover &&
                    !iciciPrice.passengerCover ? (
                    <>
                      {iciciQuoteLoading ||
                        iciciPrice.duePremium === undefined ||
                        iciciPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>{iciciQuoteLoading ? loading : "Passenger Cover"}</p>
                          </div>
                          <div className="result_pc">
                            <img src={fail} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.ncbProtection ? (
                    <>
                      {iciciQuoteLoading ||
                        iciciPrice.duePremium === undefined ||
                        iciciPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>NCB Protection Cover</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={iciciPrice?.ncbProtection ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.accessories ? (
                    <>
                      {iciciQuoteLoading ||
                        iciciPrice.duePremium === undefined ||
                        iciciPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Accessories</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={iciciPrice?.accessories ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {iciciPrice.driverCover ? (
                    <>
                      {iciciQuoteLoading ||
                        iciciPrice.duePremium === undefined ||
                        iciciPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>{iciciQuoteLoading ? loading : "Driver Cover"}</p>
                          </div>
                          <div className="result_pc">
                            <img src={success} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.driverCover &&
                    !iciciPrice.driverCover ? (
                    <>
                      {iciciQuoteLoading ||
                        iciciPrice.duePremium === undefined ||
                        iciciPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>{iciciQuoteLoading ? loading : "Driver Cover"}</p>
                          </div>
                          <div className="result_pc">
                            <img src={fail} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="bottomOptions_planCard">
                <div className="option_div_pc option_div_pc_border ">
                  {!iciciQuoteLoading && iciciPrice.duePremium && !ShowSkeleton ? (
                    <PlanDetails
                      QuickQuote={iciciQuote}
                      triggerTab="Plan Details"
                      handleBuyNow={(e) =>
                        iciciPrice.duePremium
                          ? handleBuyNow(icici, "", ICICI)
                          : ""
                      }
                      companyImg={icici}
                      companyName={ICICI}
                      vehicleBreakupPrice={iciciPrice}
                      bodyData={getBodyData}
                      setShowBottomTab={setShowBottomTab}
                      carDetails={carDetails}
                    />
                  ) : ("")}
                </div>
                <div className="option_div_pc">
                  <p>Cashless Garages</p>
                </div>
              </div>
            </div>

          </>
        ) : planType === "Third Party" ? (
          <>
            {/* *********************acko******************  */}

            {/* <div className="plancard_card">
              <div className="padding_breakup_container">
                <div className="plancard_card_img_holder">
                  <img src={acko} alt="" srcSet="" />
                </div>
                <div className="premiumContainer_pc">
                  {ackoQuoteLoading ||
                    ShowSkeleton ||
                    isNaN(ackoPrice.duePremium) ? (
                    <ButtonLoader />
                  ) : (
                    <Botton
                      text={`₹ ${!ackoQuoteLoading
                        ? (isNaN(ackoPrice.duePremium) ? 0 : ackoPrice.duePremium)
                          ? (isNaN(ackoPrice.duePremium) ? 0 : ackoPrice.duePremium?.toLocaleString('en-IN'))
                          : ""
                        : loading
                        }`}
                      className="buyNowBtn_pc"
                      name="Plan Card"
                      spanTxt="Buy Now"
                      event={(e) =>
                        !ackoQuoteLoading
                          ? ackoPrice.duePremium
                            ? handleBuyNow(acko, "", ACKO)
                            : ""
                          : ""
                      }
                    />
                  )}
                </div>
                <div className="breakup_container">
                  {ackoQuoteLoading || ackoPrice?.basicPremium === NaN ||
                    ackoPrice.duePremium === 0 ||
                    ShowSkeleton ||
                    isNaN(ackoPrice.duePremium)
                    ? (
                      <SkeletonLoader
                        variant="rectangular"
                        height={15}
                      />
                    ) : (
                      <div className="planHeadValueContainer">
                        <div className="planHead">
                          <p>
                            Basic Price{" "}
                            <span>
                              ₹{" "}
                              {ackoQuoteLoading
                                ? "...Loading"
                                : Math.round(ackoPrice?.basicPremium)?.toLocaleString('en-IN') || 0}
                            </span>
                          </p>
                        </div>
                      </div>
                    )}

                  {ackoPrice.tpCngLpg && getBodyData.data.isCngLpgVehicleType ? (
                    <>
                      {ackoQuoteLoading ||
                        ackoPrice.duePremium === undefined ||
                        ackoPrice.duePremium === 0 ||
                        ShowSkeleton ||
                        isNaN(ackoPrice.duePremium) ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>External Fitted CNG</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={ackoPrice?.tpCngLpg ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {ackoPrice?.paCover ? (
                    <>
                      {ackoQuoteLoading ||
                        ackoPrice.duePremium === undefined ||
                        ackoPrice.duePremium === 0 ||
                        ShowSkeleton ||
                        isNaN(ackoPrice.duePremium) ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              Personal Accident Cover
                              <span>
                                ₹{" "}
                                {ackoQuoteLoading ? loading : Math.round(ackoPrice?.paCover)?.toLocaleString('en-IN') || 0}
                              </span>
                            </p>
                          </div>
                          <div className="result_pc">
                            <img src={success} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.paCover && !ackoPrice?.paCover ? (
                    <>
                      {ackoQuoteLoading ||
                        ackoPrice.duePremium === undefined ||
                        ackoPrice.duePremium === 0 ||
                        ShowSkeleton ||
                        isNaN(ackoPrice.duePremium) ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              Personal Accident Cover
                              <span className="bold_premium">
                                ₹{" "}
                                {ackoQuoteLoading ? loading : Math.round(ackoPrice?.paCover)?.toLocaleString('en-IN') || 0}
                              </span>
                            </p>
                          </div>
                          <div className="result_pc">
                            <img src={fail} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {ackoPrice.passengerCover ? (
                    <>
                      {ackoQuoteLoading ||
                        ackoPrice.duePremium === undefined ||
                        ackoPrice.duePremium === 0 ||
                        ShowSkeleton ||
                        isNaN(ackoPrice.duePremium) ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>{ackoQuoteLoading ? loading : "Passenger Cover"}</p>
                          </div>
                          <div className="result_pc">
                            <img src={success} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.passengerCover &&
                    !ackoPrice.passengerCover ? (
                    <>
                      {ackoQuoteLoading ||
                        ackoPrice.duePremium === undefined ||
                        ackoPrice.duePremium === 0 ||
                        ShowSkeleton ||
                        isNaN(ackoPrice.duePremium) ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>{ackoQuoteLoading ? loading : "Passenger Cover"}</p>
                          </div>
                          <div className="result_pc">
                            <img src={fail} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {ackoPrice.driverCover ? (
                    <>
                      {ackoQuoteLoading ||
                        ackoPrice.duePremium === undefined ||
                        ackoPrice.duePremium === 0 ||
                        ShowSkeleton ||
                        isNaN(ackoPrice.duePremium) ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>{ackoQuoteLoading ? loading : "Driver Cover"}</p>
                          </div>
                          <div className="result_pc">
                            <img src={success} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.driverCover &&
                    !ackoPrice.driverCover ? (
                    <>
                      {ackoQuoteLoading ||
                        ackoPrice.duePremium === undefined ||
                        ackoPrice.duePremium === 0 ||
                        ShowSkeleton ||
                        isNaN(ackoPrice.duePremium) ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>{ackoQuoteLoading ? loading : "Driver Cover"}</p>
                          </div>
                          <div className="result_pc">
                            <img src={fail} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="bottomOptions_planCard">
                <div className="option_div_pc option_div_pc_border ">
                  {!ackoQuoteLoading && ackoPrice.duePremium && !ShowSkeleton && !isNaN(ackoPrice.duePremium) ? (
                    <PlanDetails
                      QuickQuote={ackoQuote}
                      triggerTab="Plan Details"
                      handleBuyNow={(e) =>
                        ackoPrice.duePremium
                          ? handleBuyNow(acko, "", ACKO)
                          : ""
                      }
                      companyImg={acko}
                      companyName={ACKO}
                      vehicleBreakupPrice={ackoPrice}
                      bodyData={getBodyData}
                      setShowBottomTab={setShowBottomTab}
                      carDetails={carDetails}
                    />
                  ) : ("")}
                </div>
                <div className="option_div_pc">
                  <p>Cashless Garages</p>
                </div>
              </div>
            </div> */}

            {/* **********************zuno******************** */}

            {/* <div className="plancard_card">
              <div className="padding_breakup_container">
                <div className="plancard_card_img_holder">
                  <img src={zuno} alt="" srcSet="" />
                </div>
                <div className="premiumContainer_pc">
                  {zunoQuickQuoteLoading ||
                    ShowSkeleton || zunoPrice.duePremium === undefined ? (
                    <ButtonLoader />
                  ) : (
                    <Botton
                      text={`₹ ${!zunoQuickQuoteLoading
                        ? zunoPrice.duePremium
                          ? Math.round(Number(zunoPrice?.duePremium))?.toLocaleString('en-IN')
                          : ""
                        : loading
                        }`}
                      className="buyNowBtn_pc"
                      name="Plan Card"
                      spanTxt="Buy Now"
                      event={(e) => handleBuyNow(zuno, "", ZUNO)}
                    />
                  )}
                </div>
                <div className="breakup_container">
                  {zunoQuickQuoteLoading ||
                    zunoPrice.duePremium === undefined ||
                    zunoPrice.duePremium === 0 ||
                    ShowSkeleton ? (
                    <SkeletonLoader
                      variant="rectangular"
                      height={15}
                    />
                  ) : (
                    <div className="planHeadValueContainer">
                      <div className="planHead">
                        <p>
                          Basic Price{" "}
                          <span>
                            ₹{" "}
                            {zunoQuickQuoteLoading
                              ? loading
                              : zunoPrice?.basicPremium
                                ? Math.round(zunoPrice?.basicPremium)?.toLocaleString('en-IN')
                                : ""}
                          </span>
                        </p>
                      </div>
                    </div>
                  )}
                  {checkboxTrueFalseObject.paCover ? (
                    <>
                      {zunoQuickQuoteLoading ||
                        zunoPrice.duePremium === undefined ||
                        zunoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              Personal Accident Cover
                              <span>
                                ₹{" "}
                                {zunoQuickQuoteLoading
                                  ? loading
                                  : Math.round(zunoPrice?.paCover)?.toLocaleString('en-IN') || 0}
                              </span>
                            </p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={zunoPrice?.paCover ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.passengerCover ? (
                    <>
                      {zunoQuickQuoteLoading ||
                        zunoPrice.duePremium === undefined ||
                        zunoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Passenger Cover</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={zunoPrice?.passengerCover ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.driverCover ? (
                    <>
                      {zunoQuickQuoteLoading ||
                        zunoPrice.duePremium === undefined ||
                        zunoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Driver Cover</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={zunoPrice?.driverCover ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {zunoPrice.odExternalCngLpg && getBodyData.data.isCngLpgVehicleType ? (
                    <>
                      {zunoQuickQuoteLoading ||
                        zunoPrice.duePremium === undefined ||
                        zunoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>External Fitted CNG</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={zunoPrice?.odExternalCngLpg ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="bottomOptions_planCard">
                <div className="option_div_pc option_div_pc_border ">
                  {!zunoQuickQuoteLoading && zunoPrice.duePremium && !ShowSkeleton ? (
                    <PlanDetails
                      QuickQuote={zunoQuickQuote}
                      triggerTab="Plan Details"
                      handleBuyNow={(e) => handleBuyNow(zuno, "", ZUNO)}
                      companyImg={zuno}
                      companyName={ZUNO}
                      vehicleBreakupPrice={zunoPrice}
                      bodyData={getBodyData}
                      setShowBottomTab={setShowBottomTab}
                      carDetails={carDetails}
                    />
                  ) : ("")}
                </div>
                <div className="option_div_pc">
                  <p>Cashless Garages</p>
                </div>
              </div>
            </div> */}

            {/* *********************shreeram*************** */}

            <div className="plancard_card">
              <div className="padding_breakup_container">
                <div className="plancard_card_img_holder">
                  <img src={shreeRam} alt="" srcSet="" />
                </div>
                <div className="premiumContainer_pc">
                  {shreeRamQuoteLoading ||
                    ShowSkeleton ||
                    isNaN(shreeRamPrice?.totalPremium) ||
                    shreeRamPrice?.totalPremium === false ? (
                    <ButtonLoader />
                  ) : (
                    <Botton
                      text={`₹ ${!shreeRamQuoteLoading
                        ? shreeRamPrice?.totalPremium
                          ? Math.round(shreeRamPrice?.totalPremium)?.toLocaleString('en-IN')
                          : ""
                        : "...Loading"
                        }`}
                      className="buyNowBtn_pc"
                      name="Plan Card"
                      spanTxt="Buy Now"
                      event={(e) => handleBuyNow(shreeRam, " ", SHREERAM)}
                    />
                  )}
                </div>
                <div className="breakup_container">
                  {shreeRamQuoteLoading ||
                    shreeRamPrice?.totalPremium === undefined ||
                    shreeRamPrice?.totalPremium === 0 ||
                    ShowSkeleton ||
                    shreeRamPrice?.totalPremium === false ? (
                    <SkeletonLoader
                      variant="rectangular"
                      height={15}
                    />
                  ) : (
                    <div className="planHeadValueContainer">
                      <div className="planHead">
                        <p>
                          Basic Price
                          <span>
                            ₹{" "}
                            {!shreeRamQuoteLoading
                              ? shreeRamPrice?.basicPremium
                                ? (Math.round(shreeRamPrice?.basicPremium)?.toLocaleString('en-IN'))
                                : ""
                              : "...Loading"}
                          </span>
                        </p>
                      </div>
                    </div>
                  )}
                  {checkboxTrueFalseObject.paCover ? (
                    <>
                      {shreeRamQuoteLoading ||
                        shreeRamPrice?.totalPremium === undefined ||
                        shreeRamPrice?.totalPremium === 0 ||
                        ShowSkeleton ||
                        shreeRamPrice?.totalPremium === false ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              Personal Accident Cover
                              <span>
                                ₹{" "}
                                {!shreeRamQuoteLoading
                                  ? shreeRamPrice?.paCover
                                    ? Math.round(shreeRamPrice?.paCover)?.toLocaleString('en-IN')
                                    : "0"
                                  : "...Loading"}
                              </span>
                            </p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={shreeRamPrice?.paCover ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {checkboxTrueFalseObject.passengerCover ? (
                    <>
                      {shreeRamQuoteLoading ||
                        shreeRamPrice?.totalPremium === undefined ||
                        shreeRamPrice?.totalPremium === 0 ||
                        ShowSkeleton ||
                        shreeRamPrice?.totalPremium === false ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Passenger Cover</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={
                                shreeRamPrice?.passengerCover ? success : fail
                              }
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {shreeRamPrice.tpExternalCngLpg ? (
                    <>
                      {shreeRamQuoteLoading ||
                        shreeRamPrice?.totalPremium === undefined ||
                        shreeRamPrice?.totalPremium === 0 ||
                        ShowSkeleton ||
                        shreeRamPrice?.totalPremium === false ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>External Fitted CNG</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={sbiPrice.odExternalCngLpg ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.driverCover ? (
                    <>
                      {shreeRamQuoteLoading ||
                        shreeRamPrice?.totalPremium === undefined ||
                        shreeRamPrice?.totalPremium === 0 ||
                        ShowSkeleton ||
                        shreeRamPrice?.totalPremium === false ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Driver Cover</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={shreeRamPrice?.driverCover ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="bottomOptions_planCard">
                <div className="option_div_pc option_div_pc_border">
                  {!shreeRamQuoteLoading && shreeRamPrice?.totalPremium && !ShowSkeleton && shreeRamPrice?.totalPremium !== false ? (
                    <PlanDetails
                      QuickQuote={shreeRamQuote}
                      triggerTab="Plan Details"
                      handleBuyNow={(e) => handleBuyNow(shreeRam, " ", SHREERAM)}
                      companyImg={shreeRam}
                      companyName={SHREERAM}
                      vehicleBreakupPrice={shreeRamPrice}
                      bodyData={getBodyData}
                      setShowBottomTab={setShowBottomTab}
                      carDetails={carDetails}
                    />
                  ) : ("")}
                </div>
                <div className="option_div_pc">
                  <p>Cashless Garages</p>
                </div>
              </div>
            </div>

            {/* ********************sbi******************** */}

            <div className="plancard_card">
              <div className="padding_breakup_container">
                <div className="plancard_card_img_holder">
                  <img src={sbi} alt="" srcSet="" />
                </div>
                <div className="premiumContainer_pc">
                  {sbiQuickQuoteLoading ||
                    ShowSkeleton || sbiPrice.duePremium === false ? (
                    <ButtonLoader />
                  ) : (
                    <Botton
                      text={`₹ ${!sbiQuickQuoteLoading
                        ? sbiPrice.duePremium
                          ? Math.round(sbiPrice.duePremium)?.toLocaleString('en-IN')
                          : ""
                        : loading
                        }`}
                      className="buyNowBtn_pc"
                      name="Plan Card"
                      spanTxt="Buy Now"
                      event={(e) =>
                        !sbiQuickQuoteLoading
                          ? sbiPrice.duePremium
                            ? handleBuyNow(sbi, sbiQuickQuote, SBI)
                            : ""
                          : ""
                      }
                    />
                  )}
                </div>
                <div className="breakup_container">
                  {sbiQuickQuoteLoading ||
                    ShowSkeleton || sbiPrice.duePremium === false ? (
                    <SkeletonLoader
                      variant="rectangular"
                      height={15}
                    />
                  ) : (
                    <div className="planHeadValueContainer">
                      <div className="planHead">
                        <p>
                          Basic Price
                          <span>
                            {`₹ ${!sbiQuickQuoteLoading
                              ? sbiPrice.basicPremium
                                ? Math.round(sbiPrice.basicPremium)?.toLocaleString('en-IN')
                                : "0"
                              : loading
                              }`}
                          </span>
                        </p>
                      </div>
                    </div>
                  )}
                  {sbiPrice?.paCover ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        ShowSkeleton || sbiPrice.duePremium === false ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              Personal Accident Cover
                              <span>
                                ₹{" "}
                                {sbiQuickQuoteLoading ? "" : Math.round(sbiPrice?.paCover)?.toLocaleString('en-IN') || 0}
                              </span>
                            </p>
                          </div>
                          <div className="result_pc">
                            <img src={success} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {sbiPrice.tpCngLpg && getBodyData.data.isCngLpgVehicleType ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        ShowSkeleton || sbiPrice.duePremium === false ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>CNG Fitted</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={success}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {checkboxTrueFalseObject.paCover && !sbiPrice.paCover ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        ShowSkeleton || sbiPrice.duePremium === false ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              Personal Accident Cover
                              <span className="bold_premium">
                                ₹{" "}
                                {sbiQuickQuoteLoading ? "" : Math.round(sbiPrice?.paCover)?.toLocaleString('en-IN')}
                              </span>
                            </p>
                          </div>
                          <div className="result_pc">
                            <img src={fail} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {sbiPrice.passengerCover ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        ShowSkeleton || sbiPrice.duePremium === false ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              {sbiQuickQuoteLoading ? loading : "Passenger Cover"}
                            </p>
                          </div>
                          <div className="result_pc">
                            <img src={success} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.passengerCover &&
                    !sbiPrice.passengerCover ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        ShowSkeleton || sbiPrice.duePremium === false ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              {sbiQuickQuoteLoading ? loading : "Passenger Cover"}
                            </p>
                          </div>
                          <div className="result_pc">
                            <img src={fail} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {sbiPrice.driverCover ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        ShowSkeleton || sbiPrice.duePremium === false ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>{sbiQuickQuoteLoading ? loading : "Driver Cover"}</p>
                          </div>
                          <div className="result_pc">
                            <img src={success} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.driverCover &&
                    !sbiPrice.driverCover ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        !sbiPrice.idv ||
                        ShowSkeleton || sbiPrice.duePremium === false ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>{sbiQuickQuoteLoading ? loading : "Driver Cover"}</p>
                          </div>
                          <div className="result_pc">
                            <img src={fail} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="bottomOptions_planCard">
                <div className="option_div_pc option_div_pc_border ">
                  {!sbiQuickQuoteLoading && sbiPrice.duePremium && !ShowSkeleton && sbiPrice.duePremium !== false ? (
                    <PlanDetails
                      QuickQuote={sbiQuickQuote}
                      triggerTab="Plan Details"
                      handleBuyNow={(e) =>
                        !sbiQuickQuoteLoading
                          ? sbiPrice.duePremium
                            ? handleBuyNow(sbi, sbiQuickQuote, SBI)
                            : ""
                          : ""
                      }
                      companyImg={sbi}
                      companyName={SBI}
                      vehicleBreakupPrice={sbiPrice}
                      bodyData={getBodyData}
                      setShowBottomTab={setShowBottomTab}
                      carDetails={carDetails}
                    />
                  ) : ("")}
                </div>
                <div className="option_div_pc">
                  <p>Cashless Garages</p>
                </div>
              </div>
            </div>

            {/* *********************digit****************** */}

            <div className="plancard_card">
              <div className="padding_breakup_container">
                <div className="plancard_card_img_holder">
                  <img src={digit} alt="" srcSet="" />
                </div>
                <div className="premiumContainer_pc">
                  {digitQuoteLoading ||
                    ShowSkeleton || digitPrice.duePremium === undefined ? (
                    <ButtonLoader />
                  ) : (
                    <Botton
                      text={`₹ ${!digitQuoteLoading
                        ? digitPrice.duePremium
                          ? digitPrice.duePremium?.toLocaleString('en-IN')
                          : ""
                        : loading
                        }`}
                      className="buyNowBtn_pc"
                      name="Plan Card"
                      spanTxt="Buy Now"
                      event={(e) =>
                        !digitQuoteLoading
                          ? digitPrice.duePremium
                            ? handleBuyNow(digit, "", DIGIT)
                            : ""
                          : ""
                      }
                    />
                  )}
                </div>
                <div className="breakup_container">
                  {digitQuoteLoading ||
                    ShowSkeleton || digitPrice.duePremium === undefined ? (
                    <SkeletonLoader
                      variant="rectangular"
                      height={15}
                    />
                  ) : (
                    <div className="planHeadValueContainer">
                      <div className="planHead">
                        <p>
                          Basic Price{" "}
                          {
                            digitPrice?.basicPremium ? (
                              <span>
                                ₹{" "}
                                {digitQuoteLoading
                                  ? "...Loading"
                                  : digitPrice?.basicPremium?.toLocaleString('en-IN') || 0}
                              </span>
                            ) : (<span> 0  </span>)
                          }
                        </p>
                      </div>
                    </div>
                  )}

                  {digitPrice?.paCover ? (
                    <>
                      {digitQuoteLoading ||
                        ShowSkeleton || digitPrice.duePremium === undefined
                        ? (
                          <SkeletonLoader
                            variant="rectangular"
                            height={15}
                          />
                        ) : (
                          <div className="planHeadValueContainer">
                            <div className="planHead">
                              <p>
                                Personal Accident Cover
                                {
                                  digitPrice?.paCover && (
                                    <span>
                                      ₹{" "}
                                      {digitQuoteLoading
                                        ? "...Loading"
                                        : digitPrice?.paCover?.toLocaleString('en-IN') || 0}
                                    </span>
                                  )
                                }
                              </p>
                            </div>
                            <div className="result_pc">
                              <img
                                src={digitPrice?.paCover ? success : fail}
                                alt=""
                                srcSet=""
                              />
                            </div>
                          </div>
                        )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.paCover && !digitPrice?.paCover ? (
                    <>
                      {digitQuoteLoading ||
                        ShowSkeleton || digitPrice.duePremium === undefined ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              Personal Accident Cover
                              <span className="bold_premium">
                                ₹{" "}
                                {digitQuoteLoading ? "" : digitPrice?.paCover?.toLocaleString('en-IN') || 0}
                              </span>
                            </p>
                          </div>
                          <div className="result_pc">
                            <img src={fail} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {digitPrice.passengerCover ? (
                    <>
                      {digitQuoteLoading ||
                        ShowSkeleton || digitPrice.duePremium === undefined ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>{digitQuoteLoading ? loading : "Passenger Cover"}</p>
                          </div>
                          <div className="result_pc">
                            <img src={success} />
                          </div>
                        </div>
                      )}
                    </>

                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.passengerCover &&
                    !digitPrice.passengerCover ? (
                    <>
                      {digitQuoteLoading ||
                        ShowSkeleton || digitPrice.duePremium === undefined ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>{digitQuoteLoading ? loading : "Passenger Cover"}</p>
                          </div>
                          <div className="result_pc">
                            <img src={fail} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {digitPrice.driverCover ? (
                    <>
                      {digitQuoteLoading ||
                        ShowSkeleton || digitPrice.duePremium === undefined ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>{digitQuoteLoading ? loading : "Driver Cover"}</p>
                          </div>
                          <div className="result_pc">
                            <img src={success} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.driverCover &&
                    !digitPrice.driverCover ? (
                    <>
                      {digitQuoteLoading ||
                        ShowSkeleton || digitPrice.duePremium === undefined ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>{digitQuoteLoading ? loading : "Driver Cover"}</p>
                          </div>
                          <div className="result_pc">
                            <img src={fail} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="bottomOptions_planCard">
                <div className="option_div_pc option_div_pc_border ">
                  {!digitQuoteLoading && digitPrice.duePremium && !ShowSkeleton ? (
                    <PlanDetails
                      QuickQuote={digitQuote}
                      triggerTab="Plan Details"
                      handleBuyNow={(e) => handleBuyNow(digit, "", DIGIT)}
                      companyImg={digit}
                      companyName={DIGIT}
                      vehicleBreakupPrice={digitPrice}
                      bodyData={getBodyData}
                      setShowBottomTab={setShowBottomTab}
                      carDetails={carDetails}
                    />) : ("")}
                </div>
                <div className="option_div_pc">
                  <p>Cashless Garages</p>
                </div>
              </div>
            </div>

            {/* *********************icici******************  */}

            <div className="plancard_card">
              <div className="padding_breakup_container">
                <div className="plancard_card_img_holder">
                  <img src={icici} alt="" srcSet="" />
                </div>
                <div className="premiumContainer_pc">
                  {iciciQuoteLoading ||
                    ShowSkeleton || iciciPrice.duePremium === 0 ? (
                    <ButtonLoader />
                  ) : (
                    <Botton
                      text={`₹ ${!iciciQuoteLoading
                        ? iciciPrice.duePremium
                          ? Math.round(iciciPrice.duePremium)?.toLocaleString('en-IN')
                          : ""
                        : loading
                        }`}
                      className="buyNowBtn_pc"
                      name="Plan Card"
                      spanTxt="Buy Now"
                      event={(e) =>
                        !iciciQuoteLoading
                          ? iciciPrice.duePremium
                            ? handleBuyNow(icici, "", ICICI)
                            : ""
                          : ""
                      }
                    />
                  )}
                </div>
                <div className="breakup_container">
                  {iciciQuoteLoading || iciciPrice?.basicPremium === NaN ||
                    iciciPrice.duePremium === 0 ||
                    ShowSkeleton
                    ? (
                      <SkeletonLoader
                        variant="rectangular"
                        height={15}
                      />
                    ) : (
                      <div className="planHeadValueContainer">
                        <div className="planHead">
                          <p>
                            Basic Price{" "} {
                              iciciPrice?.basicPremium && (
                                <span>
                                  ₹{" "}
                                  {iciciQuoteLoading
                                    ? "...Loading"
                                    : iciciPrice?.basicPremium?.toLocaleString('en-IN') || 0}
                                </span>
                              )
                            }
                          </p>
                        </div>
                      </div>
                    )}

                  {iciciPrice?.paCover ? (
                    <>
                      {iciciQuoteLoading ||
                        iciciPrice.duePremium === undefined ||
                        iciciPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              Personal Accident Cover
                              <span>
                                ₹{" "}
                                {iciciQuoteLoading ? loading : iciciPrice?.paCover?.toLocaleString('en-IN') || 0}
                              </span>
                            </p>
                          </div>
                          <div className="result_pc">
                            <img src={success} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.paCover && !iciciPrice?.paCover ? (
                    <>
                      {iciciQuoteLoading ||
                        iciciPrice.duePremium === undefined ||
                        iciciPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              Personal Accident Cover
                              <span className="bold_premium">
                                ₹{" "}
                                {iciciQuoteLoading ? loading : iciciPrice?.paCover?.toLocaleString('en-IN') || 0}
                              </span>
                            </p>
                          </div>
                          <div className="result_pc">
                            <img src={fail} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {iciciPrice.tpCngLpg && getBodyData.data.isCngLpgVehicleType ? (
                    <>
                      {iciciQuoteLoading ||
                        iciciPrice.duePremium === undefined ||
                        iciciPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>External Fitted CNG</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={iciciPrice?.tpCngLpg ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {iciciPrice.passengerCover ? (
                    <>
                      {iciciQuoteLoading ||
                        iciciPrice.duePremium === undefined ||
                        iciciPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>{iciciQuoteLoading ? loading : "Passenger Cover"}</p>
                          </div>
                          <div className="result_pc">
                            <img src={success} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.passengerCover &&
                    !iciciPrice.passengerCover ? (
                    <>
                      {iciciQuoteLoading ||
                        iciciPrice.duePremium === undefined ||
                        iciciPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>{iciciQuoteLoading ? loading : "Passenger Cover"}</p>
                          </div>
                          <div className="result_pc">
                            <img src={fail} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {iciciPrice.driverCover ? (
                    <>
                      {iciciQuoteLoading ||
                        iciciPrice.duePremium === undefined ||
                        iciciPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>{iciciQuoteLoading ? loading : "Driver Cover"}</p>
                          </div>
                          <div className="result_pc">
                            <img src={success} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.driverCover &&
                    !iciciPrice.driverCover ? (
                    <>
                      {iciciQuoteLoading ||
                        iciciPrice.duePremium === undefined ||
                        iciciPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>{iciciQuoteLoading ? loading : "Driver Cover"}</p>
                          </div>
                          <div className="result_pc">
                            <img src={fail} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="bottomOptions_planCard">
                <div className="option_div_pc option_div_pc_border ">
                  {!iciciQuoteLoading && iciciPrice.duePremium && !ShowSkeleton ? (
                    <PlanDetails
                      QuickQuote={iciciQuote}
                      triggerTab="Plan Details"
                      handleBuyNow={(e) =>
                        iciciPrice.duePremium
                          ? handleBuyNow(icici, "", ICICI)
                          : ""
                      }
                      companyImg={icici}
                      companyName={ICICI}
                      vehicleBreakupPrice={iciciPrice}
                      bodyData={getBodyData}
                      setShowBottomTab={setShowBottomTab}
                      carDetails={carDetails}
                    />
                  ) : ("")}
                </div>
                <div className="option_div_pc">
                  <p>Cashless Garages</p>
                </div>
              </div>
            </div>

          </>
        ) : planType === "Own Damage" ? (
          <>
            {/* *********************acko******************  */}

            {/* <div className="plancard_card">
              <div className="padding_breakup_container">
                <div className="plancard_card_img_holder">
                  <img src={acko} alt="" srcSet="" />
                </div>
                {
                  ackoisPiRequired === true && (planType !== "Bundled" && planType !== "Third Party") && (
                    <div className="inspection_tab">
                      <img src={inspection} alt="" srcSet="" />
                      <p>Inspection Required</p>
                    </div>
                  )
                }
                <div className="premiumContainer_pc">
                  <p className="idv_title_pc">
                    Cover Value(IDV) :{" "}
                    {ackoQuoteLoading ||
                      ackoPrice.duePremium === undefined ||
                      ackoPrice.duePremium === 0 ||
                      ShowSkeleton ? (
                      <SkeletonLoader
                        variant="rectangular"
                        width={120}
                        height={15}
                      />
                    ) : (
                      <span>₹ {ackoIDV?.toLocaleString('en-IN')}</span>
                    )}
                  </p>
                  {ackoQuoteLoading ||
                    ShowSkeleton ? (
                    <ButtonLoader />
                  ) : (
                    <Botton
                      text={`₹ ${ackoQuoteLoading ? "...Loading" : (isNaN(ackoPrice.duePremium) ? 0 : ackoPrice.duePremium?.toLocaleString('en-IN'))
                        }`}
                      className="buyNowBtn_pc"
                      name="Plan Card"
                      spanTxt="Buy Now"
                      event={(e) =>
                        ackoPrice.duePremium
                          ? handleBuyNow(acko, "", ACKO)
                          : ""
                      }
                    />
                  )}
                </div>
                <div className="breakup_container">
                  {ackoQuoteLoading || ackoPrice?.basicPremium === NaN ||
                    ackoPrice.duePremium === 0 ||
                    ShowSkeleton
                    ? (
                      <SkeletonLoader
                        variant="rectangular"
                        height={15}
                      />
                    ) : (
                      <div className="planHeadValueContainer">
                        <div className="planHead">
                          <p>
                            Basic Price{" "} {
                              ackoPrice?.basicPremium ? (
                                <span>
                                  ₹{" "}
                                  {ackoQuoteLoading
                                    ? "...Loading"
                                    : ackoPrice?.basicPremium?.toLocaleString('en-IN') || 0}
                                </span>
                              ) : (
                                <span>0</span>
                              )
                            }
                          </p>
                        </div>
                      </div>
                    )}

                  {checkboxTrueFalseObject.zeroDep ? (
                    <>
                      {ackoQuoteLoading ||
                        ackoPrice.duePremium === undefined ||
                        ackoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              Zero Depreciation{" "}
                              {
                                ackoPrice?.zeroDep || ackoPrice?.zeroDep != 0 ? (
                                  <span className="bold_premium">
                                    ₹{" "}
                                    {ackoQuoteLoading
                                      ? "...Loading"
                                      : ackoPrice?.zeroDep?.toLocaleString('en-IN') || 0}
                                  </span>
                                ) : (<span className="bold_premium"> 0 </span>)
                              }
                            </p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={ackoPrice?.zeroDep ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {getBodyData.data.isCngLpgVehicleType ? (
                    <>
                      {ackoQuoteLoading ||
                        ackoPrice.duePremium === undefined ||
                        ackoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>External Fitted CNG</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {
                    checkboxTrueFalseObject.shreeramProtect ? (
                      <>
                        {ackoQuoteLoading ||
                          ackoPrice.duePremium === undefined ||
                          ackoPrice.duePremium === 0 ||
                          ShowSkeleton ? (
                          <SkeletonLoader
                            variant="rectangular"
                            height={15}
                          />
                        ) : (
                          <div className="planHeadValueContainer">
                            <div className="planHead">
                              <p>Motor Protection</p>
                            </div>
                            <div className="result_pc">
                              <img
                                src={fail}
                                alt=""
                                srcSet=""
                              />
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      ""
                    )}

                  {checkboxTrueFalseObject.engineProtection ? (
                    <>
                      {ackoQuoteLoading ||
                        ackoPrice.duePremium === undefined ||
                        ackoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Engine Protector</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={ackoPrice?.engineProtection ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.consumables ? (
                    <>
                      {ackoQuoteLoading ||
                        ackoPrice.duePremium === undefined ||
                        ackoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Consumable</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={ackoPrice?.consumables ? success : fail}
                              alt=""
                              srcset=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.keyLockReplacement || checkboxTrueFalseObject.roadAssistance ? (
                    <>
                      {ackoQuoteLoading ||
                        ackoPrice.duePremium === undefined ||
                        ackoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Key and Lock Replacement</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={(ackoPrice?.keyLockReplacement || ackoPrice?.roadAssistance) ? success : fail}
                              alt=""
                              srcset=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.invoiceCover ? (
                    <>
                      {ackoQuoteLoading ||
                        ackoPrice.duePremium === undefined ||
                        ackoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Invoice Cover</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={ackoPrice?.invoiceCover ? success : fail}
                              alt=""
                              srcset=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.keyLockReplacement || checkboxTrueFalseObject.roadAssistance ? (
                    <>
                      {ackoQuoteLoading ||
                        ackoPrice.duePremium === undefined ||
                        ackoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>24x7 Roadside Assistance</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={(ackoPrice?.keyLockReplacement || ackoPrice?.roadAssistance) ? success : fail}
                              alt=""
                              srcset=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.tyreProtection ? (
                    <>
                      {ackoQuoteLoading ||
                        ackoPrice.duePremium === undefined ||
                        ackoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Tyre Protection</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={ackoPrice?.tyreProtection ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.emiProtection ? (
                    <>
                      {ackoQuoteLoading ||
                        ackoPrice.duePremium === undefined ||
                        ackoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>EMI Protection</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={ackoPrice?.emiProtection ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    " "
                  )}
                  {checkboxTrueFalseObject.lossofPersonalBelongings ? (
                    <>
                      {ackoQuoteLoading ||
                        ackoPrice.duePremium === undefined ||
                        ackoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Loss of Personal Belongings</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={
                                ackoPrice?.lossofPersonalBelongings
                                  ? success
                                  : fail
                              }
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.dailyAllowance ? (
                    <>
                      {ackoQuoteLoading ||
                        ackoPrice.duePremium === undefined ||
                        ackoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Daily Allowance</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={ackoPrice?.dailyAllowance ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.ncbProtection ? (
                    <>
                      {ackoQuoteLoading ||
                        ackoPrice.duePremium === undefined ||
                        ackoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>NCB Protection Cover</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={ackoPrice?.ncbProtection ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.accessories ? (
                    <>
                      {ackoQuoteLoading ||
                        ackoPrice.duePremium === undefined ||
                        ackoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Accessories</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={ackoPrice?.accessories ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="bottomOptions_planCard">
                <div className="option_div_pc option_div_pc_border ">
                  {!ackoQuoteLoading && ackoPrice.duePremium && !ShowSkeleton ? (
                    <PlanDetails
                      QuickQuote={ackoQuote}
                      triggerTab="Plan Details"
                      handleBuyNow={(e) =>
                        ackoPrice.duePremium
                          ? handleBuyNow(acko, "", ACKO)
                          : ""
                      }
                      companyImg={acko}
                      companyName={ACKO}
                      vehicleBreakupPrice={ackoPrice}
                      bodyData={getBodyData}
                      setShowBottomTab={setShowBottomTab}
                      carDetails={carDetails}
                    />
                  ) : ("")}
                </div>
                <div className="option_div_pc">
                  <p>Cashless Garages</p>
                </div>
              </div>
            </div> */}

            {/* **********************zuno******************** */}

            {/* <div className="plancard_card">
              <div className="padding_breakup_container">
                <div className="plancard_card_img_holder">
                  <img src={zuno} alt="" srcSet="" />
                </div>
                {
                  inspectDetails === true && (planType !== "Bundled" && planType !== "Third Party") && (
                    <div className="inspection_tab">
                      <img src={inspection} alt="" srcSet="" />
                      <p>Inspection Required</p>
                    </div>
                  )
                }
                <div className="premiumContainer_pc">
                  <p className="idv_title_pc">
                    Cover Value(IDV) :{" "}
                    {zunoQuickQuoteLoading ||
                      zunoPrice.duePremium === undefined ||
                      zunoPrice.duePremium === 0 ||
                      zunoIDV === undefined ||
                      ShowSkeleton ? (
                      <SkeletonLoader
                        variant="rectangular"
                        width={120}
                        height={15}
                      />
                    ) : (
                      <span>₹ {Math.round(zunoIDV)?.toLocaleString('en-IN')}</span>
                    )}
                  </p>
                  {zunoQuickQuoteLoading ||
                    zunoPrice?.duePremium === undefined ||
                    ShowSkeleton ? (
                    <ButtonLoader />
                  ) : (
                    <Botton
                      text={`₹ ${zunoQuickQuoteLoading
                        ? loading
                        : Math.round(Number(zunoPrice?.duePremium))?.toLocaleString('en-IN')
                        }`}
                      className="buyNowBtn_pc"
                      name="Plan Card"
                      spanTxt="Buy Now"
                      event={(e) => handleBuyNow(zuno, "", ZUNO)}
                    />
                  )}
                </div>
                <div className="breakup_container">
                  {zunoQuickQuoteLoading ||
                    zunoPrice.duePremium === undefined ||
                    zunoPrice.duePremium === 0 ||
                    ShowSkeleton ? (
                    <SkeletonLoader
                      variant="rectangular"
                      height={15}
                    />
                  ) : (
                    <div className="planHeadValueContainer">
                      <div className="planHead">
                        <p>
                          Basic Price{" "}
                          <span>
                            ₹{" "}
                            {zunoQuickQuoteLoading
                              ? loading
                              : zunoPrice?.basicPremium
                                ? Math.round(zunoPrice?.basicPremium)?.toLocaleString('en-IN')
                                : "0"}
                          </span>
                        </p>
                      </div>
                    </div>
                  )}
                  {checkboxTrueFalseObject.zeroDep ? (
                    <>
                      {zunoQuickQuoteLoading ||
                        zunoPrice.duePremium === undefined ||
                        zunoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              Zero Depreciation{" "}
                              {zunoPrice?.zeroDep && (
                                <span className="bold_premium">
                                  ₹{" "}
                                  {zunoQuickQuoteLoading
                                    ? loading
                                    : zunoPrice?.zeroDep?.toLocaleString('en-IN') || 0}
                                </span>
                              )}
                            </p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={zunoPrice?.zeroDep ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {
                    checkboxTrueFalseObject.shreeramProtect ? (
                      <>
                        {zunoQuickQuoteLoading ||
                          zunoPrice.duePremium === undefined ||
                          zunoPrice.duePremium === 0 ||
                          ShowSkeleton ? (
                          <SkeletonLoader
                            variant="rectangular"
                            height={15}
                          />
                        ) : (
                          <div className="planHeadValueContainer">
                            <div className="planHead">
                              <p>Motor Protection</p>
                            </div>
                            <div className="result_pc">
                              <img
                                src={fail}
                                alt=""
                                srcSet=""
                              />
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  {checkboxTrueFalseObject.engineProtection ? (
                    <>
                      {zunoQuickQuoteLoading ||
                        zunoPrice.duePremium === undefined ||
                        zunoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Engine Protector</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={zunoPrice?.engineProtection ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.consumables ? (
                    <>
                      {zunoQuickQuoteLoading ||
                        zunoPrice.duePremium === undefined ||
                        zunoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Consumable</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={zunoPrice?.consumables ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.keyLockReplacement ? (
                    <>
                      {zunoQuickQuoteLoading ||
                        zunoPrice.duePremium === undefined ||
                        zunoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Key and Lock Replacement</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={
                                zunoPrice?.keyLockReplacement ? success : fail
                              }
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.invoiceCover ? (
                    <>
                      {zunoQuickQuoteLoading ||
                        zunoPrice.duePremium === undefined ||
                        zunoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Invoice Cover</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={zunoPrice?.invoiceCover ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.roadAssistance ? (
                    <>
                      {zunoQuickQuoteLoading ||
                        zunoPrice.duePremium === undefined ||
                        zunoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>24x7 Roadside Assistance</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={zunoPrice?.roadAssistance ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.tyreProtection ? (
                    <>
                      {zunoQuickQuoteLoading ||
                        zunoPrice.duePremium === undefined ||
                        zunoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Tyre Protection</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={zunoPrice?.tyreProtection ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.emiProtection ? (
                    <>
                      {zunoQuickQuoteLoading ||
                        zunoPrice.duePremium === undefined ||
                        zunoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>EMI Protection</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={zunoPrice?.emiProtection ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.lossofPersonalBelongings ? (
                    <>
                      {zunoQuickQuoteLoading ||
                        zunoPrice.duePremium === undefined ||
                        zunoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Loss of Personal Belongings</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={
                                zunoPrice?.lossofPersonalBelongings ? success : fail
                              }
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.dailyAllowance ? (
                    <>
                      {zunoQuickQuoteLoading ||
                        zunoPrice.duePremium === undefined ||
                        zunoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Daily Allowance</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={zunoPrice?.dailyAllowance ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.ncbProtection ? (
                    <>
                      {zunoQuickQuoteLoading ||
                        zunoPrice.duePremium === undefined ||
                        zunoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>NCB Protection Cover</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={zunoPrice?.ncbProtection ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.accessories ? (
                    <>
                      {zunoQuickQuoteLoading ||
                        zunoPrice.duePremium === undefined ||
                        zunoPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Accessories</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={zunoPrice?.accessories ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {zunoPrice.odExternalCngLpg && getBodyData.data.isCngLpgVehicleType ? (
                    <>
                      {zunoQuickQuoteLoading ||
                        zunoPrice.duePremium === undefined ||
                        zunoPrice.duePremium === 0 ||
                        zunoPrice?.odExternalCngLpg == false ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>External Fitted CNG</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={zunoPrice?.odExternalCngLpg ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="bottomOptions_planCard">
                <div className="option_div_pc option_div_pc_border ">
                  {!zunoQuickQuoteLoading && zunoPrice.duePremium && !ShowSkeleton ? (
                    <PlanDetails
                      QuickQuote={zunoQuickQuote}
                      triggerTab="Plan Details"
                      handleBuyNow={(e) => handleBuyNow(zuno, "", ZUNO)}
                      companyImg={zuno}
                      companyName={ZUNO}
                      vehicleBreakupPrice={zunoPrice}
                      bodyData={getBodyData}
                      setShowBottomTab={setShowBottomTab}
                      carDetails={carDetails}
                    />
                  ) : ("")}
                </div>
                <div className="option_div_pc">
                  <p>Cashless Garages</p>
                </div>
              </div>
            </div> */}

            {/* *********************shreeram*************** */}

            <div className="plancard_card">
              <div className="padding_breakup_container">
                <div className="plancard_card_img_holder">
                  <img src={shreeRam} alt="" srcSet="" />
                </div>
                {
                  inspectDetails === true && (planType !== "Bundled" && planType !== "Third Party") && (
                    <div className="inspection_tab">
                      <img src={inspection} alt="" srcSet="" />
                      <p>Inspection Required</p>
                    </div>
                  )
                }
                <div className="premiumContainer_pc">
                  <p className="idv_title_pc">
                    Cover Value(IDV) :{" "}
                    {shreeRamQuoteLoading ||
                      shreeRamPrice?.totalPremium === undefined ||
                      shreeRamPrice?.totalPremium === 0 ||
                      ShowSkeleton || isNaN(shreeRamQuotePrice?.VehicleIDV) ? (
                      <SkeletonLoader
                        variant="rectangular"
                        width={120}
                        height={15}
                      />
                    ) : (
                      <span>₹ {Math.round(shreeRamQuotePrice?.VehicleIDV)?.toLocaleString('en-IN')}</span>
                    )}
                  </p>
                  {shreeRamQuoteLoading ||
                    ShowSkeleton || shreeRamPrice?.totalPremium === false ? (
                    <ButtonLoader />
                  ) : (
                    <Botton
                      text={`₹ ${!shreeRamQuoteLoading
                        ? shreeRamPrice?.totalPremium
                          ? Math.round(shreeRamPrice?.totalPremium)?.toLocaleString('en-IN')
                          : ""
                        : "...Loading"
                        }`}
                      className="buyNowBtn_pc"
                      name="Plan Card"
                      spanTxt="Buy Now"
                      event={(e) => handleBuyNow(shreeRam, " ", SHREERAM)}
                    />
                  )}
                </div>
                <div className="breakup_container">
                  {shreeRamQuoteLoading ||
                    shreeRamPrice?.totalPremium === undefined ||
                    shreeRamPrice?.totalPremium === 0 ||
                    ShowSkeleton ||
                    shreeRamPrice?.totalPremium === false ? (
                    <SkeletonLoader
                      variant="rectangular"
                      height={15}
                    />
                  ) : (
                    <div className="planHeadValueContainer">
                      <div className="planHead">
                        <p>
                          Basic Price
                          <span>
                            ₹{" "}
                            {!shreeRamQuoteLoading
                              ? shreeRamPrice?.basicPremium
                                ? (Math.round(shreeRamPrice?.basicPremium)?.toLocaleString('en-IN'))
                                : "0"
                              : "...Loading"}
                          </span>
                        </p>
                      </div>
                    </div>
                  )}
                  {checkboxTrueFalseObject.zeroDep ? (
                    <>
                      {shreeRamQuoteLoading ||
                        shreeRamPrice?.totalPremium === undefined ||
                        shreeRamPrice?.totalPremium === 0 ||
                        ShowSkeleton ||
                        shreeRamPrice?.totalPremium === false ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              Zero Depreciation
                              {shreeRamPrice?.zeroDep && (
                                <span className="bold_premium">
                                  ₹{" "}
                                  {!shreeRamQuoteLoading
                                    ? shreeRamPrice?.zeroDep
                                      ? Math.round(shreeRamPrice?.zeroDep)?.toLocaleString('en-IN')
                                      : "0"
                                    : "...Loading"}
                                </span>
                              )}
                            </p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={shreeRamPrice?.zeroDep ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {
                    checkboxTrueFalseObject.shreeramProtect ? (
                      <>
                        {shreeRamQuoteLoading ||
                          shreeRamPrice?.totalPremium === undefined ||
                          shreeRamPrice?.totalPremium === 0 ||
                          ShowSkeleton ||
                          shreeRamPrice?.totalPremium === false ? (
                          <SkeletonLoader
                            variant="rectangular"
                            height={15}
                          />
                        ) : (
                          <div className="planHeadValueContainer">
                            <div className="planHead">
                              <p>Motor Protection</p>
                            </div>
                            <div className="result_pc">
                              <img
                                src={
                                  shreeRamPrice?.shreeramProtect ? success : fail
                                }
                                alt=""
                                srcSet=""
                              />
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      ""
                    )}

                  {checkboxTrueFalseObject.engineProtection ? (
                    <>
                      {shreeRamQuoteLoading ||
                        shreeRamPrice?.totalPremium === undefined ||
                        shreeRamPrice?.totalPremium === 0 ||
                        ShowSkeleton ||
                        shreeRamPrice?.totalPremium === false ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Engine Protector</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={
                                shreeRamPrice?.engineProtection ? success : fail
                              }
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.consumables ? (
                    <>
                      {shreeRamQuoteLoading ||
                        shreeRamPrice?.totalPremium === undefined ||
                        shreeRamPrice?.totalPremium === 0 ||
                        ShowSkeleton ||
                        shreeRamPrice?.totalPremium === false ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Consumable</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={shreeRamPrice?.consumables ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.keyLockReplacement ? (
                    <>
                      {shreeRamQuoteLoading ||
                        shreeRamPrice?.totalPremium === undefined ||
                        shreeRamPrice?.totalPremium === 0 ||
                        ShowSkeleton ||
                        shreeRamPrice?.totalPremium === false ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Key and Lock Replacement</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={
                                shreeRamPrice?.keyLockReplacement
                                  ? success
                                  : fail
                              }
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.invoiceCover ? (
                    <>
                      {shreeRamQuoteLoading ||
                        shreeRamPrice?.totalPremium === undefined ||
                        shreeRamPrice?.totalPremium === 0 ||
                        ShowSkeleton ||
                        shreeRamPrice?.totalPremium === false ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Invoice Cover</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={shreeRamPrice?.invoiceCover ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.roadAssistance ? (
                    <>
                      {shreeRamQuoteLoading ||
                        shreeRamPrice?.totalPremium === undefined ||
                        shreeRamPrice?.totalPremium === 0 ||
                        ShowSkeleton ||
                        shreeRamPrice?.totalPremium === false ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>24x7 Roadside Assistance</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={
                                shreeRamPrice?.roadAssistance ? success : fail
                              }
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {shreeRamPrice.odExternalCngLpg ? (
                    <div className="planHeadValueContainer">
                      <div className="planHead">
                        <p>External Fitted CNG</p>
                      </div>
                      <div className="result_pc">
                        <img
                          // src={sbiPrice.odExternalCngLpg ? success : fail}
                          src={success}
                          alt=""
                          srcSet=""
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.tyreProtection ? (
                    <>
                      {shreeRamQuoteLoading ||
                        shreeRamPrice?.totalPremium === undefined ||
                        shreeRamPrice?.totalPremium === 0 ||
                        ShowSkeleton ||
                        shreeRamPrice?.totalPremium === false ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Tyre Protection</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={
                                shreeRamPrice?.tyreProtection ? success : fail
                              }
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.emiProtection ? (
                    <>
                      {shreeRamQuoteLoading ||
                        shreeRamPrice?.totalPremium === undefined ||
                        shreeRamPrice?.totalPremium === 0 ||
                        ShowSkeleton ||
                        shreeRamPrice?.totalPremium === false ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>EMI Protection</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={
                                shreeRamPrice?.emiProtection ? success : fail
                              }
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    " "
                  )}
                  {checkboxTrueFalseObject.lossofPersonalBelongings ? (
                    <>
                      {shreeRamQuoteLoading ||
                        shreeRamPrice?.totalPremium === undefined ||
                        shreeRamPrice?.totalPremium === 0 ||
                        ShowSkeleton ||
                        shreeRamPrice?.totalPremium === false ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Loss of Personal Belongings</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={
                                shreeRamPrice?.lossofPersonalBelongings
                                  ? success
                                  : fail
                              }
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.dailyAllowance ? (
                    <>
                      {shreeRamQuoteLoading ||
                        shreeRamPrice?.totalPremium === undefined ||
                        shreeRamPrice?.totalPremium === 0 ||
                        ShowSkeleton ||
                        shreeRamPrice?.totalPremium === false ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Daily Allowance</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={
                                shreeRamPrice?.dailyAllowance ? success : fail
                              }
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.ncbProtection ? (
                    <>
                      {shreeRamQuoteLoading ||
                        shreeRamPrice?.totalPremium === undefined ||
                        shreeRamPrice?.totalPremium === 0 ||
                        ShowSkeleton ||
                        shreeRamPrice?.totalPremium === false ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>NCB Protection Cover</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={shreeRamPrice?.ncbProtection ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.accessories ? (
                    <>
                      {shreeRamQuoteLoading ||
                        shreeRamPrice?.totalPremium === undefined ||
                        shreeRamPrice?.totalPremium === 0 ||
                        ShowSkeleton ||
                        shreeRamPrice?.totalPremium === false ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Accessories</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={shreeRamPrice?.accessories ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="bottomOptions_planCard">
                <div className="option_div_pc option_div_pc_border ">
                  {!shreeRamQuoteLoading && shreeRamPrice?.totalPremium && !ShowSkeleton && shreeRamPrice?.totalPremium !== false ? (
                    <PlanDetails
                      QuickQuote={shreeRamQuote}
                      triggerTab="Plan Details"
                      handleBuyNow={(e) => handleBuyNow(shreeRam, " ", SHREERAM)}
                      companyImg={shreeRam}
                      companyName={SHREERAM}
                      vehicleBreakupPrice={shreeRamPrice}
                      bodyData={getBodyData}
                      setShowBottomTab={setShowBottomTab}
                      carDetails={carDetails}
                    />
                  ) : ("")}
                </div>
                <div className="option_div_pc">
                  <p>Cashless Garages</p>
                </div>
              </div>
            </div>

            {/* ********************sbi******************** */}

            <div className="plancard_card">
              <div className="padding_breakup_container">
                <div className="plancard_card_img_holder">
                  <img src={sbi} alt="" srcSet="" />
                </div>
                {
                  inspectDetails === true && (planType !== "Bundled" && planType !== "Third Party") && (
                    <div className="inspection_tab">
                      <img src={inspection} alt="" srcSet="" />
                      <p>Inspection Required</p>
                    </div>
                  )
                }
                <div className="premiumContainer_pc">
                  <p className="idv_title_pc">
                    Cover Value(IDV) :
                    {sbiQuickQuoteLoading ||
                      sbiPrice.duePremium === undefined ||
                      sbiPrice.duePremium === 0 ||
                      !sbiPrice.idv ||
                      ShowSkeleton ? (
                      <SkeletonLoader
                        variant="rectangular"
                        width={120}
                        height={15}
                      />
                    ) : (
                      <span>₹ {Math.round(sbiPrice.idv)?.toLocaleString('en-IN')}</span>
                    )}
                  </p>
                  {sbiQuickQuoteLoading ||
                    ShowSkeleton ? (
                    <ButtonLoader />
                  ) : (
                    <Botton
                      text={`₹ ${!sbiQuickQuoteLoading
                        ? sbiPrice.duePremium
                          ? Math.round(sbiPrice.duePremium)?.toLocaleString('en-IN')
                          : ""
                        : loading
                        }`}
                      className="buyNowBtn_pc"
                      name="Plan Card"
                      spanTxt="Buy Now"
                      event={(e) =>
                        !sbiQuickQuoteLoading
                          ? sbiPrice.duePremium
                            ? handleBuyNow(sbi, sbiQuickQuote, SBI)
                            : ""
                          : ""
                      }
                    />
                  )}
                </div>
                <div className="breakup_container">
                  {sbiQuickQuoteLoading ||
                    sbiPrice.duePremium === undefined ||
                    sbiPrice.duePremium === 0 ||
                    ShowSkeleton ? (
                    <SkeletonLoader
                      variant="rectangular"
                      height={15}
                    />
                  ) : (
                    <div className="planHeadValueContainer">
                      <div className="planHead">
                        <p>
                          Basic Price{" "}
                          {sbiPrice.basicPremium && (
                            <span>
                              {`₹ ${!sbiQuickQuoteLoading
                                ? sbiPrice.basicPremium
                                  ? Math.round(sbiPrice.basicPremium)?.toLocaleString('en-IN')
                                  : "0"
                                : loading
                                }`}
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                  )}
                  {sbiPrice.zeroDep ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              Zero Depreciation
                              <span className="bold_premium">
                                ₹{" "}
                                {sbiQuickQuoteLoading ? loading : Math.round(sbiPrice.zeroDep)?.toLocaleString('en-IN') || 0}
                              </span>
                            </p>
                          </div>
                          <div className="result_pc">
                            <img src={success} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.zeroDep && !sbiPrice.zeroDep ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              Zero Depreciation
                              <span className="bold_premium">
                                ₹{" "}
                                {sbiQuickQuoteLoading ? loading : Math.round(sbiPrice.zeroDep)?.toLocaleString('en-IN') || 0}
                              </span>
                            </p>
                          </div>
                          <div className="result_pc">
                            <img src={fail} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {
                    checkboxTrueFalseObject.shreeramProtect ? (
                      <>
                        {sbiQuickQuoteLoading ||
                          sbiPrice.duePremium === undefined ||
                          sbiPrice.duePremium === 0 ||
                          ShowSkeleton ? (
                          <SkeletonLoader
                            variant="rectangular"
                            height={15}
                          />
                        ) : (
                          <div className="planHeadValueContainer">
                            <div className="planHead">
                              <p>Motor Protection</p>
                            </div>
                            <div className="result_pc">
                              <img
                                src={fail}
                                alt=""
                                srcSet=""
                              />
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      ""
                    )}

                  {sbiPrice.engineProtection ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              {sbiQuickQuoteLoading ? loading : "Engine Protector"}
                            </p>
                          </div>
                          <div className="result_pc">
                            <img src={success} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {checkboxTrueFalseObject.engineProtection &&
                    !sbiPrice.engineProtection ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              {sbiQuickQuoteLoading ? loading : "Engine Protector"}
                            </p>
                          </div>
                          <div className="result_pc">
                            <img src={fail} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {sbiPrice.consumables ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>{sbiQuickQuoteLoading ? loading : "Consumable"}</p>
                          </div>
                          <div className="result_pc">
                            <img src={success} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.consumables &&
                    !sbiPrice.consumables ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>{sbiQuickQuoteLoading ? loading : "Consumable"}</p>
                          </div>
                          <div className="result_pc">
                            <img src={fail} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {sbiPrice.keyLockReplacement ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              {sbiQuickQuoteLoading
                                ? loading
                                : "Key and Lock Replacement"}
                            </p>
                          </div>
                          <div className="result_pc">
                            <img src={success} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.keyLockReplacement &&
                    !sbiPrice.keyLockReplacement ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              {sbiQuickQuoteLoading
                                ? loading
                                : "Key and Lock Replacement"}
                            </p>
                          </div>
                          <div className="result_pc">
                            <img src={fail} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {sbiPrice.invoiceCover ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              {sbiQuickQuoteLoading ? loading : "Invoice Cover"}
                            </p>
                          </div>
                          <div className="result_pc">
                            <img src={success} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.invoiceCover &&
                    !sbiPrice.invoiceCover ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              {" "}
                              {sbiQuickQuoteLoading ? loading : "Invoice Cover"}
                            </p>
                          </div>
                          <div className="result_pc">
                            <img src={fail} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {sbiPrice.roadAssistance ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              {sbiQuickQuoteLoading
                                ? loading
                                : "24x7 Roadside Assistance"}
                            </p>
                          </div>
                          <div className="result_pc">
                            <img src={success} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.roadAssistance &&
                    !sbiPrice.roadAssistance ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              {sbiQuickQuoteLoading
                                ? loading
                                : "24x7 Roadside Assistance"}
                            </p>
                          </div>
                          <div className="result_pc">
                            <img src={fail} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {sbiPrice.tyreProtection ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              {sbiQuickQuoteLoading ? loading : "Tyre Protection"}
                            </p>
                          </div>
                          <div className="result_pc">
                            <img src={success} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.tyreProtection &&
                    !sbiPrice.tyreProtection ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              {sbiQuickQuoteLoading ? loading : "Tyre Protection"}
                            </p>
                          </div>
                          <div className="result_pc">
                            <img src={fail} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {sbiPrice.emiProtection ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              {sbiQuickQuoteLoading ? loading : "EMI Protection"}
                            </p>
                          </div>
                          <div className="result_pc">
                            <img src={success} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.emiProtection &&
                    !sbiPrice.emiProtection ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              {sbiQuickQuoteLoading ? loading : "EMI Protection"}
                            </p>
                          </div>
                          <div className="result_pc">
                            <img src={fail} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {sbiPrice.lossofPersonalBelongings ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              {" "}
                              {sbiQuickQuoteLoading
                                ? loading
                                : "Loss of Personal Belongings"}
                            </p>
                          </div>
                          <div className="result_pc">
                            <img src={success} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.lossofPersonalBelongings &&
                    !sbiPrice.lossofPersonalBelongings ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              {sbiQuickQuoteLoading
                                ? loading
                                : "Loss of Personal Belongings"}
                            </p>
                          </div>
                          <div className="result_pc">
                            <img src={fail} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {sbiPrice.dailyAllowance ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              {sbiQuickQuoteLoading ? loading : "Daily Allowance"}
                            </p>
                          </div>
                          <div className="result_pc">
                            <img src={success} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.dailyAllowance &&
                    !sbiPrice.dailyAllowance ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              {sbiQuickQuoteLoading ? loading : "Daily Allowance"}
                            </p>
                          </div>
                          <div className="result_pc">
                            <img src={fail} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.accessories ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Accessories</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={sbiPrice.accessories ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {sbiPrice.odExternalCngLpg ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>External Fitted CNG</p>
                          </div>
                          <div className="result_pc">
                            <img
                              // src={sbiPrice.odExternalCngLpg ? success : fail}
                              src={success}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {sbiPrice.ncbProtection ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              {sbiQuickQuoteLoading
                                ? loading
                                : "NCB Protection Cover"}
                            </p>
                          </div>
                          <div className="result_pc">
                            <img src={success} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.ncbProtection &&
                    !sbiPrice.ncbProtection ? (
                    <>
                      {sbiQuickQuoteLoading ||
                        sbiPrice.duePremium === undefined ||
                        sbiPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              {sbiQuickQuoteLoading
                                ? loading
                                : "NCB Protection Cover"}
                            </p>
                          </div>
                          <div className="result_pc">
                            <img src={fail} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="bottomOptions_planCard">
                <div className="option_div_pc option_div_pc_border ">
                  {!sbiQuickQuoteLoading && sbiPrice.duePremium && !ShowSkeleton ? (
                    <PlanDetails
                      QuickQuote={sbiQuickQuote}
                      triggerTab="Plan Details"
                      handleBuyNow={(e) =>
                        !sbiQuickQuoteLoading
                          ? sbiPrice.duePremium
                            ? handleBuyNow(sbi, sbiQuickQuote, SBI)
                            : ""
                          : ""
                      }
                      companyImg={sbi}
                      companyName={SBI}
                      vehicleBreakupPrice={sbiPrice}
                      bodyData={getBodyData}
                      setShowBottomTab={setShowBottomTab}
                      carDetails={carDetails}
                    />
                  ) : ("")}
                </div>
                <div className="option_div_pc">
                  <p>Cashless Garages</p>
                </div>
              </div>
            </div>

            {/* *********************digit****************** */}

            <div className="plancard_card">
              <div className="padding_breakup_container">
                <div className="plancard_card_img_holder">
                  <img src={digit} alt="" srcSet="" />
                </div>
                {
                  inspectDetails === true && (planType !== "Bundled" && planType !== "Third Party") && (
                    <div className="inspection_tab">
                      <img src={inspection} alt="" srcSet="" />
                      <p>Inspection Required</p>
                    </div>
                  )
                }
                <div className="premiumContainer_pc">
                  <p className="idv_title_pc">
                    Cover Value(IDV) :
                    {digitQuoteLoading ||
                      digitPrice.duePremium === undefined ||
                      digitPrice.duePremium === 0 ||
                      !digitIDV?.idv ||
                      ShowSkeleton ? (
                      <SkeletonLoader
                        variant="rectangular"
                        width={120}
                        height={15}
                      />
                    ) : (
                      <span>₹ {Math.round(digitIDV?.idv)?.toLocaleString('en-IN')}</span>
                    )}
                  </p>
                  {digitQuoteLoading ||
                    ShowSkeleton || isNaN(digitPrice.duePremium) ? (
                    <ButtonLoader />
                  ) : (
                    <Botton
                      text={`₹ ${digitQuoteLoading ? "...Loading" : Math.round(digitPrice.duePremium)?.toLocaleString('en-IN')}`}
                      className="buyNowBtn_pc"
                      name="Plan Card"
                      spanTxt="Buy Now"
                      event={(e) => handleBuyNow(digit, "", DIGIT)}
                    />
                  )}
                </div>
                <div className="breakup_container">
                  {digitQuoteLoading ||
                    digitPrice.duePremium === undefined ||
                    digitPrice.duePremium === 0 ||
                    ShowSkeleton ? (
                    <SkeletonLoader
                      variant="rectangular"
                      height={15}
                    />
                  ) : (
                    <div className="planHeadValueContainer">
                      <div className="planHead">
                        <p>
                          Basic Price
                          {
                            digitPrice?.basicPremium ? (
                              <span>
                                ₹{" "}
                                {digitQuoteLoading
                                  ? "...Loading"
                                  : digitPrice?.basicPremium || 0}
                              </span>
                            ) : (<span> 0  </span>)
                          }
                        </p>
                      </div>
                    </div>
                  )}

                  {checkboxTrueFalseObject.zeroDep ? (
                    <>
                      {digitQuoteLoading ||
                        digitPrice.duePremium === undefined ||
                        digitPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              Zero Depreciation
                              <span className="bold_premium">
                                ₹{" "}
                                {digitQuoteLoading
                                  ? "...Loading"
                                  : digitPrice?.zeroDep || 0}
                              </span>
                            </p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={digitPrice?.zeroDep ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.engineProtection ? (
                    <>
                      {digitQuoteLoading ||
                        digitPrice.duePremium === undefined ||
                        digitPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Engine Protector</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={digitPrice?.engineProtection ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.consumables ? (
                    <>
                      {digitQuoteLoading ||
                        digitPrice.duePremium === undefined ||
                        digitPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Consumable</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={digitPrice?.consumables ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.keyLockReplacement ? (
                    <>
                      {digitQuoteLoading ||
                        digitPrice.duePremium === undefined ||
                        digitPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Key and Lock Replacement</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={digitPrice?.keyLockReplacement ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.invoiceCover ? (
                    <>
                      {digitQuoteLoading ||
                        digitPrice.duePremium === undefined ||
                        digitPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Invoice Cover</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={digitPrice?.invoiceCover ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.roadAssistance ? (
                    <>
                      {digitQuoteLoading ||
                        digitPrice.duePremium === undefined ||
                        digitPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>24x7 Roadside Assistance</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={digitPrice?.roadAssistance ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.tyreProtection ? (
                    <>
                      {digitQuoteLoading ||
                        digitPrice.duePremium === undefined ||
                        digitPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Tyre Protection</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={digitPrice?.tyreProtection ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.emiProtection ? (
                    <>
                      {digitQuoteLoading ||
                        digitPrice.duePremium === undefined ||
                        digitPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>EMI Protection</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={digitPrice?.emiProtection ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    " "
                  )}
                  {checkboxTrueFalseObject.lossofPersonalBelongings ? (
                    <>
                      {digitQuoteLoading ||
                        digitPrice.duePremium === undefined ||
                        digitPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Loss of Personal Belongings</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={
                                digitPrice?.lossofPersonalBelongings
                                  ? success
                                  : fail
                              }
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.dailyAllowance ? (
                    <>
                      {digitQuoteLoading ||
                        digitPrice.duePremium === undefined ||
                        digitPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Daily Allowance</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={digitPrice?.dailyAllowance ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.ncbProtection ? (
                    <>
                      {digitQuoteLoading ||
                        digitPrice.duePremium === undefined ||
                        digitPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>NCB Protection Cover</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={digitPrice?.ncbProtection ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.accessories ? (
                    <>
                      {digitQuoteLoading ||
                        digitPrice.duePremium === undefined ||
                        digitPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Accessories</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={digitPrice?.accessories ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="bottomOptions_planCard">
                <div className="option_div_pc option_div_pc_border ">
                  {!digitQuoteLoading && digitPrice.duePremium && !ShowSkeleton ? (
                    <PlanDetails
                      QuickQuote={digitQuote}
                      triggerTab="Plan Details"
                      handleBuyNow={(e) => handleBuyNow(digit, "", DIGIT)}
                      companyImg={digit}
                      companyName={DIGIT}
                      vehicleBreakupPrice={digitPrice}
                      bodyData={getBodyData}
                      setShowBottomTab={setShowBottomTab}
                      carDetails={carDetails}
                    />) : ("")}
                </div>
                <div className="option_div_pc">
                  <p>Cashless Garages</p>
                </div>
              </div>
            </div>

            {/* *********************icici******************  */}

            <div className="plancard_card">
              <div className="padding_breakup_container">
                <div className="plancard_card_img_holder">
                  <img src={icici} alt="" srcSet="" />
                </div>
                {
                  inspectDetails === true && (planType !== "Bundled" && planType !== "Third Party") && (
                    <div className="inspection_tab">
                      <img src={inspection} alt="" srcSet="" />
                      <p>Inspection Required</p>
                    </div>
                  )
                }
                <div className="premiumContainer_pc">
                  <p className="idv_title_pc">
                    Cover Value(IDV) :{" "}
                    {iciciQuoteLoading ||
                      iciciPrice.duePremium === undefined ||
                      iciciPrice.duePremium === 0 ||
                      ShowSkeleton ? (
                      <SkeletonLoader
                        variant="rectangular"
                        width={120}
                        height={15}
                      />
                    ) : (
                      <span>₹{iciciIDV}</span>
                    )}
                  </p>
                  {iciciQuoteLoading ||
                    ShowSkeleton ||
                    iciciPrice.duePremium === 0 ? (
                    <ButtonLoader />
                  ) : (
                    <Botton
                      text={`₹ ${iciciQuoteLoading ? "...Loading" : Math.round(iciciPrice.duePremium)?.toLocaleString('en-IN')
                        }`}
                      className="buyNowBtn_pc"
                      name="Plan Card"
                      spanTxt="Buy Now"
                      event={(e) =>
                        iciciPrice.duePremium
                          ? handleBuyNow(icici, "", ICICI)
                          : ""
                      }
                    />
                  )}
                </div>
                <div className="breakup_container">
                  {iciciQuoteLoading || iciciPrice?.basicPremium === NaN ||
                    iciciPrice.duePremium === 0 ||
                    ShowSkeleton
                    ? (
                      <SkeletonLoader
                        variant="rectangular"
                        height={15}
                      />
                    ) : (
                      <div className="planHeadValueContainer">
                        <div className="planHead">
                          <p>
                            Basic Price {
                              iciciPrice?.basicPremium && (
                                <span>
                                  ₹{" "}
                                  {iciciQuoteLoading
                                    ? "...Loading"
                                    : Math.round(iciciPrice?.basicPremium)?.toLocaleString('en-IN') || 0}
                                </span>
                              )
                            }
                          </p>
                        </div>
                      </div>
                    )}

                  {checkboxTrueFalseObject.zeroDep ? (
                    <>
                      {iciciQuoteLoading ||
                        iciciPrice.duePremium === undefined ||
                        iciciPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>
                              Zero Depreciation{" "} {
                                iciciPrice?.zeroDep && (
                                  <span className="bold_premium">
                                    ₹{" "}
                                    {iciciQuoteLoading
                                      ? "...Loading"
                                      : iciciPrice?.zeroDep?.toLocaleString('en-IN') || 0}
                                  </span>
                                )
                              }
                            </p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={iciciPrice?.zeroDep ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {iciciPrice.odExternalCngLpg && getBodyData.data.isCngLpgVehicleType ? (
                    <>
                      {iciciQuoteLoading ||
                        iciciPrice.duePremium === undefined ||
                        iciciPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>External Fitted CNG</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={iciciPrice?.odExternalCngLpg ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {
                    checkboxTrueFalseObject.shreeramProtect ? (
                      <>
                        {iciciQuoteLoading ||
                          iciciPrice.duePremium === undefined ||
                          iciciPrice.duePremium === 0 ||
                          ShowSkeleton ? (
                          <SkeletonLoader
                            variant="rectangular"
                            height={15}
                          />
                        ) : (
                          <div className="planHeadValueContainer">
                            <div className="planHead">
                              <p>Motor Protection</p>
                            </div>
                            <div className="result_pc">
                              <img
                                src={fail}
                                alt=""
                                srcSet=""
                              />
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  {checkboxTrueFalseObject.engineProtection ? (
                    <>
                      {iciciQuoteLoading ||
                        iciciPrice.duePremium === undefined ||
                        iciciPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Engine Protector</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={iciciPrice?.engineProtection ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.consumables ? (
                    <>
                      {iciciQuoteLoading ||
                        iciciPrice.duePremium === undefined ||
                        iciciPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Consumable</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={iciciPrice?.consumables ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.keyLockReplacement ? (
                    <>
                      {iciciQuoteLoading ||
                        iciciPrice.duePremium === undefined ||
                        iciciPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Key and Lock Replacement</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={iciciPrice?.keyLockReplacement ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.invoiceCover ? (
                    <>
                      {iciciQuoteLoading ||
                        iciciPrice.duePremium === undefined ||
                        iciciPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Invoice Cover</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={iciciPrice?.invoiceCover ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.roadAssistance ? (
                    <>
                      {iciciQuoteLoading ||
                        iciciPrice.duePremium === undefined ||
                        iciciPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>24x7 Roadside Assistance</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={iciciPrice?.roadAssistance ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.tyreProtection ? (
                    <>
                      {iciciQuoteLoading ||
                        iciciPrice.duePremium === undefined ||
                        iciciPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Tyre Protection</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={iciciPrice?.tyreProtection ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.emiProtection ? (
                    <>
                      {iciciQuoteLoading ||
                        iciciPrice.duePremium === undefined ||
                        iciciPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>EMI Protection</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={iciciPrice?.emiProtection ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    " "
                  )}
                  {checkboxTrueFalseObject.lossofPersonalBelongings ? (
                    <>
                      {iciciQuoteLoading ||
                        iciciPrice.duePremium === undefined ||
                        iciciPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Loss of Personal Belongings</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={
                                iciciPrice?.personalBelonging
                                  ? success
                                  : fail
                              }
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.dailyAllowance ? (
                    <>
                      {iciciQuoteLoading ||
                        iciciPrice.duePremium === undefined ||
                        iciciPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Daily Allowance</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={iciciPrice?.dailyAllowance ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.ncbProtection ? (
                    <>
                      {iciciQuoteLoading ||
                        iciciPrice.duePremium === undefined ||
                        iciciPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>NCB Protection Cover</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={iciciPrice?.ncbProtection ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {checkboxTrueFalseObject.accessories ? (
                    <>
                      {iciciQuoteLoading ||
                        iciciPrice.duePremium === undefined ||
                        iciciPrice.duePremium === 0 ||
                        ShowSkeleton ? (
                        <SkeletonLoader
                          variant="rectangular"
                          height={15}
                        />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>Accessories</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={iciciPrice?.accessories ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="bottomOptions_planCard">
                <div className="option_div_pc option_div_pc_border ">
                  {!iciciQuoteLoading && iciciPrice.duePremium && !ShowSkeleton ? (
                    <PlanDetails
                      QuickQuote={iciciQuote}
                      triggerTab="Plan Details"
                      handleBuyNow={(e) =>
                        iciciPrice.duePremium
                          ? handleBuyNow(icici, "", ICICI)
                          : ""
                      }
                      companyImg={icici}
                      companyName={ICICI}
                      vehicleBreakupPrice={iciciPrice}
                      bodyData={getBodyData}
                      setShowBottomTab={setShowBottomTab}
                      carDetails={carDetails}
                    />
                  ) : ("")}
                </div>
                <div className="option_div_pc">
                  <p>Cashless Garages</p>
                </div>
              </div>
            </div>

          </>
        ) : (
          " BY DEFAULT "
        )}
      </section>
    </>
  );
};

export default Plancard;