import {
    shreeRamQuickQuoteAction,
    shreeRamProposalAction
} from "../Actions/shreeRamApiAction";
import { createSlice } from "@reduxjs/toolkit";

export const shreeRamQuickQuoteReducer = createSlice({
    name: "shreeRamQuikQuotes",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    reducers: {
        resetShreeRamQuickQuote: (state) => {
          state.data = null;
        },
      },
    extraReducers: (builder) => {
        builder.addCase(shreeRamQuickQuoteAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(shreeRamQuickQuoteAction.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(shreeRamQuickQuoteAction.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
});

export const shreeRamProposalReducer = createSlice({
    name: "shreeRamProposal",
    initialState: {
        data: null,
    },
    reducers: {
        resetShreeRamProposal: (state) => {
          state.isLoading = false;
          state.data = null;
          state.isError = false;
        },
      },
    extraReducers: (builder) => {
        builder.addCase(shreeRamProposalAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(shreeRamProposalAction.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(shreeRamProposalAction.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
});
