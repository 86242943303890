import React, { useState, useEffect } from "react";
import "./QuotesContainer.css";
import Botton from "../Button/Button";
import Plancard from "./Plancard";
import Modal from "react-modal";
import ConfirmDetails from "./ConfirmDetails.jsx";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

const QuotesContainer = (props) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const {
    PolicyPlanType,
    carDetails,
    handleInputCarDetails,
    businessType,
    vehicleData,
    ShowPcSumInsuredField,
    ShowAccesoriesField,
    isFindMyCar,
    setShowBottomTab,
    policyTypeOption,
    passVehicleData,
    policyTypeList,
    setLoaderFromConfirmPg,
    CompanyWiseLoader,
    setCarDetails,
    ShowExpDateCalendar,
    setShowExpDateCalendar,
    ShowRegDateCalendar,
    setShowRegDateCalendar,
    ShowOdExpDateCalendar,
    setShowOdExpDateCalendar,
    ShowSkeleton,
  } = props;

  const [allCarDetails, setallCarDetails] = useState({});
  const [logo, setlogo] = useState("");
  const [CompanyName, setCompanyName] = useState(null);

  function openModal(logo, carDetails, companyName) {
    setallCarDetails(carDetails);
    setlogo(logo);
    setCompanyName(companyName);
    setIsOpen(true);
    setShowBottomTab(false);
  }

  function closeModal() {
    setIsOpen(false);
    setShowBottomTab(true);
  }

  useEffect(() => {

    if (modalIsOpen) {
      setLoaderFromConfirmPg(true);
    } else {
      setLoaderFromConfirmPg(false);
    }

    if (modalIsOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    // Cleanup function to reset the overflow property when the component unmounts
    return () => {
      document.body.style.overflow = '';
    };

  }, [modalIsOpen])


  return (
    <>
      <div className="section_bottomContent_vp">
        <div>
          <Plancard
            openModal={openModal}
            planType={carDetails?.PolicyTypeValue}
            carDetails={carDetails}
            logo={logo}
            handleInputCarDetails={handleInputCarDetails}
            setShowBottomTab={setShowBottomTab}
            ShowSkeleton={ShowSkeleton}
          />
        </div>
      </div>

      {
        !CompanyWiseLoader && (
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
            id=""
          >
            <ConfirmDetails
              closeModal={closeModal}
              vehicleData={vehicleData}
              planType={carDetails?.PolicyTypeValue}
              carDetails={carDetails}
              allCarDetails={allCarDetails}
              CompanyImage={logo}
              handleInputCarDetails={handleInputCarDetails}
              CompanyName={CompanyName}
              businessType={businessType}
              isFindMyCar={isFindMyCar}
              passVehicleData={passVehicleData}
              policyTypeList={policyTypeList}
              setCarDetails={setCarDetails}
              ShowExpDateCalendar={ShowExpDateCalendar}
              setShowExpDateCalendar={setShowExpDateCalendar}
              ShowRegDateCalendar={ShowRegDateCalendar}
              setShowRegDateCalendar={setShowRegDateCalendar}
              ShowOdExpDateCalendar={ShowOdExpDateCalendar}
              setShowOdExpDateCalendar={setShowOdExpDateCalendar}
            />
          </Modal>
        )
      }
    </>
  );
};

export default QuotesContainer;
