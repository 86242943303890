import React, { useState } from "react";
import tick from "../../Assets/Images/success.svg";
import { useSelector } from "react-redux";
import {
  ACKO , TATA
} from "../../utils/commonUtils";

const Stepper = (props) => {
  const {
    ShowForm,
    formData,
    StepperValue,
    InspectionStatus,
    handleStepperRoute,
  } = props;

  const CompanyName = useSelector((state) => {
    return state.companyName?.data?.companyName;
  });

  return (
    <div>
      <div className="stepperContainer">

        <div
          className={
            ShowForm === "ownerDetail"
              ? "stepperDiv_active stepperDiv"
              : " stepperDiv"
          }
          onClick={(e) => handleStepperRoute("ownerDetail")}
        >
          <div className="signal_pform">
            {StepperValue === 2 ||
              StepperValue === 3 ||
              StepperValue === 4 ||
              StepperValue === 5 ||
              StepperValue === 6 ||
              StepperValue === 7 ? (
              <img src={tick} alt="" srcSet="" />
            ) : (
              <p>1</p>
            )}
          </div>
          <div className="title_stepper">
            <p className="small_text small_text_stepper">Step 1</p>
            <p>Owner Details</p>
          </div>
        </div>

        {
          formData.carOwner !== "isCompany" && CompanyName != ACKO && (
            <div
              className={
                ShowForm === "PersonalDetails"
                  ? "stepperDiv_active stepperDiv"
                  : " stepperDiv"
              }
              onClick={(e) => handleStepperRoute("PersonalDetails")}
            >
              <div className="signal_pform">
                {StepperValue === 3 ||
                  StepperValue === 4 ||
                  StepperValue === 5 ||
                  StepperValue === 6 ||
                  StepperValue === 7 ? (
                  <img src={tick} alt="" srcSet="" />
                ) : (
                  <p>2</p>
                )}
              </div>
              <div className="title_stepper">
                <p className="small_text small_text_stepper">Step 2</p>
                <p>Personal Details</p>
              </div>
            </div>
          )
        }

        <div
          className={
            ShowForm === "AddressDetails"
              ? "stepperDiv_active stepperDiv"
              : " stepperDiv"
          }
          onClick={(e) => handleStepperRoute("AddressDetails")}
        >
          <div className="signal_pform">
            {StepperValue === 4 ||
              StepperValue === 5 ||
              StepperValue === 6 ||
              StepperValue === 7 ? (
              <img src={tick} alt="" srcSet="" />
            ) : (
              <p>
                {
                  formData.carOwner !== "isCompany" && CompanyName != ACKO ? "3" : "2"
                }
              </p>
            )}
          </div>
          <div className="title_stepper">
            <p className="small_text small_text_stepper ">
              {
                formData.carOwner !== "isCompany" && CompanyName != ACKO ? "Step 3" : "Step 2"
              }
            </p>
            <p>Address Details</p>
          </div>
        </div>

        <div
          className={
            ShowForm === "VehicleDetails"
              ? "stepperDiv_active stepperDiv"
              : " stepperDiv"
          }
          onClick={(e) => handleStepperRoute("VehicleDetails")}
        >
          <div className="signal_pform">
            {StepperValue === 5 || StepperValue === 6 || StepperValue === 7 ? (
              <img src={tick} alt="" srcSet="" />
            ) : (
              <p>
                {
                  formData.carOwner !== "isCompany" && CompanyName != ACKO ? "4" : "3"
                }
              </p>
            )}
          </div>
          <div className="title_stepper">
            <p className="small_text small_text_stepper">
              {
                formData.carOwner !== "isCompany" && CompanyName != ACKO ? "Step 4" : "Step 3"
              }
            </p>
            <p>Vehicle Details</p>
          </div>
        </div>

        {CompanyName != ACKO && CompanyName != TATA && (
          <div
            className={
              ShowForm === "kycDetails"
                ? "stepperDiv_active stepperDiv"
                : " stepperDiv"
            }
            onClick={(e) => handleStepperRoute("kycDetails")}
          >
            <div className="signal_pform">
              {StepperValue === 6 || StepperValue === 7 ? (
                <img src={tick} alt="" srcSet="" />
              ) : (
                <p>
                  {
                    formData.carOwner !== "isCompany" && CompanyName != ACKO ? "5" : "4"
                  }
                </p>
              )}
            </div>
            <div className="title_stepper">
              <p className="small_text small_text_stepper">
                {
                  formData.carOwner !== "isCompany" && CompanyName != ACKO ? "Step 5" : "Step 4"
                }
              </p>
              <p>Update KYC</p>
            </div>
          </div>
        )}

        {InspectionStatus && CompanyName != ACKO && (
          <div
            className={
              ShowForm === "InspectionDetails"
                ? "stepperDiv_active stepperDiv"
                : " stepperDiv"
            }
            onClick={(e) => handleStepperRoute("InspectionDetails")}
          >
            <div className="signal_pform">
              {StepperValue === 7 ? (
                <img src={tick} alt="" srcSet="" />
              ) : (
                <p>
                  {
                    formData.carOwner !== "isCompany" && CompanyName != ACKO ? "6" : "5"
                  }
                </p>
              )}
            </div>
            <div className="title_stepper">
              <p className="small_text small_text_stepper">
                {
                  formData.carOwner !== "isCompany" && CompanyName != ACKO ? "Step 6" : "Step 5"
                }
              </p>
              <p>Inspection</p>
            </div>
          </div>
        )}

      </div>
    </div>
  );
};

export default Stepper;
