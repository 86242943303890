import { createAsyncThunk } from "@reduxjs/toolkit";

export const loginAction = createAsyncThunk(
  "loginData",
  async (bodyData) => {
    const response = await fetch(
      //"https://zrmmh5h8wj.execute-api.us-east-1.amazonaws.com/default/bmi-pos-login", //UAT
      "https://vripkgfhv1.execute-api.ap-south-1.amazonaws.com/default/prod_bmi_pos_login", //PROD
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);


export const updateUserAction = createAsyncThunk(
  "updateUserData",
  async (bodyData) => {
    const response = await fetch(
      //"https://zrmmh5h8wj.execute-api.us-east-1.amazonaws.com/default/bmi-pos-userDetails", //UAT
      "https://vripkgfhv1.execute-api.ap-south-1.amazonaws.com/default/prod_bmi_pos_userDetails", //PROD
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);