import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import "./Modal.css";
import Botton from "../Button/Button";
import { commonVehicleFetchApiAction, common2WVehicleFetchApiAction } from "../../redux/Actions/commonVehicleFetchApiAction";
import EditDetails from "../ViewPlans/EditDetails";
import EditDetailsBike from "../ViewPlans/EditDetailsBike";
import close from "../../Assets/Images/Close.svg";
import ModalInp from "../RegNumberForm/modalInp";
import { Routes, Route, useNavigate, Link } from "react-router-dom";
import { ROLLOVER, FINDMYCAR } from "../../utils/commonUtils";
import edit from "../../Assets/Images/document.svg"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

const PopUp = (props) => {
  const {
    text,
    className,
    name,
    event,
    leftSideTitle,
    leftSideData,
    leftClassOne,
    leftClassTwo,
    leftClassThree,
    bottomContentLeft,
    rightSideHead,
    icons,
    renderContent,
    handlePolicyTypeSelection,
    PolicyTypeValue,
    value,
    HandleExpDate,
    vehicleNumber,
    carDetails,
    isClaimPrevPolicy,
    handleInputCarDetails,
    clickEvent,
    MakeList,
    FuelTypeList,
    businessType,
    VarientList,
    ccList,
    ModelListArray,
    setCarDetails,
    carDetailsCopyState,
    handleCopiedDetails,
    setcarDetailsCopyState,
    minDateValue,
    maxDateValue,
    setRegNumberErr,
    errors,
    setLoading,
    handleMakeSelect,
    handleModelSelect,
    handleFuelSelect,
    handleVariantSelect,
    handleCCSelect,
    mobileCLickEvent,
    handleNcbValue,
    policyTypeList,
    setShowRegDateCalendar,
    ShowRegDateCalendar,
    ShowOdExpDateCalendar,
    setShowOdExpDateCalendar,
    ShowExpDateCalendar,
    setShowExpDateCalendar,
    vehicleDetails,
    setOpenCarDetailsPopUp,
    vehicleType = "4W"
  } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modalIsOpen, setIsOpen] = useState(false);

  async function openModal() {
    // alert("ksksksk")
    if (businessType === "rollover") {
      if (vehicleNumber?.length <= 0) {
        setRegNumberErr(true);
      } else {
        if (vehicleNumber) {
          if (mobileCLickEvent) {
            mobileCLickEvent();
          }
          setLoading(true);
          try {
            let getVehicleDetails

            if (vehicleType == "4W") {
              getVehicleDetails = await dispatch(
                commonVehicleFetchApiAction(vehicleNumber)
              );
            } else {
              getVehicleDetails = await dispatch(
                common2WVehicleFetchApiAction(vehicleNumber)
              );
            }
            getVehicleDetails = getVehicleDetails?.payload;
            if (
              getVehicleDetails?.data?.model_details?.[0]?.Model == null
              || getVehicleDetails.msg == "Error"
              || getVehicleDetails.message == "Internal server error"
            ) {
              setLoading(false);
              localStorage.setItem("isFindMyCar", false);
              if (vehicleType == "4W") {
                navigate("/Find-My-Car", {
                  state: {
                    businessType: FINDMYCAR,
                    vehicleNumber: vehicleNumber,
                    isFindMyCar: false,
                  },
                });
              } else {
                navigate("/Find-My-Bike", {
                  state: {
                    businessType: FINDMYCAR,
                    vehicleNumber: vehicleNumber,
                    isFindMyCar: false,
                  },
                });
              }
            } else {
              setLoading(false);
              setIsOpen(true);
            }
          } catch (error) {
            setLoading(false);
            localStorage.setItem("isFindMyCar", false);
            if (vehicleType == "4W") {
              navigate("/Find-My-Car", {
                state: {
                  businessType: FINDMYCAR,
                  vehicleNumber: vehicleNumber,
                  isFindMyCar: false,
                },
              });
            } else {
              navigate("/Find-My-Bike", {
                state: {
                  businessType: FINDMYCAR,
                  vehicleNumber: vehicleNumber,
                  isFindMyCar: false,
                },
              });
            }
          }
        } else {
          setIsOpen(true);
          // alert("kelau");
        }
      }
    } else {
      setIsOpen(true);
    }
  }

  const [errorss, setErrors] = useState({});

  const validateValidateDetails = () => {
    const newErrors = {};
    if (!carDetailsCopyState.vehicleNumberCopy) {
      newErrors.vehicleNumberCopy = "Vehicle Number is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSaveCarDetails = (event, isFindMyCar) => {
    let isValid = validateValidateDetails();
    handleInputCarDetails(carDetailsCopyState?.isClaimPrevPolicyCopy === true ? "Yes" : carDetailsCopyState?.isClaimPrevPolicyCopy, "isClaimPrevPolicy", "")
    if (isValid) {
      closeModal();
      setCarDetails((prevItems) => ({
        ...prevItems,
        vehicleNumber: carDetailsCopyState?.vehicleNumberCopy,
        prevPolicyType: carDetailsCopyState?.prevPolicyTypeCopy,
        policyExpDate: carDetailsCopyState?.policyExpCopy,
        prevPolicyODexpDate: carDetailsCopyState?.odPolicyExpCopy,
        // prevPolicyTPexpDate: carDetailsCopyState?.tpPolicyExpCopy,
        isClaimPrevPolicy: carDetailsCopyState?.isClaimPrevPolicyCopy,
        prevNcb: carDetailsCopyState?.prevNcbCopy,
        newNcb: carDetailsCopyState?.newNcbCopy,
        make: carDetailsCopyState?.makeCopy,
        model: carDetailsCopyState?.modelCopy,
        varient: carDetailsCopyState?.varientCopy,
        fuelType: carDetailsCopyState?.fuelTypeCopy,
        cc: carDetailsCopyState?.ccCopy,
        policyRegDate: carDetailsCopyState?.regDateCopy,
        isFindMyCar: isFindMyCar ? isFindMyCar : carDetails.isFindMyCar,
      }));
    }
  };

  const handleCancelModal = () => {
    if (closeModal) {
      closeModal();
    }
    setcarDetailsCopyState((prevItems) => ({
      ...prevItems,
      vehicleNumberCopy: carDetails?.vehicleNumber,
      prevPolicyTypeCopy: carDetails?.prevPolicyType,
      policyExpCopy: carDetails?.policyExpDate,
      odPolicyExpCopy: carDetails?.prevPolicyODexpDate,
      tpPolicyExpCopy: carDetails?.prevPolicyTPexpDate,
      isClaimPrevPolicyCopy: carDetails?.isClaimPrevPolicy,
      prevNcbCopy: carDetails?.prevNcb,
      newNcbCopy: carDetails?.newNcb,
      makeCopy: carDetails?.make,
      modelCopy: carDetails?.model,
      varientCopy: carDetails?.varient,
      fuelTypeCopy: carDetails?.fuelType,
      ccCopy: carDetails?.cc,
      regDateCopy: carDetails?.policyRegDate,
    }));
  };

  function closeModal() {
    setIsOpen(false);
  }

  function closeEditModal() {
    setIsOpen(false);
    handleCancelModal();
  }

  const handleRouteToFMC = () => {
    localStorage.setItem("isFindMyCar", false);
    if (vehicleType == "4W") {
      navigate("/Find-My-Car", {
        state: {
          businessType: ROLLOVER,
          vehicleNumber: vehicleNumber,
          isFindMyCar: false,
        },
      });
    } else {
      navigate("/Find-My-Bike", {
        state: {
          businessType: ROLLOVER,
          vehicleNumber: vehicleNumber,
          isFindMyCar: false,
        },
      });
    }
  };

  useEffect(() => {
    if (setOpenCarDetailsPopUp) {
      setOpenCarDetailsPopUp(modalIsOpen);
    }
  }, [modalIsOpen])

  return (
    <div id="">
      <Botton
        text={text}
        icons={icons}
        name={name}
        event={openModal}
        className={className}
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={
          renderContent === "policyDetails" ? closeModal : closeEditModal
        }
        style={customStyles}
        contentLabel="Example Modal"
        id=""
      >
        {renderContent === "policyDetails" && (
          <div className="modal_container modal_container-height">
            <div className="leftSide_modal leftSide_modal-desktop part_modal_container ">
              <p className="modal_title model_title_margin">{leftSideTitle}</p>
              {leftSideData.map((index, key) => (
                <div key={key} className={leftClassOne}>
                  <p
                    className={
                      index.title == "Company Model: "
                        ? leftClassTwo + " " + "width-title"
                        : leftClassTwo
                    }
                  >
                    {index.title}
                  </p>
                  <p className={leftClassThree}>{index.desc}</p>
                </div>
              ))}
              {bottomContentLeft.map((index, key) => (
                <div
                  key={key}
                  className={index.classOne + " " + "bottomContentPadding"}
                >
                  <p className={index.classTwo}>{index.contentOne}</p>
                  <p className={index.classThree} onClick={handleRouteToFMC}>
                    {index.contentTwo}
                  </p>
                </div>
              ))}
            </div>
            <div className="rightSide_modal rightSide_modal-width part_modal_container ">
              <img
                src={close}
                className="closeImg_modal"
                alt=""
                srcSet=""
                onClick={closeModal}
              />
              <p className="title_rightSide_modal" > {vehicleType == "4W" ? "Enter your car details" : "Enter your bike details"} </p>
              <ModalInp
                clickEvent={clickEvent}
                closeModal={closeModal}
                businessType={businessType}
                errors={errors}
                vehicleType={vehicleType}
                edit={edit}
                make={vehicleDetails?.make}
                model={vehicleDetails?.model}
                vehicleNumber={vehicleDetails?.vehicleNumber}
                varient={vehicleDetails?.varient}
                fuelType={vehicleDetails?.fuelType}
                regDate={vehicleDetails?.regDate}
                pincode={vehicleDetails?.suggested_pincode}
                handleRouteToFMC={handleRouteToFMC}
                vehicleDetails={leftSideData}
              />
            </div>
          </div>
        )}
        {vehicleType == "4W" ?
          <EditDetails
            renderContent={renderContent}
            closeModal={closeModal}
            handlePolicyTypeSelection={handlePolicyTypeSelection}
            PolicyTypeValue={PolicyTypeValue}
            handleRegDate={event}
            HandleExpDate={HandleExpDate}
            value={value}
            name={name}
            carDetails={carDetails}
            isClaimPrevPolicy={isClaimPrevPolicy}
            handleInputCarDetails={handleInputCarDetails}
            MakeList={MakeList}
            FuelTypeList={FuelTypeList}
            VarientList={VarientList}
            ModelListArray={ModelListArray}
            businessType={businessType}
            ccList={ccList}
            setCarDetails={setCarDetails}
            carDetailsCopyState={carDetailsCopyState}
            handleCopiedDetails={handleCopiedDetails}
            setcarDetailsCopyState={setcarDetailsCopyState}
            handleMakeSelect={handleMakeSelect}
            handleModelSelect={handleModelSelect}
            handleFuelSelect={handleFuelSelect}
            handleVariantSelect={handleVariantSelect}
            handleCCSelect={handleCCSelect}
            minDateValue={minDateValue}
            maxDateValue={maxDateValue}
            handleSaveCarDetails={handleSaveCarDetails}
            handleCancelModal={handleCancelModal}
            handleNcbValue={handleNcbValue}
            policyTypeList={policyTypeList}
            setShowRegDateCalendar={setShowRegDateCalendar}
            ShowRegDateCalendar={ShowRegDateCalendar}
            ShowOdExpDateCalendar={ShowOdExpDateCalendar}
            setShowOdExpDateCalendar={setShowOdExpDateCalendar}
            ShowExpDateCalendar={ShowExpDateCalendar}
            setShowExpDateCalendar={setShowExpDateCalendar}
          /> :
          <EditDetailsBike
            renderContent={renderContent}
            closeModal={closeModal}
            handlePolicyTypeSelection={handlePolicyTypeSelection}
            PolicyTypeValue={PolicyTypeValue}
            handleRegDate={event}
            HandleExpDate={HandleExpDate}
            value={value}
            name={name}
            carDetails={carDetails}
            isClaimPrevPolicy={isClaimPrevPolicy}
            handleInputCarDetails={handleInputCarDetails}
            MakeList={MakeList}
            FuelTypeList={FuelTypeList}
            VarientList={VarientList}
            ModelListArray={ModelListArray}
            businessType={businessType}
            ccList={ccList}
            setCarDetails={setCarDetails}
            carDetailsCopyState={carDetailsCopyState}
            handleCopiedDetails={handleCopiedDetails}
            setcarDetailsCopyState={setcarDetailsCopyState}
            handleMakeSelect={handleMakeSelect}
            handleModelSelect={handleModelSelect}
            handleFuelSelect={handleFuelSelect}
            handleVariantSelect={handleVariantSelect}
            handleCCSelect={handleCCSelect}
            minDateValue={minDateValue}
            maxDateValue={maxDateValue}
            handleSaveCarDetails={handleSaveCarDetails}
            handleCancelModal={handleCancelModal}
            handleNcbValue={handleNcbValue}
            policyTypeList={policyTypeList}
            setShowRegDateCalendar={setShowRegDateCalendar}
            ShowRegDateCalendar={ShowRegDateCalendar}
            ShowOdExpDateCalendar={ShowOdExpDateCalendar}
            setShowOdExpDateCalendar={setShowOdExpDateCalendar}
            ShowExpDateCalendar={ShowExpDateCalendar}
            setShowExpDateCalendar={setShowExpDateCalendar}
          />}
      </Modal>
    </div>
  );
};

export default PopUp;
