import "../../../node_modules/react-toastify/dist/ReactToastify.css";
import React, { useState, useEffect, useCallback } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import "./Collapsible.css";
import arrow from "../../Assets/Images/arrow.svg";
import edit from "../../Assets/Images/document.svg";
import arrowUp from "../../Assets/Images/arrowUp.svg";
import Botton from "../Button/Button";
import { shreeRamProposalAction } from "../../redux/Actions/shreeRamApiAction";
import { shreeRamProposalReducer } from "../../redux/Reducers/shreeRamApiReducer";
import { digitCreateQuoteAction } from "../../redux/Actions/digitApiAction";
import { digitCreateQuoteReducer } from "../../redux/Reducers/digitApiReducer";
import { zunoFullQuoteAction, zunoInsertBodydAction } from "../../redux/Actions/zunoApiAction";
import {
  iciciProposalAction,
  iciciCreateTreansactionAction,
} from "../../redux/Actions/iciciApiAction";
import {
  ackoProposalAction,
} from "../../redux/Actions/ackoApiAction";
import { zunoFullQuoteReducer } from "../../redux/Reducers/zunoApiReducer";
import {
  generateUnique16DigitNumber,
  generateCutomerId,
  generateCheckSum
} from "../../utils/commonUtils";
import Razorpayment from "../Payment/Razorpay";
import useRazorpay from "react-razorpay";
import { razorCreateOrderAction } from "../../redux/Actions/razorCreateOrderApiAction";
import {
  paymentWebhookAction,
  paymentSendURLAction,
} from "../../redux/Actions/paymentWebhookAction";
import {
  sbiPolicyGenerateAction,
  sbiPdfDownloadAction,
  sbiFullQuoteAction,
  sbiKyc,
  sbiKycAuto,
} from "../../redux/Actions/sbiApiAction";
import {
  tataAutoKycReducer,
  tataProposalReducer,
  tataManualKycReducer,
  tataOnlinePaymentReducer
} from "../../redux/Reducers/tataApiReducer";
import {
  tataAutoKycAction,
  tataManualKycAction,
  tataProposalAction,
  tataOnlinePaymentAction
} from "../../redux/Actions/tataApiAction";
import Loader from "../../components/Pages/Loader/Loader";
import loader2 from "../../Assets/Loader/Loader.gif";
import "../RegNumberForm/loaderMain.css";
import sbiImage from "../../Assets/Partners_logo/BOOKMYINSURANCE/SBI.jpg";
import { allBodyDataAction } from "../../redux/Reducers/bodyDataReducer";
import { useNavigate, useLocation, json } from "react-router-dom";
import { iciciProposalReducer } from "../../redux/Reducers/iciciApiReducer";
import { ackoProposalReducer } from "../../redux/Reducers/ackoApiReducer";
import { carDetailsforProposal, bikeDetailsforProposal } from "../../utils/commonUtils";

const Collapsible = (props) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [Razorpay] = useRazorpay();
  const search = useLocation().search;
  const transactionId = new URLSearchParams(search).get("transaction_id");
  const transaction_url_id = new URLSearchParams(search).get("id");
  const zunoKycId = new URLSearchParams(search).get("kId");
  let shreeramPolicyid = null;
  let shreeramPremium = null;
  let shreeramProdCode = null;

  // Zuno payment request variable
  let zunoPayAmount = null;
  let zunoCustomerId = null;
  let zunoAddInfo1 = null;
  let ZunoCheckSum = null;

  const {
    data,
    openModal,
    closeModal,
    setFormData,
    CompanyName,
    tataOtpValue,
    handleBackBtn,
    sbiAutoKycData,
    mappingAllData,
    collapsibleTitle,
    headerCollapsible,
    setAutoKYCrejected,
    HandleEditProposalDetails,
  } = props;

  const companyName = useSelector((state) => {
    return state.companyName?.data?.companyName;
  });

  const [loading, setLoading] = useState(false);

  let formattedDate = "";
  let formattedDt = "";

  if (data?.dob instanceof Date) {
    formattedDt = new Date(data?.dob);
    formattedDate = formattedDt.toLocaleDateString();
  } else if (typeof data?.dob === "string") {
    formattedDt = new Date(data?.dob);
    formattedDate = formattedDt.toLocaleDateString();
  }

  const [showDetailsOf, setshowDetailsOf] = useState("ownerDetails");

  const toggleColapsible = (menuName) => {
    if (menuName === showDetailsOf) {
      setshowDetailsOf("");
    } else {
      setshowDetailsOf(menuName);
    }
  };

  // inspectDetails

  const getBodyData = useSelector((state) => {
    return state?.bodyData;
  });

  const shreeRamQuote = useSelector((state) => {
    return state?.shreeRamQuickQuote;
  });

  const digitQuote = useSelector((state) => {
    return state?.digitQuickQuote;
  });

  const iciciQuote = useSelector((state) => {
    return state?.iciciQuickQuote;
  });

  const ackoQuote = useSelector((state) => {
    return state?.ackoQuickQuote;
  });

  const digitProposalRes = useSelector((state) => {
    return state?.digitProposal;
  });

  const iciciProposalResp = useSelector((state) => {
    return state?.iciciProposal;
  });

  const ackoProposalResp = useSelector((state) => {
    return state?.ackoProposal;
  });

  let iciciKycAutoData = useSelector((state) => {
    return state.iciciKycAutoData;
  });

  let iciciKycManualData = useSelector((state) => {
    return state.iciciKycData;
  });

  const shreeRamProposalRes = useSelector((state) => {
    return state?.shreeRamProposal;
  });

  const sbiQuickQuoteData = useSelector((state) => {
    return state?.sbiQuickQuote;
  });

  // get zuno QQ
  const zunoQuickQuote = useSelector((state) => {
    return state?.zunoQuickQuote;
  });

  // get zuno FQ
  const zunoProposalRes = useSelector((state) => {
    return state?.zunoFullQuote;
  });

  // get zuno Auto Kyc
  const zunoAutoKyc = useSelector((state) => {
    return state?.zunoKyc;
  });

  // tata apis
  const tataQuickQuote = useSelector((state) => {
    return state?.tataQuickQuote;
  });

  const tataProposalRes = useSelector((state) => {
    return state?.tataProposalQuote;
  });

  const tataAutoKyc = useSelector((state) => {
    return state?.tataAutoKyc;
  });

  const tatamanualKyc = useSelector((state) => {
    return state?.tataManualKyc;
  });

  //handle after proposal action icici
  useEffect(() => {
    (async function () {
      if (iciciProposalResp?.data?.data?.quote) {
        let iciciProposalResponse = iciciProposalResp?.data?.data?.quote;
        if (
          iciciProposalResponse.status == "Success"
          && !iciciProposalResponse.isApprovalRequired
          && !iciciProposalResponse.isQuoteDeviation
          && !iciciProposalResponse.breakingFlag
        ) {

          //1. create transaction then redirect to payment gateway
          let transactionData = {
            data: {
              amount: iciciProposalResponse.finalPremium,
              returnUrl: process.env.REACT_APP_ICICI_PAYMENT_WEBHOOK_URL
            }
          };
          let txnResp = await dispatch(iciciCreateTreansactionAction(transactionData));
          let txnId = txnResp?.payload?.data?.transactionId;

          //2. store response in db
          let bodyData = {
            companyname: CompanyName,
            transactionid: txnId,
            paymentTxnCode: transaction_url_id,
            bodydata: {
              icici: iciciProposalResponse,
              pospData: getBodyData.data?.pospData
            },
            type: "initiate",
          };
          await dispatch(paymentWebhookAction(bodyData));

          setLoading(false);
          dispatch(iciciProposalReducer.actions.reseticiciProposal());
          let paymentUrl = process.env.REACT_APP_ICICI_PAYMENT_URL + `/pgi/payment/PaymentMethod?transactionid=${txnId}`
          window.location.replace(paymentUrl);
        } else if (
          iciciProposalResponse.status == "Success"
          && iciciProposalResponse.isApprovalRequired
          && !iciciProposalResponse.isQuoteDeviation
          && iciciProposalResponse.breakingFlag
        ) {
          //break in case
          alert("Follow break in flow !");
          setLoading(false);
        } else {
          setLoading(false);
          dispatch(iciciProposalReducer.actions.reseticiciProposal());
          toast.error(iciciProposalResponse.message
            ? iciciProposalResponse.message : "Something went Wrong, try after sometime!", {
            position: "top-center",
            autoClose: 25000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }
    })();
  }, [iciciProposalResp])

  useEffect(() => {
    (async function () {
      if (companyName.toLowerCase() == "shreeram") {
        let shreeramProposalResponse = shreeRamProposalRes?.data?.data?.quote;
        if (
          shreeramProposalResponse?.MessageResult?.Result.toLowerCase() ==
          "success"
        ) {
          //initate entry in db for payment
          let bodyData = {
            companyname: companyName?.toLowerCase(),
            transactionid: shreeramProposalResponse?.GenerateProposalResult?.POL_SYS_ID,
            bodydata: {
              shreeram: shreeRamProposalRes,
            },
            type: "initiate",
            paymentTxnCode: transaction_url_id,
          };
          await dispatch(paymentWebhookAction(bodyData));

          shreeramPolicyid =
            shreeramProposalResponse?.GenerateProposalResult?.POL_SYS_ID;
          shreeramPremium =
            shreeramProposalResponse?.GenerateProposalResult?.CoverDtlList[
            shreeramProposalResponse?.GenerateProposalResult?.CoverDtlList
              .length - 1
            ]["Premium"];
          shreeramProdCode =
            shreeRamProposalRes?.data?.data?.body?.objPolicyEntryETT?.ProdCode;
          document.getElementsByName("prodCode")[0].value = shreeramProdCode;
          document.getElementsByName("amount")[0].value = shreeramPremium;
          document.getElementsByName("QuoteId")[0].value = shreeramPolicyid;
          document.getElementsByName(
            "sourceUrl"
          )[0].value = `${process.env.REACT_APP_SHREERAM_GET_PAYMENT_URL}?QuoteID=${shreeramPolicyid}&companyName=${companyName.toLowerCase()}`;

          setLoading(false);
          // Dispatch the action to reset the state
          document.getElementById("invisibleSubmitButton").click(); // Programmatically click the invisible button
          dispatch(shreeRamProposalReducer.actions.resetShreeRamProposal());
        } else if (
          shreeramProposalResponse?.MessageResult?.Result.toLowerCase() ==
          "failure" ||
          shreeramProposalResponse?.GenerateProposalResult?.ERROR_DESC
        ) {
          //here show errors
          // Dispatch the action to reset the state
          dispatch(shreeRamProposalReducer.actions.resetShreeRamProposal());
          setLoading(false);
          toast.error(
            shreeramProposalResponse?.GenerateProposalResult?.ERROR_DESC ||
            shreeramProposalResponse?.MessageResult?.ErrorMessage,
            {
              position: "top-center",
              autoClose: 25000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        }
      } else if (companyName == "digit" && !digitProposalRes.isLoading) {
        if (digitProposalRes?.data?.data?.payment?.[
          "Motor-Motor payment link generation Api"
        ]?.dispatcherResponse) {
          //initate entry in db for payment
          let bodyData = {
            companyname: CompanyName,
            transactionid: digitProposalRes?.data?.data?.quote?.["Motor-Create Quote"].policyNumber,
            bodydata: {
              digit: digitProposalRes,
            },
            type: "initiate",
            paymentTxnCode: transaction_url_id,
          };
          await dispatch(paymentWebhookAction(bodyData));
          //////////////////////////////////////
          setLoading(false);
          let paymentUrl =
            digitProposalRes?.data?.data?.payment?.[
              "Motor-Motor payment link generation Api"
            ]?.dispatcherResponse;

          dispatch(digitCreateQuoteReducer.actions.resetDigitProposal());
          window.location.replace(paymentUrl);
        } else if (digitProposalRes?.data?.msg == "Error") {
          setLoading(false);
          dispatch(digitCreateQuoteReducer.actions.resetDigitProposal());
          toast.error("Something went wrong!", {
            position: "top-center",
            autoClose: 25000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }

      } else if (companyName == "zuno") {
        let zunoProposalResponse = zunoProposalRes?.data?.data?.quote;
        let DataZK
        if (zunoAutoKyc?.data?.data) {
          DataZK = zunoAutoKyc?.data?.data?.quote?.data
        } else {
          let zunoData = {
            kycId: zunoKycId,
            type: "get",
          };
          let kycDataRes = await dispatch(zunoInsertBodydAction(zunoData));
          DataZK = JSON.parse(kycDataRes?.payload?.data?.paymentinfo[0]?.Data)
        }
        if (zunoProposalResponse?.premiumDetails?.grossTotalPremium > 0) {

          // 1. body data for payment
          zunoPayAmount = String((zunoProposalResponse?.premiumDetails?.grossTotalPremium).toFixed(2))
          let customerCompanyName = getBodyData?.data?.carOwner == "individualOwner" ? getBodyData?.data?.firstName + getBodyData?.data?.lastName : getBodyData?.data?.companyName;
          zunoCustomerId = generateCutomerId(customerCompanyName);
          zunoAddInfo1 = generateUnique16DigitNumber();
          let checkSumBody = `EDGENBKAGR|${zunoCustomerId}|NA|${zunoPayAmount}|NA|NA|NA|INR|NA|R|EDGENBMIIB-NA|NA|NA|F|${zunoAddInfo1}|BMI|NA|NA|NA|NA|NA|https://gcxnmzzndf.execute-api.us-east-1.amazonaws.com/zuno-browserPaymentResponse`
          ZunoCheckSum = generateCheckSum(checkSumBody);
          document.getElementsByName(
            "msg"
          )[0].value = checkSumBody + "|" + ZunoCheckSum;

          //2. store response in db
          let bodyData = {
            companyname: CompanyName,
            transactionid: zunoCustomerId,
            bodydata: {
              zuno: zunoProposalResponse,
              kyc: DataZK,
            },
            type: "initiate",
            paymentTxnCode: transaction_url_id,
          };
          await dispatch(paymentWebhookAction(bodyData));

          // 3.click payment
          dispatch(zunoFullQuoteReducer.actions.resetzunofullQuote());
          document.getElementById("invisibleSubmitButtonZuno").click(); // Programmatically click the invisible button
          // setLoading(false);
        } else if (zunoProposalResponse?.msg) {
          dispatch(zunoFullQuoteReducer.actions.resetzunofullQuote());
          setLoading(false);
          toast.error((zunoProposalResponse?.msg), {
            position: "top-center",
            autoClose: 25000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else if (companyName == "acko") {
        if (ackoProposalResp?.data?.data?.proposal) {
          if (ackoProposalResp?.data?.data?.checkoutURL) {
            let bodyData = {
              companyname: CompanyName,
              transactionid: ackoProposalResp?.data?.data?.proposal?.insured[0].proposal_ekey,
              paymentTxnCode: transaction_url_id,
              bodydata: {
                acko: ackoProposalResp?.data,
              },
              type: "initiate",
            };
            await dispatch(paymentWebhookAction(bodyData));
            dispatch(ackoProposalReducer.actions.resetackoProposal());
            window.location.replace(ackoProposalResp?.data?.data?.checkoutURL);
          } else {
            dispatch(ackoProposalReducer.actions.resetackoProposal());
            setLoading(false);
            toast.error("Something went wrong !", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        } else if (ackoProposalResp?.data?.error) {
          dispatch(ackoProposalReducer.actions.resetackoProposal());
          setLoading(false);
          toast.error(ackoProposalResp?.data?.error?.error_message ? ackoProposalResp?.data?.error?.error_message : "Something went wrong !", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else if (companyName == "tata") {
        if (tataQuickQuote?.data?.data?.quote?.data?.premium_break_up?.final_premium == tataProposalRes?.data?.data?.quote.data[0]?.premium_value) {
          // call auto kyc
          let manualKyc
          let autoKyc = await dispatch(tataAutoKycAction({
            data: {
              ...getBodyData?.data,
              ...{
                proposalNo:
                  tataProposalRes?.data?.data?.quote?.data[0]?.proposal_no,
              },
            }
          }));

          if ((getBodyData?.data?.kycAadharNumber || getBodyData?.data?.CertificateOfIncorporationNumber)) {
            autoKyc = await dispatch(tataAutoKycAction({
              data: {
                ...getBodyData?.data,
                ...{
                  proposalNo:
                    tataProposalRes?.data?.data?.quote?.data[0]?.proposal_no,
                  autoKycRsponseReqId:
                    autoKyc?.payload?.data?.quote?.data?.req_id ? autoKyc?.payload?.data?.quote?.data?.req_id : "",
                },
              }
            }));
          }

          if (autoKyc?.payload?.data?.quote?.message_txt == "OTP Sent") {
            setLoading(false);
            openModal()
          }

          if (getBodyData?.data?.kycAadharImg || getBodyData?.data?.CertificateOfIncorporationFile) {
            manualKyc = await dispatch(tataManualKycAction({
              data: {
                ...getBodyData.data,
                ...{
                  proposalNo:
                    tataProposalRes?.data?.data?.quote?.data[0]?.proposal_id,
                  autoKycRsponseReqId:
                    autoKyc?.payload?.data?.quote?.data?.req_id,
                },
              }
            }));
          }

          if (autoKyc?.payload?.data?.quote?.data?.result?.ckyc_number || manualKyc?.payload?.data?.quote?.message_txt == "successfully uploaded") {
            // insert db
            let bodyData = {
              companyname: CompanyName,
              transactionid: tataProposalRes.data.data.quote.data[0].proposal_no,
              bodydata: {
                tata: tataProposalRes?.data,
              },
              type: "initiate",
              paymentTxnCode: transaction_url_id,
            };
            await dispatch(paymentWebhookAction(bodyData));

            // start payment
            let onlinePayment = await dispatch(tataOnlinePaymentAction({
              data: {
                ...getBodyData.data,
                ...{
                  paymentId:
                    tataProposalRes.data.data.quote.data[0].payment_id,
                },
              }
            }));
            if (onlinePayment?.payload?.data?.quote?.message_txt == "Success") {
              let paymentLink = JSON.parse(onlinePayment?.payload?.data?.quote?.data)
              // window.location.replace(onlinePayment?.payload?.data?.quote?.data?.paymentLink_web);
              window.location.replace(paymentLink?.url);
            } else {
              setLoading(false);
              toast.error(onlinePayment?.payload?.data?.quote?.message_txt ? onlinePayment?.payload?.data?.quote?.message_txt : "Something went wrong !", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          } else if (manualKyc) {
            setLoading(false);
            await toast.error(manualKyc?.payload?.data?.quote?.message_txt ? manualKyc?.payload?.data?.quote?.message_txt : "Something went wrong !", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            dispatch(tataAutoKycReducer.actions.resettataAutoKycAction());
            dispatch(tataProposalReducer.actions.resettataProposalAction());
            setFormData((prevItems) => ({
              ...prevItems,
              kycAadharNumber: '',
              kycAadharImg: '',
              CertificateOfIncorporationFile: '',
              CertificateOfIncorporationNumber: '',
            }))
          } else if (autoKyc?.payload?.data?.quote?.message_txt != "OTP Sent") {
            setLoading(false);
            if (autoKyc?.payload?.data?.quote?.data?.req_id && autoKyc?.payload?.data?.quote?.message_txt != "successfully completed") {
              handleBackBtn("FormDetails")
              if ((getBodyData?.data?.kycAadharNumber && !getBodyData?.data?.kycAadharImg) || (getBodyData?.data?.CertificateOfIncorporationNumber && !getBodyData?.data?.CertificateOfIncorporationFile)) {
                setAutoKYCrejected(true)
                toast.error(autoKyc?.payload?.data?.quote?.message_txt ? autoKyc?.payload?.data?.quote?.message_txt : "Something went wrong !", {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              } else {
                toast.error("Details are not matching Please proceed with other document !", {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }
            } else {
              await toast.error(autoKyc?.payload?.data?.quote?.message_txt ? autoKyc?.payload?.data?.quote?.message_txt : "Something went wrong !", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              dispatch(tataAutoKycReducer.actions.resettataAutoKycAction());
              dispatch(tataProposalReducer.actions.resettataProposalAction());
              setFormData((prevItems) => ({
                ...prevItems,
                kycAadharNumber: '',
                kycAadharImg: '',
                CertificateOfIncorporationFile: '',
                CertificateOfIncorporationNumber: '',
              }))
            }
          }
        } else if (tataProposalRes?.data || tataProposalRes?.body) {
          dispatch(tataProposalReducer.actions.resettataProposalAction());
          setLoading(false);
          toast.error(tataProposalRes?.data?.data?.quote?.message_txt ? tataProposalRes?.data?.data?.quote?.message_txt : "Something went wrong !", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }
    })();
  }, [digitProposalRes, shreeRamProposalRes, zunoProposalRes, ackoProposalResp, tataProposalRes]);

  const payNowHandle = () => {
    if (companyName.toLowerCase() == "shreeram") {
      let proposalData = {
        proposal: getBodyData.data,
        bodyData: shreeRamQuote?.data?.data?.body?.objPolicyEntryETT,
        qouteResPayLoad: shreeRamQuote?.data?.data?.quote?.GetQuotResult,
      };
      dispatch(shreeRamProposalAction(proposalData));
      setLoading(true);
    } else if (companyName.toLowerCase() == "digit") {
      let proposalData = {
        proposal: getBodyData.data,
        bodyData: digitQuote?.data?.data?.body?.["motorQuickQuote"],
        qouteResPayLoad: digitQuote?.data?.data?.quote?.["Motor-Quick Quote"],
      };
      dispatch(digitCreateQuoteAction(proposalData));
      setLoading(true);
    } else if (companyName.toLowerCase() == "zuno") {
      setLoading(true);
      const asyncFn = async () => {
        let formZunoData = getBodyData.data
        if (zunoKycId && (!getBodyData.data.pincode || !getBodyData.data.dob)) {
          let zunoData = {
            kycId: zunoKycId,
            type: "get",
          };
          let kycDataRes = await dispatch(zunoInsertBodydAction(zunoData));
          let bodyDataAllRes = JSON.parse(kycDataRes?.payload?.data?.paymentinfo[0].bodyData)
          let bodyDataRes = bodyDataAllRes?.zunoBody
          formZunoData = bodyDataRes.data
        }
        let proposalData = {
          proposal: formZunoData,
          bodyData: zunoQuickQuote?.data?.data?.body,
          qouteResPayLoad: zunoQuickQuote?.data?.data?.quote,
        };
        await dispatch(zunoFullQuoteAction(proposalData));
      };
      asyncFn();
    } else if (companyName.toLowerCase() == "icici") {
      setLoading(true);
      let proposalData = {
        proposal: getBodyData.data,
        bodyData: iciciQuote?.data?.data?.body,
        qouteResPayLoad: iciciQuote?.data?.data?.quote,
        kycData:
          iciciKycAutoData?.data?.body?.data?.statusMessage == "Failed"
            ? iciciKycManualData?.data?.body?.data?.kyc_details
            : iciciKycAutoData?.data?.body?.data?.kyc_details,
      };
      dispatch(iciciProposalAction(proposalData));
    } else if (companyName.toLowerCase() == "acko") {
      let addOnDetails = getBodyData?.data?.vehicleType == "2W" ?
        bikeDetailsforProposal(companyName, ackoQuote, getBodyData) :
        carDetailsforProposal(companyName, ackoQuote, getBodyData)
        ;
      setLoading(true);
      let proposalData = {
        proposal: getBodyData.data,
        bodyData: ackoQuote?.data?.data?.body,
        qouteResPayLoad: ackoQuote?.data?.data?.quote,
        planDetails: addOnDetails
      };
      dispatch(ackoProposalAction(proposalData));
    } else if (companyName.toLowerCase() == "tata") {
      setLoading(true);
      let proposalData = {
        proposal: getBodyData.data,
        bodyData: tataQuickQuote?.data?.data?.body,
        qouteResPayLoad: tataQuickQuote?.data?.data?.quote
      };
      dispatch(tataProposalAction(proposalData));
    }
  };
  const [IsEdited, setIsEdited] = useState(false);

  //this is to be segregated as per company name
  const getBtnLoadingStatus = useSelector((store) => {
    return store?.sbiFullQuote?.isLoading;
  });

  const sbiBodyData = useSelector((store) => {
    return store?.bodyData;
  });

  const sbiFullQuoteData = useSelector((store) => {
    return store?.sbiFullQuote?.data?.body?.data?.quote?.PolicyObject;
  });

  const handlePaymentLink = useCallback(async () => {
    try {
      function showToast() {
        toast("Link Copied to clipboard!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      setLoading(true);
      let quoteData;
      if (CompanyName == "sbi") {
        quoteData = sbiQuickQuoteData;
      } else if (CompanyName == "icici") {
        quoteData = iciciQuote;
      } else if (CompanyName == "digit") {
        quoteData = digitQuote;
      } else if (CompanyName == "acko") {
        quoteData = ackoQuote;
      } else if (CompanyName == "shreeram") {
        quoteData = shreeRamQuote;
      }
      //setCompany quoteData
      let bodyData = {
        companyName: CompanyName,
        bodyData: getBodyData,
        quoteData,
        kycData: { // only for icici as of now
          iciciKycAutoData,
          iciciKycManualData
        },
        type: "created",
      };
      let payResp = await dispatch(paymentSendURLAction(bodyData));
      setLoading(false);
      if (payResp?.payload?.data?.transactionId) {
        showToast();
        navigator.clipboard.writeText(
          `${process.env.REACT_APP_WEB_LINK}/#/${getBodyData?.data?.vehicleType == "2W" ? "Propsal-Form-Bike" : "Propsal-Form"}?transaction_id=${payResp?.payload?.data?.transactionId}`
        );
      } else {
        toast.error("Something went Wrong, try after sometime!", {
          position: "top-center",
          autoClose: 25000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went Wrong, try after sometime!", {
        position: "top-center",
        autoClose: 25000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  });

  const handlePayment = useCallback(async () => {
    if (companyName != "sbi") {
      payNowHandle();
      return;
    }
    setLoading(true);
    //Call fullquote
    try {
      let fullBodyDetails = await dispatch(
        sbiFullQuoteAction({
          data: {
            ...sbiBodyData?.data,
            requestId:
              sbiQuickQuoteData?.data?.body?.data?.body?.RequestHeader
                ?.requestID,
          },
        }) // unique ID
      );
      if (fullBodyDetails?.payload?.body?.data?.quote?.ValidateResult) {
        toast.error(
          fullBodyDetails?.payload?.body?.data?.quote?.ValidateResult.message,
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        setLoading(false);
      } else {
        let duePremium =
          fullBodyDetails?.payload?.body?.data?.quote?.PolicyObject?.DuePremium;
        let data = {
          amount: duePremium * 100, //sbiFullQuoteData?.DuePremium * 100,
          currency: "INR",
          receipt: "123ffdfdffd",
        };
        let razorOrderResponse = await dispatch(razorCreateOrderAction(data));
        const { id } = razorOrderResponse?.payload?.body?.response;
        let bodyData = {
          companyname: CompanyName,
          transactionid: id,
          paymentTxnCode: transaction_url_id,
          bodydata: {
            sbi: fullBodyDetails?.payload?.body?.data?.quote?.PolicyObject,
          },
          type: "initiate",
        };
        await dispatch(paymentWebhookAction(bodyData));

        const options = {
          key: process.env.REACT_APP_RAZORPAY_KEY,
          amount: duePremium * 100, //sbiFullQuoteData?.DuePremium * 100,
          currency: "INR",
          name: CompanyName
            ? CompanyName?.toUpperCase() + " General Insurance"
            : "Book My Insurance",
          description: "Policy Payment",
          image: sbiImage,
          order_id: id,
          handler: async (res) => {
            try {
              setLoading(true);
              // adding response in the database when successfull payment
              // Production Url
              await fetch(
                `${process.env.REACT_APP_PAYMENT_WEBHOOK_URL}?companyName=${CompanyName}&transaction_id=` +
                id,
                {
                  method: "POST",
                  body: JSON.stringify(res),
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              );
              let PolicyNumber = "";
              if (CompanyName == "sbi") {
                let statusData = {
                  transactionid: id,
                  type: "getpaymentdata",
                };
                const paymentResponse = await dispatch(
                  paymentWebhookAction(statusData)
                );
                const { paymentinfo } = paymentResponse?.payload?.data;

                const { sbi } = JSON.parse(paymentinfo[0]?.body_data);
                if (paymentinfo[0]?.status == "captured") {
                  let bodyData = {
                    data: {
                      quotationNo: sbi?.QuotationNo, // PolicyNo
                      amount: sbi?.DuePremium,
                      requestId:
                        sbiQuickQuoteData?.data?.body?.data?.body?.RequestHeader
                          ?.requestID,
                    },
                  };
                  const policyGeneratedData = await dispatch(
                    sbiPolicyGenerateAction(bodyData)
                  );
                  if (
                    policyGeneratedData?.payload?.body?.data?.quote
                      ?.ValidateResult
                  ) {
                    toast.error(
                      policyGeneratedData?.payload?.body?.data?.quote
                        ?.ValidateResult.message,
                      {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      }
                    );
                    setLoading(false);
                  } else {
                    PolicyNumber =
                      policyGeneratedData?.payload?.body?.data?.quote?.PolicyNo;
                    if (PolicyNumber) {
                      let KYCPolicyNumber = {
                        PolicyNumber: PolicyNumber,
                      };
                      //calling KYC API for storing policy number
                      if (sbiBodyData?.data?.kycAadharImg || sbiBodyData?.data?.CertificateOfIncorporationNumber) {
                        //manual KYC
                        await dispatch(
                          sbiKyc({
                            data: {
                              ...sbiBodyData?.data,
                              ...KYCPolicyNumber,
                              ...{
                                requestId:
                                  sbiQuickQuoteData?.data?.body?.data?.body
                                    ?.RequestHeader?.requestID,
                              },
                            },
                          })
                        );
                      } else {
                        //AUTO Kyc
                        await dispatch(
                          sbiKycAuto({
                            data: {
                              ...sbiBodyData?.data,
                              ...KYCPolicyNumber,
                              ...{
                                requestId:
                                  sbiQuickQuoteData?.data?.body?.data?.body
                                    ?.RequestHeader?.requestID,
                              },
                            },
                          })
                        );
                      }

                      if (transaction_url_id && PolicyNumber) {
                        //policyNo store
                        let bodyData = {
                          policyNo: PolicyNumber,
                          policytxnId: id,
                          status: "update_payment",
                          uuid: transaction_url_id
                        };
                        await dispatch(paymentSendURLAction(bodyData));
                      }

                      //adding loader
                      setLoading(true);
                      setTimeout(() => {
                        setLoading(false);
                        navigate(
                          `/Payment-Redirect?companyName=${CompanyName}&transaction_id=${id}&PolicyNumber=${PolicyNumber}`
                        );
                      }, 15000);
                    }
                  }
                }
              }
            } catch (rejectedValueOrSerializedError) {
              setLoading(false);
              alert(" Something went wrong !");
            }
          },
          prefill: {
            name: sbiBodyData?.firstName || "",
            email: sbiBodyData?.email || "",
            contact: sbiBodyData?.phoneNumber || "", //"9999999999",
          },
          notes: {
            address: sbiBodyData?.addressOne || "Razorpay Corporate Office",
          },
          theme: {
            color: "#3399cc",
          },
        };
        const rzpay = new Razorpay(options);
        rzpay.open();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong, Please try again !", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [Razorpay]);

  useEffect(() => {
    if (loading) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [loading]);

  return (
    <div className="main-collapsible">
      <ToastContainer />
      <div id="cover-spin" style={{ display: loading ? "" : "none" }}>
        <img src={loader2} className="gifLoader"></img>
      </div>
      <div className="collapsible_container">
        {/* {!transactionId && (
          <button
            className="footer_hyper_link underline_text smallFont absolute-hyperlink"
            onClick={handlePaymentLink}
          >
            Copy Link
          </button>
        )} */}

        {/* owner details */}

        <div className="collapsible_head_content">
          <div
            className={
              showDetailsOf === "ownerDetails"
                ? "collapsible_head collapsible_head_withBorder "
                : "collapsible_head"
            }
            onClick={(e) => toggleColapsible("ownerDetails")}
          >
            <div className="title-editbtn-container-collapsible">
              <p className="collapsible_head_title">
                {data.carOwner == "individualOwner"
                  ? "Owner Details"
                  : "Company Details"}
              </p>
              {
                showDetailsOf === "ownerDetails" && (
                  <Botton
                    icons={edit}
                    text="Edit"
                    event={(e) => HandleEditProposalDetails("ownerDetails")}
                    className="edit_btn edit_btn_pform-mobile"
                  />
                )
              }
            </div>
            <img
              src={arrow}
              className={
                showDetailsOf === "ownerDetails"
                  ? "arrow-collapsible-down arrow-collapsible-up"
                  : "arrow-collapsible-down"
              }
              alt=""
              srcSet=""
            />
          </div >
          <div
            className={
              showDetailsOf === "ownerDetails"
                ? "collapsible_content show_collapsible_content "
                : "collapsible_content"
            }
          >
            <Botton
              icons={edit}
              text="Edit"
              event={(e) => HandleEditProposalDetails("ownerDetails")}
              className="edit_btn edit_btn_pform"
            />
            <div className="collapsible_content_group">
              {data.carOwner == "individualOwner" ? (
                <div className="collapsibleTab">
                  <p className="collapsibleHead">Owner Name</p>
                  <p className="collapsibleDesc">
                    {data?.firstName}&nbsp;{data?.lastName}
                  </p>
                </div>
              ) : (
                <div className="collapsibleTab">
                  <p className="collapsibleHead">Company Name</p>
                  <p className="collapsibleDesc">{data?.companyName}</p>
                </div>
              )}
              <div className="collapsibleTab">
                <p className="collapsibleHead">Email ID</p>
                <p className="collapsibleDesc">{data?.email}</p>
              </div>
            </div>
            <div className="collapsible_content_group">
              <div className="collapsibleTab">
                <p className="collapsibleHead">Phone Number</p>
                <p className="collapsibleDesc">{data?.phoneNumber}</p>
              </div>
              {
                data?.kycPanNumber && (
                  <div className="collapsibleTab">
                    <p className="collapsibleHead">PAN Number</p>
                    <p className="collapsibleDesc">{data?.kycPanNumber?.toUpperCase()}</p>
                  </div>
                )
              }

            </div>
            {
              data.carOwner !== "individualOwner" && (
                <div className="collapsible_content_group">
                  <div className="collapsibleTab">
                    <p className="collapsibleHead">Date Of Incorporation</p>
                    <p className="collapsibleDesc">{formattedDate}</p>
                  </div>
                  <div className="collapsibleTab">
                  </div>
                </div>
              )
            }
          </div>
        </div >

        {/* PERSONAL DETAILS */}

        {
          data.carOwner === "individualOwner" && companyName != "acko" && (
            <div className="collapsible_head_content">
              <div
                className={
                  showDetailsOf === "personalDetails"
                    ? "collapsible_head collapsible_head_withBorder "
                    : "collapsible_head"
                }
                onClick={(e) => toggleColapsible("personalDetails")}
              >
                <div className="title-editbtn-container-collapsible">
                  <p className="collapsible_head_title">Personal Details</p>
                  {
                    showDetailsOf === "personalDetails" && (
                      <Botton
                        icons={edit}
                        text="Edit"
                        event={(e) => HandleEditProposalDetails("personalDetails")}
                        className="edit_btn edit_btn_pform-mobile"
                      />
                    )
                  }
                </div>
                <img
                  src={arrow}
                  className={
                    showDetailsOf === "personalDetails"
                      ? "arrow-collapsible-down arrow-collapsible-up"
                      : "arrow-collapsible-down"
                  }
                  alt=""
                  srcSet=""
                />
              </div>
              <div
                className={
                  showDetailsOf === "personalDetails"
                    ? "collapsible_content show_collapsible_content "
                    : "collapsible_content"
                }
              >
                <Botton
                  icons={edit}
                  text="Edit"
                  event={(e) => HandleEditProposalDetails("personalDetails")}
                  className="edit_btn edit_btn_pform"
                />
                <div className="collapsible_content_group">
                  <div className="collapsibleTab">
                    <p className="collapsibleHead">Gender</p>
                    <p className="collapsibleDesc">{data?.gender}</p>
                  </div>
                  <div className="collapsibleTab">
                    <p className="collapsibleHead">Maritial Status</p>
                    <p className="collapsibleDesc">{data?.maritalStatus}</p>
                  </div>
                </div>
                <div className="collapsible_content_group">
                  <div className="collapsibleTab">
                    <p className="collapsibleHead">Date of Birth</p>
                    <p className="collapsibleDesc">{formattedDate}</p>
                  </div>
                  <div className="collapsibleTab">
                    <p className="collapsibleHead">Nominee Name</p>
                    <p className="collapsibleDesc">{data?.nomineeName}</p>
                  </div>
                </div>
                <div className="collapsible_content_group">
                  <div className="collapsibleTab">
                    <p className="collapsibleHead">Age</p>
                    <p className="collapsibleDesc">{data?.nomineeAge}</p>
                  </div>
                  <div className="collapsibleTab">
                    <p className="collapsibleHead">Relation</p>
                    <p className="collapsibleDesc">{data?.nomineeRelation}</p>
                  </div>
                </div>
              </div>
            </div>
          )}

        {/* ADDRESS DETAILS */}

        <div className="collapsible_head_content">
          <div
            className={
              showDetailsOf === "addressDetails"
                ? "collapsible_head collapsible_head_withBorder "
                : "collapsible_head"
            }
            onClick={(e) => toggleColapsible("addressDetails")}
          >
            <div className="title-editbtn-container-collapsible">
              <p className="collapsible_head_title">Address Details</p>
              {(sbiAutoKycData?.kycStatus !== "CKYCSuccess" && showDetailsOf === "addressDetails") && (
                <>
                  <Botton
                    icons={edit}
                    text="Edit"
                    event={(e) => HandleEditProposalDetails("addressDetails")}
                    className="edit_btn edit_btn_pform-mobile"
                  />
                </>
              )}
            </div>
            <img
              src={arrow}
              className={
                showDetailsOf === "addressDetails"
                  ? "arrow-collapsible-down arrow-collapsible-up"
                  : "arrow-collapsible-down"
              }
              alt=""
              srcSet=""
            />
          </div>
          <div
            className={
              showDetailsOf === "addressDetails"
                ? "collapsible_content show_collapsible_content "
                : "collapsible_content"
            }
          >
            {sbiAutoKycData?.kycStatus !== "CKYCSuccess" && (
              <>
                <Botton
                  icons={edit}
                  text="Edit"
                  event={(e) => HandleEditProposalDetails("addressDetails")}
                  className="edit_btn edit_btn_pform"
                />
              </>
            )}

            <div className="collapsible_content_group">
              <div className="collapsibleTab collapsibleTab-column">
                <p className="collapsibleHead">Address Line 1</p>
                <p className="collapsibleDesc">{data?.addressOne}</p>
              </div>
              <div className="collapsibleTab collapsibleTab-column">
                <p className="collapsibleHead">Address Line 2</p>
                <p className="collapsibleDesc">{data?.addressTwo}</p>
              </div>
            </div>
            <div className="collapsible_content_group">
              <div className="collapsibleTab">
                <p className="collapsibleHead">State</p>
                <p className="collapsibleDesc">{data?.state}</p>
              </div>
              <div className="collapsibleTab">
                <p className="collapsibleHead">City</p>
                <p className="collapsibleDesc">{data?.city}</p>
              </div>
            </div>
            <div className="collapsible_content_group">
              <div className="collapsibleTab">
                <p className="collapsibleHead">Pin Code</p>
                <p className="collapsibleDesc">{data?.pincode}</p>
              </div>
              <div className="collapsibleTab"></div>
            </div>
          </div>
        </div>

        {/* VEHICLE DETAILS */}

        <div className="collapsible_head_content">
          <div
            className={
              showDetailsOf === "vehicleDetails"
                ? "collapsible_head collapsible_head_withBorder "
                : "collapsible_head"
            }
            onClick={(e) => toggleColapsible("vehicleDetails")}
          >
            <div className="title-editbtn-container-collapsible">
              <p className="collapsible_head_title">Vehicle Details</p>
              {
                showDetailsOf === "vehicleDetails" && (
                  <Botton
                    icons={edit}
                    text="Edit"
                    event={(e) => HandleEditProposalDetails("vehicleDetails")}
                    className="edit_btn edit_btn_pform-mobile"
                  />
                )
              }
            </div>
            <img
              src={arrow}
              className={
                showDetailsOf === "vehicleDetails"
                  ? "arrow-collapsible-down arrow-collapsible-up"
                  : "arrow-collapsible-down"
              }
              alt=""
              srcSet=""
            />
          </div>
          <div
            className={
              showDetailsOf === "vehicleDetails"
                ? "collapsible_content show_collapsible_content "
                : "collapsible_content"
            }
          >
            <Botton
              icons={edit}
              text="Edit"
              event={(e) => HandleEditProposalDetails("vehicleDetails")}
              className="edit_btn edit_btn_pform"
            />
            {
              mappingAllData[0]?.typeOfBusiness != "New" && (
                <div className="collapsible_content_group">
                  <div className="collapsibleTab">
                    <p className="collapsibleHead">Previous Policy Number</p>
                    <p className="collapsibleDesc">{data?.prevPolicyNumber}</p>
                  </div>
                  <div className="collapsibleTab">
                    <p className="collapsibleHead">Previous Policy Insurer</p>
                    <p className="collapsibleDesc">{data?.prevPolicyNameInsurer}</p>
                  </div>
                </div>
              )
            }
            <div className="collapsible_content_group">
              <div className="collapsibleTab">
                <p className="collapsibleHead">Chassis Number</p>
                <p className="collapsibleDesc">{data?.chassisNumber?.toUpperCase()}</p>
              </div>
              <div className="collapsibleTab">
                <p className="collapsibleHead">Engine Number</p>
                <p className="collapsibleDesc">{data?.engineNumber?.toUpperCase()}</p>
              </div>
            </div>
            <div className="collapsible_content_group">
              <div className="collapsibleTab">
                <p className="collapsibleHead">Car is on Loan?</p>
                <p className="collapsibleDesc">{data?.carIsInLoan}</p>
              </div>
              <div className="collapsibleTab">
                {data?.carIsInLoan === "Yes" && (
                  <>
                    <p className="collapsibleHead">Car Hypothecation</p>
                    <p className="collapsibleDesc">
                      {data?.carHypothecationCompany}
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="btnContainer_pform btnContainer_pform_collapsible ">
          <Botton
            text="Back"
            className="button btn_pForm  secondry_btn"
            event={(e) => handleBackBtn("FormDetails")}
          />
          <Botton
            text={getBtnLoadingStatus == false ? "Yes, Pay Now" : "Loading...."}
            className="button btn_pForm"
            isDisabledButton={
              getBtnLoadingStatus == false ? getBtnLoadingStatus : true
            }
            event={handlePayment}
          />
        </div>
      </div>

      <form
        action="https://novaapi.shriramgi.com/Novapymt/MyDefaultCC.aspx"
        method="post"
        hidden
      >
        <input name="createdBy" value="LC331" />
        <input type="hidden" name="createdBy" value="LC331" />
        <input type="hidden" name="paymentFrom" value="CCAVENUE" />
        <input type="hidden" name="prodCode" value={shreeramProdCode} />
        <input type="hidden" name="QuoteId" value={shreeramPolicyid} />
        <input type="hidden" name="amount" value={shreeramPremium} />
        <input type="hidden" name="sourceUrl" value="" />
        <input type="hidden" name="DbFrom" value="NOVA" />
        <input type="hidden" name="application" value="ViaanshIns" />
        <input type="hidden" name="prodName" value="MOTOR" />
        <input type="submit" id="invisibleSubmitButton" value="Submit" />
      </form>

      {/* hidden form for zuno */}
      <form
        action="https://uat.billdesk.com/pgidsk/PGIMerchantPayment"
        method="post"
        hidden
      >
        <input type="hidden" name="msg" value="" />
        <input type="submit" id="invisibleSubmitButtonZuno" value="Submit" />
      </form>
    </div>
  );
};

export default Collapsible;
