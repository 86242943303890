import { createSlice } from "@reduxjs/toolkit";
export const companyNameReducer = createSlice({
    name: "companyName",
    initialState: {
        data: {
            "companyName": ""
        }
    },
    reducers: {
        getCompanyName: (state, action) => {
            state.data = action.payload;
        },
    },
});
export const { getCompanyName } = companyNameReducer.actions;