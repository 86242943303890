import React, { useEffect, useState } from "react";
import { getMonth, getYear } from "date-fns";
import range from "lodash/range";
import close from "../../Assets/Images/Close.svg";
import Calender from "../InputFields/Calender";
import DropDown from "../DropDown/DropDown";
import grayArrow from "../../Assets/Images/grayArrow.svg";
import Botton from "../Button/Button";
import CheckBox from "../Checkbox/CheckBox";
import RadioButton from "../RadioButton/RadioButton";
import { NEW } from "../../utils/commonUtils";
import { Routes, Route, useNavigate, Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import loader2 from "../../Assets/Loader/Loader.gif";
import "../RegNumberForm/loaderMain.css";
import InputFields from "../InputFields/InputFields";
import { alladdOnDataDataAction } from "../../redux/Reducers/addOnsDataReducer";
import { useDispatch } from "react-redux";
import ButtonLoader from "../Pages/Loader/Button Loader/ButtonLoader";
import CustomDateField from "../InputFields/CustomDateField/CustomDateField";

const ConfirmDetails = (props) => {
  const dispatch = useDispatch();
  const {
    closeModal,
    carDetails,
    allCarDetails,
    CompanyImage,
    CompanyName,
    businessType,
    handleInputCarDetails,
    vehicleData,
    isFindMyCar,
    passVehicleData,
    policyTypeList,
    setCarDetails,
    ShowExpDateCalendar,
    setShowExpDateCalendar,
    ShowRegDateCalendar,
    setShowRegDateCalendar,
    ShowOdExpDateCalendar,
    setShowOdExpDateCalendar,
  } = props;

  const month = new Date(carDetails.policyRegDate).toLocaleString("default", {
    month: "long",
  });

  const year = new Date(carDetails.policyRegDate).toLocaleString("default", {
    year: "numeric",
  });

  const navigate = useNavigate();
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");

  const sbiQuickQuote = useSelector((state) => {
    return state?.sbiQuickQuote;
  });

  const sbiQuickQuoteLoading = sbiQuickQuote?.isLoading;
  const [ShowMonthList, setShowMonthList] = useState(false);
  const [ShowYearList, setShowYearList] = useState(false);
  const [MonthValue, setMonthValue] = useState(month);
  const [YearValue, setYearValue] = useState(year);
  const [isApiLoaded, setIsApiLoaded] = useState(false);
  const [showRegNoErr, setshowRegNoErr] = useState(false);
  const [showDeclaration, setshowDeclaration] = useState(true);
  const [ShowPolicyTypeList, setShowPolicyTypeList] = useState(false);
  const [CheckboxList, setCheckboxList] = useState({
    declaration: true,
  });

  const handleClick = (checkboxName) => {
    setCheckboxList({
      ...CheckboxList,
      [checkboxName]: !CheckboxList[checkboxName]
    })
    setshowDeclaration(!showDeclaration);
  }

  useEffect(() => {
    setIsApiLoaded(sbiQuickQuoteLoading);
  }, [sbiQuickQuoteLoading]);

  const [showCngErr, setShowCngErr] = useState(null)

  useEffect(() => {
    if (carDetails?.isCngLpgVehicleType === true && carDetails.cngKitValue === null) {
      setShowCngErr(true)
    } else {
      setShowCngErr(false)
    }
  }, [carDetails?.isCngLpgVehicleType, carDetails.cngKitValue])

  const handleRoutePform = () => {
    //copying bodydata in order to show selected add-on on back click
    if (carDetails) {
      dispatch(alladdOnDataDataAction(carDetails));
    }

    let regnumber = carDetails?.vehicleNumber;
    let regNo = regnumber.split(/(\d+)/);

    if (carDetails?.typeOfBusiness?.toUpperCase() !== "NEW") {
      if (regnumber?.length > 5 && showDeclaration && showCngErr === false) {
        navigate("/Propsal-Form?id=" + id, {
          state: {
            allCarDetails: allCarDetails,
            logo: CompanyImage,
            CompanyName: CompanyName,
          },
        });
        // setShowCngErr(false)
      } else if (
        regnumber?.length <= 5 &&
        carDetails?.typeOfBusiness?.toUpperCase() !== "NEW"
      ) {
        // setShowCngErr(true)
        var divElement = document.getElementById("confirmContainer");
        divElement.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setshowRegNoErr(true);
      }
    }
    else {
      navigate("/Propsal-Form?id=" + id, {
        state: {
          allCarDetails: allCarDetails,
          logo: CompanyImage,
          CompanyName: CompanyName,
        },
      });
    }
  }

  const handlePopUps = () => {
    if (ShowMonthList === true) {
      setShowMonthList(false);
    } else if (ShowYearList === true) {
      setShowYearList(false);
    } else if (ShowPolicyTypeList === true) {
      setShowPolicyTypeList(false);
    }
  };

  let policyListForNew = [
    {
      value: "Bundled (3Y TP + 1Y OD)",
      label: "Bundled",
      desc: "1 Year OD + 3 year Third Party",
    },
  ]

  return (
    <>
      <div onClick={handlePopUps} style={{ position: "relative" }} >
        <section className="confirmDetailContainer-popUp">
          <div className="head-confirmDetailContainer-popUp">
            <p>Verify details for accurate premium.</p>
            <img src={close} onClick={closeModal} alt="" srcSet="" />
          </div>
          <div className="form-cnfrmDetails-popup" id="confirmContainer">
            {
              businessType !== NEW && (
                <div className="form-cnfrmDetails-inputGrp">
                  <div className="inputGropOne_form inputGrop inputGrop_caseTwo  ">
                    <p className="label-popUp label-popUp-two">
                      Vehicle No.
                    </p>
                    <div className="">
                      <InputFields
                        type="text"
                        className="inputs-form uppercaseText"
                        onChangeEvent={handleInputCarDetails}
                        value={carDetails?.vehicleNumber}
                        name="vehicleNumber"
                        id="vehicleNumber"
                      />
                      {showRegNoErr && (
                        <p className="err-msg">
                          Please enter a valid Registration Number.
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="inputGropOne_form inputGrop inputGrop_caseTwo ">
                    <p className="label-popUp label-popUp-two">
                      I have CNG/LPG fitted after the purchase
                    </p>
                    <div className="radioContainer-pform">
                      <RadioButton
                        label="Yes"
                        className=""
                        id="isCngLpgVehicleTypeYes"
                        groupName="isCngLpgVehicleType"
                        value="Yes"
                        changeEvent={handleInputCarDetails}
                        isChecked={
                          carDetails?.isCngLpgVehicleType === true
                            ? true
                            : false
                        }
                      />
                      <RadioButton
                        label="No"
                        className=""
                        id="isCngLpgVehicleTypeNo"
                        groupName="isCngLpgVehicleType"
                        value="No"
                        changeEvent={handleInputCarDetails}
                        isChecked={
                          carDetails?.isCngLpgVehicleType === false
                            ? true
                            : false
                        }
                      />
                    </div>
                  </div>
                </div>
              )
            }

            {
              carDetails?.isCngLpgVehicleType === true && (
                <div className="form-cnfrmDetails-inputGrp">
                  <div className="inputGropOne_form inputGrop inputGrop_caseTwo  ">
                    <p className="label-popUp label-popUp-two">
                      CNG/LPG Kit Value
                    </p>
                    <div className="">
                      <InputFields
                        type="number"
                        className="inputs-form uppercaseText"
                        onChangeEvent={handleInputCarDetails}
                        value={carDetails?.cngKitValue}
                        name="cngKitValue"
                        id="cngKitValue"
                      // placeholder="₹"
                      // isDisabled={isFindMyCar ? false : true}
                      />
                      {showCngErr && (
                        <p className="err-msg">
                          Please enter a valid CNG/LPG Kit value.
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="inputGropOne_form inputGrop inputGrop_caseTwo ">
                  </div>
                </div>
              )
            }

            <div className="form-cnfrmDetails-inputGrp">
              {businessType == NEW && CompanyName == "shreeram" ? ("") : (
                <div className="cnfrmDetails-inputGrp-container">
                  <label htmlFor="" className="label-popUp">
                    Registration Date
                  </label>
                  <div className="calender-cnfrm-popup">
                    <CustomDateField
                      className="pForm_datePicker pForm_datePicker-tab "
                      btnId="policyRegDateBtn"
                      textId="policyRegDateTxt"
                      imgId="policyRegDateImg"
                      dateValue={carDetails?.policyRegDate}
                      calendarReveal={ShowRegDateCalendar}
                      setCalendarReveal={setShowRegDateCalendar}
                    />
                  </div>
                </div>)}

              <div className="cnfrmDetails-inputGrp-container">
                <label htmlFor="" className="label-popUp">
                  Manufacturing Date
                </label>
                <div className={(businessType == NEW && CompanyName == "shreeram") ? "dpGrp-popUp dpGrp-popUp-gap" : "dpGrp-popUp"}>
                  <DropDown
                    dropDownMenu={monthList}
                    Head="Select Month"
                    clickEvent={(e) => console.log(e)}
                    revealOptions={ShowMonthList}
                    value={MonthValue}
                    arrowInGray={grayArrow}
                    afterTrueClass="dropDown_container NoBorderRadius"
                    className="dropDown_container"
                    valueSelectionEvent={(e) => setMonthValue(e.target.value)}
                    headClick={(e) => setShowMonthList(!ShowMonthList)}
                    revealOptionsClasses=" dropDownOptions   showdropDownOptions "
                    classNameTwo="dropDownOptions"
                    dropDownHeadClass="dropDownHead"
                  />
                  <DropDown
                    dropDownMenu={yearList}
                    Head="Select Year"
                    clickEvent={(e) => console.log(e)}
                    revealOptions={ShowYearList}
                    value={YearValue}
                    arrowInGray={grayArrow}
                    afterTrueClass="dropDown_container NoBorderRadius"
                    className="dropDown_container"
                    valueSelectionEvent={(e) => setYearValue(e.target.value)}
                    headClick={(e) => setShowYearList(!ShowYearList)}
                    revealOptionsClasses=" dropDownOptions showdropDownOptions"
                    classNameTwo="dropDownOptions"
                    dropDownHeadClass="dropDownHead"
                  />
                </div>
              </div>
            </div>

            <div className="form-cnfrmDetails-inputGrp">
              <div className="cnfrmDetails-inputGrp-container">
                <label htmlFor="" className="label-popUp">
                  {
                    businessType !== NEW ? "Previous Policy Type" : "New Policy Type"
                  }
                </label>
                <DropDown
                  dropDownMenu={businessType !== NEW ? policyTypeList : policyListForNew}
                  Head="Select Month"
                  name={businessType !== NEW ? "prevPolicyType" : "PolicyTypeValue"}
                  clickEvent={(e) => console.log(e)}
                  revealOptions={ShowPolicyTypeList}
                  value={businessType !== NEW ? carDetails?.prevPolicyType : carDetails?.PolicyTypeValue}
                  arrowInGray={grayArrow}
                  afterTrueClass="dropDown_container NoBorderRadius"
                  className="dropDown_container"
                  valueSelectionEvent={handleInputCarDetails}
                  headClick={(e) => setShowPolicyTypeList(!ShowPolicyTypeList)}
                  revealOptionsClasses=" dropDownOptions   showdropDownOptions "
                  classNameTwo="dropDownOptions"
                  dropDownHeadClass="dropDownHead"
                />
                {carDetails?.prevPolicyType === "Third Party" && carDetails?.PolicyTypeValue === "Comprehensive" && (
                  <p className="msgIn-red">
                    Inspection required for Comprehensive Policy.
                  </p>
                )}
              </div>
              <div className="">
                {
                  businessType !== NEW ? (
                    <>
                      {(carDetails?.prevPolicyType === "Bundled (3Y TP + 1Y OD)" ||
                        carDetails?.prevPolicyType === "Bundled") && carDetails?.PolicyTypeValue === "Own Damage" ? (
                        <>
                          <div className="cnfrmDetails-inputGrp-container">
                            <label htmlFor="" className="label-popUp">
                              Previous Policy TP Expiry Date
                            </label>
                            <div className="calender-cnfrm-popup">
                              <CustomDateField
                                className="pForm_datePicker pForm_datePicker-tab "
                                btnId="policyExpDateBtn"
                                textId="policyExpDateTxt"
                                imgId="policyExpDateImg"
                                dateValue={carDetails?.policyExpDate}
                                calendarReveal={ShowExpDateCalendar}
                                setCalendarReveal={setShowExpDateCalendar}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="cnfrmDetails-inputGrp-container">
                          <label htmlFor="" className="label-popUp">
                            Previous Policy Expiry Date
                          </label>
                          <div className="calender-cnfrm-popup">
                            <CustomDateField
                              className="pForm_datePicker pForm_datePicker-tab "
                              btnId="policyExpDateBtn"
                              textId="policyExpDateTxt"
                              imgId="policyExpDateImg"
                              dateValue={carDetails?.policyExpDate}
                              calendarReveal={ShowExpDateCalendar}
                              setCalendarReveal={setShowExpDateCalendar}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="cnfrmDetails-inputGrp-container">
                      <label htmlFor="policyStartDate" className="label-popUp">
                        New Policy Start Date
                      </label>
                      <div className="calender-cnfrm-popup">
                        <CustomDateField
                          className="pForm_datePicker pForm_datePicker-tab "
                          btnId="policyRegDateBtn"
                          textId="policyRegDateTxt"
                          imgId="policyRegDateImg"
                          dateValue={carDetails?.policyRegDate}
                          calendarReveal={ShowRegDateCalendar}
                          setCalendarReveal={setShowRegDateCalendar}
                          isDisabled={(CompanyName === "shreeram") ? true : false}
                        />
                      </div>
                    </div>
                  )}
              </div>
            </div>

            {
              businessType !== NEW && (
                <div className=" form-cnfrmDetails-inputGrp" >
                  {(carDetails?.PolicyTypeValue === "Third Party" ||
                    carDetails?.prevPolicyType === "Third Party" ||
                    passVehicleData.prevPolicyExpLastMonth === true) ? (
                    ""
                  ) :
                    (
                      <>
                        {businessType !== NEW && (
                          <div className="input_group_editForm input_group_editForm-popUp">
                            <div className="inputGropOne_form inputGrop inputGrop_caseTwo ">
                              <p className="label-popUp label-popUp-two">
                                Have you taken any Claim in your existing policy?
                              </p>
                              <div className="radioContainer-pform">
                                <RadioButton
                                  label="Yes"
                                  className=""
                                  id="claimYesConfirm"
                                  groupName="isClaimPrevPolicy"
                                  value="Yes"
                                  changeEvent={handleInputCarDetails}
                                  isChecked={
                                    carDetails?.isClaimPrevPolicy === true
                                      ? true
                                      : false
                                  }
                                />
                                <RadioButton
                                  label="No"
                                  className=""
                                  id="claimNoConfirm"
                                  groupName="isClaimPrevPolicy"
                                  value="No"
                                  changeEvent={handleInputCarDetails}
                                  isChecked={
                                    carDetails?.isClaimPrevPolicy === false
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )
                  }
                  <div className="cnfrmDetails-inputGrp-container">
                    {
                      (carDetails?.prevPolicyType === "Bundled (3Y TP + 1Y OD)" ||
                        carDetails?.prevPolicyType === "Bundled") && carDetails?.PolicyTypeValue === "Own Damage" && (
                        <>
                          <label htmlFor="" className="label-popUp">
                            Previous Policy OD Expiry Date
                          </label>
                          <div className="calender-cnfrm-popup">
                            <CustomDateField
                              className="pForm_datePicker pForm_datePicker-tab "
                              btnId="policyOdExpDateBtn"
                              textId="policyOdExpDateTxt"
                              imgId="policyOdExpDateImg"
                              dateValue={carDetails?.prevPolicyODexpDate}
                              calendarReveal={ShowOdExpDateCalendar}
                              setCalendarReveal={setShowOdExpDateCalendar}
                            />
                          </div>
                          <p className="od-msg" >(For bundled policies (1-yr OD + 3-yr TP), check the OD expiry date)</p>
                        </>
                      )
                    }
                  </div>
                </div>
              )
            }

            {businessType !== NEW && <div className="hr-popUp"></div>}

            {(carDetails?.PolicyTypeValue === "Third Party" ||
              carDetails?.prevPolicyType === "Third Party" ||
              passVehicleData.prevPolicyExpLastMonth === true) ? (
              ""
            ) :
              (
                <>
                  {businessType !== NEW && (
                    <>
                      {!carDetails?.isClaimPrevPolicy ? (
                        <div className="form-cnfrmDetails-inputGrp">
                          <div className="cnfrmDetails-inputGrp-container">
                            <label htmlFor="" className="label-popUp">
                              Previous NCB
                            </label>
                            <div className="ncb-btn-editform">
                              <Botton
                                text="0%"
                                className={
                                  carDetails.prevNcb == "0"
                                    ? "activeNcbBtn ncbBtn-editForm"
                                    : "ncbBtn-editForm"
                                }
                                event={(e) => {
                                  setIsApiLoaded(true);
                                  handleInputCarDetails(0, "prevNcb", "20");
                                }}
                              />
                              <Botton
                                text="20%"
                                className={
                                  carDetails.prevNcb == "20"
                                    ? "activeNcbBtn ncbBtn-editForm"
                                    : "ncbBtn-editForm"
                                }
                                event={(e) => {
                                  setIsApiLoaded(true);
                                  handleInputCarDetails("20", "prevNcb", "25");
                                }}
                              />
                              <Botton
                                text="25%"
                                className={
                                  carDetails.prevNcb == "25"
                                    ? "activeNcbBtn ncbBtn-editForm"
                                    : "ncbBtn-editForm"
                                }
                                event={(e) => {
                                  setIsApiLoaded(true);
                                  handleInputCarDetails("25", "prevNcb", "35");
                                }}
                              />
                              <Botton
                                text="35%"
                                className={
                                  carDetails.prevNcb == 35
                                    ? "activeNcbBtn ncbBtn-editForm"
                                    : "ncbBtn-editForm"
                                }
                                event={(e) => {
                                  setIsApiLoaded(true);
                                  handleInputCarDetails("35", "prevNcb", "45");
                                }}
                              />
                              <Botton
                                text="45%"
                                className={
                                  carDetails.prevNcb == 45
                                    ? "activeNcbBtn ncbBtn-editForm"
                                    : "ncbBtn-editForm"
                                }
                                event={(e) => {
                                  setIsApiLoaded(true);
                                  handleInputCarDetails("45", "prevNcb", "50");
                                }}
                              />
                              <Botton
                                text="50%"
                                className={
                                  carDetails.prevNcb == 50
                                    ? "activeNcbBtn ncbBtn-editForm"
                                    : "ncbBtn-editForm"
                                }
                                event={(e) => {
                                  setIsApiLoaded(true);
                                  handleInputCarDetails("50", "prevNcb", "50");
                                }}
                                name="prevNcb"
                                value="50"
                              />
                            </div>
                          </div>
                          <div className="cnfrmDetails-inputGrp-container">
                            <label htmlFor="" className="label-popUp">
                              New NCB
                            </label>
                            <div className="ncb-btn-editform">
                              <Botton
                                text={carDetails?.newNcb + "%"}
                                className="activeNcbBtn ncbBtn-editForm"
                                name="newNcb"
                                value={carDetails?.newNcb}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </>
              )
            }

            <div className="checkbox_container checkbox_container-popUp " id="checkbox-declaration" >
              <CheckBox
                label="I declare the information provided above are right as per my knowledge. And there won’t be any objection 
              during the claim process."
                value={CheckboxList.declaration}
                name="declaration"
                className="small_text small_textModal checkBox-label"
                changeEvent={(e) => handleClick("declaration")}
                checked={CheckboxList.declaration === true ? true : false}
              />
            </div>

            {!showDeclaration && (
              <p className="err-msg modified-err-msg">
                *Please accept the declaration before proceeding.
              </p>
            )}
          </div>

          <div className="btnContainer_pform btnContainer_pform-popUp btnContainer_pform-cnfrmDetails btnContainer_pform-cnfrmDetails-Mobile ">
            <Botton
              text="Back"
              className="button btn_pForm  secondry_btn"
              event={closeModal}
            />
            {
              !isApiLoaded ? (
                <Botton
                  text="Proceed"
                  className="button btn_pForm  "
                  isDisabledButton={isApiLoaded}
                  event={(e) => handleRoutePform(e)}
                />
              ) : (
                <ButtonLoader className="btn_pForm" />
              )
            }
          </div>

        </section >
        <div className="btnContainer_pform btnContainer_pform-popUp btnContainer_pform-cnfrmDetails btnContainer_pform-cnfrmDetails-deskTop ">
          <Botton
            text="Back"
            className="button btn_pForm  secondry_btn"
            event={closeModal}
          />
          {
            !isApiLoaded ? (
              <Botton
                text="Proceed"
                className="button btn_pForm  "
                isDisabledButton={isApiLoaded}
                event={(e) => handleRoutePform(e)}
              />
            ) : (
              <ButtonLoader className="btn_pForm" />
            )
          }
        </div>
      </div >
    </>
  );
};

const yearListRange = range(1990, getYear(new Date()) + 0, 1);

const yearList = yearListRange.map((data, key) => ({
  label: data,
  value: data,
}));

yearList.reverse();

let monthList = [
  { value: "January", label: "January" },
  { value: "February", label: "February" },
  { value: "March", label: "March" },
  { value: "April", label: "April" },
  { value: "May", label: "May" },
  { value: "June", label: "June" },
  { value: "July", label: "July" },
  { value: "August", label: "August" },
  { value: "September", label: "September" },
  { value: "October", label: "October" },
  { value: "November", label: "November" },
  { value: "December", label: "December" },
];

export default ConfirmDetails;