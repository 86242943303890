import React, { useRef, useEffect } from "react";
import Calendar from "react-multi-date-picker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import dayjs from "dayjs";
import "dayjs/locale/en";
import "./Calendar.css";
import close from "../../../Assets/Images/Close.svg";
import Botton from "../../Button/Button";
import arrow from "../../../Assets/Images/arrRight.svg"

const CustomCalendar = (props) => {
    const {
        name,
        btnId,
        imgId,
        value,
        textId,
        minDate,
        onClose,
        maxDate,
        headText,
        revealState,
        calendarType,
        placeholderId,
        setRevealState,
        saveCalendarValue,
        cancelCalendarValue,
        handleCopiedDetails,
        getAlternateExpOption,
        handleInputCarDetails,
        updateAlternateOptions,
        ShowAlternateExpOptions,
        preventhandleClickOutside,
        setShowAlternateExpOptions,
    } = props;

    const calendarContainerRef = useRef(null);

    const handleClickOutside = (event) => {
        if (
            event.target.id === btnId ||
            event.target.id === textId ||
            event.target.id === imgId ||
            event.target.id === placeholderId
        ) {
            return;
        }

        if (
            calendarContainerRef.current &&
            !calendarContainerRef.current.contains(event.target)
        ) {
            cancelCalendarValue(name);
        }
    };

    useEffect(() => {
        if (!preventhandleClickOutside) {
            document.addEventListener("click", handleClickOutside);
        }
        return () => {
            if (!preventhandleClickOutside) {
                document.removeEventListener("click", handleClickOutside);
            }
        };
    }, []);

    let alterateOptionsForExp = [
        {
            id: 1,
            option: "Policy has not expired yet." // assume,  it will expire after 1 week.
        },
        {
            id: 2,
            option: "Policy expired in last 90 days." // assume, it has been expired 60 days before. 
        },
        {
            id: 3,
            option: "Policy expired more than 90 days." // assume, it has been expired 90 days before. 
        },
        {
            id: 4,
            option: "I purchased a used car." // assume, it has been expired 90 days before. 
        },
    ];

    return (
        <>
            <div
                className="edit-tabs-container-mob-height-copy edit-tabs-container-mob-height-calendar "
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
            >
                {
                    !ShowAlternateExpOptions ? (
                        <div className="calendar-container" ref={calendarContainerRef}>
                            <div className="calendar-container-head">
                                <p>{headText}</p>
                                <img
                                    src={close}
                                    alt=""
                                    srcSet=""
                                    onClick={(e) => cancelCalendarValue(name)}
                                />
                            </div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateCalendar
                                    value={value ? dayjs(value) : dayjs(new Date())}
                                    onChange={(newValue) =>
                                        handleCopiedDetails(new Date(newValue ? newValue : value), name, "")
                                    }
                                    views={['year', 'month', 'day']}
                                    maxDate={maxDate ? dayjs(maxDate) : ""}
                                    minDate={minDate ? dayjs(minDate) : ""}
                                />
                            </LocalizationProvider>
                            <div className="calendar-btn-container">
                                <Botton
                                    text="Cancel"
                                    className="secondry_btn calendar-btn"
                                    event={(e) => cancelCalendarValue(name)}
                                />
                                <Botton
                                    text="Save"
                                    className="button calendar-btn"
                                    event={(e) => saveCalendarValue(name)}
                                />
                            </div>
                            {(calendarType === "expDate" || calendarType === "expDateOd") && (
                                <p
                                    className="footer_hyper_link underline_text calendarTxt-notSure"
                                    onClick={(e) =>
                                        setShowAlternateExpOptions(!ShowAlternateExpOptions)
                                    }
                                >
                                    Don't Know policy expiry date?
                                </p>
                            )}
                        </div>
                    ) : (
                        <div className="calendar-container" ref={calendarContainerRef}>
                            <div className="calendar-container-head calendar-container-head-noBorder">
                                <p>Not sure about the expiry date</p>
                                <img
                                    src={close}
                                    alt=""
                                    srcSet=""
                                    onClick={(e) => cancelCalendarValue(name)}
                                />
                            </div>
                            <div className="alternate-options-expiry">
                                <p>Tell us when did your policy expire?</p>
                                <div className="seprator-calendar"></div>
                                {
                                    alterateOptionsForExp.map((data, key) => (
                                        <div key={key} className="tab-alternate-options-exp" onClick={(e) => getAlternateExpOption(data.option, calendarType)} >
                                            <p>{data.option}</p>
                                            <img src={arrow} alt="" srcset="" />
                                        </div>
                                    ))
                                }
                            </div>
                            {(calendarType === "expDate" || calendarType === "expDateOd") && (
                                <p
                                    className="footer_hyper_link underline_text calendarTxt-notSure"
                                    onClick={(e) =>
                                        setShowAlternateExpOptions(!ShowAlternateExpOptions)
                                    }
                                >
                                    I have my policy's expiration date.
                                </p>
                            )}
                        </div>
                    )
                }
            </div>
        </>
    );
};

{/* <div style={{ display: 'flex' }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateCalendar
                                        value={value ? dayjs(value) : dayjs(new Date())}
                                        onChange={(newValue) =>
                                            handleCopiedDetails(new Date(newValue ? newValue : value), name, "")
                                        }
                                        maxDate={maxDate ? dayjs(maxDate) : ""}
                                        minDate={minDate ? dayjs(minDate) : ""}
                                    />
                                </LocalizationProvider>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateCalendar
                                        // value={value ? dayjs(value).add(1, 'month') : dayjs(new Date()).add(1, 'month')}
                                        onChange={(newValue) =>
                                            handleCopiedDetails(new Date(newValue ? newValue : value), name, "")
                                        }
                                        maxDate={maxDate ? dayjs(maxDate).add(1, 'month') : ""}
                                        minDate={minDate ? dayjs(minDate).add(1, 'month') : ""}
                                    />
                                </LocalizationProvider>
                            </div> */}

export default CustomCalendar;
