import React, { useEffect } from "react";
import CheckBox from "../Checkbox/CheckBox";
import ToolTip from "./ToolTip";
import InputFields from "../InputFields/InputFields";
import Botton from "../Button/Button";
import Modal from "react-modal";

const AddonContainer = (props) => {
  const {
    dataList,
    ShowAccesoriesField,
    ShowPcSumInsuredField,
    handleApplyClick,
    setElectricalAmt,
    ElectricalAmt,
    setNonElectricalAmt,
    NonElectricalAmt,
    setPassengerCoverAmt,
    PassengerCoverAmt,
    errors,
    handleCancelPassenger,
    cancelAccessoriesField,
  } = props;

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      // background: "red",
    },
  };

  Modal.setAppElement("#root");

  return (
    <>
      <div className={`addonContainer__ ${ShowPcSumInsuredField || ShowAccesoriesField ? 'disable-container' : ''}`}>
        {dataList.map((item, key) => (
          <div key={key}> {item} </div>
        ))}

        {/* PASSENGER POP UP */}

        <Modal
          isOpen={ShowPcSumInsuredField}
          style={customStyles}
          contentLabel="Example Modal"
          id=""
        >
          <div className="accesoriesContainer-fields sumInsuredContainer-fields">
            <div className="sum-insured-container">
              <p className="fields-accesories-title">Select Passenger Coverage</p>
              <div className="sum-insured-btns">
                <Botton
                  text="₹ 10000"
                  value="10000"
                  name="pcCoverSumInsured"
                  className={
                    PassengerCoverAmt === "10000"
                      ? "btn-sumInsurd btn-sumInsurd-active"
                      : "btn-sumInsurd"
                  }
                  event={(e) => setPassengerCoverAmt("10000")}
                />
                <Botton
                  text="₹ 50000"
                  value="50000"
                  name="pcCoverSumInsured"
                  className={
                    PassengerCoverAmt === "50000"
                      ? "btn-sumInsurd btn-sumInsurd-active"
                      : "btn-sumInsurd"
                  }
                  event={(e) => setPassengerCoverAmt("50000")}
                />
                <Botton
                  text="₹ 100000"
                  value="100000"
                  name="pcCoverSumInsured"
                  className={
                    PassengerCoverAmt === "100000"
                      ? "btn-sumInsurd btn-sumInsurd-active"
                      : "btn-sumInsurd"
                  }
                  event={(e) => setPassengerCoverAmt("100000")}
                />
                <Botton
                  text="₹ 200000"
                  value="200000"
                  name="pcCoverSumInsured"
                  className={
                    PassengerCoverAmt === "200000"
                      ? "btn-sumInsurd btn-sumInsurd-active"
                      : "btn-sumInsurd"
                  }
                  event={(e) => setPassengerCoverAmt("200000")}
                />
              </div>
              {errors.passengerCover ? (
                <p className="err-msg">{errors.passengerCover}</p>
              ) : (
                ""
              )}
            </div>

            <div className="fields-accesories-btn-container">
              <Botton
                text="Cancel"
                name="pcCoverSumInsured"
                className="btn-sumInsurd-apply secondry_btn"
                event={handleCancelPassenger}
              />
              <Botton
                text="Apply"
                name="pcCoverSumInsured"
                className="btn-sumInsurd-apply button"
                event={(e) => handleApplyClick(e, "PassengerCover")}
              />
            </div>
          </div>
        </Modal>

        {/* ACCESSORIES POP UP */}

        <Modal
          isOpen={ShowAccesoriesField}
          style={customStyles}
          contentLabel="Example Modal"
          id=""
        >
          <div className="accesoriesContainer-fields">
            <div className="fields-accesories">
              <p className="fields-accesories-title">
                Enter electrical accessories value
              </p>
              <div className="inputGroup-accesories">
                <InputFields
                  placeholder="Enter Amount"
                  type="number"
                  value={ElectricalAmt}
                  name="electricalAccesoriesCopy"
                  onChangeEvent={(e) => setElectricalAmt(e.target.value)}
                />
                <p>
                  Cover your car's extra fitted electrical accessories like
                  stereo, fog lamps, buzzers etc
                </p>
              </div>
            </div>

            <div className="fields-accesories">
              <p className="fields-accesories-title">
                Enter non-electrical accessories value
              </p>
              <div className="inputGroup-accesories">
                <InputFields
                  placeholder="Enter Amount"
                  type="number"
                  value={NonElectricalAmt}
                  name="nonElectricalAccesoriesCopy"
                  onChangeEvent={(e) => setNonElectricalAmt(e.target.value)}
                />
                <p>
                  Cover your car's extra fitted non-electrical accessories like
                  roof rack, wheel cover, etc
                </p>
              </div>
              {errors.accesories ? (
                <p className="err-msg">{errors.accesories}</p>
              ) : (
                ""
              )}
            </div>

            <div className="fields-accesories-btn-container fields-accesories-btn-container-width">
              <Botton
                text="Cancel"
                className="button btn-accesories secondry_btn"
                event={cancelAccessoriesField}
              />
              <Botton
                text="Apply"
                className="button btn-accesories"
                event={(e) => handleApplyClick(e, "Accessories")}
              />
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default AddonContainer;
