import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import CheckBox from "../Checkbox/CheckBox";
import InputFields from "../InputFields/InputFields";
import Calender from "../InputFields/Calender";
import Botton from "../Button/Button";
import { useLocation, Link, useNavigate, useHistory } from "react-router-dom";
import { SBI, ICICI, DIGIT, ZUNO, ACKO, SHREERAM } from "../../utils/commonUtils";
import backIcon from "../../Assets/Images/backIcon.svg";
import DropDown from "../DropDown/DropDown";
import grayArrow from "../../Assets/Images/grayArrow.svg"
import RadioButton from "../RadioButton/RadioButton";
import AckoCreative from "../Widgets/AckoCreative";
import CustomDateField from "../InputFields/CustomDateField/CustomDateField";
import CustomCalendar from "../InputFields/Calendar/Calendar";
import ButtonLoader from "../Pages/Loader/Button Loader/ButtonLoader";

const ProposalOne = (props) => {
  const {
    emailValue,
    phoneValue,
    panValue,
    gstValue,
    HandleInputChange,
    handleSubmitForm,
    ownerValue,
    compNameValue,
    formData,
    Edit,
    errors,
    companyName,
    sbiAutoKycData,
    dateValue,
    isPaCoverChecked,
    ShowNilDepState,
    handleDobClone,
    saveCalendarValue,
    cancelCalendarValue,
    ShowDobCalendar,
    setShowDobCalendar,
    DobClone,
  } = props;

  const [ParamaterForRoute, setParamaterForRoute] = useState("ownerDetail");
  const [ShowPaExclusionReason, setShowPaExclusionReason] = useState(false);
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");

  useEffect(() => {
    if (formData.carOwner == "isCompany") {
      setParamaterForRoute("PersonalDetails");
    } else {
      setParamaterForRoute("ownerDetail");
    }
  }, [formData.carOwner]);

  const navigate = useNavigate();

  const paExclusionReasons = [
    { label: "Name of Company/Institution", value: "Name of Company/Institution" },
    { label: "Not have eff. DL With Decl. Letter", value: "Not have eff. DL With Decl. Letter" },
    { label: "Already CPA Policy Exists", value: "Already CPA Policy Exists" },
  ]

  const handlePopUps = () => {
    if (ShowPaExclusionReason) {
      setShowPaExclusionReason(false);
    }
  };

  let vehicleType = localStorage.getItem("vehicleType");

  let transactionStatus = useSelector((state) => {
    return state.getTransactionStatus;
  });

  return (
    <>
      <div>
        <div className="titleContainer  ">
          <p>Owner Details</p>
          <p>All fields are mandatory</p>
        </div>
        <form className="formSection-pform" action="" onClick={handlePopUps}>
          {/* blank-checkBox-div */}
          <div className="checkBox_form">
            <CheckBox
              label="Vehicle Is Owned By Company ?"
              className="small_text small_textModal checkBox-label"
              classNameTwo=""
              value=""
              changeEvent={(e) => HandleInputChange(e, "carOwner")}
              name="carOwner"
              id="carOwner"
              checked={formData?.carOwner === "isCompany" ? true : false}
            />
          </div>
          {ownerValue === "individualOwner" ? (
            <div className="input_group_pform">
              <div className="inputGropOne_form inputGrop ">
                <InputFields
                  value={formData.firstName}
                  placeholder="First Name"
                  id="firstName"
                  type="text"
                  name="firstName"
                  errState={errors?.firstName ? true : false}
                  errMsg={errors?.firstName}
                  errClassName="input-err"
                  onChangeEvent={(e) => HandleInputChange(e)}
                  isDisabled={
                    companyName === SBI &&
                      sbiAutoKycData?.kycStatus === "CKYCSuccess"
                      ? true
                      : false
                  }
                />
              </div>
              <div className="inputGropOne_form inputGrop ">
                <InputFields
                  value={formData.lastName}
                  placeholder="Last Name"
                  id="lastName"
                  type="text"
                  name="lastName"
                  errState={errors?.lastName ? true : false}
                  errMsg={errors?.lastName}
                  errClassName="input-err"
                  onChangeEvent={(e) => HandleInputChange(e)}
                  isDisabled={
                    companyName === SBI &&
                      sbiAutoKycData?.kycStatus === "CKYCSuccess"
                      ? true
                      : false
                  }
                />
              </div>
            </div>
          ) : (
            <div className="input_group_pform">
              <div className="inputGropOne_form inputGrop ">
                <InputFields
                  value={compNameValue}
                  placeholder="Company Name"
                  id="companyName"
                  type="text"
                  name="companyName"
                  errState={errors?.companyName ? true : false}
                  errMsg={errors?.companyName}
                  errClassName="input-err"
                  onChangeEvent={(e) => HandleInputChange(e)}
                  isDisabled={
                    companyName === SBI &&
                      sbiAutoKycData?.kycStatus === "CKYCSuccess"
                      ? true
                      : false
                  }
                />
              </div>
              <div className="inputGropOne_form inputGrop ">
                <InputFields
                  value={phoneValue}
                  placeholder="Phone Number"
                  id="phoneNumber"
                  type="number"
                  name="phoneNumber"
                  errState={errors?.phoneNumber ? true : false}
                  errMsg={errors?.phoneNumber}
                  errClassName="input-err"
                  onChangeEvent={(e) => HandleInputChange(e)}
                  isDisabled={
                    companyName === SBI &&
                      sbiAutoKycData?.kycStatus === "CKYCSuccess"
                      ? true
                      : false
                  }
                />
              </div>
            </div>
          )}

          <div className="input_group_pform">
            <div className="inputGropOne_form inputGrop ">
              <InputFields
                value={emailValue}
                placeholder="Email ID"
                id="email"
                type="email"
                name="email"
                errState={errors?.email ? true : false}
                errMsg={errors?.email}
                errClassName="input-err"
                onChangeEvent={(e) => HandleInputChange(e)}
                isDisabled={
                  companyName === SBI &&
                    sbiAutoKycData?.kycStatus === "CKYCSuccess" &&
                    sbiAutoKycData?.email !== ""
                    ? true
                    : false
                }
              />
            </div>
            <div className="inputGropOne_form inputGrop ">
              {ownerValue !== "individualOwner" ? (
                companyName === ACKO ?
                  <div className="inputGropOne_form inputGrop "></div>
                  :
                  <InputFields
                    value={panValue}
                    placeholder={companyName === ACKO ? "PAN Number (optional)" : "PAN Number"}
                    id="kycPanNumber"
                    type="text"
                    name="kycPanNumber"
                    className="uppercaseText"
                    onChangeEvent={(e) => HandleInputChange(e)}
                    errState={errors?.kycPanNumber ? true : false}
                    errMsg={errors?.kycPanNumber}
                    errClassName="input-err"
                  />
              ) : (
                <InputFields
                  value={phoneValue}
                  placeholder="Phone Number"
                  id="phoneNumber"
                  type="number"
                  name="phoneNumber"
                  errState={errors?.phoneNumber ? true : false}
                  errMsg={errors?.phoneNumber}
                  errClassName="input-err"
                  onChangeEvent={(e) => HandleInputChange(e)}
                  isDisabled={
                    companyName === SBI &&
                      sbiAutoKycData?.kycStatus === "CKYCSuccess"
                      ? true
                      : false
                  }
                />
              )}
            </div>
          </div>

          {ownerValue === "individualOwner" && companyName != ACKO && (
            <div className="input_group_pform">
              <div className="inputGropOne_form inputGrop ">
                <InputFields
                  value={gstValue}
                  placeholder="Gst Number (optional)"
                  id="gstNumber"
                  type="text"
                  name="gstNumber"
                  className="uppercaseText"
                  errState={errors?.gstNumber ? true : false}
                  errMsg={errors?.gstNumber}
                  errClassName="input-err"
                  onChangeEvent={(e) => HandleInputChange(e)}
                />
              </div>
              {companyName != ACKO ?
                <div className="inputGropOne_form inputGrop ">
                  <InputFields
                    value={panValue}
                    placeholder="PAN Number"
                    id="kycPanNumber"
                    type="text"
                    name="kycPanNumber"
                    className="uppercaseText"
                    onChangeEvent={(e) => HandleInputChange(e)}
                    errState={errors?.kycPanNumber ? true : false}
                    errMsg={errors?.kycPanNumber}
                    errClassName="input-err"
                  />
                </div>
                :
                <div className="inputGropOne_form inputGrop ">
                </div>
              }
            </div>
          )}

          {ownerValue !== "individualOwner" && companyName != ACKO && (
            <div className="input_group_pform">
              <div className="inputGropOne_form inputGrop ">
                <InputFields
                  value={gstValue}
                  placeholder="Gst Number (optional)"
                  id="gstNumber"
                  type="text"
                  name="gstNumber"
                  className="uppercaseText"
                  errState={errors?.gstNumber ? true : false}
                  errMsg={errors?.gstNumber}
                  errClassName="input-err"
                  onChangeEvent={(e) => HandleInputChange(e)}
                />
              </div>
              {/* <div></div> */}
              {
                (companyName == SHREERAM && !isPaCoverChecked) && (
                  <div className="inputGropOne_form inputGrop ">
                    <DropDown
                      dropDownMenu={paExclusionReasons}
                      value={formData?.paExclusionReason}
                      name="paExclusionReason"
                      classNameForHead="headTxtClassPform"
                      Head="Select PA Exclusion Reason"
                      label="PA Cover Reason"
                      arrowInGray={grayArrow}
                      inputId="paExclusionReason"
                      clickEvent={(e) => console.log(e)}
                      headClick={(e) =>
                        setShowPaExclusionReason(!ShowPaExclusionReason)
                      }
                      revealOptions={ShowPaExclusionReason}
                      afterTrueClass="dropDown_container NoBorderRadius"
                      className="dropDown_container"
                      valueSelectionEvent={HandleInputChange}
                      revealOptionsClasses=" dropDownOptions showdropDownOptions "
                      classNameTwo="dropDownOptions"
                      dropDownHeadClass="dropDownHead"
                      idForHead="relHead"
                      idForHeadTxt="relHeadTxt"
                      idForArrow="relHeadArrow"
                      dropdownId="relListDp"
                      idForParentObj="relParentDp"
                      errState={errors?.paExclusionReason ? true : false}
                      errMsg={errors?.paExclusionReason}
                      errClassName="input-err"
                    />
                  </div>
                )
              }
              {companyName == ICICI || companyName == DIGIT || companyName == ZUNO || companyName == SBI ? (
                <div className="inputGropOne_form inputGrop ">
                  <label htmlFor="dobPform">
                    {/* <Calender
                      className="pForm_datePicker"
                      placeholder="Date of Incorporation (DD MM YYYY)"
                      name="dob"
                      id="dobPform"
                      value={dateValue}
                      calenderType="dob"
                      errState={errors?.dob ? true : false}
                      errMsg={errors?.dob}
                      errClassName={errors?.dob ? "input-err" : ""}
                      label="Date Of Incorporation"
                      changeEvent={(e) => HandleInputChange(e)}
                    /> */}
                    <CustomDateField
                      className="pForm_datePicker pForm_datePicker-tab "
                      btnId="doiCopyBtn"
                      textId="doiCopyTxt"
                      imgId="doiCopyImg"
                      dateValue={DobClone}
                      placeholder="Date of Incorporation"
                      placeholderId="doiPlaceholderId"
                      calendarReveal={ShowDobCalendar}
                      setCalendarReveal={setShowDobCalendar}
                    />
                  </label>
                </div>
              ) : (
                <>
                  {
                    (companyName == SHREERAM && isPaCoverChecked) && (
                      <div className="inputGropOne_form inputGrop "></div>
                    )
                  }
                </>
              )}
            </div>
          )}

          {
            (ownerValue == "individualOwner" && companyName == SHREERAM && !isPaCoverChecked) && (
              <div className="input_group_pform">
                <div className="inputGropOne_form inputGrop ">
                  <DropDown
                    dropDownMenu={paExclusionReasons}
                    value={formData?.paExclusionReason}
                    name="paExclusionReason"
                    classNameForHead="headTxtClassPform"
                    Head="Select PA Exclusion Reason"
                    label="PA Exclusion"
                    arrowInGray={grayArrow}
                    inputId="paExclusionReason"
                    clickEvent={(e) => console.log(e)}
                    headClick={(e) =>
                      setShowPaExclusionReason(!ShowPaExclusionReason)
                    }
                    revealOptions={ShowPaExclusionReason}
                    afterTrueClass="dropDown_container NoBorderRadius"
                    className="dropDown_container"
                    valueSelectionEvent={HandleInputChange}
                    revealOptionsClasses=" dropDownOptions showdropDownOptions "
                    classNameTwo="dropDownOptions"
                    dropDownHeadClass="dropDownHead"
                    idForHead="relHead"
                    idForHeadTxt="relHeadTxt"
                    idForArrow="relHeadArrow"
                    dropdownId="relListDp"
                    idForParentObj="relParentDp"
                    errState={errors?.paExclusionReason ? true : false}
                    errMsg={errors?.paExclusionReason}
                    errClassName="input-err"
                  />
                </div>
                <div className="inputGropOne_form inputGrop inputGrop_caseTwo ">
                  <p className="customLabel_pform">Nil Depreciation in previous policy?</p>
                  <div className="radioContainer-pform">
                    <RadioButton
                      label="Yes"
                      className=""
                      id="YesZerDep"
                      groupName="isNilDepInPrevPolicy"
                      value={true}
                      changeEvent={(e) => HandleInputChange(e)}
                      isChecked={formData?.isNilDepInPrevPolicy === "true" ? true : false}
                    />
                    <RadioButton
                      label="No"
                      className=""
                      id="NoZerDep"
                      groupName="isNilDepInPrevPolicy"
                      value={false}
                      changeEvent={(e) => HandleInputChange(e)}
                      isChecked={formData?.isNilDepInPrevPolicy === "false" ? true : false}
                    />
                  </div>
                  <p className="err-msg">{errors?.isNilDepInPrevPolicy ? errors.isNilDepInPrevPolicy : ""}</p>
                  <p className="err-msg" >
                    {(ShowNilDepState != true && ShowNilDepState != null) && ("if you have not taken zero deprecitation in previous policy, you can't include zero depreciation(engine protection, consumables) in your current policy.")}
                  </p>
                </div>
              </div>
            )
          }

          {
            (ownerValue !== "individualOwner" && companyName == SHREERAM) && (
              <div className="input_group_pform">
                <div className="inputGropOne_form inputGrop inputGrop_caseTwo ">
                  <p className="customLabel_pform">Nil Depreciation in previous policy?</p>
                  <div className="radioContainer-pform">
                    <RadioButton
                      label="Yes"
                      className=""
                      id="YesZerDep"
                      groupName="isNilDepInPrevPolicy"
                      value={true}
                      changeEvent={(e) => HandleInputChange(e)}
                      isChecked={formData?.isNilDepInPrevPolicy === "true" ? true : false}
                    />
                    <RadioButton
                      label="No"
                      className=""
                      id="NoZerDep"
                      groupName="isNilDepInPrevPolicy"
                      value={false}
                      changeEvent={(e) => HandleInputChange(e)}
                      isChecked={formData?.isNilDepInPrevPolicy === "false" ? true : false}
                    />
                  </div>
                  <p className="err-msg">{errors?.isNilDepInPrevPolicy ? errors.isNilDepInPrevPolicy : ""}</p>
                  <p className="err-msg">
                    {(ShowNilDepState != true && ShowNilDepState != null) && ("if you have not taken zero deprecitation in previous policy, you can't include zero depreciation(engine protection, consumables) in your current policy.")}
                  </p>
                </div>
                <div className="inputGropOne_form inputGrop inputGrop_caseTwo ">
                </div>
              </div>
            )
          }

          <section className="btnContainer_pform">
            <Botton
              text="Cancel"
              className="button btn_pForm  secondry_btn"
              event={() => navigate(vehicleType == "2W" ? "/View-Plans-Bike?id=" + id : "/View-Plans?id=" + id)}
            />

            {transactionStatus?.isLoading ?
              <ButtonLoader className="btn_pForm" />
              : <Botton
                text="Save"
                className="button btn_pForm"
                event={(e) =>
                  handleSubmitForm(Edit ? "kycDetails" : ParamaterForRoute, e)
                }
              />}
          </section>
        </form>

        {
          ShowDobCalendar && (
            <CustomCalendar
              value={DobClone}
              headText="Enter Date of Incorporation"
              name="doiCopy"
              btnId="doiCopyBtn"
              textId="doiCopyTxt"
              imgId="doiCopyImg"
              placeholderId="doiPlaceholderId"
              revealState={ShowDobCalendar}
              setRevealState={setShowDobCalendar}
              handleCopiedDetails={handleDobClone}
              cancelCalendarValue={cancelCalendarValue}
              saveCalendarValue={saveCalendarValue}
              onClose={(e) => setShowDobCalendar(false)}
            // maxDate={maxDobDate}
            />
          )
        }

        {
          companyName === "acko" && (
            <AckoCreative />
          )
        }

      </div>
    </>
  );
};

{/*
           <div className="kyc_info_container">
          <p className="title_kyc_pf">Update KYC</p>
          <p className="small_text">
            From January 1, 2023, KYC authentication has been a mandatory
            process to purchase insurance policies from all insurance providers
            in India.
          </p>
          <p className="title_kyc_pf">
            Your details are safe with us. You can complete your KYC in 1 min.
          </p>
        </div> 
        */}

export default ProposalOne;
