import {
  getRtoAction,
  getStateAction,
  getCityAction,
  getloanProvider,
  getInsurerList,
} from "../Actions/stateDetails";
import { createSlice } from "@reduxjs/toolkit";

export const getRtoReducer = createSlice({
  name: "rtoReducer",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getRtoAction.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getRtoAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(getRtoAction.rejected, (state, action) => {
      console.error("Error", action.payload);
      state.isError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    resetRtoAction: (state, action) => {
      state.data = undefined
    }
  }
});

export const { resetRtoAction } = getRtoReducer.actions;

export const getStateReducer = createSlice({
  name: "stateReducer",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getStateAction.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getStateAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(getStateAction.rejected, (state, action) => {
      console.error("Error", action.payload);
      state.isError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    resetStateAction: (state, action) => {
      state.data = undefined
    }
  }
});

export const { resetStateAction } = getStateReducer.actions;

export const getCityReducer = createSlice({
  name: "cityReducer",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getCityAction.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getCityAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(getCityAction.rejected, (state, action) => {
      console.error("Error", action.payload);
      state.isError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    resetCityAction: (state, action) => {
      state.data = undefined
    }
  }
});

export const { resetCityAction } = getCityReducer.actions;

export const getLoanCompanyReducer = createSlice({
  name: "loanCompanyReducer",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getloanProvider.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getloanProvider.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(getloanProvider.rejected, (state, action) => {
      console.error("Error", action.payload);
      state.isError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    resetLoanAction: (state, action) => {
      state.data = undefined
    }
  }
});

export const { resetLoanAction } = getLoanCompanyReducer.actions;

export const getInsurerReducer = createSlice({
  name: "insurerReducer",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getInsurerList.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getInsurerList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(getInsurerList.rejected, (state, action) => {
      console.error("Error", action.payload);
      state.isError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    resetInsurerAction: (state, action) => {
      state.data = undefined
    }
  }
});

export const { resetInsurerAction } = getInsurerReducer.actions;
